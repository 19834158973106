import { API } from 'aws-amplify'
import React, { useEffect, useReducer } from 'react'
import { Accordion, Flex, IconComponent, Tooltip } from '../../common'
import { fetchAdminConfig, postAdminConfig } from '../../../actions'
import {
  FlexUIConfigInitialState,
  FlexUIConfigReducer,
} from './../../../reducers/TTDReducers/flexUIConfigReducer'
import { defaultDropdown } from '../../../constants'
import { IDropdownOption } from '../../common/Atoms/Dropdown'
import {
  AudioConfig,
  ChromeConfig,
  FusionConfig,
  MonitoringConfig,
  TrainingConfig,
} from './configs/'
import { IAudioConfig, IConfigProps } from '../../../interfaces'
import UseDebounce from '../../../hooks/UseDebounce'
import { checkPermissions } from '../../../utils'

const FlexUI = ({ popToast }: { popToast: (msg: string) => void }) => {
  const [state, dispatch] = useReducer(FlexUIConfigReducer, FlexUIConfigInitialState)
  const permission = checkPermissions('flex', 'edit')
  const {
    audioOptions,
    audioConfig,
    monitorConfig,
    notificationConfig,
    timerConfig,
    fusionConfig,
  } = state
  state.permission = permission
  const debounceValue = UseDebounce(state, 1500)
  const getAdminConfig = async () => {
    try {
      let config = await fetchAdminConfig()
      const {
        flexConfig: {
          monitorConfig = {},
          timerConfig = {},
          audioConfig = {},
          notificationConfig = {},
        } = {},
      } = config
      const fusionConfig = config.flexConfig.monitorConfig.warnings.fusionEjection || {}
      const processAudioOptions = (audioConfig: IAudioConfig) => {
        let audioOptionsArr = []
        for (const option in audioConfig) {
          const audioOption = {
            display: option,
            id: option,
            value: option,
          }
          audioOptionsArr.push(audioOption)
        }
        return (
          audioOptionsArr.sort((a, b) => (a.display >= b.display ? 1 : -1)) || [defaultDropdown]
        )
      }
      const audioOptions: IDropdownOption[] = processAudioOptions(audioConfig)

      dispatch({ type: 'setMonitorConfig', payload: monitorConfig })
      dispatch({ type: 'setNotificationConfig', payload: notificationConfig })
      dispatch({ type: 'setTimerConfig', payload: timerConfig })
      dispatch({ type: 'setAudioConfig', payload: audioConfig })
      dispatch({ type: 'setFusionConfig', payload: fusionConfig })
      dispatch({ type: 'setAudioOptions', payload: audioOptions })
    } catch (err) {
      console.error('Error fetching adminConfig', err)
    }
  }

  useEffect(() => {
    getAdminConfig()
  }, [])

  useEffect(() => {
    const debounceCopy = { ...debounceValue }
    delete debounceCopy?.audioOptions
    ;(async () => {
      try {
        const apiName = 'ADMIN'
        const apiPath = '/admin'
        const bundlesParams: IConfigProps = {
          queryStringParameters: { table: 'AdminConfig', id: 'admin-config' },
          body: { set: debounceCopy.requestObj },
        }
        if (debounceCopy.permission) {
          await API.put(apiName, apiPath, bundlesParams)
        }
        return
      } catch (ex) {
        console.error('Error saving: ', ex)
        popToast('Error Saving.  See log')
        return
      } finally {
        if (debounceCopy.permission) {
          popToast('Successfully Saved')
        }
      }
    })()
  }, [debounceValue.requestObj])

  const renderConfig: Record<string, any> = {
    Audio: <AudioConfig config={audioConfig} />,
    Monitoring: <MonitoringConfig config={monitorConfig} dispatch={dispatch} popToast={popToast} />,
    'Chrome Notifications': (
      <ChromeConfig
        config={notificationConfig}
        audioConfig={audioConfig}
        audioOptions={audioOptions}
        dispatch={dispatch}
        popToast={popToast}
      />
    ),
    'Training Dialog': (
      <TrainingConfig
        config={timerConfig}
        audioOptions={audioOptions}
        audioConfig={audioConfig}
        dispatch={dispatch}
        popToast={popToast}
      />
    ),
    Fusion: (
      <FusionConfig
        config={fusionConfig}
        audioOptions={audioOptions}
        audioConfig={audioConfig}
        dispatch={dispatch}
        popToast={popToast}
      />
    ),
  }
  return (
    <React.Fragment>
      {['Audio', 'Monitoring', 'Chrome Notifications', 'Training Dialog', 'Fusion'].map((item) => (
        <Accordion
          label={item}
          key={item}
          tooltip={item === 'Audio' ? 'configuration.Flex.Audio' : null}
        >
          {renderConfig[item]}
        </Accordion>
      ))}
    </React.Fragment>
  )
}

export default FlexUI
