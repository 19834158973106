import React, { useState } from 'react'
import styled, { StyledComponent } from 'styled-components'
import { IconComponent } from '../IconComponent'
import CreateOutlined from '@material-ui/icons/CreateOutlined'
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'
import { LanguageOutlined, LanguageRounded } from '@material-ui/icons'
import { useSelector } from 'react-redux'
import themes from '../../../../themes/schema'

interface ITextField {
  // eslint-disable-next-line no-unused-vars
  saveChanges: (prevValue: string, newValue: string) => void
  // eslint-disable-next-line no-unused-vars
  deleteField: (name: string) => void
  type: string
  // style: CSSProperties
  className: string | undefined
  value: string
  isUpdating?: boolean
  link?: string
  config?: any
  width?: string
}

const TextField = ({
  width = '',
  link = '',
  value = '',
  saveChanges = () => {},
  deleteField = () => {},
  config = { global: true },
}: Partial<ITextField>) => {
  const [savedValue, setSavedValue] = useState(value)
  const [currentValue, setCurrentValue] = useState(value)
  const [isEditing, setIsEditing] = useState(false)
  const [isHovering, setIsHovering] = useState(false)
  const {
    userSettings: { darkMode },
  } = useSelector((state: any) => state?.user)
  const customDisplayType = darkMode ? 'darkMode' : 'originalFlavor'
  const getStyle: Record<string, any> = {
    color_darkMode: themes.darkMode.secondColor,
    color_originalFlavor: themes.originalFlavor.secondColor,
  }

  const handleSave = () => {
    setIsEditing(false)
    saveChanges(savedValue, currentValue)
    setSavedValue(currentValue)
  }

  return (
    <StyledInputContainer
      style={{ width }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <StyledTextField
        spellCheck="false"
        readOnly={!isEditing}
        value={currentValue}
        placeholder={currentValue}
        onChange={(e) => {
          setCurrentValue(e.target.value)
        }}
      />
      <StyledButtonContainer className={isHovering ? 'show' : ''}>
        {isEditing ? (
          <button className={'ttd-bare-button'} onClick={handleSave}>
            <IconComponent
              style={{
                cursor: 'pointer',
                display: 'inline-block',
              }}
              icon={'check_circle_outline'}
            />
          </button>
        ) : (
          <button className={'ttd-bare-button'} onClick={() => setIsEditing(true)}>
            <IconComponent
              style={{
                cursor: 'pointer',
                display: 'inline-block',
              }}
              icon={'create_outlined'}
            >
              <CreateOutlined />
            </IconComponent>
          </button>
        )}
        <button className={'ttd-bare-button'} onClick={() => deleteField(savedValue)}>
          <IconComponent
            style={{
              cursor: 'pointer',
              display: 'inline-block',
            }}
            icon={'closed_outlined'}
          >
            <CloseOutlinedIcon />
          </IconComponent>
        </button>
        {config.global && (
          <button className={'ttd-bare-button'} onClick={() => window.open(link)}>
            <IconComponent
              style={{
                cursor: 'pointer',
                display: 'inline-block',
              }}
              icon={'language'}
            />
          </button>
        )}
      </StyledButtonContainer>
    </StyledInputContainer>
  )
}

const StyledInputContainer: StyledComponent<'div', any, {}, never> = styled.div`
  &&& {
    margin-bottom: 2px;
    padding: 0;
    width: 20rem;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
  }
`

const StyledTextField: StyledComponent<'input', any, {}, never> = styled.input`
  &&& {
    margin: 0;
    height: auto;
    padding-bottom: 5px;
    margin-right: auto;
    border: none;
    /* flex: 1 1; */
    font-weight: bold;
    color: ${(props) => props.theme.secondColor};
    :focus {
      outline: none;
    }
    &:hover {
      border-bottom: 1px solid #4a0684;
    }
  }
`
const StyledButtonContainer: StyledComponent<'div', any, {}, never> = styled.div`
  &&& {
    padding: 0;
    display: flex;
    align-items: center;
    transition: all 0.5s;
    opacity: 0;
    margin-left: auto;
    &.show {
      opacity: 1;
    }
    *:focus {
      outline: none;
    }
  }
`
export default TextField
