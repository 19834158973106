import React, { createContext, useState } from 'react'

export const UIContext = createContext<any>([])
const UIProvider = ({ children }: { children: any }) => {
  const [showConfetti, setShowConfetti] = useState<boolean>(false)

  return (
    <UIContext.Provider value={{ showConfetti, setShowConfetti }}>{children}</UIContext.Provider>
  )
}

export default UIProvider
