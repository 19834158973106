import React, { useState, useEffect, useReducer } from 'react'
import { Flex, Tab } from '../common'
import { redisInitialState, redisReducer } from '../../reducers/TTDReducers/redisReducer'
import catgif from '../../assets/cat-f-u.gif'
import { IDropdownOption } from '../common/Atoms/Dropdown'
import ToggleSwitch from '../common/Atoms/ToggleSwitch'
import Redis from './Redis'
import { getAppConfig, updateAppConfig } from './devActions'

const Dev = () => {
  const [selectedTab, setSelectedTab] = useState<string>('redis')
  const [state, dispatch] = useReducer(redisReducer, redisInitialState)
  const [toggleUIAspectConfig, setToggleUIAspectConfig] = useState('Current')
  const [currentAspectURL, setCurrentAspectURL] = useState('')
  const [backupAspectURL, setBackupAspectURL] = useState('')
  const [lastUpdatedBy, setLastUpdatedBy] = useState('')
  const [lastUpdatedDateTime, setLastUpdatedDateTime] = useState('')
  const [shownGif, setShownGif] = useState<boolean>(false)

  useEffect(() => {
    if (!shownGif) {
      setShownGif(!shownGif)
      setTimeout(() => {
        return setShownGif(false)
      }, 1500)
    }
  }, [])

  useEffect(() => {
    getAppConfig()
      .then((data) => {
        const { Aspect } = data[0].globalConfig
        setCurrentAspectURL(Aspect?.API_BASE_URL)
        setBackupAspectURL(Aspect?.API_BACKUP_URL)
      })
      .catch((e) => {
        console.error(' Unable To Retrieve appConfig: ', e)
      })
  }, [])

  const tabList: IDropdownOption[] = [
    { value: 'redis', display: 'Redis', id: '0' },
    { value: 'aspect', display: 'Aspect', id: '1' },
  ]

  const renderSelectedTab = (selectedTab: string) => {
    switch (selectedTab) {
      case 'redis':
        return <Redis state={state} dispatch={dispatch} />
      case 'aspect':
        return (
          <div style={{ display: 'grid', placeItems: 'center', width: '98vw' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '2px',
                padding: '3px 40px 30px 40px',
                boxShadow: '4px 4px 10px grey',
                borderRadius: '5px',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <h4> Toggle Aspect Config </h4>
              </div>
              <div
                style={{
                  display: 'grid',
                  placeItems: 'center',
                  padding: '2em',
                  border: '1px solid grey',
                  borderRadius: '4px',
                }}
              >
                <ToggleSwitch
                  onClick={async () => {
                    //This is UI only
                    setToggleUIAspectConfig(
                      toggleUIAspectConfig !== 'Current' ? 'Current' : 'Rollback',
                    )

                    // we now want to swap current url with backup url;
                    await updateAppConfig(backupAspectURL, currentAspectURL)
                      .then((i) => {
                        const { response } = JSON.parse(i.results[0].body)
                        const { Attributes } = response
                        const { lastUpdatedDateTime, lastUpdatedBy } = Attributes
                        const prettyDate = new Date(lastUpdatedDateTime).toString().split('GMT')[0]
                        setLastUpdatedDateTime(prettyDate)
                        setLastUpdatedBy(lastUpdatedBy)
                      })
                      .catch((e) => {
                        console.error('ERR ON UPDATE ', e)
                      })

                    await getAppConfig()
                      .then((i) => {
                        const {
                          globalConfig: { Aspect },
                        } = i[0]
                        setCurrentAspectURL(Aspect?.API_BASE_URL)
                        setBackupAspectURL(Aspect?.API_BACKUP_URL)
                        console.log(' Retrieved app Config after update')
                      })
                      .catch((e) => {
                        console.error(' Cannot retrieve after update ', e)
                      })
                  }}
                  primaryLabel={'Current'}
                  secondaryLabel={'Rollback'}
                  selected={toggleUIAspectConfig}
                />
              </div>
              <div
                style={{
                  background: '#8223d2',
                  color: 'white',
                  padding: '15px',
                  borderRadius: '5px',
                }}
              >
                <p>
                  <strong>Time Last Updated: </strong> {lastUpdatedDateTime}{' '}
                </p>
                <p>
                  <strong>User Last Updated: </strong>
                  {lastUpdatedBy}
                </p>
                <hr />
                <p>
                  <strong>Backup:</strong> {backupAspectURL}{' '}
                </p>
                <p>
                  <strong>Current:</strong> {currentAspectURL}{' '}
                </p>
              </div>
            </div>
          </div>
        )
    }
  }
  return (
    <Flex config={['column']} style={{ height: '100%' }}>
      <div
        style={{
          height: 500,
          width: 500,
          position: 'absolute',
          right: shownGif ? -30 : -500,
          zIndex: 999,
        }}
      >
        <img src={catgif} alt="" />
      </div>
      <Flex config={['alignCenter']} style={{ marginBottom: 25 }}>
        {tabList.map(({ value, display }: IDropdownOption) => (
          <Tab
            onTabSelect={(tab) => {
              setSelectedTab(tab)
            }}
            key={value}
            display={display}
            value={value}
            isSelected={selectedTab === value}
          />
        ))}
      </Flex>
      <Flex style={{ height: '100%', overflow: 'scroll' }}>{renderSelectedTab(selectedTab)}</Flex>
    </Flex>
  )
}

export default Dev
