import React, { useEffect, useContext } from 'react'
import  { Amplify, Auth, Hub } from 'aws-amplify'
import { useDispatch, useSelector } from 'react-redux'
import { User } from '../../../data/datatypes'
import { setWithExpiry, getValueFromLocalStorage } from '../../../hooks/UseLocalStorage'
import config from '../../../config'
import { getEnvironment } from '../../../utils/applicationEnvironment'
import { queryAllRoles, getAssignedRole } from '../../../actions'
import {
  userAuthToUserSignIn,
  selectorToUserAuthenticated,
  adGroupTokenToSetAdGroup,
} from '../../../reducers/UserReducer'
import kibanaEventTrigger, {
  KIBANA_FAILED_STATE,
  KIBANA_SUCCESS_STATE,
  KIBANA_USER_LOG_IN_EVENT,
} from '../../../services/KibanaEvents'

const FIFTY_MIN_IN_MILLIS = 50 * 60 * 1000
const env = getEnvironment()
Amplify.configure(config.Amplify)
const AUTH_SIGN_IN = 'signIn',
  AUTH_SIGN_IN_FAILED = 'signIn_failure',
  TOKEN_REFRESH = 'tokenRefresh',
  TOKEN_REFRESH_ERROR = 'tokenRefresh_failure',
  amplifyKeyName = 'amplify-redirected-from-hosted-ui'

const kibanaInitial = {
  event: KIBANA_USER_LOG_IN_EVENT,
}
// awsUserToPayload :: AwsUser -> Payload
export const awsUserToPayload = (awsUser) => awsUser.signInUserSession.idToken.payload

// getUserId :: [Identity] -> UserId
export const getUserId = (identities) => identities[0].userId

// formatUser :: Payload -> UserId -> User
export const formatUser =
  ({ given_name, family_name, email, 'custom:empId': empId }) =>
  (userId, { userRoleName, userRoleDefinition }) =>
    new User(given_name, family_name, email, userId, empId, userRoleName, userRoleDefinition)

const getPermissions = async (empId) => {
  console.log(`file: index.js 💫 line 45 💫 getPermissions 💫 empId`, empId)
  const roles = await getAssignedRole(empId)
  const { userRoleName, userRoleDefinition } = roles.response[0] || {
    userRoleName: 'Error',
    userRoleDefinition: { route: ['error'], ability: 'read' },
  }
  return { userRoleName, userRoleDefinition }
}

const combineUserData = async (payload) =>
  formatUser(payload)(getUserId(payload.identities), await getPermissions(payload['custom:empId']))

// reformatUserData :: AwsUser -> User
const reformatUserData = (awsUser) => combineUserData(awsUserToPayload(awsUser))

const getUser = async () => {
  const awsUser = await Auth.currentAuthenticatedUser()
  console.log(`file: index.js 💫 line 61 💫 getUser 💫 awsUser`, awsUser)
  return reformatUserData(awsUser)
}

const signIn = (dispatch) => {
  dispatch(
    userAuthToUserSignIn({
      authenticated: true,
    }),
  )
}

const initialUserDataFetch = async (dispatch) => {
  const user = await getUser()
  dispatch(userAuthToUserSignIn({ user }))
  const orbitAuthKeyName = 'authenticated'
  setWithExpiry(orbitAuthKeyName, 'true', FIFTY_MIN_IN_MILLIS)

  const amplifyRedirectKey = getValueFromLocalStorage(amplifyKeyName, false)
  if (amplifyRedirectKey) {
    await kibanaEventTrigger({
      ...kibanaInitial,
      state: KIBANA_SUCCESS_STATE,
    })
    localStorage.removeItem(amplifyKeyName)
  }
}

const cognitoUserAuth = async (dispatch) => {
  try {
    const session = await Auth.currentSession()
    if (session) {
      const idToken = session.getIdToken()
      const { payload } = idToken
      let adGroups = payload['custom:groups'] || ''
      // if (['DEV', 'LOCAL'].includes(env)) {
      //    adGroups = [`whateveriwant`];
      // }
      const adGroupAction = adGroupTokenToSetAdGroup(adGroups)
      dispatch(adGroupAction)
    } else {
      console.log(`No current session`)
    }
  } catch (error) {
    console.log(`Auth.currentSession() Error`, error)
  }
}

const checkUserAuth = async (dispatch) => {
  const authenticated = getValueFromLocalStorage('authenticated', false),
    amplifyRedirectKey = getValueFromLocalStorage(amplifyKeyName, false)

  if (authenticated) {
    signIn(dispatch)
  } else if (!amplifyRedirectKey) {
    // TRIGGER USER SIGN IN
    await Auth.federatedSignIn({
      provider: config.Amplify.Auth.oauth.identityProvider,
    })
  }
}

const AuthHub = () => {
  const dispatch = useDispatch()
  const authenticated = useSelector(selectorToUserAuthenticated)

  // everytime on page load
  useEffect(async () => {
    await checkUserAuth(dispatch)
  }, [])

  // runs once after the user successfully signs in
  useEffect(async () => {
    if (authenticated) {
      await initialUserDataFetch(dispatch)
      await cognitoUserAuth(dispatch)
    }
  }, [authenticated])

  const listener = async ({ payload: { event = '' } } = {}) => {
    switch (event) {
      case AUTH_SIGN_IN:
        console.log('[ AWS AMPLIFY ] - user signed in')
        await signIn(dispatch)
        break
      case TOKEN_REFRESH_ERROR:
        console.error('token refresh failed')
        break
      case AUTH_SIGN_IN_FAILED:
        console.error('[ AWS AMPLIFY ] - user sign in failed')
        await kibanaEventTrigger({
          ...kibanaInitial,
          state: KIBANA_FAILED_STATE,
        })
        break
      case TOKEN_REFRESH:
        console.log('[ AWS AMPLIFY ] - token refresh succeeded')
        break
      default:
        break
    }
  }

  // LISTEN FOR AUTH SIGN IN EVENTS
  Hub.listen('auth', listener)

  return <React.Fragment />
}

export default AuthHub
