// @ts-ignore
import { API } from 'aws-amplify'
import { IConfigProps } from '../interfaces'

const getAutoAssignments = async () => {
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const table = 'AssignmentRules'
    const params: IConfigProps = {
      queryStringParameters: { table, action: 'scan' },
      body: {
        config: {
          pageSize: 5000,
        },
      },
    }
    const { response } = await API.post(apiName, apiPath, params)
    return response
  } catch (ex) {
    console.error(ex)
  }
}

const getCurrentAutoAssignment = async (assignmentRuleId: string) => {
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const table = 'AssignmentRules'
    const params: IConfigProps = {
      queryStringParameters: { table, action: 'scan' },
      body: {
        scan: {
          assignmentRuleId,
        },
      },
    }

    return await API.post(apiName, apiPath, params)
  } catch (ex) {
    console.error(ex)
  }
}

const getEmpGroupsData = async (ids: any[]) => {
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const params: any = {
      queryStringParameters: { table: 'EmpGroups', action: 'scan' },
      body: {
        scan: {
          groupId: ids,
        },
      },
    }
    return await API.post(apiName, apiPath, params)
  } catch (error) {
    console.log(error)
  }
}
const getEmpGroups = async () => {
  // grab empGroupAspectTree that is in redis
}

const createNewAutoAssignment = async (autoAssignmentObj: {
  autoAssignmentName: string
  trainingIds: string[]
}) => {
  const defaultAutoAssignment = {
    ...autoAssignmentObj,
    automationType: '1',
    isActive: '0',
    dueDays: 14,
    endDays: 30,
  }
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const params: IConfigProps = {
      queryStringParameters: { table: 'AssignmentRules' },
      body: defaultAutoAssignment,
    }
    return await API.post(apiName, apiPath, params)
  } catch (e) {
    console.error(e)
  }
}
const updateAutoAssignment = async (
  option: {
    autoAssignmentName: string
    automationType: string
    dueDays: string
    endDays: string
    isActive: string
  },
  id: string,
) => {
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'

    const autoAssignmentParams: IConfigProps = {
      queryStringParameters: { table: 'AssignmentRules', id },
      body: { set: { ...option }, rdsSet: { ...option } },
    }
    return await API.put(apiName, apiPath, autoAssignmentParams)
  } catch (ex) {
    console.error('Error saving: ', ex)
    return
  }
}

const updateAutoAssignmentEMPGroupLogic = async (
  assignmentRuleId: string,
  empGroupIdLogic: string[][],
) => {
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'

    if (empGroupIdLogic.length < 0) return

    const autoAssignmentParams: IConfigProps = {
      queryStringParameters: { table: 'AssignmentRules', id: assignmentRuleId },
      body: { set: { empGroupIdLogic }, rdsSet: { empGroupIdLogic } },
    }
    return await API.put(apiName, apiPath, autoAssignmentParams)
  } catch (ex) {
    console.error(ex)
  }
}

const updateAutoAssignmentTraining = async (assignmentRuleId: string, trainingIds: string[]) => {
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'

    const autoAssignmentParams: IConfigProps = {
      queryStringParameters: { table: 'AssignmentRules', id: assignmentRuleId },
      body: { set: { trainingIds }, rdsSet: { trainingIds } },
    }
    return await API.put(apiName, apiPath, autoAssignmentParams)
  } catch (ex) {
    console.error(ex)
  }
}

export {
  getCurrentAutoAssignment,
  getAutoAssignments,
  getEmpGroups,
  getEmpGroupsData,
  createNewAutoAssignment,
  updateAutoAssignment,
  updateAutoAssignmentEMPGroupLogic,
  updateAutoAssignmentTraining,
}
