import React, { useState } from 'react'
import { Dropdown, Flex, FormLabel, Input, TextArea } from '../../../common'
import { playSound } from '../../../../utils'
import { INotificationConfigProps } from '../../../../interfaces'

const ChromeNotifications = ({
  audioAssigned = [],
  audio_notification = false,
  text = '',
  timeoutDuration = '0',
  title = '',
  audioOptions = [],
  audioConfig,
  name,
  handleChange,
}: INotificationConfigProps) => {
  const [currentAudioNotification, setCurrentAudioNotification] =
    useState<boolean>(audio_notification)
  const [currentText, setCurrentText] = useState<string>(text)
  const [currentAudio, setCurrentAudio] = useState({
    id: audioAssigned,
    display: audioAssigned,
    value: audioAssigned,
  })

  const keyMap: Record<string, any> = {
    audioAssigned: `config.flexConfig.notificationConfig.${name}.audioAssigned`,
    audio_notification: `config.flexConfig.notificationConfig.${name}.audio_notification`,
    text: `config.flexConfig.notificationConfig.${name}.text`,
    timeoutDuration: `config.flexConfig.notificationConfig.${name}.timeoutDuration`,
    title: `config.flexConfig.notificationConfig.${name}.title`,
  }
  const timeoutDurationAdjustedForMinutes = String(Number(timeoutDuration) / 60)
  return (
    <Flex config={['column']} style={{ flexWrap: 'wrap' }}>
      <Flex>
        <FormLabel
          id={`${title}Title`}
          value={'Title'}
          tooltip={{
            selector: 'configuration.ChromeNotifications.Title',
          }}
        >
          <Input
            type="rolled"
            value={title}
            handleChange={(e) =>
              handleChange(
                name,
                {
                  title: e.currentTarget.value,
                },
                keyMap.title,
              )
            }
          />
        </FormLabel>
        <FormLabel
          id={`${timeoutDuration}TimeoutDuration`}
          value={'Duration'}
          tooltip={{
            selector: 'configuration.ChromeNotifications.Duration',
          }}
        >
          <Input
            type="rolled"
            value={timeoutDuration}
            handleChange={(e) => {
              if (!/\D/g.test(e.currentTarget.value)) {
                const currentValue = e.currentTarget.value
                handleChange(
                  name,
                  {
                    timeoutDuration: currentValue,
                  },
                  keyMap.timeoutDuration,
                )
              }
            }}
          />
        </FormLabel>
      </Flex>
      <Flex>
        <FormLabel
          id={`${title}Play`}
          value={'Play Audio'}
          tooltip={{
            selector: 'configuration.ChromeNotifications.PlayAudio',
          }}
        >
          <Dropdown
            type={'rolled'}
            options={[
              { id: title + 'PlayAudio', display: 'Yes', value: 'true' },
              { id: title + 'PlayAudioNot', display: 'No', value: 'false' },
            ]}
            selected={
              currentAudioNotification
                ? { id: title + 'PlayAudio', display: 'Yes', value: 'true' }
                : { id: title + 'PlayAudioNot', display: 'No', value: 'false' }
            }
            emitDropdownOption={(e) => {
              setCurrentAudioNotification(e.value === 'true' ? true : false)
              handleChange(
                name,
                {
                  audio_notification: e.value === 'true' ? true : false,
                },
                keyMap.audio_notification,
              )
            }}
            noSearch={true}
          />
        </FormLabel>
        <FormLabel
          id="audioOptions"
          value="Audible Ding"
          tooltip={{
            selector: 'configuration.ChromeNotifications.AudibleDing',
          }}
        >
          <Dropdown
            noSearch={true}
            type={'rolled'}
            options={audioOptions}
            selected={currentAudio}
            emitDropdownOption={(e) => {
              const selected = audioOptions.find((opt: any) => opt.id === e.id)
              const { ramp, tone, duration } = audioConfig[selected.id]
              setCurrentAudio(selected)
              currentAudioNotification
                ? playSound({ ramp, tone, duration })
                : console.log('Play Audio set to "No"')
              handleChange(
                name,
                {
                  audioAssigned: [e.value],
                },
                keyMap.audioAssigned,
              )
            }}
          />
        </FormLabel>
      </Flex>
      <Flex>
        <FormLabel
          id={`${title}Text`}
          value={'Text'}
          containerStyle={{ marginRight: 10 }}
          tooltip={{
            selector: 'configuration.ChromeNotifications.Text',
          }}
        >
          <TextArea
            value={currentText}
            handleChange={(e: any) => {
              setCurrentText(e.currentTarget.value)
              handleChange(
                name,
                {
                  text: e.currentTarget.value,
                },
                keyMap.text,
              )
            }}
          />
        </FormLabel>
      </Flex>
    </Flex>
  )
}

export default ChromeNotifications
