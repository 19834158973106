import React, { useState, useEffect, useContext, useRef } from 'react'
import '@asurion/atomic-ui-library'
import '@asurion/sass-design-system/build/asurion/asurion.css'
import { autoAssignmentHeaders, trainingHeaders, trainingBundlesHeaders } from '../../constants'
import { RouteContext } from '../../context/RouteContext'
import FilterableTable from '../common/Organisms/FilterableTable'
import { GroupsContext } from '../../context/GroupsContext'
import { getTrainings } from '../../actions/trainings'
import { checkPermissions } from '../../utils'

import { Flex, Tab, FormLabel, Tooltip } from '../common'
import { TrainingTooltip } from '../../interfaces'

const tabList: TrainingTooltip[] = [
  { value: 'trainings', display: 'Trainings', id: '0', tooltip: 'trainings.trainings.tab' },
  { value: 'bundles', display: 'Bundles', id: '1', tooltip: 'trainings.trainingBundles.tab' },
  {
    value: 'autoAssignments',
    display: 'Auto Assignment',
    id: '2',
    tooltip: 'trainings.trainingAutoAssignment.tab',
  },
]

const Training = () => {
  const {
    autoAssignments,
    trainings,
    setTrainings,
    loading,
    loadAutoAssignments,
    setLoading,
    setTrainingAspectData,
    bundles,
  } = useContext(GroupsContext)
  const {
    handleNavigation,
    setSelectedLink,
    setSelectedLinkProps,
    selectedLinkProps,
    selectedTab = 'trainings',
    setSelectedTab,
  } = useContext(RouteContext)

  const [newBundles, setNewBundles] = useState<any[]>(bundles)
  const isMounting = useRef(true)
  const handleGetTrainings = async () => {
    await getTrainings(setTrainings, setTrainingAspectData, setLoading)
    return
  }

  useEffect(() => {
    if (selectedTab === '') setSelectedTab('trainings')
  }, [selectedTab])

  useEffect(() => {
    if (isMounting.current || selectedLinkProps === 'update') {
      if (selectedLinkProps === 'update') {
        setNewBundles([])
      }
      ;(async () => {
        try {
          await handleGetTrainings()
          isMounting.current = false
        } catch (e) {
          console.error(e)
        }
      })()
    }
  }, [selectedLinkProps])

  useEffect(() => {
    if (!bundles) return
    setNewBundles(bundles)
  }, [bundles])
  return (
    <Flex style={{ height: '100%' }} config={['column']}>
      <Flex config={['alignCenter']} style={{ marginBottom: 25 }}>
        {tabList.map(({ value, display, tooltip }: TrainingTooltip) => {
          if (!checkPermissions(value)) return false
          const shouldDisable = value !== 'trainings' && trainings.length === 0
          return (
            <Tooltip key={JSON.stringify(value)} selector={tooltip}>
              <Tab
                onTabSelect={(tab: string) => {
                  setSelectedTab(tab)
                  setSelectedLink(value)
                }}
                disabled={shouldDisable}
                key={value}
                display={display}
                value={value}
                isSelected={selectedTab === value || (selectedTab === '' && value === 'trainings')}
              />
            </Tooltip>
          )
        })}
      </Flex>
      <Flex style={{ height: 'calc(100% - 80px)' }} config={['column']}>
        {selectedTab === 'trainings' && (
          <FilterableTable
            loading={loading}
            width={2250}
            data={trainings}
            bundle={newBundles}
            headers={trainingHeaders}
            onUpdate={async (hey) => {
              if (hey === 'autoAssignments') {
                await loadAutoAssignments()
              } else {
                await handleGetTrainings()
              }
            }}
            handleRowClick={(data) => {
              setSelectedLinkProps(data)
              setSelectedLink('newTraining')
            }}
          />
        )}

        {selectedTab === 'bundles' && (
          <FilterableTable
            loading={loading}
            width={'100%'}
            data={newBundles}
            headers={trainingBundlesHeaders}
            onUpdate={handleGetTrainings}
            ruleType={'bundle'}
            handleRowClick={(data) => {
              setSelectedLinkProps(data)
              setSelectedLink('singleBundle')
            }}
            filterIndex={0}
          />
        )}
        {selectedTab === 'autoAssignments' && (
          <FilterableTable
            width={'100%'}
            data={autoAssignments}
            headers={autoAssignmentHeaders}
            ruleType={'autoAssignments'}
            handleRowClick={(data) => {
              setSelectedLinkProps(data)
              setSelectedLink('singleAutoAssignment')
            }}
            filterIndex={0}
          />
        )}
      </Flex>
    </Flex>
  )
}
export default Training
