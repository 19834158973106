import React, { useState } from 'react'
import { TextField } from '../../'
import { IHeader } from '../../../../interfaces'
import { renderData } from '../../Molecules/Table/renderData'
import styled, { StyledComponent } from 'styled-components'
import { IconComponent } from '../../../common/Atoms/IconComponent'
import CreateOutlined from '@material-ui/icons/CreateOutlined'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'
import { useSelector } from 'react-redux'
import themes from '../../../../themes/schema'
const BabyTable = ({
  data,
  editable,
  headers,
  saveRow,
  deleteRow,
  mappedData,
}: {
  data: any
  editable?: any[]
  headers: any
  saveRow?: any
  deleteRow?: any
  mappedData?: any
}) => {
  const {
    userSettings: { darkMode },
  } = useSelector((state: any) => state?.user)
  const customDisplayType = darkMode ? 'darkMode' : 'originalFlavor'
  const getStyle: Record<string, any> = {
    table_color_originalFlavor: themes.originalFlavor.table.color,
    table_color_darkMode: themes.darkMode.table.color,
  }
  const [isEditing, setIsEditing] = useState(false)
  const [isHovering, setIsHovering] = useState(false)
  return (
    <table>
      <thead>
        <tr className="ttd-tr">
          {headers.map((header: Partial<IHeader>) => (
            <th className="ttd-th" key={header.value}>
              {header.display}
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {data.map((item: any, index: any, arr: any) => {
          return (
            <tr className="ttd-tr" key={JSON.stringify(item) + index}>
              {headers.map(({ value }: { value: string }) => {
                const editableCheck = editable && editable.indexOf(value) !== -1 ? true : false
                const dataName = item[value]
                return (
                  <td className="ttd-td" key={JSON.stringify(value) + index}>
                    {editableCheck ? (
                      <TextField
                        width={'50%'}
                        deleteField={(current) => deleteRow(current, data, index, value)}
                        saveChanges={(saved: any, current: any) => {
                          saveRow(current, index)
                        }}
                        value={index}
                        config={{ edit: true, delete: true, global: false }}
                      />
                    ) : (
                      renderData({
                        header: value,
                        data: item,
                        customDisplayType,
                        getStyle,
                      })
                    )}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const StyledInputContainer: StyledComponent<'div', any, {}, never> = styled.div`
  &&& {
    margin-bottom: 2px;
    padding: 0;
    width: 20rem;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
  }
`

const StyledTextField: StyledComponent<'input', any, {}, never> = styled.input`
  &&& {
    margin: 0;
    height: auto;
    padding-bottom: 5px;
    margin-right: auto;
    border: none;
    /* flex: 1 1; */
    font-weight: bold;
    color: #4a0684;
    :focus {
      outline: none;
    }
    &:hover {
      border-bottom: 1px solid #4a0684;
    }
  }
`
const StyledButtonContainer: StyledComponent<'div', any, {}, never> = styled.div`
  &&& {
    padding: 0;
    display: flex;
    align-items: center;
    transition: all 0.5s;
    opacity: 0;
    margin-left: auto;
    &.show {
      opacity: 1;
    }
    *:focus {
      outline: none;
    }
  }
`

export default BabyTable
