import React, { FC, ReactElement } from 'react'
import { useSelector } from 'react-redux'
import themes from '../../../../themes/schema'

export const MATERIAL_ICON_CLASS = 'material-icons'

const styleDataToClass = (styleClass: string | undefined) => {
  return `${MATERIAL_ICON_CLASS} ${styleClass}`
}

const getStyleClass = (styleClass: string | undefined) =>
  styleClass !== '' ? styleDataToClass(styleClass) : MATERIAL_ICON_CLASS

interface IIconComponent {
  icon?: string | undefined
  id?: string
  styleClass?: string | undefined
  style?: any
  className?: string
  children?: any
}

export const IconComponent: FC<IIconComponent> = ({
  icon = '',
  id,
  styleClass = '',
  className,
  style,
  children,
}: IIconComponent): ReactElement<any, any> => {
  const iconClassName = getStyleClass(styleClass)
  const modifiedIcon = icon
  icon = icon.replace(/_[a-z]+[A-Z][a-z]+/, '')

  const {
    userSettings: { darkMode },
  } = useSelector((state: any) => state?.user)
  const customDisplayType = darkMode ? 'darkMode' : 'originalFlavor'
  const getStyle: Record<string, any> = {
    default_darkMode: themes.darkMode.iconComponent.colors.default,
    keyboard_arrow_up_darkMode: themes.darkMode.iconComponent.colors.default,
    add_circle_outline_darkMode: themes.darkMode.iconComponent.colors.public,
    add_circle_outline_anotherFlavor_darkMode: themes.darkMode.iconComponent.colors.default,
    get_app_darkMode: themes.darkMode.iconComponent.colors.default,
    public_darkMode: themes.darkMode.iconComponent.colors.public,
    cached_darkMode: themes.darkMode.iconComponent.colors.tertiary,
    info_darkMode: themes.darkMode.iconComponent.colors.tertiary,
    language_darkMode: themes.darkMode.iconComponent.colors.secondary,
    closed_outlined_darkMode: themes.darkMode.iconComponent.colors.secondary,
    create_outlined_darkMode: themes.darkMode.iconComponent.colors.secondary,
    check_circle_outline_darkMode: themes.darkMode.iconComponent.colors.secondary,

    default_originalFlavor: themes.originalFlavor.iconComponent.colors.default,
    keyboard_arrow_up_originalFlavor: themes.originalFlavor.iconComponent.colors.default,
    add_circle_outline_originalFlavor: themes.originalFlavor.iconComponent.colors.dataNode,
    add_circle_outline_anotherFlavor_originalFlavor:
      themes.originalFlavor.iconComponent.colors.primary,
    get_app_originalFlavor: themes.originalFlavor.iconComponent.colors.primary,
    public_originalFlavor: themes.originalFlavor.iconComponent.colors.secondary,
    cached_originalFlavor: themes.originalFlavor.iconComponent.colors.secondary,
    info_originalFlavor: themes.originalFlavor.iconComponent.colors.tertiary,
    language_originalFlavor: themes.originalFlavor.iconComponent.colors.secondary,
    closed_outlined_originalFlavor: themes.originalFlavor.iconComponent.colors.secondary,
    create_outlined_originalFlavor: themes.originalFlavor.iconComponent.colors.secondary,
    check_circle_outline_originalFlavor: themes.originalFlavor.iconComponent.colors.secondary,
  }

  return (
    <i
      data-testid={id}
      className={`material-icons ${iconClassName} ${className}`}
      style={{
        ...style,
        color:
          getStyle[`${modifiedIcon}_${customDisplayType}`] ||
          getStyle[`default_${customDisplayType}`],
      }}
    >
      {children || icon}
    </i>
  )
}

export default IconComponent
