import { FormLabel, Button } from '../../common'
import React, { ReactElement, SyntheticEvent, useState } from 'react'
import RedisForm from './redisDataForm'
import styled, { StyledComponent } from 'styled-components'
import { checkPermissions } from '../../../utils'

const RedisObjectDisplay = ({
  keyName,
  index,
  data,
  handleButtonClick,
}: {
  keyName: string
  index: number
  data: string
  handleButtonClick: (x: number, y: any, z: string) => void
}) => {
  const [value, setValue] = useState<string>(data)
  const [invalidJSON, setInvalidJSON] = useState<boolean>(false)
  const permission = checkPermissions('dev', 'edit')
  return (
    <PrettyContainer>
      <FormLabel
        id={`ttd-name`}
        value={'Key Name'}
        style={{ marginTop: 3, fontSize: 18, marginBottom: 3 }}
      >
        <FormText>{keyName}</FormText>
      </FormLabel>
      <div>
        <StyledInputContainer length={data.length} invalidJSON={invalidJSON}>
          <StyledTextArea
            value={value}
            placeholder={''}
            onChange={(e: any) => {
              setValue(e.currentTarget.value)
              try {
                JSON.parse(e.currentTarget.value)
                setInvalidJSON(false)
              } catch (error) {
                setInvalidJSON(true)
              }
            }}
            length={data.length}
          />
        </StyledInputContainer>
      </div>
      {permission && (
        <ButtonHolder>
          <Button
            size={'sm'}
            style={{ marginRight: 13 }}
            handleClick={() => {
              if (!invalidJSON) {
                handleButtonClick(index, JSON.parse(value), 'update')
              }
            }}
          >
            Update
          </Button>
          <Button
            displayType={'outline'}
            size={'sm'}
            handleClick={() => {
              handleButtonClick(index, '', 'delete')
            }}
          >
            Remove
          </Button>
        </ButtonHolder>
      )}
    </PrettyContainer>
  )
}
export default RedisObjectDisplay

const FormText: StyledComponent<'div', any, {}, never> = styled.div`
  overflow-wrap: break-word;
  max-width: 200px;
  font-size: 18px;
  flex-grow: 1;
`
const StyledInputContainer: StyledComponent<
  'div',
  any,
  { length: number; invalidJSON: boolean },
  never
> = styled.div`
  border: 1px solid #aaafb4;
  padding: 4px 5px 4px 12px;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  overflow: auto;
  min-width: 400px;
  border-color: ${({ invalidJSON, length }: { invalidJSON: boolean; length: number }) =>
    invalidJSON ? 'coral' : 'none'};
  overflow: hidden;
  max-width: 400px;
  height: ${({ length }: { length: number }) => `${length}px`};
  max-height: 700px;
  align-items: center;

  &hover {
    border-color: #4a0684;
  }
`

const StyledTextArea: StyledComponent<'textarea', any, { length: number }, never> = styled.textarea`
  margin: 0;
  min-width: 200px;
  max-width: 400px;
  word-break: break-all;
  border: none;
  font-size: 0.85em;
  resize: none;
  height: 200px;
  height: ${({ length }: { length: number }) => `${length}px`};
  max-height: 700px;

  :focus {
    outline: none;
  }

  &hover {
  }
`
const ButtonHolder: StyledComponent<'div', any, {}, never> = styled.div`
  margin-top: 25px;
  margin-left: 22px;
  display: flex;
  flex-grow: 1;
`
const PrettyContainer: StyledComponent<'div', any, {}, never> = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 780px;
`
