import React from 'react'
import { Button, Flex, Modal } from '../../../common'

interface IProps {
  toggle: (show: boolean) => void
  deleteAssignment: () => void
}
const DeleteAutoAssignmentModal = ({ deleteAssignment, toggle }: IProps) => {
  return (
    <Modal
      header="Confirm Delete"
      close={() => {
        toggle(false)
      }}
      height={'250'}
    >
      <Flex config={['column']} style={{ borderRadius: '16px', width: 'max-content' }}>
        Are you sure you want to delete this Auto Assignment? This action cannot be undone.
        <Flex style={{ margin: '15px 0px 15px 0px' }}>
          <Button handleClick={deleteAssignment} style={{ marginRight: 15 }}>
            Delete Auto Assignments
          </Button>
          <Button
            handleClick={() => {
              toggle(false)
            }}
            displayType="outline"
            size="lg"
          >
            Cancel
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

export default DeleteAutoAssignmentModal
