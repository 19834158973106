import { dataFormatToPartnerData } from '../../transformations/utils';
import {
   GroupQueueData,
   GroupWorkForceDetail,
   GroupWorkForceEventStat,
   GroupWorkForceGlobalStat
} from '../../data/datatypes'

export const filterNoWorkerStatus = ['Select Status', 'All'];
const defaultStatusSelect = 'Select Status';

export const
   initialState = {
      checkedIds: [999999],
      openPanel: null,
      isMenuOpen: false,
      selectedCoach: null,
      isStatExpanded: false,
      expandedStatRowId: 9999,
      workerList: [],
      workerListFilter: defaultStatusSelect,
      group: {
         selection: [],
         active: null
      },
      supervisorList: [],
      twilioSyncObject: {},
      isButtonSelected: false,
      selectedInterval: 'Current Interval',
      selectedWFInterval: 'Current Interval',
      workForceGlobalStats: [dataFormatToPartnerData('Current Interval')({ 'loading': new GroupWorkForceGlobalStat() })],
      workForceEventStats: {
         'loading': { 'loading': new GroupWorkForceEventStat() }
      },
      workForceDetailStats: {
         'loading': { 'loading': new GroupWorkForceDetail() }
      },
      agentAdminDetails: {
         'loading': { 'loading': new GroupQueueData() }
      }
   }

// state selectors
export const selectorToActiveGroup = state => state.expert.group,
   selectorToAgentAdminDetails = state => state.expert.agentAdminDetails,
   selectorToSelectedWFInterval = state => state.expert.selectedWFInterval,
   selectorToSelectedInterval = state => state.expert.selectedInterval,
   selectorToSyncObject = state => state.expert.twilioSyncObject,
   selectorToWorkForceEventStats = state => state.expert.workForceEventStats,
   selectorToWorkForceDetailStats = state => state.expert.workForceDetailStats,
   selectorToWorkForceGlobalStats = state => state.expert.workForceGlobalStats,
   selectorToSupervisorList = state => state.expert.supervisorList,
   selectorToWorkerList = state => state.expert.workerList,
   selectorToIsStatExpandedState = state => state.expert.expandedStatRowId,
   selectorToSelectedCoach = state => state.expert.selectedCoach,
   selectorToIsMenuOpenState = state => state.expert.isMenuOpen,
   selectorToOpenPanelState = state => state.expert.openPanel,
   selectorToCheckedIdState = state => state.expert.checkedIds,
   selectorToWorkerListFilter = state => state.expert.workerListFilter,
   selectorToStatusFilters = state => workerListToStatusSelectOptions(state)


//Action types
export const
   IS_CHECKED = 'IS_CHECKED',
   TOGGLE_PANEL = 'TOGGLE_PANEL',
   IS_MENU_OPEN = 'IS_MENU_OPEN',
   BLUR_MENU = 'BLUR_MENU',
   SELECT_COACH = 'SELECT_COACH',
   IS_STAT_EXPANDED = 'IS_STAT_EXPANDED',
   GET_ALL_WORKERS = 'GET_ALL_WORKERS',
   GET_ALL_SUPERVISORS = 'GET_ALL_SUPERVISORS',
   GET_SYNC_OBJECT = 'GET_SYNC_OBJECT',
   GET_WORKFORCE_GLOBAL = 'GET_WORKFORCE_GLOBAL',
   GET_WORKFORCE_DETAIL = 'GET_WORKFORCE_DETAIL',
   IS_BUTTON_SELECTED = 'IS_BUTTON_SELECTED',
   IS_WF_BUTTON_SELECTED = 'IS_WF_BUTTON_SELECTED',
   GET_PRIORITY_DELAY = 'GET_PRIORITY_DELAY',
   SET_GROUP_LIST = 'SET_GROUP_LIST',
   GET_WORKFORCE_EVENT_STATS = 'GET_WORKFORCE_EVENT_STATS',
   SET_ACTIVE_STATUS_FILTER = 'SET_ACTIVE_STATUS_FILTER';

export const
   TODAY_INTERVAL = 'Today',
   CURRENT_INTERVAL = 'Current Interval';


//Action Creators
export const valueToIsCheckedAction = value => ({ 'type': IS_CHECKED, 'payload': value });

export const valueToPanelAction = value => ({ 'type': TOGGLE_PANEL, 'payload': value });

export const valueToIsButtonSelectedAction = value => ({ 'type': IS_BUTTON_SELECTED, 'payload': value });

export const valueToIsWFButtonSelectedAction = value => ({ 'type': IS_WF_BUTTON_SELECTED, 'payload': value });

export const valueToIsMenuOpenAction = value => ({ 'type': IS_MENU_OPEN, 'payload': value });

export const valueToIsMenuBlurAction = () => ({ 'type': BLUR_MENU });

export const valueToSelectMenuItemAction = value => ({ 'type': SELECT_COACH, 'payload': value });

export const valueToIsStatExpandedAction = rowId => ({ 'type': IS_STAT_EXPANDED, 'payload': rowId });

export const valueToGetWorkersAction = workers => ({ 'type': GET_ALL_WORKERS, 'payload': workers });

export const valueToGetSupervisorsAction = supervisors => ({ 'type': GET_ALL_SUPERVISORS, 'payload': supervisors });

export const valueToGetWorkForceGlobalAction = data => ({ 'type': GET_WORKFORCE_GLOBAL, 'payload': data });

export const valueToGetWorkForceDetailAction = data => ({ 'type': GET_WORKFORCE_DETAIL, 'payload': data });

export const valueToWorkForceEventStats = data => ({ 'type': GET_WORKFORCE_EVENT_STATS, 'payload': data });

export const valueToTwilioSyncAction = syncObject => ({ 'type': GET_SYNC_OBJECT, 'payload': syncObject });

export const valueToPriorityDelayAction = data => ({ 'type': GET_PRIORITY_DELAY, 'payload': data });

export const valueToGroupSelect = data => ({ 'type': SET_GROUP_LIST, 'payload': data });

export const valueToSetStatusFilter = data => ({ 'type': SET_ACTIVE_STATUS_FILTER, 'payload': data });


//Transformations
const rowIdToExpandedStatus = (rowId, state) => { return { ...state, expandedStatRowId: rowId } }
const isMatchingEmpId = empId => item => item.workerId === empId;

const namesToReorderedNames = (str1, str2) => (str1 < str2) ? -1 : (str1 > str2) ? 1 : 0;
const normalizeName = str => str.supervisorName.toUpperCase();
const sortNames = (a, b) => namesToReorderedNames(normalizeName(a), normalizeName(b));

const workerListToStatusSelectOptions = ({ expert: { workerListFilter = defaultStatusSelect, workerList = [] } = {} } = {}) => {
   const defaultSelection = filterNoWorkerStatus.includes(workerListFilter) ? [] : ['All'];
   return workerList.reduce((acc, { status = false }) => {
      if (status && !acc.includes(status)) {
         acc.push(status);
      }
      return acc;
   }, defaultSelection);
}

//Reducer
export const ExpertReducer = (state = initialState, { type = '', payload }) => {
   switch (type) {
      case IS_MENU_OPEN: return { ...state, isMenuOpen: !payload };
      case BLUR_MENU: return { ...state, isMenuOpen: false };
      case SELECT_COACH: return state.selectedCoach === payload ? state : { ...state, workerListFilter: defaultStatusSelect, selectedCoach: payload };
      case SET_ACTIVE_STATUS_FILTER: return { ...state, workerListFilter: payload };
      case GET_ALL_SUPERVISORS:
         const abcSupervisorList = payload.sort(sortNames);
         return { ...state, supervisorList: abcSupervisorList, selectedCoach: payload.length ? payload[0].supervisorId : null };
      case GET_WORKFORCE_DETAIL: {
         const keyedGroups = Object.keys(payload);
         if (keyedGroups.length === 1) {
            const key = keyedGroups[0];
            return { ...state, workForceDetailStats: { ...state.workForceDetailStats, [key]: { ...payload[key] } } }
         }
         return { ...state, workForceDetailStats: payload };
      }
      case GET_SYNC_OBJECT: return { ...state, twilioSyncObject: payload };
      case GET_PRIORITY_DELAY: {
         const keyedGroups = Object.keys(payload);
         if (keyedGroups.length === 1) {
            const key = keyedGroups[0];
            return { ...state, agentAdminDetails: { ...state.agentAdminDetails, [key]: { ...payload[key] } } }
         }
         return { ...state, agentAdminDetails: payload };
      }
      case IS_CHECKED: return state.checkedIds.includes(payload) ?
         { ...state, checkedIds: state.checkedIds.filter(id => id !== payload) } :
         { ...state, checkedIds: [...state.checkedIds, payload] };
      case IS_BUTTON_SELECTED: return state.selectedInterval === TODAY_INTERVAL ?
         { ...state, selectedInterval: CURRENT_INTERVAL } :
         { ...state, selectedInterval: TODAY_INTERVAL };
      case IS_WF_BUTTON_SELECTED: return state.selectedWFInterval === TODAY_INTERVAL ?
         { ...state, selectedWFInterval: CURRENT_INTERVAL } :
         { ...state, selectedWFInterval: TODAY_INTERVAL };
      case IS_STAT_EXPANDED:
         const expandedRowValue = (payload === state.expandedStatRowId) ? 9999 : payload;
         const isStatExpandedValue = rowIdToExpandedStatus(expandedRowValue, state);
         return isStatExpandedValue;
      case GET_ALL_WORKERS: {
         const { data, empId } = payload;

         if (empId) {
            const { workerList } = state;
            const arrayWithMatchingEmpId = isMatchingEmpId(empId);
            const indexDetermined = workerList.findIndex(arrayWithMatchingEmpId);

            if (indexDetermined !== -1) {
               workerList.splice(indexDetermined, 1, data);
            }
            return { ...state, workerList }
         } else {
            return { ...state, workerList: data }
         }
      }
      case SET_GROUP_LIST: {
         if (typeof payload === 'string') {
            if (state.group.active === payload) {
               return state
            }
            return {
               ...state,
               supervisorList: [],
               workerList: [],
               workerListFilter: defaultStatusSelect,
               group: {
                  ...state.group,
                  active: payload
               }
            }
         } else {
            return {
               ...state,
               group: {
                  selection: payload,
                  active: payload[0]
               }
            }
         }
      };
      case TOGGLE_PANEL: return state.openPanel === payload ?
         { ...state, openPanel: null } : { ...state, openPanel: payload };
      case GET_WORKFORCE_GLOBAL: {
         const keyedGroups = Object.keys(payload);
         const modifiedData = keyedGroups.map(group => dataFormatToPartnerData(state.selectedWFInterval)({ [group]: { ...payload[group] } }));
         if (keyedGroups.length === 1) {
            const indexToMod = state.workForceGlobalStats.findIndex(data => data.workflowSid === modifiedData[0].workflowSid);
            const newArr = [...state.workForceGlobalStats];
            newArr.splice(indexToMod, 1, modifiedData[0])

            return { ...state, workForceGlobalStats: newArr }
         }

         return { ...state, workForceGlobalStats: modifiedData };
      }
      case GET_WORKFORCE_EVENT_STATS: {
         const keyedGroups = Object.keys(payload);
         if (keyedGroups.length === 1) {
            const key = keyedGroups[0];
            return { ...state, workForceEventStats: { ...state.workForceEventStats, [key]: { ...payload[key] } } }
         }
         return { ...state, workForceEventStats: payload };
      }
      default:
         return state;
   };
}

export default ExpertReducer;