import React, { useEffect, useState } from 'react'
import { Button, FilterableTable, Flex, Modal } from '../../common'
import { FusionModalContent } from '../styles'
import Sidebar from '../../Trainings/TrainingAssignmentModal/SideBar'
import { fusionAssignmentHeaders } from '../../../constants'
import { postFusionExperts, getFusionExperts } from '../../../actions/fusion'
import { checkPermissions } from '../../../utils'

export const Selection = ({ modalActions, modalName, data, state, dispatch }: any) => {
  const [voiceDisabled, setVoiceDisabled] = useState<boolean>(false)
  const [messagingDisabled, setMessagingDisabled] = useState<boolean>(false)
  const { clearFusion, toggleModal } = modalActions
  const { assignmentState, payload, clearChecks, workersSelected } = state
  const dispatcher = (dispatch: any, type: string, payload: any) => {
    dispatch({ type, payload })
  }
  const permission = checkPermissions('fusion', 'edit')
  // Disables Assign to Voice/Messaging as appropriate
  useEffect(() => {
    function unique(value: any, index: any, self: any) {
      return self.indexOf(value) === index
    }

    const currentStates = assignmentState
      .map((i: any) => {
        if (workersSelected.includes(i.empId)) return i.taskStatus
      })
      .filter(Boolean)
    const filteredStates = currentStates.filter(unique)
    setMessagingDisabled(false)
    setVoiceDisabled(false)
    if (filteredStates.includes('Messaging')) {
      setMessagingDisabled(true)
    }
    if (filteredStates.includes('Available')) {
      setVoiceDisabled(true)
    }
    if (filteredStates.includes('Offline')) {
      setMessagingDisabled(true)
      setVoiceDisabled(true)
    }
  }, [workersSelected])

  return (
    <Modal height={'90vh'} close={() => toggleModal(modalName, false)}>
      <FusionModalContent>
        <Flex config={['column']}>
          <div className={'sidebar-wrapper'}>
            <Sidebar />
          </div>
        </Flex>
        <Flex style={{ height: '100%', width: '100%' }} config={['column']}>
          <FilterableTable
            width={'100%'}
            data={assignmentState}
            headers={fusionAssignmentHeaders}
            filterIndex={0}
            noExport={true}
            fusion={true}
            fusionDispatch={dispatch}
            setPayload={dispatcher}
            payload={payload}
            hideSelectionBar={true}
            reportType={'fusion'}
            clearChecks={clearChecks}
            workersSelected={workersSelected}
            addWorkerToWorkerSelected={(workerList) =>
              dispatcher(dispatch, 'setWorkersSelected', workerList)
            }
          />
          <Flex config={['row', 'justifyBetween']} style={{ marginTop: '8px' }}>
            <Button displayType={'ghost'} size={'md'} handleClick={async () => clearFusion()}>
              Clear All
            </Button>
            <Button
              displayType={'outline'}
              size={'md'}
              handleClick={async () => {
                const experts = await getFusionExperts()
                await dispatcher(dispatch, 'setAssignmentState', experts)
              }}
            >
              Get All Workers
            </Button>
            {permission && (
              <>
                <Button
                  disabled={voiceDisabled}
                  displayType={'flat'}
                  size={'lg'}
                  handleClick={async () => {
                    await postFusionExperts('Ejector', payload)
                    dispatcher(dispatch, 'setClearChecks', true)
                    dispatcher(dispatch, 'setWorkersSelected', [])
                    dispatcher(dispatch, 'setPayload', [])
                    dispatcher(dispatch, 'setClearChecks', false)
                    toggleModal(modalName, false)
                  }}
                >
                  Assign to Voice
                </Button>
                <Button
                  disabled={messagingDisabled}
                  displayType={'flat'}
                  size={'lg'}
                  handleClick={async () => {
                    await postFusionExperts('Dispatcher', payload)
                    dispatcher(dispatch, 'setClearChecks', true)
                    dispatcher(dispatch, 'setWorkersSelected', [])
                    dispatcher(dispatch, 'setPayload', [])
                    dispatcher(dispatch, 'setClearChecks', false)
                    toggleModal(modalName, false)
                  }}
                >
                  Assign to Messaging
                </Button>
              </>
            )}
          </Flex>
        </Flex>
      </FusionModalContent>
    </Modal>
  )
}
export default Selection
