import React, { ReactElement, SyntheticEvent } from 'react'
import { Flex, FormLabel, Input } from '../../common'
import { getEmpGroupByDepartmentName, getEmpIdByWorker } from '../devActions'
import LookupInputDev from '../devComponents/input/LookupInputDev'

const Forms = (props: any) => {
  const handleEmpIdInput = (e: SyntheticEvent<HTMLInputElement>) => {
    if (e.currentTarget.value === '') {
      props.dispatch({ type: 'setEmpIdsForForm', payload: [] })
    }

    if (e.currentTarget.value.length >= 6 && /^(((\d{6})[ ]?)+)$/.test(e.currentTarget.value)) {
      const empIdsAsArray = e.currentTarget.value.split(/\D/g).filter((empId) => empId)
      props.dispatch({ type: 'setEmpIdsForForm', payload: empIdsAsArray })
    }
    props.dispatch({ type: 'setEmpIdValue', payload: e.currentTarget.value })
  }
  const handleWorkerSidInput = (e: SyntheticEvent<HTMLInputElement>) => {
    if (e.currentTarget.value === '') {
      props.dispatch({ type: 'setWorkerSids', payload: [] })
    }
    if (
      e.currentTarget.value.length >= 34 &&
      /^((([a-zA-Z0-9]{34})[ ]?)+)$/.test(e.currentTarget.value)
    ) {
      const workerSids = e.currentTarget.value
        .split(/[^a-zA-Z0-9]/g)
        .filter((workerSid) => workerSid)
      props.dispatch({ type: 'setWorkerSids', payload: workerSids })
    }
    props.dispatch({ type: 'setWorkerSid', payload: e.currentTarget.value })
  }

  const handleEmpNamesInput = (name: any, action: string) => {
    let namesToDispatch = props.empNameSelection || []
    if (action === 'remove') {
      namesToDispatch = props.empNameSelection.filter(
        (selected: any) => name.full_name !== selected.full_name,
      )
      props.dispatch({ type: 'setEmpNameSelection', payload: namesToDispatch })
    } else {
      props.dispatch({ type: 'setEmpNameSelection', payload: [...namesToDispatch, name] })
    }
  }

  const handleDepartmentNamesInput = (name: any, action: string) => {
    let namesToDispatch = props.departmentNameSelection || []
    if (action === 'remove') {
      namesToDispatch = props.departmentNameSelection.filter(
        (selected: any) => name.groupName !== selected.groupName,
      )
      props.dispatch({ type: 'setDepartmentNameSelection', payload: namesToDispatch })
    } else {
      props.dispatch({ type: 'setDepartmentNameSelection', payload: [...namesToDispatch, name] })
    }
  }
  const handleDeptInput = (e: SyntheticEvent<HTMLInputElement>) => {
    if (e.currentTarget.value === '') {
      props.dispatch({ type: 'setDepartment', payload: '' })
    } else {
      props.dispatch({ type: 'setDepartment', payload: e.currentTarget.value })
    }
  }

  const handleWorkerInput = (e: SyntheticEvent<HTMLInputElement>) => {
    if (e.currentTarget.value === '') {
      props.dispatch({ type: 'setWorkerSid', payload: '' })
    } else {
      props.dispatch({ type: 'setWorkerSid', payload: e.currentTarget.value })
    }
  }

  const { config = [] } = props
  const renderComponentByConfigKey = (key: string) => {
    const options: Record<string, ReactElement> = {
      empId: (
        <FormLabel key={key} id="getAssignmentMapEmpIdInput" value="EmpId(s)">
          <Input value={props.empIdValue} handleChange={handleEmpIdInput} />
        </FormLabel>
      ),
      empName: (
        <FormLabel key={key} id="ttd-name" value="Name(s)">
          <LookupInputDev
            selections={props.empNameSelection}
            handleSelect={handleEmpNamesInput}
            objectKey={'full_name'}
            getSelections={getEmpIdByWorker}
          />
        </FormLabel>
      ),
      department: (
        <FormLabel key={key} id="ttd-name" value="Department">
          <Input value={props.department} handleChange={handleDeptInput} />
        </FormLabel>
      ),
      workerSids: (
        <FormLabel key={key} id="ttd-name" value="WorkerSid(s)">
          <Input value={props.workerSid} handleChange={handleWorkerSidInput} />
        </FormLabel>
      ),
      departmentName: (
        <FormLabel key={key} id="ttd-name" value="Name(s)">
          <LookupInputDev
            selections={props.departmentNameSelection}
            handleSelect={handleDepartmentNamesInput}
            objectKey={'groupName'}
            getSelections={getEmpGroupByDepartmentName}
          />
        </FormLabel>
      ),
    }

    return options[key]
  }

  return (
    <Flex config={['column']}>{config.map((key: string) => renderComponentByConfigKey(key))}</Flex>
  )
}

export default Forms
