import React, { useState, CSSProperties } from 'react'
import styled, { StyledComponent } from 'styled-components'
import { IconComponent } from '../../index'
import { IDropdownTableOption, IMenuOption } from '../../../../interfaces'
import { useSelector } from 'react-redux'
import themes from '../../../../themes/schema'

interface IMenu {
  type?: string
  style?: CSSProperties | null | undefined
  children?: any
  hasBundle?: boolean
  options?: IMenuOption[]
  emitAction?: any
  displayEllipse?: boolean
}

const Menu = ({ style, options, emitAction, hasBundle = false, displayEllipse = true }: IMenu) => {
  const {
    userSettings: { darkMode },
  } = useSelector((state: any) => state?.user)
  const customDisplayType = darkMode ? 'darkMode' : 'originalFlavor'
  const getStyle: Record<string, any> = {
    selectionBar_menu_backgroundColor_originalFlavor:
      themes.originalFlavor.selectionBar.menu.backgroundColor,
    selectionBar_menu_backgroundColor_darkMode: themes.darkMode.selectionBar.menu.backgroundColor,
  }

  const [showMenu, setShowMenu] = useState<boolean>(false)
  const handleEmitAction = (option: any) => {
    if (hasBundle && (option.display === 'Create Bundle' || option.display === 'Add to Bundle')) {
      return
    }
    emitAction(option.type)
  }
  return (
    <DropdownTableContainer style={{ ...style }} className="dropdown-container-check">
      {displayEllipse && (
        <button type="button" className={'ttd-bare-button'} onClick={() => setShowMenu(!showMenu)}>
          <IconComponent style={{ alignSelf: 'center', cursor: 'pointer' }} icon={'more_vert'} />
        </button>
      )}

      {showMenu && options && (
        <OptionContainer>
          {options.map((option: any) => {
            return (
              <Option
                key={JSON.stringify(option)}
                onClick={() => handleEmitAction(option)}
                hasBundle={hasBundle}
                optionDisplay={option.display}
                customDisplayType={customDisplayType}
              >
                {option.display}
              </Option>
            )
          })}
        </OptionContainer>
      )}
    </DropdownTableContainer>
  )
}

export default Menu

const DropdownTableContainer: StyledComponent<
  'div',
  any,
  { style: CSSProperties | null | undefined },
  never
> = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;
`

const OptionContainer = styled.div<any>`
  position: absolute;
  height: auto;
  white-space: nowrap;
  color: black;
  top: 45px;
  right: 0px;
  box-shadow: 0px 2px 4px 0.4px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  border: 1px solid var(--grimace);
  background-color: white;
  z-index: 9999;
`

const Option: StyledComponent<
  'div',
  any,
  { hasBundle: boolean; optionDisplay: string; customDisplayType: string },
  never
> = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 25px;
  height: 47px;
  justify-content: space-between;
  ${({
    hasBundle,
    optionDisplay,
    customDisplayType,
    getStyle,
  }: {
    hasBundle: boolean
    optionDisplay: string
    customDisplayType: string
    getStyle: any
  }) => {
    if (hasBundle && (optionDisplay === 'Create Bundle' || optionDisplay === 'Add to Bundle')) {
      return `
      cursor: not-allowed;
      background: #EBEBE4;
      color:#C6C6C6;`
    } else if (customDisplayType === 'darkMode') {
      return `
      cursor: pointer;
      background: grey;
      &:hover {
        background: #dedede;`
    } else {
      return `
      cursor: pointer;
      background: white;
      &:hover {
        background: #dedede;`
    }
  }}
`
