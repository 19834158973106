import React, { useContext } from 'react'
import { RouteContext } from '../../../context/RouteContext'
import Modal from '../Atoms/Modal'
import { Confirm } from '../index'

const Renderer = () => {
  const { componentToRender, confirmNavigation, showAlert, setShowAlert } = useContext(RouteContext)
  return (
    <div style={{ height: '100%' }}>
      {componentToRender}
      {showAlert && (
        <Modal header="Warning" close={() => setShowAlert(false)}>
          <Confirm
            handleConfirm={confirmNavigation}
            handleCancel={() => {
              setShowAlert(false)
            }}
          />
        </Modal>
      )}
    </div>
  )
}

export default Renderer
