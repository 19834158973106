import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import '@asurion/sass-design-system/build/asurion/asurion.css'
import '@asurion/atomic-ui-library'
import { ButtonSet, Dropdown, FormLabel, IconComponent, Input } from '../../index'
import { IDropdownOption } from '../../Atoms/Dropdown'
import { IEjectRule } from '../../../../interfaces'
import EjectRuleRow from '../../../Rules/TrainingRules/AddTrainingRule/EjectRuleRow'
import { optionConfig } from '../../../../constants'
import { getEjectRules } from '../../../../actions'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { checkInput, checkPermissions, handleInput } from '../../../../utils'

export interface IEjectRuleDialog {
  allRules?: any[]
  index?: number
  postEjectRule: any
  cancelAddEjectRules: any
  config: any
  onEditInTrainingRule?: (e: any) => void
  formValidation?: any
}

// eslint-disable-next-line no-unused-vars
const EjectRuleDialog = (
  {
    index,
    allRules = [],
    onEditInTrainingRule,
    postEjectRule,
    cancelAddEjectRules,
    config,
    formValidation,
  }: IEjectRuleDialog,
  ref: any,
) => {
  const defaultEjectRule = useMemo(
    () => ({
      ejectRule: 'callsInQueue',
      comparator: '>',
      value: '1',
    }),
    [],
  )

  const priorityOptions = optionConfig['value']
  const unitOfMeasurementOptions = optionConfig['unitOfMeasurement']
  const unitValueOptions = optionConfig['unitValue']
  const logicalOperationsOptions = optionConfig['logicalOperations']

  const [loading, setLoading] = useState<boolean>(false)

  const [presetEjectRules, setPresetEjectRules] = useState<any[]>([])
  const [customEjectRules, setCustomEjectRules] = useState<any[]>(
    config?.rules || [defaultEjectRule],
  ) // to be defined in modal
  const [showPresetDropdown, setShowPresetDropdown] = useState<boolean>(false) // optional display
  const [presetDropdownOptions, setPresetDropdownOptions] = useState<IDropdownOption[]>([
    { value: '', display: 'No Available Eject Rules', id: 'null' },
  ])

  const [unitOfMeasurement, setUnitOfMeasurement] = useState<IDropdownOption>(
    unitOfMeasurementOptions.find(
      (option: IDropdownOption) => option.value === config?.expertsToEject?.unitOfMeasurement,
    ) || unitOfMeasurementOptions[0],
  )
  // eslint-disable-next-line no-unused-vars
  const [unitValue, setUnitValue] = useState<string>(config?.expertsToEject?.value)
  const [ejectRuleName, setEjectRuleName] = useState<string>(config.name)

  const [logicalOperationsInCustom, setLogicalOperationsInCustom] = useState<IDropdownOption>(
    logicalOperationsOptions.find((op: any) => op.value === config.logicalOperation) ||
      logicalOperationsOptions[0],
  )
  const [isActive, setIsActive] = useState<string>(config.isActive === '1' ? 'Active' : 'Inactive')

  const [selectedEjectRule, setSelectedEjectRule] = useState<any>()
  const [formValid, setFormValid] = useState<boolean>(true)

  useEffect(() => {
    if (formValidation) {
      formValidation(formValid)
    }
  }, [formValid])

  useEffect(() => {
    if (presetEjectRules.length > 0) {
      const formattedEjectRuleDropdownOptions: IDropdownOption[] =
        presetEjectRules.map((rule) => {
          return {
            value: rule.name,
            id: rule.ejectRuleId,
            display: rule.name,
          }
        }) || []
      setPresetDropdownOptions(formattedEjectRuleDropdownOptions)
    }
  }, [presetEjectRules])

  useImperativeHandle(
    ref,
    () => ({
      getState: () => {
        if (onEditInTrainingRule) {
          const rules = [...allRules]
          const buildRule = {
            expertsToEject: {
              unitOfMeasurement: unitOfMeasurement?.value,
              value: unitValue,
            },
            name: ejectRuleName,
            rules: customEjectRules,
            logicalOperation: logicalOperationsInCustom.value || '&&',
            isActive: isActive === 'Active' ? '1' : '0',
          }
          // @ts-ignore
          return buildRule
        }
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      customEjectRules,
      isActive,
      logicalOperationsInCustom,
      onEditInTrainingRule,
      ejectRuleName,
      selectedEjectRule,
      unitOfMeasurement?.value,
      unitValue,
    ],
  )

  const handleAddEjectRule = (rule: IDropdownOption, _index: number, allRules: any) => {
    const { id: key, value } = rule
    const updatedEjectRule: Partial<IEjectRule> | undefined = {
      [key]: value,
    }
    const ruleFromState = customEjectRules[_index]
    customEjectRules[_index] = Object.assign({}, ruleFromState, { ...updatedEjectRule })
    setCustomEjectRules(customEjectRules)
    // return onEditInTrainingRule && onEditInTrainingRule([...allRules])
  }

  const handleRemoveRow = (index: number) => {
    const removedList = customEjectRules.filter((rule, i) => index !== i)

    setCustomEjectRules(removedList)
  }
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
    clearErrors,
  } = useForm({
    defaultValues: {
      ejectRuleName: ejectRuleName || '',
      unitValue: unitValue || '',
      unitOfMeasurement: unitOfMeasurement || '',
    },
  })

  const handleSave = async () => {
    const buildRule = {
      ejectRuleId: config.ejectRuleId,
      expertsToEject: {
        unitOfMeasurement: unitOfMeasurement?.value,
        value: unitValue,
      },
      name: ejectRuleName,
      rules: customEjectRules,
      logicalOperation: logicalOperationsInCustom.value || '&&',
      isActive: isActive === 'Active' ? '1' : '0',
    }
    if (formValid) await postEjectRule(buildRule)
  }
  const permission = checkPermissions('rules', 'edit')

  return (
    <Flex style={{ flexDirection: 'column' }}>
      <form onSubmit={handleSubmit(handleSave)}>
        <RuleContainer>
          <Flex>
            <Flex style={{ flexDirection: 'column', marginRight: 50, width: 280 }}>
              {!onEditInTrainingRule && (
                <FormLabel id="ejectRuleName" value="Name of Eject Rule">
                  <Input
                    {...register('ejectRuleName', {
                      required: { value: true, message: 'This field is required.' },
                      validate: (value) => {
                        if (value.trim().length === 0) {
                          setFormValid(false)
                          return 'This field is required.'
                        }
                      },
                    })}
                    error={errors.ejectRuleName || null}
                    value={ejectRuleName}
                    type={'rolled'}
                    handleChange={(e) => {
                      handleInput(e, setEjectRuleName)
                      setValue('ejectRuleName', e.target.value)
                      checkInput(
                        'ejectRuleName',
                        e.target.value,
                        trigger,
                        clearErrors,
                        setFormValid,
                      )
                    }}
                  />
                </FormLabel>
              )}
              <FormLabel id="status" value="Status">
                <div
                  style={{ display: 'flex', cursor: 'pointer', width: 150 }}
                  tabIndex={0}
                  role="button"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      return setIsActive('Active')
                    }
                  }}
                  onClick={() => {
                    setIsActive('Active')
                  }}
                >
                  <IconComponent
                    icon={isActive === 'Active' ? 'radio_button_checked' : 'radio_button_unchecked'}
                  />
                  <span style={{ marginLeft: 15 }}>Active</span>
                </div>
                <div
                  style={{ display: 'flex', cursor: 'pointer', width: 150 }}
                  tabIndex={0}
                  role="button"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      return setIsActive('Inactive')
                    }
                  }}
                  onClick={() => {
                    setIsActive('Inactive')
                  }}
                >
                  <IconComponent
                    icon={
                      isActive === 'Inactive' ? 'radio_button_checked' : 'radio_button_unchecked'
                    }
                  />
                  <span style={{ marginLeft: 15 }}>Inactive</span>
                </div>
              </FormLabel>
            </Flex>
            <Flex style={{ flexDirection: 'column', marginRight: 50 }}>
              <div>
                <FormLabel id="unitValue" value="Experts to Eject">
                  <Input
                    {...register('unitValue', {
                      required: { value: true, message: 'This field is required.' },
                      validate: (value) => {
                        if (isNaN(Number(value))) {
                          setFormValid(false)
                          return 'This field requires a number.'
                        }
                        if (value.trim().length === 0) {
                          setFormValid(false)
                          return 'This field is required.'
                        }
                      },
                    })}
                    error={errors.unitValue || null}
                    value={unitValue}
                    type={'rolled'}
                    handleChange={(e) => {
                      handleInput(e, setUnitValue)
                      setValue('unitValue', e.target.value)
                      checkInput('unitValue', e.target.value, trigger, clearErrors, setFormValid)
                    }}
                  />
                </FormLabel>
              </div>
              <div>
                <FormLabel id="unitOfMeasurement" value="Unit of Measurement">
                  <Dropdown
                    type={'rolled'}
                    options={unitOfMeasurementOptions}
                    selected={unitOfMeasurement}
                    emitDropdownOption={(data) => {
                      setUnitOfMeasurement(data)
                    }}
                  />
                </FormLabel>
              </div>
            </Flex>
            {allRules.length > 1 && (
              <Flex
                style={{
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-end',
                  flexGrow: 1,
                }}
              >
                <button
                  className={'ttd-bare-button'}
                  onClick={() => {
                    const tempRules = [...allRules]
                    tempRules.splice(index!, 1)
                    onEditInTrainingRule!(tempRules)
                  }}
                >
                  <IconComponent
                    style={{ color: '#ce023f', marginTop: 20, marginLeft: 20, cursor: 'pointer' }}
                    icon="cancel"
                  />
                </button>
              </Flex>
            )}
          </Flex>
          {customEjectRules?.map((ejectRuleRow, index) => (
            <div key={JSON.stringify(ejectRuleRow)}>
              {index !== 0 && (
                <div style={{ margin: '15px 0 35px', width: 200 }}>
                  <FormLabel id="logicalOperation" value="Logical Operation">
                    <Dropdown
                      isFilter={false}
                      type={'rolled'}
                      options={logicalOperationsOptions}
                      selected={logicalOperationsInCustom}
                      emitDropdownOption={(data) => setLogicalOperationsInCustom(data)}
                    />
                  </FormLabel>
                </div>
              )}
              <EjectRuleRow
                ejectRule={ejectRuleRow}
                emitEjectRule={(rule: IDropdownOption) => {
                  if (rule) {
                    handleAddEjectRule(rule, index, allRules)
                    return rule
                  }
                }}
              >
                {index !== 0 ? (
                  <button
                    className={'ttd-bare-button'}
                    onClick={() => {
                      handleRemoveRow(index)
                    }}
                  >
                    <IconComponent
                      style={{
                        color: '#ce023f',
                        marginTop: 20,
                        marginLeft: 20,
                        cursor: 'pointer',
                      }}
                      icon="cancel"
                    />
                  </button>
                ) : (
                  <></>
                )}
              </EjectRuleRow>
            </div>
          ))}
          {customEjectRules.length < 2 && (
            <div
              tabIndex={0}
              role="button"
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  return setCustomEjectRules([...customEjectRules, defaultEjectRule])
                }
              }}
              onClick={() => setCustomEjectRules([...customEjectRules, defaultEjectRule])}
              style={{
                display: 'inline-flex',
                maxWidth: 'max-content',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <IconComponent style={{ color: '#8223d2' }} icon="add_circle_outline" />
              <span style={{ margin: '10px', color: '#8223d2' }}>Add More</span>
            </div>
          )}
        </RuleContainer>
      </form>
      {!onEditInTrainingRule && (
        <Flex style={{ justifyContent: 'flex-start' }}>
          {permission && (
            <ButtonSet
              formValid={true}
              labels={['Cancel', 'Save Changes']}
              handleSave={handleSave}
              handleCancel={cancelAddEjectRules}
            />
          )}
        </Flex>
      )}
    </Flex>
  )
}

const Flex = styled.div`
  display: flex;
`
const RuleContainer = styled.div`
  border-radius: 4px;
  padding: 15px 25px;
  border: 1px solid #a5aaaf;
  display: flex;
  flex-direction: column;
`

export default forwardRef(EjectRuleDialog)
