import React, { ChangeEvent } from 'react'
import { IconComponent, Input } from '../../index'
// import '../../../../App.css'

interface ISearch {
  handleInput: (e: ChangeEvent<HTMLInputElement>) => typeof e
  searchValue: string | undefined
  placeholder: string | undefined
  style?: any
  type?: 'box' | 'pill'
  icon?: string
}

const Search = ({ icon, handleInput, searchValue, placeholder, style, type = 'box' }: ISearch) => {
  const getClass: Record<string, string> = {
    box: `ttd-input ttd-input-box`,
    pill: `ttd-input ttd-input-pill`,
  }

  return (
    <Input
      type={type}
      style={{ ...style }}
      handleChange={handleInput}
      className={getClass[type]}
      placeholder={placeholder}
      value={searchValue}
    >
      {icon && <IconComponent className="ttd-placeholder" icon={'search'} />}
    </Input>
  )
}

export default Search
