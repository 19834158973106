import React, { SyntheticEvent } from 'react'
import styled, { StyledComponent } from 'styled-components'
import IconComponent from '../IconComponent'
import { IChip } from '../../../../interfaces/groups'

const Chip = ({
  handleClick,
  value,
  data,
  backgroundColor = '',
  color = '',
  showIcon = true,
}: IChip) => (
  <StyledChip
    tabIndex={0}
    role="button"
    onKeyPress={(e) => {
      if (e.key === 'Enter') {
        return data && handleClick(data)
      }
    }}
    onClick={() => data && handleClick(data)}
    backgroundColor={backgroundColor}
    color={color}
  >
    {showIcon && <IconComponent icon={'remove_circle'} />}
    {value}
  </StyledChip>
)

const StyledChip = styled.div<{ backgroundColor: string; color: string }>`
  margin-right: 5;
  margin-top: 5;
  width: max-content;
  padding: 3px 15px;
  border-radius: 1em;
  background-color: ${(props) => props.theme.chip.backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.chip.color};
`

export default Chip
