import { IRedisState, IRedisBody } from '../../../interfaces'
import React from 'react'
import Forms from '../Forms'
import { fetchRedisData } from '../devActions/redis'
const deriveConfigFromType = (state: IRedisState) => {
  switch (state.redisActionType.value) {
    case 'GetAssignmentMap':
      return ['empName', 'empId', 'department']
    case 'GetDepartmentsRecord':
      return ['departmentName']
    case 'GetWorkersRecord':
      return ['empName', 'empId', 'workerSid']
    case 'GetTaskQueueRecord':
      return ['empName', 'empId', 'workerSids']
    default:
      return []
  }
}

const derivePropsForForm = (state: IRedisState, dispatch: any) => {
  const config = deriveConfigFromType(state) // An Array of keys for mapping dynamic component rendering
  return { ...state, config, dispatch }
}

const deriveBodyFromState = (state: IRedisState) => {
  const body: IRedisBody = {
    actionType: state.redisActionType.id,
    ...(state.redisActionType.value === 'GetAssignmentMap' && {
      hkey: [...state.empIdsForForm, ...state.empNameSelection.map(({ empId }: any) => empId)],
      department: state.department,
    }),
    ...(state.redisActionType.value === 'GetDepartmentsMonitored' && {
      hkey: 'departmentsMonitored',
    }),
    ...(state.redisActionType.value === 'GetDepartmentsRecord' && {
      hkey: state.departmentNameSelection.map(({ groupId }: any) => groupId),
    }),
    ...(state.redisActionType.value === 'GetWorkersRecord' && {
      hkey: [...state.empIdsForForm, ...state.empNameSelection.map(({ empId }: any) => empId)],
      workerSids: state.workerSids,
    }),
    ...(state.redisActionType.value === 'GetTaskQueueRecord' && {
      hkey: [...state.workerSids, ...state.empNameSelection.map(({ workerSid }: any) => workerSid)],
      ...(state.empIdsForForm.length && {
        empId: state.empIdsForForm,
      }),
    }),
  }
  return body
}
const deriveFiltersFromType = (state: IRedisState, dispatch: any) => {
  return <Forms {...derivePropsForForm(state, dispatch)} />
}

const handleGetRedisData = async (state: IRedisState, dispatch: any) => {
  if (state.redisActionType.value === 'selectOne') return
  const body: IRedisBody = deriveBodyFromState(state)
  if (!body.department && !body.hkey?.length) return
  dispatch({ type: 'setLoading', payload: true })
  try {
    const { response = {} } = await fetchRedisData(body)
    dispatch({ type: 'setRedisData', payload: response })
  } catch (error) {
    console.error(error)
  } finally {
    dispatch({ type: 'setLoading', payload: false })
  }
}

export { deriveFiltersFromType, handleGetRedisData }
