import React, { useEffect, useState } from 'react'
import { Checkbox, Chip, Flex } from '../../common'
import styled, { StyledComponent } from 'styled-components'
import { getChildren } from '../../../actions'
import { INode2Props, IUpdatedChildren } from '../../../interfaces/configurations'
import { useSelector } from 'react-redux'
import themes from '../../../themes/schema'

const AspectTree = ({
  node,
  parentId,
  mustDisable = [],
  style,
  childGroupIds = [],
  objMap = {},
  setObjMap,
  parentNode,
  root,
  rootName,
  marked,
  parentGroupIds,
  markedObjects,
}: INode2Props) => {
  const {
    userSettings: { darkMode },
  } = useSelector((state: any) => state?.user)
  const customDisplayType = darkMode ? 'darkMode' : 'originalFlavor'
  const getStyle: Record<string, any> = {
    aspectTree_icon_color_darkMode: themes.darkMode.aspectTree.icon.color,
    aspectTree_icon_color_originalFlavor: themes.originalFlavor.aspectTree.icon.color,
  }

  const { groupName, groupId, groupTreeId } = node
  const previousGroupId = node.groupId
  const [children, setChildren] = useState<Map<string, IUpdatedChildren>>(new Map())
  const [showChildren, setShowChildren] = useState(false)
  const [checked, setChecked] = useState(false)
  const currentNode = {
    childGroupIds,
    groupId,
    groupName,
    groupTreeId,
    parentId,
    parentNode,
    parentGroupIds,
  }
  // data
  const processChildren = (id: string, newChildren: any) => {
    const formattedChildren: Array<[string, IUpdatedChildren]> = newChildren.map(
      (node: IUpdatedChildren) => [node.groupName, node],
    )
    const updatedChildren: Map<string, any> = new Map([...children, ...formattedChildren])
    const updatedObjMap = JSON.parse(JSON.stringify(objMap))
    if (updatedObjMap[rootName]) {
      // if current node is in updatedObjMap, then add all my children to updatedObjMap
      if (updatedObjMap[rootName].some((item: any) => item.groupId === groupId)) {
        const childrenArray = Array.from(updatedChildren.values()).map((item: any) => {
          item.tableName = rootName
          item.parentNode = currentNode
          return item
        })
        updatedObjMap[rootName] = [...new Set([...updatedObjMap[rootName], ...childrenArray])]
      }
    }
    if (newChildren) {
      setChildren(updatedChildren)
    } else {
      setChildren(new Map())
    }
    setObjMap(updatedObjMap)
  }

  useEffect(() => {
    if (showChildren) {
      const fetchHelper = async () => {
        await getChildren(groupId, 'parentGroupId', processChildren)
      }
      fetchHelper()
    }
  }, [showChildren])

  const watchedObjMap = objMap[rootName] ? objMap[rootName] : []
  useEffect(() => {
    if (rootName === groupName || !objMap.hasOwnProperty(rootName)) return
    let isChecked = false
    isChecked = objMap[rootName].some((item: any) => {
      return item.groupName === groupName || parentId === item.groupId
    })

    setChecked(isChecked)
  }, [watchedObjMap])

  const addToRoot = (old: {}, children: string[]) => {
    const updated = JSON.parse(JSON.stringify(old))
    if (updated[rootName]) {
      updated[rootName] = [...new Set([...updated[rootName], ...children])]
      return updated
    }
  }
  useEffect(() => {
    if (childGroupIds && checked) {
      const updated: any = addToRoot(objMap, childGroupIds)
      setObjMap(updated)
    }
  }, [])

  const handleDropdown = async () => {
    await setShowChildren(!showChildren)
  }

  const isRoot = parentId === 'root'
  const cantSelect = mustDisable.some((id: string) => id === groupId)
  const toggleDropdownVisibility = () => {
    if (isRoot) {
      return showChildren ? 'keyboard_arrow_down' : 'keyboard_arrow_up'
    } else {
      return childGroupIds && childGroupIds.length
        ? showChildren
          ? 'keyboard_arrow_down'
          : 'keyboard_arrow_up'
        : 'b'
    }
  }
  const handleCheck = async () => {
    const item = {
      groupName,
      groupId,
      parentId,
      groupTreeId,
      tableName: rootName,
      childGroupIds,
      parentNode,
      parentGroupIds,
    }

    const updatedObj = JSON.parse(JSON.stringify(objMap))
    // Table exists, and the box is not checked
    if (updatedObj[rootName] && updatedObj[rootName].length && !checked) {
      // If the group already exists in the Table, return
      if (updatedObj[rootName].some((each: any) => each.groupId === groupId)) return
      // Add item to table
      updatedObj[rootName].push(item)
      await addChildrenByParentInObjMap(item, updatedObj, parentNode)
    } else if (updatedObj && !updatedObj[rootName] && !checked) {
      // Table doesn't existand item not checked
      updatedObj[rootName] = [item]
      await addChildrenByParentInObjMap(item, updatedObj)
    } else {
      const targetArray = updatedObj[rootName]
      const groupsToRemove = [groupId]
      const addParentToRemoveArray = (parentId: string, targetArray: any[], root: any[]) => {
        const rootArray = root && root.map((item: any) => item.groupId)
        const isRoot = rootArray && rootArray.includes(parentId)
        if (isRoot) return

        groupsToRemove.push(parentId)
        const checkedParent =
          targetArray && targetArray.filter((item: any) => item.groupId === parentId)[0]
        if (checkedParent) {
          const targetParent = checkedParent.parentNode && checkedParent.parentNode.groupId
          if (targetParent) {
            addParentToRemoveArray(targetParent, targetArray, root)
          }
        }
      }
      addParentToRemoveArray(parentId, targetArray, root)

      const uncheckAllChildren = (uncheckGroupId: any, targetArray: any, groupsToRemove: any) => {
        // I: the specific groupId we want to uncheck, targetArray (copy of objMap), and all the current groupsToRemove
        // A: Push this groupId and all of the (uncheckGroupId's) children's groupIds to groupsToRemove, recursively
        // O: groupsToRemove

        const thisGroup = targetArray.find((each: any) => each.groupId === uncheckGroupId)
        if (!groupsToRemove.includes(uncheckGroupId)) {
          groupsToRemove.push(uncheckGroupId)
        }
        if (!thisGroup || !thisGroup.childGroupIds || thisGroup.childGroupIds.length < 1) return
        thisGroup.childGroupIds.forEach((eachChild: any) => {
          uncheckAllChildren(eachChild, targetArray, groupsToRemove)
        })
        return groupsToRemove
      }

      const finalRemovalArray = uncheckAllChildren(groupId, targetArray, groupsToRemove)

      const updatedArr =
        targetArray &&
        targetArray.filter((record: any) => {
          const shouldRemove = groupsToRemove && groupsToRemove.includes(record.groupId)
          return !shouldRemove
        })

      if (updatedArr && updatedArr.length === 0) {
        delete updatedObj[rootName]
        if (showChildren) {
          handleDropdown()
        }
      } else {
        updatedObj[rootName] = updatedArr
      }
    }
    setObjMap(updatedObj)
    setChecked(!checked)
  }

  const addChildrenByParentInObjMap = async (
    item: any,
    updatedObjMap: any,
    parentNode: any = {}, // needed to add parents recursively
  ) => {
    if (parentNode?.childGroupIds) {
      // check if the last sibling is being checked
      const tempExistingSiblings = [...updatedObjMap[rootName], { groupId: item.groupId }]
      const allSiblingsChecked =
        parentNode.childGroupIds.filter((childId: string) => {
          return tempExistingSiblings.some((node: any) => {
            return node.groupId === childId
          })
        }).length === parentNode.childGroupIds.length
      if (allSiblingsChecked && parentNode.parentId !== 'root') {
        // add parent to objMap
        await addChildrenByParentInObjMap(parentNode, updatedObjMap, parentNode.parentNode)
      }
    }
    // does not exist in updatedObjMap
    if (
      updatedObjMap[rootName] &&
      !updatedObjMap[rootName].some((each: any) => each.groupId === item.groupId)
    ) {
      addToObjMapDedupe(item, updatedObjMap)
    }
    // base case, no chilren
    if (!item.childGroupIds) return
    const arrayOfChildren = await getChildren(
      item.groupId,
      'parentGroupId',
      (id: any, map: any) => {
        return map.map((child: any) => ({ ...child, parentNode: item }))
      },
    )
    if (arrayOfChildren && arrayOfChildren.length) {
      arrayOfChildren.forEach(async (eachItem: any) => {
        await addChildrenByParentInObjMap(eachItem, updatedObjMap)
      })
    }
  }
  // prevents duplicates from adding to the objMap?
  const addToObjMapDedupe = (item: any, updatedObjMap: any) => {
    const key = root.find((each: any) => each.groupTreeId === item.groupTreeId)
    const duplicateGroupId = updatedObjMap[key.groupName].find(
      (each: any) => each.groupId === item.groupId,
    )
    if (duplicateGroupId) {
      return
    }
    const {
      childGroupIds,
      groupId,
      groupName,
      groupTreeId,
      tableName = key.groupName,
      parentGroupId: parentId,
      parentNode,
      parentGroupIds,
    } = item
    updatedObjMap[key.groupName].push({
      childGroupIds,
      groupId,
      groupName,
      groupTreeId,
      parentId,
      tableName,
      parentNode,
      parentGroupIds,
    })
  }

  return (
    <div style={{ ...style }}>
      <Flex
        config={['alignCenter']}
        style={{
          width: '100%',
          padding: '15px 0',
          borderBottom: '1px solid #dfdfdf',
          marginRight: '2px',
          fontStyle: cantSelect ? 'italic' : '',
          color: cantSelect ? 'grey' : '',
          cursor: cantSelect ? 'not-allowed' : 'auto',
        }}
      >
        <StyledActionableIcon
          type="button"
          style={{
            cursor: cantSelect ? 'not-allowed' : 'pointer',
            color: getStyle[`aspectTree_icon_color_${customDisplayType}`],
          }}
          className="material-icons"
          onClick={() => (cantSelect ? null : handleDropdown())}
        >
          {toggleDropdownVisibility()}
        </StyledActionableIcon>
        {!isRoot && (
          <Checkbox
            style={{ cursor: cantSelect ? 'not-allowed' : 'auto' }}
            checked={checked}
            onClick={() => {
              return cantSelect ? null : handleCheck()
            }}
          />
        )}
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {groupName}
          {((markedObjects && markedObjects.includes(groupId)) || checked || marked) && <Mark />}
        </div>
      </Flex>
      {showChildren &&
        Array.from(children.values()).map(
          ({
            groupName = '',
            groupId = '',
            parentGroupId: parentId = '',
            childGroupIds,
            groupTreeId = '',
            parentGroupIds = [],
          }) => {
            const childNode = {
              groupName,
              groupTreeId,
              groupId,
              parentId,
              parentNode: currentNode,
              parentGroupIds,
            }
            return (
              childNode.parentId === previousGroupId && (
                <AspectTree
                  key={groupId}
                  node={childNode}
                  childGroupIds={childGroupIds}
                  parentNode={currentNode}
                  mustDisable={mustDisable}
                  parentId={parentId}
                  style={{ marginLeft: 30 }}
                  objMap={objMap}
                  setObjMap={setObjMap}
                  rootName={rootName}
                  root={root}
                  parentGroupIds={parentGroupIds}
                  markedObjects={markedObjects}
                />
              )
            )
          },
        )}
    </div>
  )
}

export default AspectTree

const StyledActionableIcon: StyledComponent<'button', any, {}, never> = styled.button`
  cursor: pointer;
  background: none;
  color: #5c1697;
  border: none;
  :active {
    background: none;
  }
  :focus {
    background: none;
  }
`
const Mark: StyledComponent<'div', any, {}, never> = styled.div`
  height: 1rem;
  width: 1rem;
  background-color: var(--grimace);
  border-radius: 1rem;
  margin-right: 10px;
`
