import React from 'react'
import { IconComponent } from '../../../index'
import { IDateFilter } from '../../../../../interfaces'
import styled, { StyledComponent } from 'styled-components'
import { formatCamelCase } from '../../../../../utils'
import { format, parseISO } from 'date-fns'
interface IProps {
  from: string
  name: string
  to: string
  dateFilters: IDateFilter
  setDateFilters: (dateFilters: IDateFilter) => void
}
const DateFilter = ({ from, name, to, dateFilters, setDateFilters }: IProps) => {
  const title = formatCamelCase(name)
  const handleRemoveFilter = () => {
    const updatedFilters = {
      ...dateFilters,
      [name]: { from: '', to: '' },
    }
    setDateFilters(updatedFilters)
  }
  return (
    <Wrapper>
      <p className={'title'}>{title}</p>
      <div className={'fromToContainer'}>
        <div>
          <p>From:</p>
          <p>{format(parseISO(from), 'MM/dd/yyyy')}</p>
        </div>
        <div>
          <p>To:</p>
          <p>{format(parseISO(to), 'MM/dd/yyyy')}</p>
        </div>
      </div>
      <button className={'ttd-bare-button'} onClick={handleRemoveFilter}>
        <IconComponent
          style={{
            fontSize: '1rem',
            margin: '4px 0',
          }}
          icon="close"
        />
      </button>
    </Wrapper>
  )
}

export default DateFilter

const Wrapper = styled.div`
  display: flex;
  border: 1px solid purple;
  border-radius: 4px;
  font-size: 0.8rem;
  height: 2.4rem;
  margin: 8px;
  .title {
    font-size: 1rem;
    font-weight: bold;
    align-self: center;
    margin: 0 0.8rem;
  }
  .fromToContainer {
    display: flex;
    min-width: 10.5rem;
    justify-content: space-around;
    p {
      height: 1rem;
      margin: 0;
    }
    .ttd-bare-button {
      display: flex;
    }
  }
`
