import React, { HTMLAttributes, useContext } from 'react'
import { Button, Flex } from '../../index'
import { GroupsContext } from '../../../../context/GroupsContext'
import { IButtonSet } from '../../../../interfaces'

const ButtonSet = ({ id = '', handleSave, handleCancel, labels, formValid, style }: IButtonSet) => {
  const [cancel = 'Cancel', save = 'Save'] = labels || []
  return (
    <Flex config={['justifyStart']} style={{ margin: '35px 0', ...style }}>
      {formValid && (
        <Button id={`${id}-save`} size="lg" handleClick={handleSave}>
          {save}
        </Button>
      )}
      <Button
        id={`${id}-cancel`}
        displayType="outline"
        size="lg"
        handleClick={handleCancel}
        className="is-primary is-ghost"
        style={{ marginLeft: 25 }}
      >
        {cancel}
      </Button>
    </Flex>
  )
}

export default ButtonSet
