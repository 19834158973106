import {defaultDropdown} from './index'

const optionConfig: Record<string, any> = {
  opsHierarchyOptions: [
    {
      value: 'agent',
      display: 'Agent',
      id: 'Agent',
    },
    {
      value: 'manager',
      display: 'Manager',
      id: 'Manager',
    },
    {
      value: 'coach',
      display: 'Supervisor',
      id: 'Coach',
      checked: false,
    },
  ],
  dbSelectionOptions: [
    {
      value: 'RDS',
      display: 'RDS',
      id: 'RDS',
    },
    {
      value: 'Dynamo',
      display: 'Dynamo',
      id: 'Dynamo',
    },
  ],
  dataPipelineOptions: [
    {value: 'EmpGroups', display: 'EmpGroups', id: 'EmpGroups'},
    {value: 'WorkersXrefs', display: 'WorkersXrefs', id: 'WorkersXrefs'},
    {value: 'RdsEmpGroups', display: 'RdsEmpGroups', id: 'RdsEmpGroups'},
    {value: 'RdsWorkers', display: 'RdsWorkers', id: 'RdsWorkers'},
    {value: 'Workers', display: 'Workers', id: 'Workers'},
    {
      value: 'MyAbsorbReconciliation',
      display: 'MyAbsorbReconciliation',
      id: 'MyAbsorbReconciliation',
    },
    {
      value: 'ReportingDistribution',
      display: 'ReportingDistribution',
      id: 'ReportingDistribution',
    },
  ],
  dateHierarchyOptions: [
    {
      value: 'day',
      display: 'Day',
      id: 'day',
    },
    {
      value: 'month',
      display: 'Month',
      id: 'month',
    },
    {
      value: 'year',
      display: 'Year',
      id: 'year',
    },
  ],
  employeeStatusOptions: [
    {
      value: null,
      display: 'All',
      id: 'employeeStatusOptions',
    },
    {
      value: '1',
      display: 'Active',
      id: 'employeeStatusOptions',
    },
    {
      value: '0',
      display: 'Inactive',
      id: 'employeeStatusOptions',
    },
  ],
  statusOptions: [
    {
      value: 'completed',
      display: 'Complete',
      id: 'assignmentStatus',
      checked: false,
    },
    {
      value: 'incomplete',
      display: 'Incomplete',
      id: 'assignmentStatus',
      checked: false,
    },
  ],
  dateKeyOptions: [
    {value: 'startdate', display: 'Start Date', id: 'startdate'},
    {value: 'duedate', display: 'Due Date', id: 'duedate'},
    {value: 'enddate', display: 'End Date', id: 'enddate'},
  ],
  dateRangeOptions: [
    {value: 'today', display: 'Today', id: 'today'},
    {value: 'yesterday', display: 'Yesterday', id: 'yesterday'},
    {value: 'weekToDate', display: 'Week To Date', id: 'weekToDate'},
    {value: 'lastFullWeek', display: 'Last Full Week', id: 'lastFullWeek'},
    {value: 'monthToDate', display: 'Month To Date', id: 'monthToDate'},
    {value: 'lastFullMonth', display: 'Last Full Month', id: 'lastFullMonth'},
    {value: 'quarterToDate', display: 'Quarter To Date', id: 'quarterToDate'},
    {value: 'lastFullQuarter', display: 'Last Full Quarter', id: 'lastFullQuarter'},
    {value: 'custom', display: 'Custom Date Range', id: 'custom'},
  ],
  reportListOptions: [
    {id: 'selectOne', value: 'selectOne', display: 'Select One'},
    {id: 'Assignments', value: 'Assignments', display: 'Real Time'},
    {id: 'Daily/Monthly', value: 'Daily/Monthly', display: 'Daily/Monthly'},
    {id: 'FusionUserAudit', value: 'FusionUserAudit', display: 'Fusion User Audit'},
    {id: 'MascotStatistics', value: 'MascotStatistics', display: 'Mascot Statistics'},
    {id: 'TrainingStatus', value: 'TrainingStatus', display: 'Training Status'},
    {id: 'TrainingCompletion', value: 'TrainingCompletion', display: 'Training Completion'},
    {id: 'TrainingDelivery', value: 'TrainingDelivery', display: 'Training Delivery'},
    {id: 'TrainingSummary', value: 'TrainingSummary', display: 'Training Summary'},
    {id: 'UserEventAudit', value: 'UserEventAudit', display: 'User Event Audit'},
  ],
  RedisListOptions: [
    {id: 'selectOne', value: 'selectOne', display: 'Select One'},
    {id: 'getAssignmentMap', value: 'GetAssignmentMap', display: 'Get Assignment Map'},
    {
      id: 'getDepartmentsMonitored',
      value: 'GetDepartmentsMonitored',
      display: 'Get Departments Monitored',
    },
    {
      id: 'getDepartmentsRecord',
      value: 'GetDepartmentsRecord',
      display: 'Get Departments Record',
    },
    {
      id: 'getTaskQueueRecord',
      value: 'GetTaskQueueRecord',
      display: 'Get TaskQueue Records',
    },
    // {
    //   id: 'getWorkersRecord',
    //   value: 'GetWorkersRecord',
    //   display: 'Get Workers Record',
    // },
  ],
  hours: [...Array(24).keys()].map((val) => ({
    value: val < 10 ? `0${val}` : val.toString(),
    display: val < 10 ? `0${val}` : val.toString(),
    id: 'priority',
  })),
  isActive: [
    {
      value: '1',
      display: 'Active',
      id: 'isActive',
    },
    {
      value: '0',
      display: 'Inactive',
      id: 'isActive',
    },
  ],
  isActiveOptions: [
    {
      value: '1',
      display: 'Active',
      id: 'isActive',
    },
    {
      value: '0',
      display: 'Inactive',
      id: 'isActive',
    },
  ],
  logicalOperations: [
    {value: '&&', display: 'AND', id: '&&'},
    {value: '||', display: 'OR', id: '||'},
  ],
  maxDispatchLimitTypes: [
    {
      value: 'attempts',
      display: 'Attempts',
      id: 'attempts',
    },
    {
      value: 'percent',
      display: 'Percent',
      id: 'percent',
    },
  ],
  minutes: ['00', '15', '30', '45'].map((val) => ({
    value: val,
    display: val,
    id: 'priority',
  })),
  monitor: [
    {
      value: '0',
      display: 'None',
      id: 'monitor',
    },
    {
      value: '1',
      display: 'Mouse / Keyboard',
      id: 'monitor',
    },
    {
      value: '2',
      display: 'Focus',
      id: 'monitor',
    },
    {
      value: '3',
      display: 'Focus and Mouse / Keyboard',
      id: 'monitor',
    },
  ],
  priority: [
    {
      value: '0',
      display: 'Low',
      id: 'priority',
    },
    {
      value: '1',
      display: 'Medium',
      id: 'priority',
    },
    {
      value: '2',
      display: 'High',
      id: 'priority',
    },
  ],
  sideNavigationMenu: [
    {
      value: 'trainings',
      display: 'Trainings',
      id: '0',
    },
    {
      value: 'assignments',
      display: 'Assignments',
      id: '1',
    },
    {
      value: 'reporting',
      display: 'Reporting',
      id: '2',
    },
    {
      value: 'rules',
      display: 'Rules',
      id: '4',
    },
    {
      value: 'configuration',
      display: 'Configuration',
      id: '5',
    },
    {
      value: 'dev',
      display: 'Dev',
      id: '6',
    },
    {
      value: 'fusion',
      display: 'Fusion',
      id: '7',
    },
    {
      value: 'broadcast',
      display: 'Broadcast',
      id: '8',
    },
  ],

  unitOfMeasurement: [
    {
      value: 'expert',
      display: 'Expert',
      id: 'unitOfMeasurement',
    },
    {
      value: 'percent',
      display: 'Percent',
      id: 'unitOfMeasurement',
    },
  ],
  unitValue: [...Array(100).keys()].map((val) => ({
    value: (val + 1).toString(),
    display: (val + 1).toString(),
    id: 'unitValue',
  })),
  value: [...Array(100).keys()].map((val) => ({
    value: (val + 1).toString(),
    display: (val + 1).toString(),
    id: 'value',
  })),
  weight: [...Array(99).keys()].map((val) => ({
    value: (val + 1).toString(),
    display: (val + 1).toString(),
    id: 'priority',
  })),
  ejectRule: [
    {
      value: 'callsInQueue',
      display: 'Calls In Queue',
      id: 'ejectRule',
    },
    {
      value: 'longestWait',
      display: 'Longest Wait',
      id: 'ejectRule',
    },
  ],
  comparator: [
    {
      value: '<',
      display: 'Less Than',
      id: 'comparator',
    },
    {
      value: '>',
      display: 'Greater Than',
      id: 'comparator',
    },
    {
      value: '<=',
      display: 'Less Than Or Equal To',
      id: 'comparator',
    },
    {
      value: '>=',
      display: 'Greater Than Or Equal To',
      id: 'comparator',
    },
    {
      value: '===',
      display: 'Exactly',
      id: 'comparator',
    },
    {
      value: '!==',
      display: 'Not',
      id: 'comparator',
    },
  ],
}
const bulkEditOptions = [
  {id: 'duration', value: 'duration', display: 'Duration'},
  {id: 'maxDelivery', value: 'maxDelivery', display: 'Max Delivery'},
  {id: 'monitorMethod', value: 'monitorMethod', display: 'Monitor Method'},
  {id: 'priority', value: 'priority', display: 'Priority'},
  {id: 'status', value: 'status', display: 'Status'},
  {id: 'tags', value: 'tags', display: 'Tags'},
  {id: 'notes', value: 'notes', display: 'Notes'},
]

export {optionConfig, bulkEditOptions}
