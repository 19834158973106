import { useState } from 'react'

import { LocalStorageItem } from '../../data/datatypes'

export const setWithExpiry = (key: any, value: any, ttl: any = 0) => {
  const now = new Date()

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = new LocalStorageItem(value, now.getTime() + ttl, ttl)
  window.localStorage.setItem(key, JSON.stringify(item))
}

export const itemFromStorageOrInitialValue = (item: any, initialValue: any) =>
  item ? JSON.parse(item) : initialValue

const timeComparison = (timestamp: any, expiry: any) => timestamp > expiry
const isPermanentItem = (ttl: any) => ttl === 0
const isNotInStorage = (item: any) => !item

export const getValueFromLocalStorage = (key: any, initialValue: any) => {
  try {
    // Get from local storage by key
    const item: any = window.localStorage.getItem(key)
    // Parse stored json or if none return initialValue
    if (isNotInStorage(item)) return initialValue
    const itemToJSON = JSON.parse(item)
    if (isPermanentItem(itemToJSON.ttl)) return itemToJSON.value

    const now = new Date()
    if (timeComparison(now.getTime(), itemToJSON.expiry)) {
      localStorage.removeItem(key)
      return initialValue
    }

    return itemToJSON
  } catch (error) {
    // If error also return initialValue
    console.error(error)
    return initialValue
  }
}

export const useLocalStorage = (key: any, initialValue: any) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue] = useState(() => getValueFromLocalStorage(key, initialValue))

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: any, ttl = 0) => setWithExpiry(key, value, ttl)

  return [storedValue, setValue]
}


