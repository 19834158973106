import { IRedisState, IReducer } from '../../interfaces'
import { defaultDropdown } from '../../constants'

export const redisInitialState: IRedisState = {
  empIds: [],
  empNameSelection: [],
  departmentName: [],
  department: '',
  workerSids: [],
  workerSid: '',
  redisActionType: defaultDropdown,
  empIdsForForm: [],
  empIdValue: '',
  loading: false,
  expand: false,
  departmentNameSelection: [],
  redisData: {},
  toggleJSON: 'JSON',
}

export const redisReducer = (state: IRedisState, action: IReducer) => {
  switch (action.type) {
    case 'setEmpIds':
      return {
        ...state,
        empIds: action.payload,
      }
    case 'setEmpNameSelection':
      return {
        ...state,
        empNameSelection: action.payload,
      }
    case 'setDepartment':
      return {
        ...state,
        department: action.payload,
      }

    case 'setDepartmentName':
      return {
        ...state,
        departmentName: action.payload,
      }
    case 'setDepartmentNameSelection':
      return {
        ...state,
        departmentNameSelection: action.payload,
      }
    case 'setRedisActionType':
      return {
        ...state,
        redisActionType: action.payload,
      }
    case 'setEmpIdValue':
      return {
        ...state,
        empIdValue: action.payload,
      }
    case 'setEmpIdsForForm':
      return {
        ...state,
        empIdsForForm: action.payload,
      }
    case 'setLoading':
      return {
        ...state,
        loading: action.payload,
      }
    case 'setRedisData':
      return {
        ...state,
        redisData: action.payload,
      }
    case 'setToggleJSON':
      return {
        ...state,
        toggleJSON: action.payload,
      }
    case 'setExpand':
      return {
        ...state,
        expand: action.payload,
      }
    case 'setWorkerSids':
      return {
        ...state,
        workerSids: action.payload,
      }
    case 'setWorkerSid':
      return {
        ...state,
        workerSid: action.payload,
      }
    default:
      return { ...state }
  }
}
