import React, {
  useState,
  createRef,
  useEffect,
  SyntheticEvent,
  useContext,
  useReducer,
} from 'react'
import styled, { StyledComponent } from 'styled-components'
import { Flex, IconComponent, Loading } from '../../index'
import useDebounce from '../../../../hooks/UseDebounce'
import { fusionInitialState, fusionReducer } from '../../../../reducers/TTDReducers/fusionReducer'
import { FusionContext } from '../../../../context/FusionContext'

const TaskQueueLookup = ({
  selections,
  handleSelect,
}: {
  selections: any[]
  handleSelect: any
}) => {
  const { queues } = useContext(FusionContext)
  const [state, dispatch] = useReducer(fusionReducer, fusionInitialState)

  const [scrollEnd, setScrollEnd] = useState<boolean>(false)
  const [showDropdownOptions, setShowDropdownOptions] = useState<boolean>(!close || false)
  const [searchedOptions, setSearchedOptions] = useState<any[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [top, setTop] = useState<number>(53)
  const debouncedValue = useDebounce(searchValue, 350)
  const formatTaskQueuesForDropdown = (list: any[]) => {
    const compare = (a: any, b: any) => {
      if (a.taskqueueName < b.taskqueueName) {
        return -1
      }
      if (a.taskqueueName > b.taskqueueName) {
        return 1
      }
      return 0
    }
    return list.sort(compare)
  }
  const dispatcher = (dispatch: any, type: string, payload: any) => {
    dispatch({ type, payload })
  }

  useEffect(() => {
    setShowDropdownOptions(true)
    ;(async () => {
      try {
        const orderedList = formatTaskQueuesForDropdown(queues)
        dispatcher(dispatch, 'setTaskQueueList', orderedList)
        setSearchedOptions(orderedList)
        return
      } catch (e) {
        console.error(e)
      }
    })()
  }, [debouncedValue])

  const handleDropDown: () => void = () => {
    setShowDropdownOptions(true)
  }
  const scrollRef = createRef<HTMLDivElement>()
  const boundingBox = createRef<HTMLDivElement>()
  useEffect(() => {
    const currentRef = scrollRef.current
    if (currentRef) {
      currentRef.addEventListener('scroll', () => {
        return setScrollEnd(currentRef.scrollTop + 55 >= 165)
      })
    }
    return
  }, [scrollRef, scrollEnd])

  useEffect(() => {
    const currentRef = boundingBox.current
    if (currentRef) {
      const boundingClient = currentRef.getBoundingClientRect()
      setTop(boundingClient.height)
    }
    return
  }, [boundingBox])

  useEffect(() => {
    document.addEventListener('mousedown', (e) => {
      const selector = e.target as HTMLDivElement
      if (!selector.closest('.dropdown-container-check')) {
        setShowDropdownOptions(false)

        return () => {
          document.removeEventListener('mousedown', () => {})
        }
      }
    })
    document.addEventListener('mouseout', (e) => {
      const selector = e.target as HTMLDivElement
      if (!selector.closest('.dropdown-container-check')) {
        setShowDropdownOptions(false)

        return () => {
          document.removeEventListener('mouseout', () => {})
        }
      }
    })
    return
  }, [showDropdownOptions, setShowDropdownOptions])
  const regEx = new RegExp(searchValue, 'i')

  return (
    <DropdownContainer className="dropdown-container-check">
      <StyledInputContainer ref={boundingBox} onClick={handleDropDown}>
        <Flex config={['wrap']} style={{ marginRight: 5, maxWidth: '450px' }}>
          {selections.map((queue: any) => (
            <StyledChip key={queue.taskqueueName}>
              {queue.taskqueueName}
              <button
                style={{ color: 'white' }}
                className={'ttd-bare-button'}
                onClick={() => handleSelect(queue, 'remove')}
              >
                <IconComponent icon={'close'} />
              </button>
            </StyledChip>
          ))}
          <StyledInput
            onChange={(e: SyntheticEvent<HTMLInputElement>) => {
              setSearchValue(e.currentTarget.value)
            }}
            value={searchValue}
            placeholder={'Search Task Queues'}
          />
        </Flex>
      </StyledInputContainer>
      {showDropdownOptions && (
        <OptionContainer top={top} ref={scrollRef}>
          <div style={{ overflow: 'auto' }}>
            {searchedOptions
              .filter((item) => regEx.test(item.taskqueueName))
              .map((taskQueue: any) => {
                //Dedupe dropdown
                if (selections.some((item: any) => item.taskqueueName === taskQueue.taskqueueName))
                  return false
                return (
                  <Option
                    tabIndex={0}
                    onClick={() => {
                      handleSelect(taskQueue, 'add')
                      setSearchValue('')
                      setSearchedOptions([])
                    }}
                    key={taskQueue.taskqueueSid}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    >
                      {taskQueue.taskqueueName}
                    </div>
                  </Option>
                )
              })}
          </div>
        </OptionContainer>
      )}
      {/*{!scrollEnd && showDropdownOptions && options.length > 3 && <WhiteOverlay />}*/}
    </DropdownContainer>
  )
}

export default TaskQueueLookup

const DropdownContainer: StyledComponent<'div', any, {}, never> = styled.div`
  position: relative;
`

const OptionContainer: StyledComponent<'div', any, { top: number }, never> = styled.div`
  position: absolute;
  min-width: 100%;
  width: max-content;
  overflow-y: scroll;
  max-height: 300px;
  box-shadow: 0px 2px 4px 0.4px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  background-color: white;
  z-index: 5;
  top: ${({ top }: { top: number }) => top}px;
`

const Option: StyledComponent<'div', any, {}, never> = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 25px;
  height: 47px;
  min-width: 100%;
  width: max-content;
  border-bottom: 1px solid #dedede;
  cursor: pointer;
  background: white;

  &:hover {
    background: #dedede;
  }
`
const WhiteOverlay: StyledComponent<'div', any, {}, never> = styled.div`
  height: 20px;
  width: 100%;
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 5;
  position: absolute;
  bottom: -165px;
`

const StyledInputContainer: StyledComponent<
  'div',
  {
    onClick: SyntheticEvent
  },
  {},
  never
> = styled.div`
  background-color: white;
  min-height: 48px;
  border: 1px solid #a5aaaf;
  border-radius: 4px;
  padding: 4px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    border-color: #000;
  }

  &:active {
    border-color: #8223d2;
  }
`

const StyledInput: StyledComponent<'input', any, {}, never> = styled.input`
  margin: 0;
  width: 100%;
  height: 26px;
  border: none;
  font-size: 1.08em;
  outline: none;

  &:focus {
    outline: none;
    border: none;
  }

  &:hover {
    outline: none;
    border: none;
  }
`

const StyledChip: StyledComponent<
  'div',
  {
    onClick: SyntheticEvent
  },
  {},
  never
> = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  background: black;
  color: white;
  border-radius: 32px;
  padding: 1px 5px;
`
