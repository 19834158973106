import React, {useState} from 'react'
import {tooltipMap} from '../../../../constants/configurations'

interface ITooltip {
  children?: any
  content?: string
  delay?: number
  direction?: string
  selector?: string
}

const Tooltip = ({children, content = '', delay = 200, direction = '', selector = ''}: ITooltip) => {
  let timeout: any

  const [active, setActive] = useState(false)

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true)
    }, delay)
  }

  const hideTip = () => {
    clearInterval(timeout)
    setActive(false)
  }

  return (
    <div className="Tooltip-Wrapper" onMouseEnter={showTip} onMouseLeave={hideTip}>
      {children}
      {active && (
        <div className={`Tooltip-Tip ${direction}`} style={{width: '10em'}}>
          {tooltipMap[selector]}
        </div>
      )}
    </div>
  )
}

export default Tooltip
