import { Button, FormLabel } from '../../common'
import { Input } from '../devComponents/input/input'
import TextArea from '../devComponents/input/textArea'
import React, { SyntheticEvent } from 'react'
import styled, { StyledComponent } from 'styled-components'

const RedisForm = ({
  handleInput,
  name,
  nameLabel,
  input,
  inputLabel,
  handleButtonClick,
  index,
  index1 = 0,
}: {
  name: string
  nameLabel: string
  input: string
  inputLabel: string
  index: number
  index1?: number
  handleInput: (e: SyntheticEvent<HTMLInputElement>, x: number, y: number) => void
  handleButtonClick: (x: number, y: number, z: string) => void
}) => {
  const inputOrTextArea = input.length > 27
  return (
    <>
      <PrettyContainer>
        <FormLabel
          id="ttd-name"
          value={nameLabel}
          style={{ marginTop: 3, fontSize: 18, marginBottom: 3 }}
        >
          <FormText>{name}</FormText>
        </FormLabel>

        {input && (
          <FormLabel id="ttd-name" value={inputLabel} style={{ fontSize: 18, paddingLeft: 12 }}>
            {inputOrTextArea ? (
              <TextArea
                placeholder={name}
                style={{ width: 250, marginBottom: 0 }}
                value={input}
                handleChange={(e: any) => {
                  handleInput(e, index, index1)
                }}
              ></TextArea>
            ) : (
              <Input
                placeholder={name}
                style={{ width: 250, height: 32, marginBottom: 0 }}
                value={input}
                handleChange={(e: any) => {
                  handleInput(e, index, index1)
                }}
              />
            )}
          </FormLabel>
        )}
        {input && (
          <ButtonHolder inputOrTextArea={inputOrTextArea}>
            <Button
              size={'sm'}
              style={{ marginRight: 13 }}
              handleClick={() => {
                handleButtonClick(index, index1, 'update')
              }}
            >
              Update
            </Button>
            <Button
              displayType={'outline'}
              size={'sm'}
              handleClick={() => {
                handleButtonClick(index, index1, 'delete')
              }}
            >
              Remove
            </Button>
          </ButtonHolder>
        )}
      </PrettyContainer>
    </>
  )
}

export default RedisForm

const FormText: StyledComponent<'div', any, {}, never> = styled.div`
  overflow-wrap: break-word;
  width: 200px;
  font-size: 18px;
`
const ButtonHolder: StyledComponent<'div', any, { inputOrTextArea: boolean }, never> = styled.div`
  margin-top: ${({ inputOrTextArea }: { inputOrTextArea: boolean }) =>
    inputOrTextArea ? '35px' : '25px'};
  display: flex;
`
const PrettyContainer: StyledComponent<'div', any, {}, never> = styled.div`
  display: flex;

  flex-direction: row;
  max-width: 780px;
`
