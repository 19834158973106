import React, { useState } from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { Flex, IconComponent } from '../../'

// Make Sortable accept headers and data

const SortableItem = SortableElement(
  ({ trainingName, percentComplete, sequence, deleteRow }: any) => (
    <Flex>
      <div style={{ display: 'flex', flexDirection: 'row' }} className={'ttd-sortable-item'}>
        <div
          style={{
            width: '200px',
            padding: '5px 0',
            cursor: 'grab',
            overflowWrap: 'break-word',
          }}
        >
          {trainingName}
        </div>
        <div style={{ width: '125px', padding: '5px 0', cursor: 'grab' }}>{percentComplete}</div>
        <div style={{ width: '75px', padding: '5px 0', cursor: 'grab', marginLeft: 20 }}>
          {sequence + 1}
        </div>
      </div>
      <button
        style={{ cursor: 'pointer', color: '#8223d2' }}
        className="ttd-bare-button"
        onClick={() => deleteRow(sequence)}
      >
        <IconComponent style={{ width: '100px', fontSize: 17, paddingTop: 3 }} icon="close" />
      </button>
    </Flex>
  ),
)

const SortableList = SortableContainer(({ children }: { children: any }) => {
  return <div>{children}</div>
})

const Sortable = ({ data = [], onSortEnd, deleteRow }: any) => {
  return (
    <Flex config={['column']} style={{ marginBottom: 15 }}>
      <Flex style={{ fontWeight: 700, marginBottom: 5 }}>
        <div style={{ width: '200px' }}>Name</div>
        <div style={{ width: '125px' }}>% Complete</div>
        <div style={{ width: '75px', marginLeft: 20 }}>Sequence</div>
      </Flex>

      <SortableList distance={10} onSortEnd={onSortEnd}>
        {data &&
          data.length > 0 &&
          data.filter(Boolean).map((value: any, index: any) => {
            return (
              <SortableItem
                key={`item-${JSON.stringify(value)}`}
                index={index}
                trainingName={value.trainingName}
                percentComplete={value.trainingPercentComplete}
                sequence={index}
                deleteRow={deleteRow}
              />
            )
          })}
      </SortableList>
    </Flex>
  )
}

export default Sortable
