import { grey } from '@material-ui/core/colors'

const darkMode = {
  '--bold-header': '#6efac3',
  '--subtle-contrast-color': '#555',
  '--neutral-white': 'whitesmoke',
  '--modal-backdrop': 'grey',
  '--text-color': 'rgba(240, 240, 245, 1)',
  '--mdc-theme-secondary': '#4a68f9',
  '--mdc-theme-primary': 'rgba(168, 57, 243, 1)',
  '--asurion-purple': 'rgba(168, 57, 243, 1)',
  '--background-color-offset': '#191919',
  '--background-color': '#262626',
  '--expanded-row': '#363636',
  '--asurion-light-purple': 'rgba(203, 90, 255, 0.7)',
  '--background-hover-color': 'rgba(168, 57, 243, 0.1)',
}
const root = {
  '--cloud-fart': '#f0f0f5',
  '--gulag-of-my-soul': '#6e767d',
  '--grimace': '#8223d2',
  '--diet-grimace': '#f3e9fb',
}
const themes = {
  disabled: {
    backgroundColor: '#E7E9EB',
  },
  darkMode: {
    accordion: {
      color: darkMode['--text-color'],
    },
    aspectTree: { icon: { color: darkMode['--text-color'] } },
    button: {
      disabled: '#E7E9EB',
      backgroundColor: darkMode['--asurion-light-purple'], //#8223d2
      color: darkMode['--text-color'],
      hover: darkMode['--asurion-light-purple'],
      focus: darkMode['--asurion-light-purple'],
      outline: {
        border: `3px solid ${darkMode['--asurion-light-purple']}`,
        backgroundColor: darkMode['--background-color'],
        color: darkMode['--asurion-light-purple'],
      },
      ghost: {
        border: 'none',
        backgroundColor: 'transparent',
        color: root['--grimace'],
        textDecoration: 'underline',
      },
    },
    checkbox: { color: darkMode['--asurion-light-purple'] },
    chip: {
      backgroundColor: darkMode['--background-color'],
      color: darkMode['--text-color'],
    },
    datePicker: {
      border: {
        show: `2px solid #8223d2`,
        hover: `2px solid #000`,
        default: `2px solid #a5aaaf`,
      },
      backgroundColor: darkMode['--background-color'],
      color: darkMode['--text-color'],
      label: {
        show: `#8223d2`,
        hover: '#000',
        default: `#a5aaaf`,
      },
    },
    dragAndDrop: {
      backgroundColor: darkMode['--expanded-row'],
      dragging: {
        backgroundColor: '#f3e9fb',
      },
    },
    dropdown: {
      option: {
        backgroundColor: darkMode['--expanded-row'],
        color: darkMode['--text-color'],
        hoverText: darkMode['--background-color'],
      },
      color: darkMode['--text-color'],
      backgroundColor: darkMode['--background-color'],
      input: { backgroundColor: darkMode['--background-color'], borderColor: 'white' },
    },
    header: { backgroundColor: darkMode['--background-color'], color: 'white' },
    iconComponent: {
      colors: {
        dataNode: 'white',
        public: darkMode['--asurion-light-purple'],
        default: 'white',
        primary: 'black',
        secondary: '#8223D2',
        tertiary: 'grey',
      },

      cursor: 'pointer',
      color: '#8223D2',
      marginRight: 15,
    },
    input: {
      backgroundColor: darkMode['--background-color'],
      color: darkMode['--text-color'],
    },
    label: {
      color: darkMode['--text-color'],
    },
    loading: {
      color: '#8223D2',
    },
    modal: {
      backgroundColor: darkMode['--expanded-row'],
      screenColor: 'rgba(0, 0, 0, 0.3)',
    },
    selectionBar: {
      color: darkMode['--text-color'],
      backgroundColor: darkMode['--expanded-row'],
      menu: {
        backgroundColor: darkMode['--background-color'],
      },
    },
    sideNav: { active: darkMode['--background-color'] },
    submitButton: {
      backgroundColor: darkMode['--asurion-light-purple'],
      color: darkMode['--text-color'],
    },
    svg: {
      color: 'white',
    },
    tab: {
      active: {
        borderBottom: `5px solid ${darkMode['--text-color']}`,
        color: darkMode['--text-color'],
      },
      inactive: {
        borderBottom: `5px solid ${root['--gulag-of-my-soul']}`,
        color: darkMode['--text-color'],
      },
    },
    table: { color: darkMode['--text-color'] },
    tag: {
      addTag: { backgroundColor: root['--gulag-of-my-soul'], color: darkMode['--text-color'] },
      backgroundColor: root['--gulag-of-my-soul'],
      color: darkMode['--text-color'],
    },
    toggleSwitch: {
      border: `1px solid ${darkMode['--asurion-light-purple']}`,
      main: darkMode['--asurion-light-purple'],
      on: {
        color: darkMode['--text-color'],
        backgroundColor: darkMode['--asurion-light-purple'],
      },
      off: {
        backgroundColor: darkMode['--background-color'],
        color: darkMode['--asurion-light-purple'],
      },
    },
    backgroundColor: darkMode['--background-color'],
    color: darkMode['--text-color'],
    secondColor: darkMode['--asurion-light-purple'],
  },
  originalFlavor: {
    accordion: {
      color: 'black',
    },
    aspectTree: { icon: { color: 'black' } },

    button: {
      disabled: '#E7E9EB',
      backgroundColor: 'rgb(130, 35, 210)',
      color: 'white',
      hover: 'rgb(74, 6, 132)',
      focus: 'rgb(74, 6, 132)',
      outline: {
        backgroundColor: 'transparent',
        border: '3px solid #8223d2',
        color: '#8223d2',
      },
      ghost: {
        border: 'none',
        backgroundColor: 'transparent',
        color: '#8223d2',
        textDecoration: 'underline',
      },
    },
    checkbox: { color: '#5c1697' },
    chip: {
      backgroundColor: 'rgb(240, 240, 245)',
      color: 'rgb(130, 35, 210)',
    },
    datePicker: {
      border: {
        show: `2px solid #8223d2`,
        hover: `2px solid #000`,
        default: `2px solid #a5aaaf`,
      },
      backgroundColor: 'white',
      color: '#000',
      label: {
        show: `#8223d2`,
        hover: '#000',
        default: `#a5aaaf`,
      },
    },
    dragAndDrop: {
      backgroundColor: 'white',
      dragging: {
        backgroundColor: '#f3e9fb',
      },
    },
    dropdown: {
      option: {
        backgroundColor: 'white',
        color: 'black',
        hoverText: 'black',
      },
      color: 'white',
      backgroundColor: 'white',
      input: { backgroundColor: 'white', borderColor: '#a5aaaf' },
    },
    header: { backgroundColor: 'white', color: 'black' },
    iconComponent: {
      colors: {
        dataNode: '#8223D2',
        default: 'black',
        primary: 'white',
        secondary: '#8223D2',
        tertiary: root['--gulag-of-my-soul'],
      },
      cursor: 'pointer',
      color: '#8223D2',
    },
    input: {
      backgroundColor: 'white',
      color: 'black',
    },
    label: {
      color: 'black',
    },
    loading: {
      color: '#8223D2',
    },
    modal: {
      backgroundColor: 'white',
      screenColor: 'rgba(0, 0, 0, 0.3)',
    },
    selectionBar: {
      color: 'black',
      backgroundColor: '#F3E9FB',
      menu: {
        backgroundColor: 'white',
      },
    },
    sideNav: { active: 'white', inactive: 0.6 },
    submitButton: {
      backgroundColor: '#8223d2',
      color: 'white',
    },
    svg: {
      color: 'black',
    },
    tab: {
      active: { borderBottom: '5px solid #000', color: '#000' },
      inactive: { borderBottom: '5px solid #F0F0F5', color: '#a5aaaf' },
    },
    table: { color: 'black' },
    tag: {
      addTag: {
        backgroundColor: 'rgb(240, 240, 245)',
        color: 'black',
      },
      backgroundColor: 'rgb(240, 240, 245)',
      color: 'black',
    },
    toggleSwitch: {
      border: `1px solid #8223d2`,
      main: '#8223d2',
      on: {
        backgroundColor: 'white',
        color: darkMode['--text-color'],
      },
      off: {
        backgroundColor: 'white',
        color: root['--grimace'],
      },
    },
    backgroundColor: 'white',
    color: 'black',
    secondColor: '#8223d2',
  },
}
export default themes
