import React, { useContext } from 'react'
import { Flex, Modal } from '../../../common'
import FilterableTable from '../../../common/Organisms/FilterableTable'
import { trainingHeaders } from '../../../../constants'
import { IMappedTraining } from '../../../../interfaces'
import { GroupsContext } from '../../../../context/GroupsContext'

interface IProps {
  toggle: (show: boolean) => void
  options: {
    trainings: any[]
    handleAutoAssignmentTrainingUpdate: (data: any) => void
    autoAssignment: any[]
  }
}
const TrainingModal = ({ toggle, options }: IProps) => {
  const { autoAssignment, handleAutoAssignmentTrainingUpdate, trainings } = options
  const { trainings: allTrainings } = useContext(GroupsContext)
  return (
    <Modal
      width="90%"
      header="Assign Training"
      close={() => {
        toggle(false)
      }}
    >
      <Flex style={{ overflow: 'auto' }}>
        <FilterableTable
          width={1000}
          data={allTrainings.filter((training: IMappedTraining) => {
            const trainingIds = trainings.map(
              ({ trainingId }: { trainingId: string }) => trainingId,
            )
            return !trainingIds.includes(training?.trainingId)
          })}
          type={{ name: 'autoAssignment', data: { autoAssignment, trainings } }}
          headers={trainingHeaders}
          hideSelectionBar={true}
          handleTrainingUpdate={handleAutoAssignmentTrainingUpdate}
          close={() => {
            toggle(false)
          }}
          noExport={true}
        />
      </Flex>
    </Modal>
  )
}

export default TrainingModal
