import React, { createContext, useState, useEffect } from 'react'
import { getTaskQueueSids } from '../actions/fusion'

interface FusionContextProps {
  queues: any[]
}

export const FusionContext = createContext<FusionContextProps>({
  queues: [],
})

const FusionProvider = ({ children }: { children: any }) => {
  const [queues, setQueues] = useState<any[]>([])
  useEffect(() => {
    ;(async () => {
      try {
        setQueues(await getTaskQueueSids())
      } catch (e) {
        console.error(e)
      }
    })()
  }, [])

  return (
    <FusionContext.Provider
      value={{
        queues,
      }}
    >
      {children}
    </FusionContext.Provider>
  )
}
export default FusionProvider
