import React, { useCallback, useEffect, useState } from 'react'
import { Dropdown } from '../../../common'
import { IDropdownOption } from '../../Atoms/Dropdown'
import { Flex, IconComponent } from '../../index'
interface IPagination {
  chunkSize: IDropdownOption
  page: number
  resultCount: number
  setChunkSize: (x: IDropdownOption) => void
  setPage: (x: number) => void
}

const Pagination = ({ chunkSize, page, resultCount, setChunkSize, setPage }: IPagination) => {
  const [pageOffset, setPageOffset] = useState<{ from: number; to: number }>({ from: 0, to: 10 })
  const generateChunkSizes = useCallback(
    (count: number, { value }) => {
      const totalOptions = Math.ceil(count / parseInt(value))
      const options = []
      for (let i = 1; i <= totalOptions; i++) {
        options.push({
          id: 'chunkSizes',
          value: `${i * parseInt(value)}`,
          display: `${i * parseInt(value)}`,
        })
      }
      return options
    },
    [resultCount],
  )

  const generatedChunkSizes = generateChunkSizes(resultCount, chunkSize)
  return (
    <Flex style={{ width: 800, margin: '10px auto 0' }}>
      <Flex config={['alignStart', 'justifyCenter']} style={{ width: 800, margin: '0 auto' }}>
        <Flex config={['alignCenter']}>
          <Flex style={{ minWidth: '6.8rem' }}>{resultCount} Results</Flex>
          <Flex style={{ marginRight: 10 }}>Show:</Flex>
          <Flex style={{ width: 185, marginRight: 25 }}>
            <Dropdown
              invertPopUp={true}
              options={generateChunkSizes(resultCount, { value: '50' }).reverse()}
              selected={chunkSize}
              emitDropdownOption={(data) => {
                setChunkSize(data)
              }}
            />
          </Flex>
          <Flex config={['alignCenter']} style={{ maxWidth: 'max-content' }}>
            {page !== 0 && (
              <button
                className={'ttd-bare-button'}
                onClick={() => {
                  if (page === 0) return
                  setPageOffset({
                    from: 0,
                    to: 10,
                  })
                  setPage(0)
                }}
              >
                <IconComponent icon={'keyboard_arrow_left'} />
              </button>
            )}
            {page !== 0 && (
              <button
                className={'ttd-bare-button'}
                onClick={() => {
                  if (page === 0) return
                  if (page - 1 !== 0 && page - 1 <= pageOffset.from) {
                    setPageOffset({ from: pageOffset.from - 10, to: pageOffset.to - 10 })
                  }
                  setPage(page - 1)
                }}
              >
                <div>Prev</div>
              </button>
            )}
            {generatedChunkSizes.map((item, i) => {
              return (
                i >= pageOffset.from &&
                i < pageOffset.to && (
                  <button
                    style={{
                      color: page === i ? '#8223D2' : 'black',
                      textDecoration: page === i ? 'underline' : 'none',
                    }}
                    className={'ttd-bare-button'}
                    onClick={() => setPage(i)}
                    key={JSON.stringify(item)}
                  >
                    {i + 1}
                  </button>
                )
              )
            })}
            {page < generatedChunkSizes.length - 1 && '...'}
            {page !== generatedChunkSizes.length - 1 && (
              <button
                className={'ttd-bare-button'}
                onClick={() => {
                  if (page === generatedChunkSizes.length - 1) return
                  if (page + 1 >= pageOffset.to) {
                    setPageOffset({ from: pageOffset.from + 10, to: pageOffset.to + 10 })
                  }
                  setPage(page + 1)
                }}
              >
                <div>Next</div>
              </button>
            )}
            {page !== generatedChunkSizes.length - 1 && (
              <button
                className={'ttd-bare-button'}
                onClick={() => {
                  if (page === generatedChunkSizes.length) return
                  setPageOffset({
                    from: generatedChunkSizes.length - 10,
                    to: generatedChunkSizes.length,
                  })
                  setPage(generatedChunkSizes.length - 1)
                }}
              >
                <IconComponent icon={'keyboard_arrow_right'} />
              </button>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Pagination
