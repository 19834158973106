import React from 'react'
import { Checkbox as MaterialCheckbox, CheckboxProps } from '@material-ui/core'
import styled, { StyledComponent } from 'styled-components'

const Checkbox = (props: Partial<CheckboxProps>) => <StyledCheckbox {...props} />

const StyledCheckbox: StyledComponent<typeof MaterialCheckbox, any, {}, never> = styled(
  MaterialCheckbox,
)`
  &&& {
    color: ${(props) => props.theme.checkbox.color};
  }
`
export default Checkbox
