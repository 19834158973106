import { API } from 'aws-amplify'
import { IRedisBody, IRedisModifyData } from '../../../interfaces'
const fetchRedisData = async (body: IRedisBody) => {
  try {
    const apiName = 'ADMIN'
    const apiPath = '/sessionreader'
    const params = {
      queryStringParameters: {
        controller: `Redis`,
      },
      body,
    }
    return await API.post(apiName, apiPath, params)
  } catch (e) {
    return console.error(e)
  }
}
const updateRedisData = async (body: IRedisModifyData) => {
  try {
    const apiName = 'ADMIN'
    const apiPath = '/sessionreader'
    const params = {
      queryStringParameters: {
        controller: `Redis`,
      },
      body,
    }
    return await API.put(apiName, apiPath, params)
  } catch (e) {
    return console.error(e)
  }
}
const removeRedisData = async (body: IRedisModifyData) => {
  try {
    const apiName = 'ADMIN'
    const apiPath = '/sessionreader'
    const params = {
      queryStringParameters: {
        controller: `Redis`,
      },
      body,
    }
    return await API.del(apiName, apiPath, params)
  } catch (e) {
    return console.error(e)
  }
}
export { fetchRedisData, updateRedisData, removeRedisData }
