import React, { useState, useEffect, useRef, useContext } from 'react'
import styled, { StyledComponent } from 'styled-components'
import { Loading, Flex } from '../../index'
import { evaluateType } from '../../../../utils'
import * as HeaderTypes from '../../../../constants'
import { RouteContext } from '../../../../context/RouteContext'
import { ITable } from '../../../../interfaces'
import { Headers } from './Headers'
import ActionCell from './ActionCell'
import { renderData } from './renderData'
import { IHeaders } from '../../../../interfaces/common'
import { useSelector } from 'react-redux'
import themes from '../../../../themes/schema'

const Table = ({
  allSelected = false,
  columnData,
  displayFilter = true,
  dateFilters,
  ruleType,
  handleEdit,
  reorderFilters = () => {},
  filters,
  data,
  emitFilters,
  emitDateFilter,
  handleSort,
  setHeaders,
  headers,
  removeRow,
  addToStaging,
  handleRowClick,
  reportType = '',
  checkTracker = () => {},
  loading = false,
  checkMap,
  selected,
  selectRow,
  setColumnData,
}: ITable) => {
  const { selectedLink } = useContext(RouteContext)
  const {
    userSettings: { darkMode },
  } = useSelector((state: any) => state?.user)
  const customDisplayType = darkMode ? 'darkMode' : 'originalFlavor'
  const getStyle: Record<string, any> = {
    table_color_originalFlavor: themes.originalFlavor.table.color,
    table_color_darkMode: themes.darkMode.table.color,
  }
  const [list, setList] = useState<any[]>([])
  const [columnInputFields, setColumnInputFields] = useState<any[]>([])
  const [toggleAll, setToggleAll] = useState<boolean>(false)
  const idType = useRef<string>(null!)
  const getHeaderType = (headers: any[]) => {
    let result = ''
    for (const [headerType, headerFields] of Object.entries(HeaderTypes)) {
      if (headerFields === headers) {
        result = headerType
      }
    }
    return result
  }

  useEffect(() => {
    const headerType = getHeaderType(headers)
    const selLinkPlus = ruleType ? `${selectedLink}:${ruleType}` : selectedLink
    const idNames: Record<string, string> = {
      trainings: 'trainingId',
      singleBundle: 'trainingId',
      'singleBundle:assignments': 'empId',
      fusion: 'empId',
      singleAutoAssignment: 'trainingId',
      'trainings:bundle': 'empId',
      'trainings:trainings': 'trainingId',
      newTraining: headerType === 'workersHeaders' ? 'empId' : 'assignmentId',
      assignments: 'assignmentId',
      'rules:eject': 'ejectRuleId',
      'rules:dispatch': 'dispatchRuleId',
      'rules:trainingRules': 'groupId',
      'rules:removeGroup': 'groupId',
      reporting: 'reportId',
      configuration: 'groupId',
    }
    idType.current = idNames[selLinkPlus]
    checkTracker(false)
  }, [ruleType])

  useEffect(() => {
    setList(data)
  }, [data])

  useEffect(() => {
    setToggleAll(allSelected)
  }, [allSelected])

  const canEdit = handleEdit !== undefined
  const canRemove = removeRow !== undefined
  const canAddToStaging = addToStaging !== undefined

  const handleAddToStaging = (row: any) => {
    if (columnInputFields.length) {
      const getRowFromTryThis: any = columnInputFields.filter(
        (rule) => rule.ejectRule === row.ejectRule,
      )
      return addToStaging(getRowFromTryThis)
    } else {
      return addToStaging(row)
    }
  }
  const handleAction = (row: any) => {
    const actionType = evaluateType({ canAddToStaging, canRemove, canEdit })
    const action: Record<string, any> = {
      canEdit: handleEdit,
      canAddToStaging: handleAddToStaging,
      canRemove: removeRow,
    }

    return actionType && action[actionType](row)
  }

  const tableRef = useRef<any>(null)

  const updated = new Map(checkMap)
  const checkboxClick = (row: any) => {
    if (updated.has(row[idType.current])) {
      updated.delete(row[idType.current])
    } else {
      updated.set(row[idType.current], row)
    }
    selectRow(updated)
  }

  const handleToggleAll = () => {
    const shouldActivateAll = !toggleAll
    setToggleAll(shouldActivateAll)
    checkTracker(shouldActivateAll, idType.current)
  }

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    const tempArr = [...headers]
    tempArr.splice(newIndex, 0, tempArr.splice(oldIndex, 1)[0])
    setHeaders(
      tempArr.map((header: Partial<IHeaders>, index) => ({
        ...header,
        order: index,
        id: String(header) + String(index),
      })),
    )
    reorderFilters(newIndex, headers[oldIndex].value || '')
  }

  const renderHeaders = () => {
    const props = {
      emitDateFilter,
      filters,
      dateFilters,
      emitFilters,
      tableRef,
      canEdit,
      headers,
      handleSort,
      selected,
      canAddToStaging,
      canRemove,
      handleToggleAll,
      toggleAll,
      onSortEnd,
      items: headers,
      displayFilter,
    }
    return (
      <Headers
        axis="x"
        lockAxis="x"
        {...props}
        columnData={columnData}
        setColumnData={setColumnData}
      />
    )
  }
  return (
    <TableWrapper reportType={reportType} data={data}>
      <table ref={tableRef} style={{ marginBottom: 50, position: 'relative' }} className="table">
        {renderHeaders()}
        <tbody>
          {!loading &&
            list &&
            list.length > 0 &&
            list.map((row, index) => {
              if (!row) return
              const isEven = darkMode
                ? index % 2 === 0
                  ? '#555'
                  : 'transparent'
                : index % 2 === 0
                ? '#F0F0F5'
                : 'transparent'
              const isAvailableStyle = { cursor: 'pointer' }
              const isNotAvailable = { cursor: 'default' }
              const belongsToTrainingGroupStyle = {
                fontStyle: 'italic',
                color: '#aaa',
              }
              const unclickableTableRows = row && (row.assignmentId || row.dispatchRuleId)
              const style =
                row && row.belongsToTrainingGroup
                  ? belongsToTrainingGroupStyle
                  : unclickableTableRows
                  ? isNotAvailable
                  : isAvailableStyle
              return (
                <React.Fragment key={'reactFragmentForTableRow' + index}>
                  <tr
                    key={JSON.stringify(row) + index}
                    className="ttd-tr"
                    style={{
                      ...style,
                      backgroundColor: isEven,
                    }}
                  >
                    {headers.map(({ value }: any) => {
                      const columnWidths = () => {
                        if (value === 'trainingLink') {
                          return 50
                        } else if (value !== 'trainingLink' && columnData[value]) {
                          return columnData[value] + 10
                        } else {
                          return 50
                        }
                      }
                      return (
                        <td className="ttd-td" key={(value || '') + index}>
                          {value !== 'dropdown' && value !== 'newPage' && (
                            <Flex
                              config={['justifyStart', 'alignCenter']}
                              style={{
                                textAlign: 'left',
                                height: 50,
                                minWidth: '50px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'unset',
                                width: columnWidths(),
                              }}
                            >
                              {renderData({
                                header: value || '',
                                data: row,
                                idType,
                                handleRowClick,
                                checkboxClick,
                                checkMap,
                                customDisplayType,
                                getStyle,
                              })}
                            </Flex>
                          )}
                        </td>
                      )
                    })}
                    {(canAddToStaging || canRemove || canEdit) && (
                      <ActionCell
                        actionTypes={{
                          canRemove,
                          canAdd: canAddToStaging,
                          canEdit,
                        }}
                        handleClick={() => handleAction(row)}
                      />
                    )}
                  </tr>
                </React.Fragment>
              )
            })}
        </tbody>
      </table>
      {loading && <Loading size={'xl'} />}
    </TableWrapper>
  )
}

export default Table

const TableWrapper: StyledComponent<
  'div',
  any,
  { reportType: string; data: any[] },
  never
> = styled.div`
  padding-bottom: 25px;
  ${({ reportType, data }: { reportType: string; data: any[] }) => {
    if (reportType !== `` && data?.length) {
      return `
      overflow: scroll;
      max-height: 50rem
      `
    } else if (reportType === `fusion`) {
      return `overflow: scroll;
      `
    } else if (reportType === ``) {
      return `overflow: scroll;
      height: 50rem
      `
    }
  }}
`
