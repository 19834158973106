import React, {createContext, useState, useEffect} from 'react'
import AddTraining from '../components/Trainings/AddTraining'
import {ADMIN_ADGROUP, TTD_ADMIN_ADGROUP, TTD_MC_FUSION_ADMIN} from '../constants'
import Assignments from '../components/Assignments'
import AutoAssignment from '../components/Trainings/AutoAssignment'
import Configuration from '../components/Configuration'
import Dev from '../components/Dev'
import Fusion from '../components/Fusion'
import Reporting from '../components/Reporting'
import Rules from '../components/Rules'
import Training from '../components/Trainings'
import Broadcast from '../components/Broadcast'
import TrainingBundles from '../components/Trainings/TrainingBundles'
import {useSelector} from 'react-redux'
import {checkPermissions} from '../utils'
import {IDropdownOption} from '../components/common/Atoms/Dropdown'
import {optionConfig} from '../constants'
import {Loading} from '../components/common'

export const RouteContext = createContext<any>({})

const RouteProvider = ({children}: { children: any }) => {
  const adGroup = useSelector((state: any) => {
    const parseADGroups = state?.user?.adGroup?.split(',') || []
    return parseADGroups
  })
  const {sideNavigationMenu} = optionConfig
  const validRoutes = sideNavigationMenu.filter((route: IDropdownOption) =>
    checkPermissions(route.value),
  )

  const [isAdmin, setIsAdmin] = useState<boolean>(true)
  const [isTTDAdmin, setTTDIsAdmin] = useState<boolean>(true)
  const [isTTDFusionAdmin, setIsTTDFusionAdmin] = useState<boolean>(true)
  const [attemptedRoute, setAttemptedRoute] = useState<string>('')
  const [selectedLink, setSelectedLink] = useState<string>(
    window.localStorage.getItem('selectedLink') || '',
  )
  const [selectedLinkProps, setSelectedLinkProps] = useState<any>({})
  const [hasEdited, setHasEdited] = useState<boolean>(false)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [selectedTab, setSelectedTab] = useState<string>('')

  const handleNavigation = (route: string, tab: string) => {
    if (hasEdited) {
      setAttemptedRoute(route)
      setShowAlert(true)
    } else {
      setSelectedLink(route)
    }
    if (tab) {
      setSelectedTab(tab)
    }
  }
  const confirmNavigation = () => {
    setSelectedLink(attemptedRoute)
    setHasEdited(false)
    setShowAlert(false)
  }

  useEffect(() => {
    const linkValid = validRoutes.some((each: any) => each.value === selectedLink)
    if (linkValid) {
      window.localStorage.setItem('selectedLink', selectedLink)
    } else if (
      !linkValid &&
      validRoutes.length &&
      selectedLink !== 'newTraining' &&
      selectedLink !== 'singleBundle' &&
      selectedLink !== 'singleAutoAssignment'
    ) {
      window.localStorage.setItem('selectedLink', validRoutes[0].value)
      setSelectedLink(validRoutes[0].value)
    }
  }, [validRoutes])

  const renderSelectedView = (selectedLink: string) => {
    const view: Record<string, any> = {
      assignments: <Assignments/>,
      autoAssignments: <Training/>,
      bundles: <Training/>,
      configuration: <Configuration/>,
      dev: <Dev/>,
      fusion: <Fusion/>,
      newTraining: <AddTraining {...selectedLinkProps} />,
      reporting: <Reporting/>,
      rules: <Rules/>,
      singleAutoAssignment: <AutoAssignment/>,
      singleBundle: <TrainingBundles/>,
      trainings: <Training/>,
      broadcast: <Broadcast/>
    }
    const check = checkPermissions(selectedLink)
    if (check) {
      return view[selectedLink]
    } else {
      return <Loading size="md"/>
    }
  }

  return (
    <RouteContext.Provider
      value={{
        componentToRender: renderSelectedView(selectedLink),
        confirmNavigation,
        hasEdited,
        isAdmin,
        isTTDAdmin,
        isTTDFusionAdmin,
        selectedLink,
        setSelectedLink,
        selectedLinkProps,
        setSelectedLinkProps,
        handleNavigation,
        showAlert,
        setShowAlert,
        setHasEdited,
        selectedTab,
        setSelectedTab,
        validRoutes,
      }}
    >
      {children}
    </RouteContext.Provider>
  )
}

export default RouteProvider
