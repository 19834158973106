import React from 'react'
import styled, { StyledComponent } from 'styled-components'
import { IButton } from '../../../../interfaces'
import { useSelector } from 'react-redux'
import themes from '../../../../themes/schema'

const Button = ({
  disabled = false,
  id = '',
  displayType = 'flat',
  children,
  style,
  className,
  handleClick,
  size = 'lg',
  type = 'button',
}: Partial<IButton>) => {
  const {
    userSettings: { darkMode },
  } = useSelector((state: any) => state?.user)
  const customDisplayType = darkMode ? 'darkMode_' + displayType : displayType

  const getSize: Record<string, any> = {
    tiny: {
      height: 32,
      width: 32,
    },
    sm: {
      height: 32,
      width: 'max-content',
      padding: '0 15px',
    },
    md: {
      height: 40,
    },
    lg: {
      height: 50,
      width: 'max-content',
      padding: '0 25px',
    },
  }

  const getDisplayType: Record<string, any> = {
    flat: {},
    disabled: themes.originalFlavor.button.disabled,
    darkMode_disabled: themes.darkMode.button.disabled,
    outline: themes.originalFlavor.button.outline,
    ghost: themes.originalFlavor.button.ghost,
    darkMode_outline: themes.darkMode.button.outline,
    darkMode_ghost: themes.darkMode.button.ghost,
  }

  return (
    <StyledButton
      disabled={disabled}
      type={type}
      data-testid={id}
      style={{ ...style, ...getSize[size], ...getDisplayType[customDisplayType] }}
      className={className}
      onClick={handleClick}
    >
      {children}
    </StyledButton>
  )
}
const StyledButton: StyledComponent<'button', any, {}, never> = styled.button`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => {
    return !props.disabled ? props.theme.button.backgroundColor : props.theme.button.disabled
  }};
  color: ${(props) => props.theme.button.color};
  border-radius: 25px;
  border: none;
  &:hover {
    background-color: ${(props) => props.theme.button.hover};
  }
  &:focus {
    background-color: ${(props) => props.theme.button.focus};
  }
  cursor: pointer;
`

export default Button
