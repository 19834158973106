import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { getSavedReports } from '../actions'
import { useSelector } from 'react-redux'
import { mapSavedReportsForTable } from '../actions/reporting'

interface IReportingDistributionFreqTypesConfig {
  isActive: string
  lastRunTime: string
}

interface IReportingDistributionFreqTypes {
  daily: IReportingDistributionFreqTypesConfig
  last7: IReportingDistributionFreqTypesConfig
  last30: IReportingDistributionFreqTypesConfig
}

export interface IReportingDistributionRecord {
  reportTitle: string
  email: string
  givenName: string
  familyName: string
  empId: string
  cc: string[]
  frequency: IReportingDistributionFreqTypes
  requestPayload: any // define all as types | types
  reportId: string
  created: string
  createdBy: string
  lastUpdatedBy: string
  lastUpdatedDateTime: string
  freq?: string
  lastRunTime?: string
  readOnlyFilters?: string
}

interface IReportingDistributionContext {
  updateSavedReportsList: any
  savedReports: IReportingDistributionRecord[]
  setSavedReports: Dispatch<SetStateAction<IReportingDistributionRecord[]>>
}

export const ReportingDistributionContext = createContext<IReportingDistributionContext>({
  savedReports: [],
  setSavedReports: () => null,
  updateSavedReportsList: () => null,
})

const ReportingDistributionProvider = ({ children }: { children: any }) => {
  const {
    user: { empId },
  } = useSelector((state: any) => state?.user)
  const [savedReports, setSavedReports] = useState<IReportingDistributionRecord[]>([])

  const updateSavedReportsList = async () => {
    const updatedSavedReportList = await getSavedReports(empId)
    const mappedSavedReportList = mapSavedReportsForTable(updatedSavedReportList)
    setSavedReports(mappedSavedReportList)
  }

  useEffect(() => {
    if (empId) {
      ;(async () => {
        const data = await getSavedReports(empId)
        setSavedReports(mapSavedReportsForTable(data))
      })()
    }
  }, [empId])
  return (
    <ReportingDistributionContext.Provider
      value={{ savedReports, setSavedReports, updateSavedReportsList }}
    >
      {children}
    </ReportingDistributionContext.Provider>
  )
}

export default ReportingDistributionProvider
