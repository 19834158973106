// @ts-ignore
import {API, Storage} from 'aws-amplify'
import {sort} from '../utils'
import {Dispatch, SetStateAction} from 'react'
import {optionConfig} from '../constants'
import {
  batchGetAssignmentsByAssignmentId,
  getAllPagesFunction,
  getAssignments,
  getAssignmentsByAssignmentId,
  getAssignmentsForTraining,
  getTwilioToken,
  mapAssignments,
  postAssignmentsData,
  updateAssignments,
  getAssignmentsByBundleId,
  getUpdatedAssignmentsByBundleId,
} from './assignments'
import {
  createNewAutoAssignment,
  getAutoAssignments,
  getEmpGroups,
  getEmpGroupsData,
  getCurrentAutoAssignment,
  updateAutoAssignment,
  updateAutoAssignmentEMPGroupLogic,
  updateAutoAssignmentTraining,
} from './autoAssignments'
import {postBroadcast} from './broadcast'

import {queryAllRoles, attachRoles, getAssignedRole} from './roles'
import {fetchReport, saveReport, getSavedReports} from './reporting'
import {format} from 'date-fns'
import {IConfigProps} from '../interfaces'
import {runPipeline, updateConfig} from './configurations'
import {updateBulkTrainings} from './trainings'

interface GroupProps {
  empGroupIds: string[]
  dispatchConfig: any
  ejectConfig: any
  groupDescription: string
  groupId: string
  groupName: string
  maxCap: string
  threshold: string
  trainingGroupId: string
}

const doTransformation: Record<string, any> = {
  Groups: (data: Partial<GroupProps>[]) =>
    data.map(
      ({
         empGroupIds,
         groupId,
         groupName,
         ejectConfig,
         dispatchConfig,
         threshold,
         maxCap,
       }: Partial<GroupProps>) => {
        return {
          groupId,
          empGroupIds,
          groupName: groupName,
          dispatchConfig,
          ejectConfig,
          threshold: threshold,
          maxCap: maxCap,
        }
      },
    ),
  EmpGroups: (data: GroupProps[], hash: Record<any, any>) =>
    data.map((group: GroupProps) => ({
      belongsToTrainingGroup: (group.trainingGroupId && hash[group.trainingGroupId]) || '',
      ...group,
    })),
}

const getGroups = async (
  setStateCB: Dispatch<SetStateAction<any[]>> | null,
  config: IConfigProps,
  setAspectCB: Dispatch<SetStateAction<any[]>>,
) => {
  if (setStateCB) {
    try {
      const apiName = 'ADMIN'
      const apiPath = '/admin'
      const {queryStringParameters} = config
      const data = await API.get(apiName, apiPath, {queryStringParameters})
      const args = [config.workers || []]
      const transformedData = doTransformation[config?.queryStringParameters?.table](data, ...args)
      const key = 'groupName'
      const direction = 'asc'
      const sortedData = sort(transformedData, key, direction)
      const massagedData = sortedData.map((trainingGroup: any) => {
        const dataNeeded = {
          threshold: trainingGroup.dispatchConfig?.threshold,
          taskQueueMaxPercent:
            trainingGroup.dispatchConfig && trainingGroup.dispatchConfig?.taskQueueMaxPercent,
          ejectRules:
            trainingGroup.ejectConfig &&
            trainingGroup.ejectConfig.length > 0 &&
            trainingGroup.ejectConfig.map((ejectRule: any) => JSON.stringify(ejectRule)).join('\n'),
        }

        return {...trainingGroup, ...dataNeeded}
      })

      setAspectCB(
        sort(
          massagedData.map((group: any) => {
            return {
              value: group.groupId,
              id: group.groupId,
              display: group.groupName,
            }
          }),
          'groupName',
          'asc',
        ),
      )

      setStateCB(massagedData)
    } catch (ex) {
      console.log(ex)
    }
  }
}

const getAspectRoots = async (callback: Dispatch<SetStateAction<any[]>>) => {
  const params: any = {
    queryStringParameters: {table: 'EmpGroups', action: 'scan'},
    body: {
      scan: {
        attributesNotExist: ['parentGroupId'],
      },
    },
  }
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const roots = await API.post(apiName, apiPath, params)
    return callback(roots)
  } catch (error) {
    console.log(error)
  }
}
const getAspectTree = async (callback: Dispatch<SetStateAction<any[]>>) => {
  const params: any = {
    queryStringParameters: {table: 'EmpGroups', key: 'aspectTree'},
  }
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const {response} = await API.get(apiName, apiPath, params)
    return callback(response)
  } catch (error) {
    console.log(error)
  }
}

const getChildren = async (id = 'null', key: string, callback: any) => {
  const params: any = {
    queryStringParameters: {table: 'EmpGroups', action: 'scan'},
    body: {
      scan: {
        [key]: id,
      },
    },
  }
  if (key === 'groupTreeId') {
    params.body.scan.attributesNotExist = ['childGroupIds']
  }
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const roots = await API.post(apiName, apiPath, params)
    const sortedRoots = sort(roots, 'groupName', 'asc')
    return callback(id, sortedRoots)
  } catch (error) {
    console.log(error)
  }
}

const getSelectedGroups = async (groupIds: any[]) => {
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const params: any = {
      queryStringParameters: {table: 'EmpGroups', action: 'scan'},
      body: {
        paginate: true,
        scan: {
          groupIds,
        },
      },
    }
    return await API.post(apiName, apiPath, params)
  } catch (error) {
    console.log(error)
  }
}

const getWorkersForGroup = async (payload: string[][]) => {
  if (!payload.length) return
  const empGroupArray = payload.filter((item) => item.length)
  const empGroupIds = empGroupArray.flat()
  try {
    const params = {
      queryStringParameters: {
        table: 'Workers',
        action: 'scan',
      },
      body: {
        config: {
          page: 0,
          empGroupsCount: empGroupArray.length,
          empGroupIds,
          pageSize: 5000,
          method: `getAllWorkersForSelectedFilter`,
        },
      },
    }
    const {
      response: [{count}],
    } = await getWorkersCount('empGroupIds', empGroupIds)
    return await getAllPagesFunction({params, count})
  } catch (e) {
    console.error(e)
  }
}

const awesomeCoolRecursiveScanFunction = async ({params}: any) => {
  const apiName = 'ADMIN'
  const apiPath = '/admin'
  return await API.post(apiName, apiPath, params).then(async (response: any) => {
    if (response.LastEvaluatedKey) {
      const newParams = JSON.parse(JSON.stringify(params))
      newParams.body.LastEvaluatedKey = response.LastEvaluatedKey
      const pageResult = await awesomeCoolRecursiveScanFunction({params: newParams})
      response.Items.push(...pageResult)
      return response.Items
    } else {
      return response.Items
    }
  })
}

const fetchAdminConfig = async () => {
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const table = 'AdminConfig'
    const params: IConfigProps = {
      queryStringParameters: {table, action: 'scan'},
      body: {scan: {}},
    }
    const call = await API.get(apiName, apiPath, params)
    const {config} = call[0]
    return config
  } catch (ex) {
    console.error(ex)
  }
}

const postAdminConfig = async (body: any) => {
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const table = 'AdminConfig'
    const id = 'admin-config'

    const params: IConfigProps = {
      queryStringParameters: {table, id},
      body,
    }
    return await API.put(apiName, apiPath, params)
  } catch (ex) {
    console.error(ex)
  }
}

const fetchAssignmentsData = async (
  table: string,
  options: any,
  setLoading: any,
  setAssignments: any,
) => {
  setLoading(true)
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const params: IConfigProps = {
      queryStringParameters: {table, action: 'scan'},
      body: {scan: {}},
    }
    const optionsForAllAssignments: IConfigProps = {
      queryStringParameters: {table: 'Assignments', action: 'scan'},
      body: {scan: {}},
    }
    const optionsForTrainings = {
      queryStringParameters: {
        table: 'Trainings',
      },
    }
    const optionsForBundles = {
      queryStringParameters: {
        table: 'TrainingBundles',
      },
    }

    let trainings
    let trainingsArr: any[] = []

    switch (table) {
      case 'AllAssignments':
        table = 'Assignments'
        trainings = await API.get(apiName, apiPath, optionsForTrainings)
        if (trainings) {
          trainings = Array.isArray(trainings) ? trainings : [trainings]
          trainings.forEach((training) => trainingsArr.push(training.trainingId))
        }
        optionsForAllAssignments.body.scan.trainingId = trainingsArr

        return setAssignments(
          sort(
            await API.post(apiName, apiPath, optionsForAllAssignments).then((data: any) =>
              mapAssignments(data),
            ),
            'workerName',
            'asc',
          ),
        )
      case 'Assignments':
        params.body.scan.trainingId = options.trainingId
        return setAssignments(
          sort(
            await API.post(apiName, apiPath, params).then((data: any) => mapAssignments(data)),
            'workerName',
            'asc',
          ),
        )
      case 'ReportingAssignments':
        const today = new Date().toISOString()
        const end = '00:00:00.000Z'
        const someTime = today.split('T') // ['start', 'T', 'end']
        someTime[1] = end
        someTime.join('T')

        const optionsForReportingAssignments: IConfigProps = {
          queryStringParameters: {table: 'Assignments', action: 'scan'},
          body: {
            scan: {
              lastUpdatedDateTime: {
                comparator: '>',
                value: someTime[0],
              },
            },
          },
        }
        return setAssignments(
          sort(
            await API.post(apiName, apiPath, optionsForReportingAssignments).then((data: any) =>
              mapAssignments(data),
            ),
            'workerName',
            'asc',
          ),
        )
      default:
        return
    }
  } catch (ex) {
    console.error(ex)
  } finally {
    setLoading(false)
  }
}
const getWorkersCount = async (key?: string, value?: string[]) => {
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const params = {
      queryStringParameters: {
        table: 'Workers',
        action: 'scan',
      },
      body: {
        config: {
          method: `getWorkersCount`,
          ...(value &&
            key && {
              [`${key}`]: value,
            }),
        },
      },
    }
    return await API.post(apiName, apiPath, params)
  } catch (e) {
    console.error(e)
  }
}

const getAEWorkersCount = async (key?: string, value?: string[]) => {
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const params = {
      queryStringParameters: {
        table: 'Workers',
        action: 'scan',
      },
      body: {
        config: {
          method: `getWorkersCount`,
          sqlKey: 'fusion',
          ...(value &&
            key && {
              [`${key}`]: value,
            }),
        },
      },
    }
    return await API.post(apiName, apiPath, params)
  } catch (e) {
    console.error(e)
  }
}

const getAllWorkers = async () => {
  try {
    const params = {
      timeout: 180000,
      queryStringParameters: {
        table: 'Workers',
        action: 'scan',
      },
      body: {
        config: {
          page: 0,
          pageSize: 5000,
          method: `getAllWorkers`,
        },
      },
    }
    const {
      response: [{count}],
    } = await getWorkersCount()
    const workers = (await getAllPagesFunction({params, count})) || []
    const sortedWorkers = sort(workers, 'workerName', 'asc')
    return {
      workers: sortedWorkers,
    }
  } catch (error) {
    console.log('Error getting workers: ', error)
  }
}

const getEjectRules = async (
  setStateCB: Dispatch<SetStateAction<any[]>>,
  setLoadingCB: Dispatch<SetStateAction<boolean>>,
) => {
  setLoadingCB(true)
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const data = await API.get(apiName, apiPath, {
      queryStringParameters: {
        table: 'EjectRules',
      },
    })

    const {comparator, ejectRule} = optionConfig

    const formattedRules = (rules: any[], logicalOperation: string) => {
      return rules
        .map((rule: any) => {
          const ruleDisplay = ejectRule.find(
            ({value}: { value: string }) => value === rule.ejectRule,
          ).display
          const comparatorDisplay = comparator.find(
            ({value}: { value: string }) => value === rule.comparator,
          ).display

          return `${ruleDisplay} ${comparatorDisplay} ${rule.value}`
        })
        .join(` ${logicalOperation === '&&' ? 'AND' : 'OR'} `)
    }

    const key = 'name'
    const direction = 'asc'
    const sortedData = sort(data, key, direction)
    const formattedTableData = sortedData.map((data) => {
      return {
        ...data,
        expertsToEjectDisplay:
          data.expertsToEject?.unitOfMeasurement === 'percent'
            ? `${data.expertsToEject?.value / 100}%`
            : `${data.expertsToEject?.value} experts`,
        readableRules: formattedRules(data?.rules, data.logicalOperation),
        lastModified: `${format(new Date(data.lastUpdatedDateTime), 'MM/dd/yyyy')} ${
          data.lastUpdatedBy
        }`,
      }
    })

    return setStateCB(formattedTableData)
  } catch (ex) {
    console.log(ex)
  } finally {
    setLoadingCB(false)
    return
  }
}

const prettify = (option: string) => {
  const options: Record<string, string> = {
    '<': 'less than',
    '>': 'greater than',
    '===': 'exactly',
    '<=': 'less than or equal to',
    '>=': 'greater than or equal to',
    callsInQueue: 'Calls in queue',
    longestWait: 'Longest wait',
    '&&': 'AND',
    '||': 'OR',
  }
  return options[option]
}

const prettifyEjectRuleData = ({ejectConfig}: any) => {
  return ejectConfig.map(({expertsToEject, rules, logicalOperation}: any) => {
    return `Experts to eject: ${expertsToEject?.value}${
      expertsToEject?.unitOfMeasurement === 'expert' ? ' expert' : '%'
    }, ${rules?.map(
      (
        {
          comparator,
          value,
          ejectRule,
        }: {
          comparator: string
          value: string
          ejectRule: string
        },
        index: number,
        arr: any[],
      ) =>
        `${prettify(ejectRule)} ${prettify(comparator)} ${value} ${
          arr.length - 1 !== index ? prettify(logicalOperation) : ''
        } `,
    )}`
  })
}

const getTrainingRules = async (
  setStateCB: Dispatch<SetStateAction<any[]>>,
  setLoadingCB: Dispatch<SetStateAction<boolean>>,
) => {
  setLoadingCB(true)
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const params = {
      queryStringParameters: {
        table: 'Groups',
        RDSTable: `TrainingRules`,
      },
    }
    const {response: data} = await API.get(apiName, apiPath, params)
    const key = 'trainingRuleName'
    const direction = 'asc'
    const sortedData = sort(data, key, direction)
    const massagedData = sortedData.map((trainingGroup: any) => {
      const dataNeeded = {
        groupId: trainingGroup.trainingRuleId,
        groupName: trainingGroup.trainingRuleName,
        threshold: trainingGroup.dispatchConfig?.threshold,
        empGroupIds: trainingGroup.empGroupIds.map(({empGroupId}: any) => empGroupId),
        taskQueueMaxPercent:
          trainingGroup.dispatchConfig && trainingGroup.dispatchConfig?.taskQueueMaxPercent,
        ejectRules:
          (trainingGroup.ejectConfig.length > 0 && prettifyEjectRuleData(trainingGroup)) || '',
        lastModified: `${format(new Date(trainingGroup.updatedAt), 'MM/dd/yyyy')} ${
          trainingGroup.lastUpdatedBy
        }`,
      }

      return {...trainingGroup, ...dataNeeded, name: trainingGroup.trainingRuleName}
    })
    const flatGroupIds = massagedData
      .map(({empGroupIds}: { empGroupIds: string[] }) => empGroupIds)
      .flat(1)

    const empGroupParams: IConfigProps = {
      queryStringParameters: {table: 'EmpGroups', action: 'scan'},
      body: {
        attributesToGet: ['groupId', 'groupName'],
        scan: {
          groupId: [flatGroupIds],
        },
      },
    }

    const empGroups = await API.post(apiName, apiPath, empGroupParams)

    for (const group of massagedData) {
      const empGroupsForThisTrainingRule = empGroups.flat(1).filter((empGroup: any) => {
        return group.empGroupIds.some((id: string) => id === empGroup.groupId)
      })
      group.empGroupNames = [
        empGroupsForThisTrainingRule.map((empGroup: any) => empGroup.groupName),
      ]
        .flat(1)
        .join(', ')
    }
    setStateCB(massagedData)
  } catch (ex) {
    console.log(ex)
  } finally {
    return setLoadingCB(false)
  }
}

const getDispatchRules = async (
  setStateCB: Dispatch<SetStateAction<any[]>>,
  setLoadingCB: Dispatch<SetStateAction<boolean>>,
) => {
  setLoadingCB(true)
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const data = await API.get(apiName, apiPath, {
      queryStringParameters: {
        table: 'DispatchRules',
      },
    })

    const key = 'name'
    const direction = 'asc'
    const sortedData = sort(data, key, direction).map((data: any) => ({
      ...data,
      lastModified: `${format(new Date(data.lastUpdatedDateTime), 'MM/dd/yyyy')} ${
        data.lastUpdatedBy
      }`,
    }))

    setStateCB(sortedData)
  } catch (ex) {
    console.log(ex)
  } finally {
    setLoadingCB(false)
  }
}

const postEjectRule = async (rule: any) => {
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const table = 'EjectRules'
    const params: IConfigProps = {
      queryStringParameters: {table, action: 'reassign'},
      body: rule,
    }

    return await API.post(apiName, apiPath, params)
  } catch (ex) {
    console.error(ex)
  }
}

const postDispatchRule = async (rule: any) => {
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const table = 'DispatchRules'
    const params: IConfigProps = {
      queryStringParameters: {table, action: 'reassign'},
      body: rule,
    }

    return await API.post(apiName, apiPath, params)
  } catch (ex) {
    console.error(ex)
  }
}

const getTrainings = async () => {
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const table = 'Trainings'
    const params: IConfigProps = {
      queryStringParameters: {table},
    }

    return await API.post(apiName, apiPath, params)
  } catch (ex) {
    console.error(ex)
  }
}
const del = async (link: string, list: any[]) => {
  const idNames: Record<string, string> = {
    autoAssignments: 'assignmentRuleId',
    reporting: 'reportId',
    assignments: 'assignmentId',
    newTraining: 'assignmentId',
    trainings: 'trainingId',
    trainingBundles: 'bundleId',
    'rules:eject': 'ejectRuleId',
    'rules:dispatch': 'dispatchRuleId',
    'rules:trainingRules': 'groupId',
  }
  const selLinksToTables: Record<string, string> = {
    autoAssignments: 'AssignmentRules',
    assignments: 'Assignments',
    newTraining: 'Assignments',
    trainingBundles: 'TrainingBundles',
    reporting: 'ReportingDistribution',
    trainings: 'Trainings',
    'rules:eject': 'EjectRules',
    'rules:dispatch': 'DispatchRules',
    'rules:trainingRules': 'Groups',
  }
  const table = selLinksToTables[link]
  if (table !== 'Assignments') {
    try {
      const promises = list.map((selection) => {
        const apiName = 'ADMIN'
        const apiPath = '/admin'
        const id = selection[idNames[link]]
        const params = {
          queryStringParameters: {table, id},
        }
        return API.del(apiName, apiPath, params).then((res: any) => {
          if (link === 'trainings') {
            const {trainingAttachments} = selection
            if (trainingAttachments?.length > 0) {
              trainingAttachments.forEach(({filename}: { filename: string }) => {
                Storage.remove(`${id}/${filename}`)
                  .then((result: any) => console.log(result))
                  .catch((err: any) => console.log(err))
              })
            }
          }
          return res
        })
      })

      await Promise.all(promises)
      return 'success'
    } catch (e) {
      console.log(e)
    }
  } else {
    try {
      const apiName = 'ADMIN'
      const apiPath = '/admin'
      const params = {
        queryStringParameters: {table, id: ''},
        body: {
          assignmentIds: list.map((item) => item[idNames[link]]),
        },
      }
      return await API.del(apiName, apiPath, params)
    } catch (e) {
      console.log(e)
    }
  }
}

const postTrainingRule = async (rule: any) => {
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const table = 'Groups'
    const params: IConfigProps = {
      queryStringParameters: {table},
      body: rule,
    }

    return await API.post(apiName, apiPath, params)
  } catch (ex) {
    console.error(ex)
  }
}
const removeDeptFromRule = async (rule: any) => {
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const table = 'Groups'
    const params: IConfigProps = {
      queryStringParameters: {table, id: rule.ruleId},
      body: {set: {empGroupIds: rule.empGroupIds}},
    }

    return await API.put(apiName, apiPath, params)
  } catch (ex) {
    console.error(ex)
  }
}

const putTrainingRule = async (rule: any) => {
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const table = 'Groups'
    const params: IConfigProps = {
      queryStringParameters: {table, id: rule.groupId},
      body: {set: {...rule}},
    }

    return await API.put(apiName, apiPath, params)
  } catch (ex) {
    console.error(ex)
  }
}

const getGroupsForTrainingRules = async (ids: any[]) => {
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const table = 'EmpGroups'
    const params = {
      queryStringParameters: {table, action: 'scan'},
      body: {
        scan: {
          groupId: ids,
        },
      },
    }

    const data = await API.post(apiName, apiPath, params)
    if (data.length > 0) return data
  } catch (ex) {
    console.error(ex)
  }
}

const getTrainingBundles = async () => {
  try {
    const params = {
      queryStringParameters: {
        table: 'TrainingBundles',
        action: 'scan',
      },
      body: {
        config: {
          page: 0,
          pageSize: 5000,
          method: `getAllTrainingBundles`,
        },
      },
    }
    return await getAllPagesFunction({params})
  } catch (e) {
    console.error(e)
  }
}

const getTrainingBundlesById = async (bundleId: any) => {
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const bundles = await API.get(apiName, apiPath, {
      queryStringParameters: {
        table: 'TrainingBundles',
        bundleId,
      },
    })
    return bundles.find((bundlesReturned: any) => bundlesReturned.bundleId === bundleId)
  } catch (e) {
    console.error(e)
  }
}

const updateBundle = async (
  bundle: {
    bundleId: string
    bundleName: string
    trainingIds: string[]
  },
  isRemoved: boolean = false,
  trainingId?: string,
) => {
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const {bundleId, bundleName, trainingIds} = bundle
    const rdsSet = {
      isRemoved,
      bundleName,
      trainingIds,
      ...(trainingId && {removedTrainingId: trainingId}),
    }
    if (trainingIds.length < 0) return

    const bundlesParams: IConfigProps = {
      queryStringParameters: {table: 'TrainingBundles', id: bundleId},
      body: {set: {bundleName, trainingIds}, rdsSet},
    }
    return await API.put(apiName, apiPath, bundlesParams)
  } catch (ex) {
    console.error(ex)
  }
}

const postBundle = (bundleObj: { bundleName: string; trainingIds: string[] }) => {
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const params: IConfigProps = {
      queryStringParameters: {table: 'TrainingBundles'},
      body: bundleObj,
    }
    API.post(apiName, apiPath, params)
  } catch (e) {
    console.error(e)
  }
}

export {
  batchGetAssignmentsByAssignmentId,
  createNewAutoAssignment,
  del,
  fetchAdminConfig,
  fetchAssignmentsData,
  fetchReport,
  getAllWorkers,
  getAspectRoots,
  getAspectTree,
  getAssignments,
  getAssignmentsByAssignmentId,
  getAssignmentsForTraining,
  getAutoAssignments,
  getChildren,
  getCurrentAutoAssignment,
  getDispatchRules,
  getEmpGroups,
  getEmpGroupsData,
  getEjectRules,
  getGroups,
  getGroupsForTrainingRules,
  queryAllRoles,
  attachRoles,
  getSavedReports,
  getSelectedGroups,
  getTrainingBundles,
  getTrainingBundlesById,
  getTrainingRules,
  getTrainings,
  getTwilioToken,
  getWorkersForGroup,
  mapAssignments,
  postAdminConfig,
  postAssignmentsData,
  postBundle,
  postDispatchRule,
  postEjectRule,
  postTrainingRule,
  putTrainingRule,
  removeDeptFromRule,
  runPipeline,
  saveReport,
  updateAssignments,
  updateAutoAssignment,
  updateAutoAssignmentEMPGroupLogic,
  updateAutoAssignmentTraining,
  updateBundle,
  updateConfig,
  updateBulkTrainings,
  getAssignmentsByBundleId,
  getWorkersCount,
  getAEWorkersCount,
  getUpdatedAssignmentsByBundleId,
  getAssignedRole,
  postBroadcast
}
