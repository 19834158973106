import { postApiData } from './shared'
import { getEnvironment } from '../utils/applicationEnvironment'
import config from '../config.json'
import { Amplify } from 'aws-amplify'

export const KIBANA_WORKFLOW_PRIORITY_EVENT = 'workflow-priority'
export const KIBANA_USER_LOG_IN_EVENT = 'user-login'
export const KIBANA_OPS_VIEW_SELECTED_EVENT = 'ops-view-selected'
export const KIBANA_WF_VIEW_SELECTED_EVENT = 'wf-view-selected'
export const KIBANA_EXPERT_ATTR_VIEW_SELECTED_EVENT = 'expert-attr-view-selected'
export const KIBANA_SUPPORT_CLICK_EVENT = 'support-click'

export const KIBANA_FAILED_STATE = 'fail'
export const KIBANA_SUCCESS_STATE = 'success'

const env = getEnvironment()
Amplify.configure(config[env].KIBANA_EVENT_URL)

export const kibanaUpdateURL = config[env].KIBANA_EVENT_URL

export default async function kibanaEventTrigger(body) {
  const timeStamp = new Date().toISOString()
  console.log(`file: KibanaEvents.js 💫 line 23 💫 kibanaEventTrigger 💫 timeStamp`, timeStamp)
  postApiData(kibanaUpdateURL, {
    ...body,
    timeStamp,
  })
    .then((success) => console.log('[ Kibana event: Success ]', success))
    .catch((fail) => console.log('[ Kibana event: Fail]', fail))
}
