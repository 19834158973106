import React, {useContext} from 'react'
import DataNode from './DataNode'
import {AssignTrainingContext} from '../../../context/AssignTrainingContext'
import {sort} from '../../../utils'

// filtered parameter used exclusively for Add Training Rule.
const SideBar = ({
                   filtered,
                   mustDisable = [],
                   clearAll = false,
                   setClearAll = () => {
                   },
                   nodeType,
                 }: {
  filtered?: boolean
  mustDisable?: string[]
  clearAll?: boolean
  setClearAll?: any
  nodeType?: string
}) => {
  const {aspectTree} = useContext(AssignTrainingContext)
  const root = sort(Object.values(aspectTree), 'groupName', 'asc')
  return (
    <>
      {filtered
        ? root
          .filter((group) => group.groupName === 'DEPT')
          .map(({groupId, groupName, groupTreeId}) => {
            return (
              <DataNode
                mustDisable={mustDisable}
                key={groupId}
                groupTreeId={groupTreeId}
                groupId={groupId}
                parentId="root"
                name={groupName}
                nodeType={nodeType}
              />
            )
          })
        : root.map(({groupId, groupName, groupTreeId}) => {
          return (
            <DataNode
              mustDisable={mustDisable}
              key={groupId}
              groupTreeId={groupTreeId}
              groupId={groupId}
              parentId="root"
              name={groupName}
              nodeType={nodeType}
            />
          )
        })}
    </>
  )
}

export default SideBar
