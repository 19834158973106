import { IFusionConfig, IMonitorConfig, INotificationConfig, ITimerConfig } from '../interfaces'

const defaultEjectRuleObject = {
  expertsToEject: {
    unitOfMeasurement: 'expert',
    value: '1',
  },
  name: 'New eject rule',
  rules: [
    {
      ejectRule: 'callsInQueue',
      comparator: '===',
      isActive: '1',
      value: '1',
    },
  ],
  logicalOperation: '&&',
  isActive: '1',
}

const defaultEnv = 'module'
const defaultDispatchRuleObject = {
  outageStartTime: '',
  outageEndTime: '',
  isEnabled: '1',
  threshold: String(50 / 100),
  taskQueueMaxPercent: String(50 / 100),
}

const defaultFrequency = {
  daily: {
    isActive: '1',
    lastRunTime: '',
  },
  last7: {
    isActive: '0',
    lastRunTime: '',
  },
  last30: {
    isActive: '0',
    lastRunTime: '',
  },
}

const defaultDropdown = { id: 'selectOne', value: 'selectOne', display: 'Select One' }
const defaultCheckboxDropdown = {
  checked: false,
  id: 'selectOne',
  value: 'selectOne',
  display: 'Select',
}
const defaultCount = { value: 'foo', display: `(0) Selected`, id: 'foo' }

const ADMIN_ADGROUP = 'HQDOMAIN_MGMT-US-N-APP-TTD-ADMIN'
const TTD_ADMIN_ADGROUP = 'HQDOMAIN_TEAM-US-N-TTD-DEV'
const TTD_MC_FUSION_ADMIN = 'HQDOMAIN_MGMT-US-N-AP-MISSIONCONTROL-FUSION-ADMIN'

const ASPECTRTA_ADGROUP = 'HQDOMAIN_AspectRtaUsers'

const defaultNotificationConfig: INotificationConfig = {
  ejectionReceived: {
    audio_notification: false,
    audioAssigned: [],
    text: '',
    timeoutDuration: '0',
    title: '',
    audioConfig: [],
    audioOptions: [],
  },
  inactivity: {
    audio_notification: false,
    audioAssigned: [],
    text: '',
    timeoutDuration: '0',
    title: '',
    audioConfig: [],
    audioOptions: [],
  },
  lostfocus: {
    audio_notification: false,
    audioAssigned: [],
    text: '',
    timeoutDuration: '0',
    title: '',
    audioConfig: [],
    audioOptions: [],
  },
  lowTimeWarning: {
    audio_notification: false,
    audioAssigned: [],
    text: '',
    timeoutDuration: '0',
    title: '',
    audioConfig: [],
    audioOptions: [],
  },
  trainingReceived: {
    audio_notification: false,
    audioAssigned: [],
    text: '',
    timeoutDuration: '0',
    title: '',
    audioConfig: [],
    audioOptions: [],
  },
  trainingTimeExpired: {
    audio_notification: false,
    audioAssigned: [],
    text: '',
    timeoutDuration: '0',
    title: '',
    audioConfig: [],
    audioOptions: [],
  },
}

const defaultMonitorConfig: IMonitorConfig = {
  inactivityTime: '0',
  focusGracePeriod: '0',
  warnings: {
    inactivity: {
      message: '',
      timer: '0',
      title: '',
    },
    lostfocus: {
      message: '',
      timer: '0',
      title: '',
    },
  },
}
const defaultFusionConfig: IFusionConfig = {
  inactivityTime: '0',
  focusGracePeriod: '0',
  warnings: {
    inactivity: {
      message: '',
      timer: '0',
      title: '',
    },
    lostfocus: {
      message: '',
      timer: '0',
      title: '',
    },
  },
}

const defaultTimerConfig: ITimerConfig = {
  ejected: {
    duration: 0,
    message: '',
    name: '',
    assignAudio: [],
    reason: '',
  },
  expired: {
    duration: 0,
    message: '',
    name: '',
    assignAudio: [],
    reason: '',
  },
  inactivity: {
    duration: 0,
    message: '',
    name: '',
    assignAudio: [],
    reason: '',
  },
  lostfocus: {
    duration: 0,
    message: '',
    name: '',
    assignAudio: [],
    reason: '',
  },
  received: {
    duration: 0,
    message: '',
    name: '',
    assignAudio: [],
    reason: '',
  },
}

export {
  defaultFusionConfig,
  defaultNotificationConfig,
  defaultMonitorConfig,
  defaultTimerConfig,
  defaultDispatchRuleObject,
  defaultEjectRuleObject,
  defaultDropdown,
  defaultFrequency,
  ADMIN_ADGROUP,
  defaultCount,
  defaultCheckboxDropdown,
  ASPECTRTA_ADGROUP,
  defaultEnv,
  TTD_ADMIN_ADGROUP,
  TTD_MC_FUSION_ADMIN,
}
