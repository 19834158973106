const dateKeys = [
  'startDate',
  'dueDate',
  'endDate',
  'minStartDate',
  'maxDueDate',
  'maxEndDate',
  'created',
  'Date',
  'Status Date',
  'Start Date',
  'Due Date',
  'End Date',
  'created',
]
const dateTimeKeys = [
  'taskEndedDateTime',
  'startedTraining',
  'outageStartTime',
  'lastUpdatedDateTime',
  'outageEndTime',
  'taskEndTime',
  'Start Time',
  'End Time',
  'Date/Time',
]

const combinedDateTimeKeys = [...dateTimeKeys, ...dateKeys]

export { dateKeys, dateTimeKeys, combinedDateTimeKeys }
