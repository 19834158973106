import { IReducer, IReportingState } from '../../interfaces'
import { defaultCount, defaultDropdown, optionConfig } from '../../constants'
import { getDateRanges } from '../../utils'

const {
  statusOptions,
  opsHierarchyOptions,
  dateHierarchyOptions,
  employeeStatusOptions,
  dateKeyOptions,
  dateRangeOptions,
  dbSelectionOptions,
} = optionConfig
const defaultDateRange = getDateRanges()
const hours = new Date(defaultDateRange[dateRangeOptions[4].value].start).getHours()
const minutes = new Date(defaultDateRange[dateRangeOptions[4].value].start).getMinutes()
const rightThen = `${hours}:${minutes}`

const hoursNow = new Date(defaultDateRange[dateRangeOptions[4].value].end).getHours()
const minutesNow = new Date(defaultDateRange[dateRangeOptions[4].value].end).getMinutes()
const rightNow = `${hoursNow}:${minutesNow}`

export const reportingInitialState: IReportingState = {
  businessUnits: [],
  businessUnitsCount: defaultCount,
  consolidatedData: [],
  data: [],
  // Remove me
  dbSelection: dbSelectionOptions[0],
  dateHierarchy: dateHierarchyOptions[1],
  dateKey: dateKeyOptions[1],
  dateSelectionDropdown: dateRangeOptions[4],
  empIdsForReport: [],
  empIdValue: '',
  empNameSelection: [],
  employeeStatus: employeeStatusOptions[1],
  endDays: 0,
  filteredData: [],
  foldFilters: false,
  fromDate: defaultDateRange[dateRangeOptions[4].value].start,
  fromTime: rightThen,
  generatingReport: false,
  groupedHeaders: [],
  groupedData: [],
  headers: [],
  isRealtime: false,
  loading: false,
  mascot: [],
  mascotCount: defaultCount,
  opsHierarchy: opsHierarchyOptions[1],
  reportType: defaultDropdown,
  siteCount: defaultCount,
  showCustomDates: false,
  startDays: 0,
  statusCount: defaultCount,
  status: statusOptions,
  site: [],
  savedReport: {},
  tagCount: defaultCount,
  tags: [],
  toDate: defaultDateRange[dateRangeOptions[4].value].end,
  toTime: rightNow,
  trainings: [],
  trainingsCount: defaultCount,
  url: '',
}

export const reportingReducer = (state: IReportingState, action: IReducer) => {
  switch (action.type) {
    case 'setData':
      return {
        ...state,
        data: action.payload,
      }
    case 'setTags':
      return {
        ...state,
        tags: action.payload,
      }
    case 'setTagCount':
      return {
        ...state,
        tagCount: action.payload,
      }
    case 'setStatus':
      return {
        ...state,
        status: action.payload,
      }
    case 'setStatusCount':
      return {
        ...state,
        statusCount: action.payload,
      }
    case 'setEmpIdValue':
      return {
        ...state,
        empIdValue: action.payload,
      }
    case 'setEmpIdsForReport':
      return {
        ...state,
        empIdsForReport: action.payload,
      }
    case 'setFilteredData':
      return {
        ...state,
        filteredData: action.payload,
      }
    case 'setFromDate':
      return {
        ...state,
        fromDate: action.payload,
      }
    case 'setGeneratingReport':
      return {
        ...state,
        generatingReport: action.payload,
      }
    case 'setHeaders':
      return {
        ...state,
        headers: action.payload,
      }
    case 'setIsRealtime':
      return {
        ...state,
        isRealtime: action.payload,
      }
    case 'setReportType':
      return {
        ...state,
        reportType: action.payload,
      }
    case 'setToDate':
      return {
        ...state,
        toDate: action.payload,
      }
    case 'setTrainings':
      return {
        ...state,
        trainings: action.payload,
      }
    case 'setTrainingsCount':
      return {
        ...state,
        trainingsCount: action.payload,
      }
    case 'setUrl':
      return {
        ...state,
        url: action.payload,
      }
    case 'setShowCustomDates':
      return {
        ...state,
        showCustomDates: action.payload,
      }
    case 'setFoldFilters':
      return {
        ...state,
        foldFilters: action.payload,
      }
    case 'setDateSelectionDropdown':
      return {
        ...state,
        dateSelectionDropdown: action.payload,
      }
    case 'setDateKey':
      return {
        ...state,
        dateKey: action.payload,
      }
    case 'setBusinessUnits':
      return {
        ...state,
        businessUnits: action.payload,
      }
    case 'setBusinessUnitsCount':
      return {
        ...state,
        businessUnitsCount: action.payload,
      }
    case 'setMascot':
      return {
        ...state,
        mascot: action.payload,
      }
    case 'setMascotCount':
      return {
        ...state,
        mascotCount: action.payload,
      }
    case 'setSite':
      return {
        ...state,
        site: action.payload,
      }
    case 'setSiteCount':
      return {
        ...state,
        siteCount: action.payload,
      }
    case 'setEmpNameSelection':
      return {
        ...state,
        empNameSelection: action.payload,
      }
    case 'setEmployeeStatus':
      return {
        ...state,
        employeeStatus: action.payload,
      }
    case 'setFromTime':
      return {
        ...state,
        fromTime: action.payload,
      }
    case 'setToTime':
      return {
        ...state,
        toTime: action.payload,
      }
    case 'setOpsHierarchy':
      return {
        ...state,
        opsHierarchy: action.payload,
      }
    case 'setDateHierarchy':
      return {
        ...state,
        dateHierarchy: action.payload,
      }
    case 'setDBSelection':
      return {
        ...state,
        // Remove me
        dbSelection: action.payload,
      }
    case 'setGroupedData':
      return {
        ...state,
        groupedData: action.payload,
      }
    case 'setGroupedHeaders':
      return {
        ...state,
        groupedHeaders: action.payload,
      }
    case 'setConsolidatedData':
      return {
        ...state,
        consolidatedData: action.payload,
      }
    case 'setStartDays':
      return {
        ...state,
        startDays: action.payload,
      }
    case 'setEndDays':
      return {
        ...state,
        endDays: action.payload,
      }
    case 'setLoading':
      return {
        ...state,
        loading: action.payload,
      }
    case 'resetState':
      return { ...reportingInitialState, ...action.payload }
    case 'setEditSavedReport':
      return { ...state, savedReport: action.payload }
    default:
      return { ...state }
  }
}
