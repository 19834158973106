import expertReducer from './ExpertReducer'
import userReducer from './UserReducer'
import { createStore, combineReducers } from 'redux'

export const rootReducer = combineReducers({
  user: userReducer,
  expert: expertReducer,
})

export const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
)

export default store
