import React, { memo } from 'react'
import Confetti from 'react-confetti'
import useWindowSize from 'react-use/lib/useWindowSize'

const RenderConfetti = memo((props) => {
  const { width, height } = useWindowSize()
  return (
    <div>
      <Confetti
        width={width}
        height={height}
        recycle={false}
        numberOfPieces={800}
        gravity={0.14}
        wind={0}
        initialVelocityX={4}
      />
    </div>
  )
})

export default RenderConfetti
