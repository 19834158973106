import React, { useContext, useEffect, useRef, useState } from 'react'
import '@asurion/sass-design-system/build/asurion/asurion.css'
import '@asurion/atomic-ui-library'
import EjectRuleDialog from '../../../common/Organisms/EjectRuleDialog'
import { Button, DispatchRuleDialog, Flex, FormLabel, Input, SubmitButton } from '../../../common'
import { defaultDispatchRuleObject, defaultEjectRuleObject } from '../../../../constants'
import FilterableTable from '../../../common/Organisms/FilterableTable'
import SideBar from '../../../Trainings/TrainingAssignmentModal/SideBar'
import { AssignTrainingContext } from '../../../../context/AssignTrainingContext'
import { checkInput, checkPermissions, handleInput } from '../../../../utils'
import { RouteContext } from '../../../../context/RouteContext'
import { getGroupsForTrainingRules, getTrainingRules, putTrainingRule } from '../../../../actions'
import useDynamicRefs from '../refs-utils'
import { useForm } from 'react-hook-form'

const AddTrainingRule = ({
  cancelAddTrainingRule,
  postTrainingRule,
  existingDispatchRules,
}: {
  cancelAddTrainingRule: any
  postTrainingRule: any
  existingDispatchRules: any[]
}) => {
  const { selectedLinkProps } = useContext(RouteContext)
  const { setShouldGetGroupsOnly, empGroupsAsTableData, resetAllChecks } =
    useContext(AssignTrainingContext)

  const isAnEdit = selectedLinkProps && 'groupId' in selectedLinkProps
  const [trainingRuleName, setTrainingRuleName] = useState<string>(
    isAnEdit ? selectedLinkProps.name : '',
  )
  const [dispatchConfig, setDispatchConfig] = useState<any>(
    isAnEdit ? selectedLinkProps.dispatchConfig : defaultDispatchRuleObject,
  )
  const [ejectConfig, setEjectConfig] = useState<any[]>(
    isAnEdit ? selectedLinkProps.ejectConfig : [defaultEjectRuleObject],
  )
  const [empGroupIds, setEmpGroupIds] = useState<string[]>(selectedLinkProps?.empGroupIds || [])
  const [existingEmpGroups, setExistingEmpGroups] = useState<any[]>([])
  const [data, setData] = useState<any[]>(empGroupsAsTableData)
  const [formValid, setFormValid] = useState<boolean>(true)

  const [getRefs, setRef] = useDynamicRefs()

  const {
    clearErrors,
    handleSubmit,
    formState: { errors },
    register,
    setValue,
    trigger,
  } = useForm({
    defaultValues: {
      trainingRuleName: trainingRuleName || '',
    },
  })

  useEffect(() => setShouldGetGroupsOnly(true), [])
  useEffect(() => {
    if (Array.isArray(existingEmpGroups) && existingEmpGroups?.length > 0) {
      setData([...existingEmpGroups, ...empGroupsAsTableData])
    } else {
      setData(empGroupsAsTableData)
    }
  }, [empGroupsAsTableData])

  useEffect(() => {
    if (Array.isArray(empGroupIds) && empGroupIds?.length > 0) {
      setData(existingEmpGroups)
    }
  }, [existingEmpGroups])

  useEffect(() => {
    if (isAnEdit) {
      getGroupsForTrainingRules(selectedLinkProps.empGroupIds).then(
        (dataForCurrentTrainingRule) => {
          setExistingEmpGroups(dataForCurrentTrainingRule)
        },
      )
    }
  }, [empGroupIds])

  const handleFormSubmit = async () => {
    const rule: Partial<{
      trainingRuleName: string
      dispatchConfig: any
      ejectConfig: any[]
      empGroupIds?: string[]
      groupId?: string
    }> = {
      trainingRuleName,
      dispatchConfig,
      ejectConfig: getRefs(),
      empGroupIds: data?.map(({ groupId }: { groupId: string }) => groupId) || [],
    }
    if (!formValid) return
    if (isAnEdit) {
      rule.groupId = selectedLinkProps.groupId
      await postTrainingRule(rule, 'put')
    } else {
      await postTrainingRule(rule, 'post')
    }
  }
  const permission = checkPermissions('rules', 'edit')
  return (
    <div>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div style={{ width: 400 }}>
          <FormLabel id={'trainingRuleName'} value={'Training Rule Name'}>
            <Input
              {...register('trainingRuleName', {
                required: { value: true, message: 'This field is required.' },
                validate: (value) => {
                  if (value.trim().length === 0) return 'This field is required.'
                },
              })}
              error={errors.trainingRuleName || null}
              value={trainingRuleName}
              type={'rolled'}
              handleChange={(e) => {
                handleInput(e, setTrainingRuleName)
                setValue('trainingRuleName', e.currentTarget.value)
                checkInput('trainingRuleName', e.target.value, trigger, clearErrors, setFormValid)
              }}
            />
          </FormLabel>
        </div>
        <div style={{ marginBottom: 20 }}>
          <DispatchRuleDialog
            existingDispatchRules={existingDispatchRules}
            onEditInTrainingRule={(e: any) => setDispatchConfig(e)}
            rule={dispatchConfig}
            cancelAddDispatchRules={() => null}
            postDispatchRule={() => null}
            formValidation={setFormValid}
          />
        </div>
        <FormLabel id={'ejectConfigs'} value={'Eject Rules'}>
          {ejectConfig.map((config: any, index: number) => {
            return (
              <div style={{ marginBottom: 20 }}>
                <EjectRuleDialog
                  ref={setRef()}
                  index={index}
                  allRules={ejectConfig}
                  key={JSON.stringify(config)}
                  onEditInTrainingRule={setEjectConfig}
                  postEjectRule={() => null}
                  cancelAddEjectRules={() => 'cancelled'}
                  config={config}
                  formValidation={setFormValid}
                />
              </div>
            )
          })}
        </FormLabel>
        <div style={{ marginBottom: 20 }}>
          <Button
            type="button"
            handleClick={() => setEjectConfig([...ejectConfig, defaultEjectRuleObject])}
            size={'sm'}
            displayType={'outline'}
          >
            Add eject rule
          </Button>
        </div>
        <Flex
          style={{
            minHeight: 'auto',
            maxHeight: 500,
            overflow: 'hidden',
            marginBottom: 20,
          }}
        >
          <div
            style={{
              width: 400,
              minHeight: 'auto',
              maxHeight: 500,
              overflow: 'auto',
              borderRight: '1px solid #efefef',
              marginRight: 15,
            }}
          >
            <SideBar
              filtered={true}
              mustDisable={selectedLinkProps.allEmpGroupIdsNotBelongingToThisRule || []}
            />
          </div>
          <div
            style={{
              width: '100%',
              minHeight: 'auto',
              maxHeight: 500,
              overflow: 'auto',
            }}
          >
            <FilterableTable
              hideSelectionBar={false}
              width={'100%'}
              data={data || []}
              headers={[
                { value: 'checkbox', display: '', order: 0 },
                { value: 'groupName', display: 'Group Name', sortable: true, order: 0 },
              ]}
              ruleType={'removeGroup'}
              onUpdate={(remove) => {
                const payload = JSON.parse(remove)
                const { empGroupIds } = payload
                const filtered = data.filter((item) =>
                  empGroupIds.some((groupId: string) => item.groupId === groupId),
                )
                setData(filtered)
              }}
              noExport={true}
            />
          </div>
        </Flex>
        <Flex config={['justifyStart', 'alignCenter']}>
          {permission && <SubmitButton marginTop={25}>Save Changes</SubmitButton>}

          <Button
            displayType="outline"
            size="lg"
            handleClick={cancelAddTrainingRule}
            className="is-primary is-ghost"
            style={{ margin: 25 }}
          >
            Cancel
          </Button>
        </Flex>
      </form>
    </div>
  )
}

export default AddTrainingRule
