import React, { useState, useEffect, ChangeEvent, useContext, useRef } from 'react'
import {
  FormLabel,
  ToggleSwitch,
  Button,
  Input,
  IconComponent,
  Tag,
  DatePicker,
  Flex,
  Dropdown,
  Loading,
} from '../common'
import { fetchAdminConfig, postAdminConfig, runPipeline } from '../../actions'
import { format } from 'date-fns'
import { RouteContext } from '../../context/RouteContext'
import { AdminConfigContext } from '../../context/AdminConfigContext'
import { optionConfig } from '../../constants'
import { IDropdownOption } from '../common/Atoms/Dropdown'
import { useSelector } from 'react-redux'
import themes from '../../themes/schema'
import { checkPermissions } from '../../utils'

const keyMap: { [key: string]: string } = {
  cooldownMinutes: 'config.dispatchConfig.cooldownMinutes',
  maxTrainingsPerDay: 'config.dispatchConfig.maxTrainingsPerDay',
  outageStartTime: 'config.dispatchConfig.outageStartTime',
  outageEndTime: 'config.dispatchConfig.outageEndTime',
  isEnabled: 'config.dispatchConfig.isEnabled',
  tags: 'config.tags',
  aspectTrainingBuffer: 'config.dispatchConfig.aspectTrainingBuffer',
}

const Dispatch = ({ popToast }: { popToast: (msg: string) => void }) => {
  const { setHasEdited } = useContext(RouteContext)
  const { refreshContext } = useContext(AdminConfigContext)
  const { dataPipelineOptions } = optionConfig
  const [adminConfig, setAdminConfig] = useState<any>({})
  const [systemFlagIsActive, setSystemFlagIsActive] = useState<string>('Shut Off')
  const [cooldownMinutes, setCooldownMinutes] = useState<string>()
  const [cooldownMinutesValid, setCooldownMinutesValid] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [maxTrainingsPerDay, setMaxTrainingsPerDay] = useState<string>()
  const [maxTrainingsPerDayValid, setMaxTrainingPerDayValid] = useState<boolean>(true)
  const [tags, setTags] = useState<string[]>([])
  const [endTime, setEndTime] = useState('')
  const [startTime, setStartTime] = useState('')
  const [aspectTrainingBuffer, setAspectTrainingBuffer] = useState<number>(4)
  const [aspectTrainingBufferValid, setAspectTrainingBufferValid] = useState<boolean>(true)
  const [dataPipelineTarget, setDataPipelineTarget] = useState<IDropdownOption>(
    dataPipelineOptions[0],
  )
  const isMounting = useRef(true)
  const {
    userSettings: { darkMode },
  } = useSelector((state: any) => state?.user)
  const customDisplayType = darkMode ? 'darkMode' : 'originalFlavor'
  const getStyle: Record<string, any> = {
    addTagColor_darkMode: themes.darkMode.tag.addTag.color,
    addTagBackgroundColor_darkMode: themes.darkMode.tag.addTag.backgroundColor,
    addTagColor_originalFlavor: themes.originalFlavor.tag.addTag.color,
    addTagBackgroundColor_originalFlavor: themes.originalFlavor.tag.addTag.backgroundColor,
  }
  useEffect(() => {
    ;(async () => {
      try {
        const adminConfig = await fetchAdminConfig()
        setAdminConfig(adminConfig)
        return
      } catch (ex) {
        console.error(ex, 'No assignments for Workers')
      }
    })()
  }, [setAdminConfig])
  useEffect(() => {
    if (isMounting.current) {
      isMounting.current = false
      return
    }
    const { dispatchConfig = {} } = adminConfig
    const {
      maxTrainingsPerDay,
      cooldownMinutes,
      isEnabled,
      outageStartTime,
      outageEndTime,
      aspectTrainingBuffer,
    } = dispatchConfig
    const preloadedTags = adminConfig.tags || []
    setMaxTrainingsPerDay(maxTrainingsPerDay)
    setCooldownMinutes(cooldownMinutes)
    setSystemFlagIsActive(isEnabled !== '0' ? 'Active' : 'Shut Off')
    setAspectTrainingBuffer(aspectTrainingBuffer)
    if (outageStartTime && outageEndTime) {
      setStartTime(format(new Date(outageStartTime), "yyyy-MM-dd'T'HH:mm") || '')
      setEndTime(format(new Date(outageEndTime), "yyyy-MM-dd'T'HH:mm") || '')
    }
    setTags(preloadedTags.sort())
  }, [adminConfig])

  const isValid = (config: { [key: string]: any }) => {
    let validationResult = true
    const validationMap: any = {
      cooldownMinutes: setCooldownMinutesValid,
      maxTrainingsPerDay: setMaxTrainingPerDayValid,
      aspectTrainingBuffer: setAspectTrainingBuffer,
    }
    Object.keys(validationMap).forEach((key) => {
      if (Number(config[key]) <= 0) {
        validationMap[key](false)
        validationResult = false
      } else {
        if (key === 'aspectTrainingBuffer') {
          validationMap[key](Number(aspectTrainingBuffer))
          return
        }
        validationMap[key](true)
      }
    })
    return validationResult
  }
  const handleRunPipeline = async () => {
    setLoading(true)
    await runPipeline(dataPipelineTarget.value)
    setLoading(false)
  }
  const handleFormSubmit = () => {
    const formatTimezone = (date: any) => {
      return new Date(date).toISOString()
    }

    const configObj: { [key: string]: any } = {
      cooldownMinutes: parseInt(cooldownMinutes!, 10) || -1,
      maxTrainingsPerDay: parseInt(maxTrainingsPerDay!, 10) || -1,
      outageStartTime: startTime ? formatTimezone(startTime) : null,
      outageEndTime: endTime ? formatTimezone(endTime) : null,
      isEnabled: systemFlagIsActive === 'Active' ? '1' : '0',
      tags: tags,
      aspectTrainingBuffer: aspectTrainingBuffer,
    }
    if (isValid(configObj)) {
      ;(async () => {
        try {
          const updateObj = Object.keys(configObj).reduce((acc, currentKey) => {
            return { ...acc, [keyMap[currentKey]]: configObj[currentKey] }
          }, {})
          await postAdminConfig({ set: updateObj })
          await refreshContext()
          popToast('Successfully Saved')
        } catch (ex) {
          console.error(ex, 'Error in posting to the Admin Configuration')
        }
      })()
    }
    setHasEdited(false)
  }
  const handleTagUpdate = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const updatedTags = tags.map((tag: string, n: number) => {
      if (n === index) {
        tag = e.currentTarget.value
      }
      return tag
    })
    return setTags(updatedTags)
  }
  const handleClear = (index: number) => {
    const tagList = tags.filter((val, n) => {
      return n !== index
    })
    setTags(tagList)
  }
  const permission = checkPermissions('dispatch', 'edit')
  return (
    <div style={{ maxWidth: '850px', padding: '26px 16px' }}>
      <Flex config={['justifyBetween']}>
        <Flex config={['column']} style={{ marginRight: 50, flex: '1 1 350px' }}>
          <FormLabel
            id="adminConfig-cooldown"
            value="Cooldown (minutes)"
            tooltip={{ selector: 'configuration.Dispatch.Cooldown' }}
          >
            <Input
              handleChange={(e) => {
                if (!/\D/g.test(e.currentTarget.value)) {
                  setCooldownMinutes(e.currentTarget.value)
                  setHasEdited(true)
                }
              }}
              type="number"
              value={cooldownMinutes}
              style={
                cooldownMinutesValid
                  ? { maxWidth: '250px' }
                  : { border: '2px solid red', maxWidth: '250px' }
              }
              disabled={!permission}
            />
          </FormLabel>
          <FormLabel
            id="adminConfig-max-delivery"
            value="Maximum Delivery Per Day"
            tooltip={{ selector: 'configuration.Dispatch.MaximumDeliveryPerDay' }}
          >
            <Input
              handleChange={(e) => {
                if (!/\D/g.test(e.currentTarget.value)) {
                  setMaxTrainingsPerDay(e.currentTarget.value)
                  setHasEdited(true)
                }
              }}
              type="number"
              value={maxTrainingsPerDay}
              style={
                maxTrainingsPerDayValid
                  ? { maxWidth: '250px' }
                  : { border: '2px solid red', maxWidth: '250px' }
              }
              disabled={!permission}
            />
          </FormLabel>
        </Flex>
        <div style={{ flex: '1 1 350px', marginRight: 50 }}>
          <FormLabel id="adminConfig-data-pipeline" value="Run DataPipeline">
            <Flex config={['alignCenter', 'justifyBetween']} style={{ width: 365 }}>
              <Dropdown
                type="rolled"
                style={{ width: 300 }}
                options={dataPipelineOptions}
                selected={dataPipelineTarget}
                emitDropdownOption={(e: IDropdownOption) => {
                  setDataPipelineTarget(e)
                }}
              />
              {permission && (
                <>
                  {loading ? (
                    <Loading size="xs" />
                  ) : (
                    <Button size="sm" handleClick={handleRunPipeline}>
                      Run
                    </Button>
                  )}
                </>
              )}
            </Flex>
          </FormLabel>
          <FormLabel
            id="adminConfig-aspectTrainingBuffer"
            value="Training Buffer"
            tooltip={{ selector: 'configuration.Aspect.TrainingBuffer' }}
          >
            <Input
              handleChange={(e) => {
                if (!/\D/g.test(e.currentTarget.value)) {
                  setAspectTrainingBuffer(Number(e.currentTarget.value))
                  setHasEdited(true)
                }
              }}
              type="number"
              value={String(aspectTrainingBuffer)}
              style={
                aspectTrainingBufferValid
                  ? { maxWidth: '250px' }
                  : { border: '2px solid red', maxWidth: '250px' }
              }
              disabled={!permission}
            />
          </FormLabel>
        </div>
      </Flex>
      <FormLabel
        id="globalOutage"
        value="Global Outage Settings"
        tooltip={{ selector: 'configuration.Dispatch.GlobalOutageSettings' }}
      >
        <div></div>
      </FormLabel>
      <Flex>
        <Flex config={['column']} style={{ marginRight: 50, flex: '1 1 350px' }}>
          <FormLabel id="adminConfig-outage-start" value="Outage Start">
            <DatePicker
              dateType={'datetime-local'}
              type={'rolled'}
              handleChange={(e: string) => {
                setHasEdited(true)
                setStartTime(e)
              }}
              value={startTime}
              placeholder={'Select a start time'}
              disabled={!permission}
            />
          </FormLabel>
        </Flex>
        <Flex config={['column']} style={{ marginRight: 50, flex: '1 1 350px' }}>
          <FormLabel id="adminConfig-outage-end" value="Outage End">
            <DatePicker
              dateType={'datetime-local'}
              type={'rolled'}
              handleChange={(e: string) => {
                setHasEdited(true)
                setEndTime(e)
              }}
              value={endTime}
              placeholder={'Select an end time'}
              disabled={!permission}
            />
          </FormLabel>
        </Flex>
      </Flex>
      <FormLabel id="tags" value="Tags" tooltip={{ selector: 'configuration.Dispatch.Tags' }}>
        <div>
          <Flex config={['wrap']}>
            {tags &&
              tags?.length > 0 &&
              tags.map((tag, index) => (
                <Tag
                  disabled={!permission}
                  type="input"
                  value={tag}
                  key={index}
                  onApply={(e): void => {
                    setHasEdited(true)
                    handleTagUpdate(e, index)
                  }}
                  inputValue={tag}
                  onClear={() => {
                    setHasEdited(true)
                    handleClear(index)
                  }}
                />
              ))}
            {permission && (
              <button
                type="button"
                className={'ttd-bare-button'}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: 'max-content',
                  marginRight: 5,
                  marginTop: 5,
                  padding: '3px 12px',
                  borderRadius: '16px',
                  backgroundColor: getStyle[`addTagBackgroundColor_${customDisplayType}`],
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setHasEdited(true)
                  setTags([...tags, ''])
                }}
              >
                <span
                  style={{
                    marginRight: 5,
                    backgroundColor: getStyle[`addTagBackgroundColor_${customDisplayType}`],
                    color: getStyle[`addTagColor_${customDisplayType}`],
                  }}
                  role="textbox"
                >
                  Add tag
                </span>
                <IconComponent icon={'add_circle_outline'} />
              </button>
            )}
          </Flex>
        </div>
      </FormLabel>
      <Flex>
        {permission && (
          <FormLabel
            id="adminConfig-system-flag"
            value="System Flag"
            tooltip={{ selector: 'configuration.Dispatch.SystemFlag' }}
          >
            <ToggleSwitch
              onClick={() => {
                setHasEdited(true)
                switch (systemFlagIsActive) {
                  case 'Active':
                    return setSystemFlagIsActive('Shut Off')
                  case 'Shut Off':
                    return setSystemFlagIsActive('Active')
                  default:
                    return setSystemFlagIsActive('Shut Off')
                }
              }}
              selected={systemFlagIsActive}
              primaryLabel={'Active'}
              secondaryLabel={'Shut Off'}
            />
          </FormLabel>
        )}
      </Flex>
      {permission && (
        <Button style={{ marginTop: 15 }} handleClick={handleFormSubmit}>
          Save Changes
        </Button>
      )}
    </div>
  )
}

export default Dispatch
