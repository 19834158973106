import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import SSO from './components/SSO/index'
import AuthHub from './components/SSO/AuthHub/index'
import store from './reducers'
import './App.scss'
import '@asurion/sass-design-system/build/asurion/asurion.css'
import '@asurion/asurion-design-assets-asurion/asurion.css'
import 'react-toastify/dist/ReactToastify.css'
import TTD from './components/common/TTD/'
import Error from './components/common/Error'

const Home = () => {
  return <Navigate replace to="/trainings" />
}

const App = () => {
  return (
    <Provider store={store}>
      <AuthHub />
      <BrowserRouter>
        <Routes>
          <Route path="/auth" element={<SSO />} />
          <Route path="/trainings" element={<TTD />} />
          <Route path="/" element={<Home />} />
          <Route path="/error" element={<Error />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  )
}

export default App
