import { IEjectRuleRow } from '../../../../../interfaces'
import { optionConfig } from '../../../../../constants'
import React, { useState } from 'react'
import { IDropdownOption } from '../../../../common/Atoms/Dropdown'
import { Dropdown, Flex, FormLabel, Input } from '../../../../common'

const EjectRuleRow = ({ children, emitEjectRule, ejectRule }: IEjectRuleRow) => {
  const getOptionsPerKey = () => {
    const cloneEjectRule: Record<string, any> = { ...ejectRule }
    const obj: Record<string, any> = {}
    for (const key in cloneEjectRule) {
      obj[key] =
        optionConfig[key] &&
        optionConfig[key]?.findIndex((option: any) => option.value === cloneEjectRule[key])
    }
    return obj
  }
  const [defaultOptionValues] = useState<any>(getOptionsPerKey())
  const [value, setValue] = useState<string>(ejectRule.value || '1')
  const [ejectRuleData, setEjectRuleData] = useState<IDropdownOption>()
  const [comparator, setComparator] = useState<IDropdownOption>()
  const handleEmit = (obj: IDropdownOption) => emitEjectRule(obj)
  const dropdowns = ['ejectRule', 'comparator', 'value']
  const display: Record<string, string> = {
    ejectRule: 'Eject Rule',
    comparator: 'Comparator',
    value: 'Value',
  }
  const dropdownMap: Record<string, any> = {
    ejectRule: ejectRuleData,
    comparator,
  }
  return (
    <Flex config={['alignCenter']} style={{ width: '100%' }}>
      {dropdowns.map((dropdownType, index) => {
        const option = optionConfig[dropdownType]
        return (
          <div key={`dropdownType${index}`} style={{ width: 280, marginRight: 50 }}>
            <FormLabel
              key={`dropdownType${index}Form`}
              id={`dropdownType${index}`}
              value={display[dropdownType]}
            >
              {dropdownType === 'value' ? (
                <Input
                  value={value}
                  key={`dropdownType${index}Input`}
                  type={'rolled'}
                  dataType="number"
                  handleChange={({ target: { value } }) => {
                    handleEmit({ id: 'value', value } as IDropdownOption)
                    setValue(value)
                  }}
                />
              ) : (
                <Dropdown
                  type={'rolled'}
                  options={option}
                  selected={dropdownMap[dropdownType] || option[defaultOptionValues[dropdownType]]}
                  emitDropdownOption={(option: IDropdownOption) => {
                    if (dropdownType === 'comparator') {
                      setComparator(option)
                    } else {
                      setEjectRuleData(option)
                    }
                    handleEmit(option)
                  }}
                />
              )}
            </FormLabel>
          </div>
        )
      })}
      {children}
    </Flex>
  )
}

export default EjectRuleRow
