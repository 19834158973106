import { IActionCell } from "../../../../interfaces";
import React, { SyntheticEvent, useState } from "react";
import { IconComponent, Loading } from "../../index";
import { evaluateType } from "../../../../utils";

const ActionCell = ({ actionTypes, handleClick }: IActionCell) => {
  const [dispatchingAction, setDispatchingAction] = useState<boolean>(false);

  const actionType: string = evaluateType(actionTypes);
  const base = {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
  };
  const style: Record<string, any> = {
    canEdit: {
      td: {
        ...base,
        color: "#3587cd",
      },
    },
    canAdd: {
      td: { ...base, color: "#35ac3f" },
    },
    canRemove: {
      td: {
        ...base,
        color: "#ce023f",
      },
    },
    truncated: {
      td: {
        ...base,
        color: "red",
      },
    },
    none: {
      td: { ...base },
    },
  };
  const icon: Record<string, string> = {
    canAdd: "add_circle",
    canRemove: "remove_circle",
    canEdit: "edit",
    none: "",
  };

  const label: Record<string, string> = {
    canEdit: "Edit",
    canAdd: "Add",
    canRemove: "Remove",
    none: "",
  };

  const handleActionClick = (e: SyntheticEvent) => {
    setDispatchingAction(true);
    return handleClick(e);
  };

  return dispatchingAction ? (
    <td className="ttd-td">
      <Loading size="sm" />
    </td>
  ) : (
    <td className="ttd-td">
      <button onClick={handleActionClick} style={style[actionType].td || {}}>
        <IconComponent style={{ marginRight: 5 }} icon={icon[actionType]} />
        <span> {label[actionType]} </span>
      </button>
    </td>
  );
};

export default ActionCell;
