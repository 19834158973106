import React from 'react'
import { Flex, Modal } from '../../../common'
import Sidebar from '../../TrainingAssignmentModal/SideBar'
import { GroupAssignmentModalContent } from '../styles'
interface IProps {
  children: any
  toggle: (show: boolean) => void
}
const GroupAssignmentModal = ({ toggle, children }: IProps) => {
  return (
    <Modal
      width="75%"
      header="Assign Employee Groups"
      close={() => {
        toggle(false)
      }}
    >
      {' '}
      <GroupAssignmentModalContent>
        <div className={'sidebar-wrapper'}>
          <Sidebar />
        </div>
        <div className={'groups-wrapper'}>
          <Flex config={['column']}>
            <h3>Selected</h3>
            {children}
          </Flex>
        </div>
      </GroupAssignmentModalContent>
    </Modal>
  )
}

export default GroupAssignmentModal
