const version = () => '[AIV]{version}[/AIV]'

const isProd = () => {
  const host = window.location.hostname
  return host.includes('missioncontrol.asurion53.com')
}

const isUat = () => {
  const host = window.location.hostname
  return host.includes('missioncontrol.nonprod-asurion53.com')
}
const isDev = () => {
  const host = window.location.hostname
  return host.includes('dev.missioncontrol.nonprod-asurion53.com')
}

const getEnvironment = () => {
  let env = ''
  if (isProd()) {
    env = 'PROD'
  } else if (isDev()) {
    env = 'DEV'
  } else if (isUat()) {
    env = 'UAT'
  } else {
    env = 'LOCAL'
  }
  return env
}

const getEnvironmentDomain = (env) => {
  switch (env) {
    case 'prod':
      return 'prod'
    case 'dev':
      return 'dev'
    case 'qa':
      return 'qa1.test'
    case 'training':
      return 'train'
    default:
      return 'prod'
  }
}

export { version, isProd, isDev, isUat, getEnvironment, getEnvironmentDomain }
