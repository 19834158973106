import { IReducer } from '../../interfaces'

export const fusionInitialState = {
  assignmentState: [],
  clearChecks: false,
  disabled: false,
  expertNumberNotificationData: {},
  payload: [],
  reportingState: [],
  requestedExperts: 0,
  showExpertNumberModal: false,
  showExpertNumberNotification: false,
  showSelectionModal: false,
  selectedTaskQueue: [],
  taskQueueList: [],
  workersSelected: [],
}

export const fusionReducer = (state: any, action: IReducer) => {
  const { payload, type } = action
  switch (type) {
    case 'setAssignmentState':
      return {
        ...state,
        assignmentState: payload,
      }
    case 'setClearChecks':
      return {
        ...state,
        clearChecks: payload,
      }
    case 'setExpertNumberNotificationData':
      return {
        ...state,
        expertNumberNotificationData: payload,
      }
    case 'setPayload':
      return {
        ...state,
        payload: payload,
      }
    case 'setRequestedExperts':
      return {
        ...state,
        requestedExperts: payload,
      }
    case 'setShowExpertNumberModal':
      return {
        ...state,
        showExpertNumberModal: payload,
      }
    case 'setShowExpertNumberNotification':
      return {
        ...state,
        showExpertNumberNotification: payload,
      }
    case 'setShowSelectionModal':
      return {
        ...state,
        showSelectionModal: payload,
      }
    case 'setSelectedTaskQueues':
      return {
        ...state,
        selectedTaskQueues: payload,
      }
    case 'setStatusChange':
      return {
        ...state,
        statusChange: payload,
      }
    case 'setReportingState':
      return {
        ...state,
        reportingState: payload,
      }
    case 'setTaskQueueList':
      return {
        ...state,
        taskQueueList: payload,
      }
    case 'setWorkersSelected':
      return {
        ...state,
        workersSelected: payload,
      }

    default:
      return { ...state }
  }
}
