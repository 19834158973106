import React, { useState } from 'react'
import {
  Dropdown,
  FormLabel,
  Button,
  Loading,
  Flex,
  IconComponent,
  ToggleSwitch,
} from '../../common'
import DataFromRedis from './redisData'
import { optionConfig } from '../../../constants'
import { deriveFiltersFromType, handleGetRedisData } from '../Functions'
import { useEffect } from 'react'
import styled, { StyledComponent } from 'styled-components'

const { RedisListOptions } = optionConfig

const Redis = ({ state, dispatch }: any) => {
  const handleFoldFilters = () => {
    dispatch({ type: 'setExpand', payload: !state.expand })
  }
  useEffect(() => {
    dispatch({ type: 'setEmpIds', payload: [] })
    dispatch({ type: 'setEmpNameSelection', payload: [] })
    dispatch({ type: 'setDepartment', payload: '' })
    dispatch({ type: 'setDepartmentName', payload: [] })
    dispatch({ type: 'setDepartmentNameSelection', payload: [] })
    dispatch({ type: 'setEmpIdValue', payload: '' })
    dispatch({ type: 'setEmpIdsForForm', payload: [] })
    dispatch({ type: 'setRedisData', payload: {} })
    dispatch({ type: 'setWorkerSid', payload: '' })
  }, [state.redisActionType, dispatch])

  const handleRedisActionList = (e: { id: string; value: string; display: string }) => {
    if (e.id !== state.redisActionType.id) {
      dispatch({ type: 'setRedisActionType', payload: e })
    }
  }

  return (
    <React.Fragment>
      <RedisActionContainer expand={state.expand}>
        {!state.expand && (
          <FormLabel id="ttd-report-list" value="Action">
            <Dropdown
              style={{ width: 300 }}
              options={RedisListOptions}
              selected={state.redisActionType}
              emitDropdownOption={handleRedisActionList}
            />
          </FormLabel>
        )}
        {!state.expand && deriveFiltersFromType(state, dispatch)}
        <Flex config={['alignCenter', 'justifyBetween']} style={{ marginTop: 'auto' }}>
          {!state.expand && (
            <Flex config={['justifyBetween']} style={{ width: '75%' }}>
              <Button
                displayType={'outline'}
                size={'sm'}
                handleClick={() => handleGetRedisData(state, dispatch)}
              >
                {state.loading ? <Loading size={'sm'} /> : 'Get Redis Data'}
              </Button>
            </Flex>
          )}
          <ArrowButton onClick={handleFoldFilters}>
            <IconComponent icon={state.expand ? 'keyboard_arrow_right' : 'keyboard_arrow_left'} />
          </ArrowButton>
        </Flex>
      </RedisActionContainer>
      <Container>
        <ToggleSwitch
          onClick={() => {
            if (state.toggleJSON === 'JSON') {
              dispatch({ type: 'setToggleJSON', payload: 'Pretty' })
            } else {
              dispatch({ type: 'setToggleJSON', payload: 'JSON' })
            }
          }}
          selected={state.toggleJSON}
          primaryLabel={'JSON'}
          secondaryLabel={'Pretty'}
        />
        {!!Object.keys(state.redisData).length && (
          <DataFromRedis state={state} dispatch={dispatch} />
        )}
      </Container>
    </React.Fragment>
  )
}
export default Redis

const RedisActionContainer: StyledComponent<'div', any, { expand: boolean }, never> = styled.div`
  overflow: 'auto';
  display: flex;
  width: 100%;
  border-right: 1px solid #dfdfdf;
  padding-right: 15px;
  margin-right: 15px;
  flex-direction: column;
  ${({ expand }: { expand: boolean }) => {
    if (expand) {
      return `
      width: 50px;
      min-width: 50px;
      `
    } else {
      return `
      height: 100%;
      width: 395px;
      `
    }
  }}
`

const Container: StyledComponent<'div', any, {}, never> = styled.div`
  margin-left: 12px;
  width: 100%;
  height: 100%;
`
const ArrowButton: StyledComponent<'button', any, {}, never> = styled.button`
  margin-top: 5px;
  background: transparent;
  border: none;
  cursor: pointer;
`
