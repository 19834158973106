const reportingAssignments = [
  { id: 'expertName', value: 'expertName', display: 'Expert Name' },
  { id: 'empId', value: 'empId', display: 'Emp Id' },
  { id: 'trainingName', value: 'trainingName', display: 'Training' },
  { id: 'empId', value: 'empId', display: 'Emp Id' },
  { id: 'startedTraining', value: 'startedTraining', display: 'Start' },
  {
    id: 'status',
    value: `[
      { id: 'created', value: 'created', display: 'Offered' },
      { id: 'started', value: 'started', display: 'In Progress' },
      { id: 'completed', value: 'completed', display: 'Completed' },
      { id: 'timeout', value: 'timeout', display: 'Ignored' },
      { id: 'timelimitmet', value: 'timelimitmet', display: 'Time Exceeded' },
      { id: 'ejected', value: 'ejected', display: 'Ejected' },
    ]`,
    display: 'Status',
  },
  { id: 'trainingLength', value: 'trainingLength', display: 'Duration' },
  { id: 'taskSids', value: 'taskSids', display: 'Attempts' },
  { id: 'businessUnit', value: 'businessUnit', display: 'Business Unit' },
  { id: 'site', value: 'site', display: 'Site' },
  { id: 'mascot', value: 'mascot', display: 'Mascot' },
  { id: 'deptGroupName', value: 'deptGroupName', display: 'Department' },
  { id: 'supervisor_name', value: 'supervisor_name', display: 'Supervisor' },
  { id: 'timezone', value: 'timezone', display: 'Timezone' },
]

const statuses: Record<string, string> = {
  created: 'Training Sent',
  'fusion.canceled': 'Move Canceled',
  'fusion.completed': 'Move Completed',
  'fusion.dispatched': 'Moving to Anywhere Expert',
  'fusion.eject': 'Moving to Voice',
  'fusion.ended': 'Moved to Voice',
  'fusion.started': 'Moved to Anywhere Expert',
  'training.completed': 'Training Completed',
  'training.started': 'Training Started',
  'training.timelimitmet': 'Training Time Expired',
  'training.timeout': 'Training Ignored',
  'ejector.ejecting': 'Training Cancellation Started',
  'training.ejected': 'Training Cancelled - High Call Volume',
  'training.ejected.lostfocus': 'Training Cancelled - Lost Focus',
  'training.ejected.inactivity': 'Training Cancelled - Inactivity',
  'training.ejected.completed': 'Completed after High Call Volume ejection',
  'training.ejected.lostfocus.completed': 'Completed after Lost Focus threshold exceeded',
  'training.ejected.inactivity.completed': 'Completed after Inactivity threshold exceeded',
  'training.monitoring.warning.lostfocus.initiate': 'Lost Focus Warning Sent',
  'training.monitoring.warning.lostfocus.cancel': 'Focus Regained',
  'training.monitoring.warning.inactivity.initiate': 'Inactivity Warning Sent',
  'training.monitoring.warning.inactivity.cancel': 'Activity Detected',
  'training.completed.tabclose': 'Login after Flex Tab Closed',
  'training.tab.ontabopen': 'Login after Flex Tab Closed',
  'training.pageclose': 'Flex Tab Refreshed or Closed',
}

export { reportingAssignments, statuses }
