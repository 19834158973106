import { API } from 'aws-amplify'
export const updateConfig = async (key: string, value: any) => {
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const params = {
      queryStringParameters: { table: 'AdminConfig', id: 'admin-config' },
      body: { set: { [key]: value } },
    }
    return await API.put(apiName, apiPath, params)
  } catch (ex) {
    console.error('Error saving: ', ex)
    return
  }
}

export const runPipeline = async (key: string) => {
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const params = {
      queryStringParameters: { table: 'DataPipeline' },
      body: { controller: key },
    }
    return await API.post(apiName, apiPath, params)
  } catch (ex) {
    console.error('Error saving: ', ex)
    return
  }
}
