import styled from 'styled-components'
import { IItemProps } from '../../../interfaces'
import { Flex } from '../../common/index'

export const Item = styled.div<IItemProps>`
  display: flex;
  margin: 2px 0;
  color: ${({ toBeDeleted }) => (toBeDeleted ? '#bdbdbd' : '#201f1e')};

  span {
    align-self: center;
  }

  p {
    margin: 0;
    color: purple;
    text-decoration: underline;
    text-decoration-color: purple;
  }
`
export const Divider = styled.h6`
  margin-bottom: 1.8rem;
  text-align: left;
  font-weight: bold;
`

export const ContainerFlex = styled(Flex)`
  .navigate-back-button {
    cursor: pointer;
  }

  .top-section {
    display: flex;
    width: 70%;
    padding: 15px 25px;
    border-bottom: 1px solid #a5aaaf;
  }
`

export const TrainingContainerFlex = styled(Flex)`
  display: flex;

  button {
    display: flex;
    width: 3rem;
    cursor: pointer;
  }

  .delete-training-icon {
    width: 100px;
    font-size: 17;
    align-self: center;
    margin-left: 1rem;
  }
`
export const DeleteAutoAssignmentFlex = styled(Flex)`
  display: flex;
  width: 50%;
  padding: 15px 25px;
  border-bottom: 1px solid #a5aaaf;

  .button-wrapper {
    display: flex;
    align-items: center;
  }
`

export const GroupAssignmentModalContent = styled.div`
  display: flex;
  min-height: auto;
  max-height: 80vh;
  overflow: hidden;

  .sidebar-wrapper {
    width: 400;
    min-height: auto;
    max-height: 750;
    overflow: auto;
    border-right: 1px solid #efefef;
    margin-right: 15;
  }

  .groups-wrapper {
    width: 100%;
    margin: 0 0 0 2rem;
    min-height: auto;
    overflow: scroll;
  }
`
