import { IDropdownOption, IFilterDropdownOption } from '../../common/Atoms/Dropdown'
import { IMappedAssignments, IMappedTraining, IReportingState } from '../../../interfaces'
import { addDays } from 'date-fns'
import ReportForm from '../ReportForm'
import React from 'react'
import { reportingInitialState } from '../../../reducers/TTDReducers/reportingReducer'
import { optionConfig } from '../../../constants'
import { getDateRanges } from '../../../utils'

const { dateRangeOptions, reportListOptions, dateKeyOptions } = optionConfig

const handlePopulateStateFromSavedReport = (payload: any, dispatch: any, state: any) => {
  const {
    dateConfig = {},
    dateKey = '',
    names = [],
    reportType = '',
    tags = [],
    trainingIds = [],
    empIds = [],
    status = [],
    employeeStatus = { value: '', display: '', id: '' },
    opsHierarchy = { value: '', display: '', id: '' },
    dateHierarchy = { value: '', display: '', id: '' },
  } = payload

  const dateRangeSelected =
    dateRangeOptions.find(
      (option: IDropdownOption) => Object.keys(dateConfig).length && option.id === dateConfig.key,
    ) || {}

  const reportTypeSelected = reportListOptions.find(
    (option: IDropdownOption) => option.value === reportType,
  )

  dispatch({ type: 'setReportType', payload: reportTypeSelected })
  dispatch({ type: 'setDateSelectionDropdown', payload: dateRangeSelected })

  if (dateRangeSelected.value === 'custom') {
    const { start, end } = dateConfig
    const dateFromStart = addDays(new Date(), start).toISOString()
    const dateFromEnd = addDays(new Date(), end).toISOString()
    dispatch({ type: 'setEndDays', payload: dateConfig.start })
    dispatch({ type: 'setStartDays', payload: dateConfig.end })
    dispatch({ type: 'setFromDate', payload: dateFromStart })
    dispatch({ type: 'setToDate', payload: dateFromEnd })
  }

  if (opsHierarchy.value) {
    dispatch({ type: 'setOpsHierarchy', payload: opsHierarchy })
  }

  if (dateHierarchy.value) {
    dispatch({ type: 'setDateHierarchy', payload: dateHierarchy })
  }

  if (employeeStatus.value) {
    dispatch({ type: 'setEmployeeStatus', payload: employeeStatus })
  }

  if (dateKey) {
    const dateKeySelected = dateKeyOptions.find(
      (option: IDropdownOption) => option.value === reportType,
    )
    dispatch({ type: 'setDateKey', payload: dateKeySelected })
  }

  if (tags.length) {
    const updatedTags: IFilterDropdownOption[] = []

    state.tags.forEach((sTag: IFilterDropdownOption) => {
      if (tags.includes(sTag.display)) {
        sTag.checked = !sTag.checked
      }
      updatedTags.push(sTag)
    })
    const count: IDropdownOption = {
      id: 'count',
      value: 'count',
      display: `(${
        updatedTags.filter((item: IFilterDropdownOption) => item.checked).length
      }) Selected`,
    }
    dispatch({ type: 'setTags', payload: updatedTags })
    dispatch({ type: 'setTagCount', payload: count })
  }

  if (trainingIds.length) {
    const updatedTrainings: IFilterDropdownOption[] = []
    state.trainings.forEach((sTraining: IFilterDropdownOption) => {
      if (trainingIds.includes(sTraining.id)) {
        sTraining.checked = !sTraining.checked
      }
      updatedTrainings.push(sTraining)
    })
    const count: IDropdownOption = {
      id: 'count',
      value: 'count',
      display: `(${
        updatedTrainings.filter((item: IFilterDropdownOption) => item.checked).length
      }) Selected`,
    }
    dispatch({ type: 'setTrainings', payload: updatedTrainings })
    dispatch({ type: 'setTrainingsCount', payload: count })
  }

  if (status.length) {
    const updatedStatus: IFilterDropdownOption[] = []
    state.status.forEach((sStatus: IFilterDropdownOption) => {
      if (status.includes(sStatus.value)) {
        sStatus.checked = !sStatus.checked
      }
      updatedStatus.push(sStatus)
    })
    const count: IDropdownOption = {
      id: 'count',
      value: 'count',
      display: `(${
        updatedStatus.filter((item: IFilterDropdownOption) => item.checked).length
      }) Selected`,
    }
    dispatch({ type: 'setStatus', payload: updatedStatus })
    dispatch({ type: 'setStatusCount', payload: count })
  }

  dispatch({ type: 'setEmpIdValue', payload: empIds })
  dispatch({ type: 'setEmpNameSelection', payload: names })
}

const determineValuesToUpdateForMultipleSelect = (state: any, e: any, key: any) => {
  const setValueRecord: Record<string, any> = {
    trainings: [state.trainings, 'setTrainings', state.trainingsCount, 'setTrainingsCount'],
    tags: [state.tags, 'setTags', state.tagCount, 'setTagCount'],
    status: [state.status, 'setStatus', state.statusCount, 'setStatusCount'],
    businessUnit: [
      state.businessUnits,
      'setBusinessUnits',
      state.businessUnitsCount,
      'setBusinessUnitsCount',
    ],
    mascot: [state.mascot, 'setMascot', state.mascot, 'setMascotCount'],
    site: [state.site, 'setSite', state.site, 'setSiteCount'],
  }
  const [value, setValue, count, setCount] = setValueRecord[key]
  let updatedArr
  if (`value` in e) {
    updatedArr = value.map((item: IFilterDropdownOption) => {
      if (item.value === e.value) {
        item.checked = !item.checked
      }
      return item
    })
  } else {
    const isAllChecked =
      value.length === value.filter((item: IFilterDropdownOption) => item.checked).length
    updatedArr = value.map((item: IFilterDropdownOption) => {
      item.checked = !isAllChecked
      return item
    })
  }
  const updatedCount: IDropdownOption = {
    ...count,
    display: `(${
      updatedArr.filter((item: IFilterDropdownOption) => item.checked).length
    }) Selected`,
  }

  return [setCount, updatedCount, setValue, updatedArr]
}

const getCheckedValues = (options: IFilterDropdownOption[]) =>
  options.filter((status: IFilterDropdownOption) => status.checked).map(({ value }) => value)

const makeTimestamp = (date: string, time: string, inputType: string) => {
  if (!time) {
    if (inputType === 'toTime') {
      return addDays(new Date(date), 1).toISOString()
    } else {
      const [yyyymmdd] = date.split('T')
      return `${yyyymmdd}T00:00:00.000Z`
    }
  }
  const [hours, minutes] = time.split(':') // ['08', ':', '23']
  //const timestamp = new Date(date)
  const [yyyymmdd] = date.split('T')
  return `${yyyymmdd} ${hours}:${minutes}:00.000`
  // timestamp.setHours(Number(hours))
  // timestamp.setMinutes(Number(minutes))
  // return timestamp.toISOString().replace('T', ' ').replace('Z', '')
}
const formatDate = (date: string, state: any) => {
  if (state.reportType.value === 'UserEventAudit') {
    return makeTimestamp(date, state.fromTime, '')
  }
  return date.split('T')[0]
}

const deriveBodyFromState = (state: IReportingState, selectedIds: Map<string, string[]>) => {
  const filterDropdownResults = (arr: IFilterDropdownOption[]) => {
    const results = arr.reduce((acc: string[], cur) => {
      if (cur.checked) {
        acc.push(cur.value)
      }
      return acc
    }, [])
    return results && results.length ? results : undefined
  }

  const defaultDateRange = getDateRanges()

  const fromDate =
    state.fromDate && state.fromDate.length
      ? state.fromDate
      : defaultDateRange[dateRangeOptions[4].value].start
  const toDate =
    state.toDate && state.toDate.length
      ? state.toDate
      : defaultDateRange[dateRangeOptions[4].value].end
  const statuses = ['ejected', 'started', 'timeout', 'timelimitmet']
  const statusesSelected = getCheckedValues(state.status)
  const status = statusesSelected.includes('incomplete')
    ? [...statusesSelected, ...statuses]
    : statusesSelected

  const body: any = {
    fromDate: formatDate(fromDate, state),
    toDate: formatDate(addDays(new Date(toDate), 1).toISOString(), state),
    reportType: state.reportType?.value,
    trainingIds: filterDropdownResults(state.trainings),
    empGroupIds:
      // @ts-ignore
      Array.from(selectedIds.values()).flat(1).length
        ? // @ts-ignore
          Array.from(selectedIds.values()).flat(1)
        : undefined,
    ...(state.employeeStatus.value && { isActive: state.employeeStatus.value }),
    dateKey: (state.dateKey.value !== 'selectOne' && state.dateKey.value) || '',
    ...(state.empIdsForReport.length && { empIds: state.empIdsForReport }),
    ...(state.empNameSelection.length && { names: state.empNameSelection }),
    ...(getCheckedValues(state.tags).length && { tags: getCheckedValues(state.tags) }),
    ...(status.length && { status }),
    businessUnits: getCheckedValues(state.businessUnits),
  }
  return body
}

const buildHeadersForTable = (headersFromData: string[]) =>
  headersFromData.map((header: string, index: number) => {
    return {
      value: header,
      display: header,
      sortable: true,
      order: index,
    }
  })

const mapHeadersToData = (headers: any, data: any) => {
  const actualData = data.slice(1, -1)
  const allData: any[] = []
  actualData.forEach((row: string[], n: number) => {
    const mappedData: Record<string, string> = {}
    row.forEach((column: string, index: number) => {
      mappedData[headers[index].value] = column
    })
    allData.push(mappedData)
  })
  return allData
}

const derivePropsForForm = (state: IReportingState, handlers: any) => {
  const config = deriveConfigFromReportType(state) // An Array of keys for mapping dynamic component rendering
  return { ...state, config, ...handlers }
}

const deriveConfigFromReportType = (state: IReportingState) => {
  // Remove me
  switch (state.reportType.value) {
    case 'RealTime':
      return []
    case 'ActivityReport':
    case 'TrainingSummary':
      return ['date', 'empId', 'name', 'training', 'tag', 'dbSelection', 'department']
    case 'TrainingDelivery':
      return [
        'employeeStatus',
        'date',
        'empId',
        'name',
        'training',
        'tag',
        'status',
        'dbSelection',
        'department',
      ]
    case 'TrainingStatus':
      return [
        'employeeStatus',
        'dateKey',
        'date',
        'empId',
        'name',
        'training',
        'tag',
        'status',
        'dbSelection',
        'department',
      ]
    case 'TrainingCompletion':
      return ['dateKey', 'date', 'training', 'tag', 'status']
    case 'UserEventAudit':
      return ['employeeStatus', 'time', 'date', 'empId', 'name', 'training', 'department']
    case 'FusionUserAudit':
      return ['time', 'date', 'empId', 'name', 'department']
    case 'MascotStatistics':
      return ['date', 'empId', 'name', 'training', 'department']
    case 'Daily/Monthly':
      return ['employeeStatus', 'opsHierarchy', 'dateHierarchy', 'date', 'department']
    default:
      return []
  }
}

const deriveReportToSaveFromReportType = (
  state: Partial<IReportingState> = reportingInitialState,
  selectedIds: any,
) => {
  const {
    empIdsForReport,
    dateSelectionDropdown = { value: '', display: '', id: '' },
    fromDate: stateFromDate = '',
    fromTime = '',
    toDate: stateToDate = '',
    toTime = '',
    reportType = { value: '', display: '', id: '' },
    dateKey = { value: 'startdate', display: '', id: '' },
    status = [],
    tags = [],
    trainings = [],
    businessUnits = [],
    mascot = [],
    site = [],
    empNameSelection,
    employeeStatus,
    opsHierarchy,
    dateHierarchy,
  } = state
  const objToReturn: any = {}
  const fromDate =
    stateFromDate && stateFromDate.length
      ? new Date(stateFromDate).toISOString()
      : '2019-09-01T00:00:00.000Z'
  const toDate =
    stateToDate && stateToDate.length
      ? addDays(new Date(stateToDate), 1).toISOString()
      : addDays(new Date(), 1).toISOString()
  objToReturn.dateConfig = {
    key: dateSelectionDropdown.value,
  }

  if (dateSelectionDropdown.value === 'custom') {
    objToReturn.dateConfig.start = state?.startDays
    objToReturn.dateConfig.end = state?.endDays
  }
  objToReturn.reportType = reportType.value

  switch (reportType.value) {
    case 'ActivityReport':
    case 'TrainingSummary':
      objToReturn.dateKey = dateKey.value
      objToReturn.names =
        empNameSelection && empNameSelection?.length > 0 ? empNameSelection : undefined
      objToReturn.trainingIds =
        getCheckedValues(trainings).length > 0 ? getCheckedValues(trainings) : undefined
      objToReturn.tags = getCheckedValues(tags).length > 0 ? getCheckedValues(tags) : undefined
      objToReturn.empGroupIds =
        Array.from(selectedIds.values()).flat(1).length > 0
          ? // @ts-ignore
            Array.from(selectedIds.values()).flat(1)
          : undefined
      return objToReturn
    case 'TrainingDelivery':
      objToReturn.dateKey = dateKey.value
      objToReturn.empIds =
        empIdsForReport && empIdsForReport.length > 0 ? empIdsForReport : undefined
      objToReturn.employeeStatus = employeeStatus
      objToReturn.names =
        empNameSelection && empNameSelection?.length > 0 ? empNameSelection : undefined
      objToReturn.trainingIds =
        getCheckedValues(trainings).length > 0 ? getCheckedValues(trainings) : undefined
      objToReturn.tags = getCheckedValues(tags).length > 0 ? getCheckedValues(tags) : undefined
      objToReturn.status =
        getCheckedValues(status).length > 0 ? getCheckedValues(status) : undefined
      objToReturn.empGroupIds =
        Array.from(selectedIds.values()).flat(1).length > 0
          ? // @ts-ignore
            Array.from(selectedIds.values()).flat(1)
          : undefined
      return objToReturn
    case 'TrainingStatus':
      objToReturn.empIds =
        empIdsForReport && empIdsForReport.length > 0 ? empIdsForReport : undefined
      objToReturn.employeeStatus = employeeStatus
      objToReturn.dateKey = dateKey.value
      objToReturn.names =
        empNameSelection && empNameSelection?.length > 0 ? empNameSelection : undefined
      objToReturn.trainingIds =
        getCheckedValues(trainings).length > 0 ? getCheckedValues(trainings) : undefined
      objToReturn.tags = getCheckedValues(tags).length > 0 ? getCheckedValues(tags) : undefined
      objToReturn.status =
        getCheckedValues(status).length > 0 ? getCheckedValues(status) : undefined
      objToReturn.empGroupIds =
        Array.from(selectedIds.values()).flat(1).length > 0
          ? // @ts-ignore
            Array.from(selectedIds.values()).flat(1)
          : undefined
      return objToReturn
    case 'TrainingCompletion':
      objToReturn.empIds =
        empIdsForReport && empIdsForReport.length > 0 ? empIdsForReport : undefined
      objToReturn.dateKey = dateKey.value
      objToReturn.names =
        empNameSelection && empNameSelection?.length > 0 ? empNameSelection : undefined
      objToReturn.trainingIds =
        getCheckedValues(trainings).length > 0 ? getCheckedValues(trainings) : undefined
      objToReturn.tags = getCheckedValues(tags).length > 0 ? getCheckedValues(tags) : undefined
      objToReturn.status =
        getCheckedValues(status).length > 0 ? getCheckedValues(status) : undefined
      objToReturn.empGroupIds = objToReturn.empGroupIds =
        Array.from(selectedIds.values()).flat(1).length > 0
          ? // @ts-ignore
            Array.from(selectedIds.values()).flat(1)
          : undefined
      return objToReturn
    case 'UserEventAudit':
      objToReturn.dateKey = dateKey.value
      objToReturn.fromTime = makeTimestamp(fromDate, fromTime, '')
      objToReturn.toTime = makeTimestamp(toDate, toTime, '')
      objToReturn.empIds =
        empIdsForReport && empIdsForReport.length > 0 ? empIdsForReport : undefined
      objToReturn.names =
        empNameSelection && empNameSelection?.length > 0 ? empNameSelection : undefined
      objToReturn.employeeStatus = employeeStatus
      objToReturn.trainingIds =
        getCheckedValues(trainings).length > 0 ? getCheckedValues(trainings) : undefined
      objToReturn.empGroupIds =
        Array.from(selectedIds.values()).flat(1).length > 0
          ? // @ts-ignore
            Array.from(selectedIds.values()).flat(1)
          : undefined
      return objToReturn
    case 'MascotStatistics':
      objToReturn.dateKey = dateKey.value
      objToReturn.fromDate = fromDate
      objToReturn.toDate = toDate
      objToReturn.empIds =
        empIdsForReport && empIdsForReport.length > 0 ? empIdsForReport : undefined
      objToReturn.names =
        empNameSelection && empNameSelection?.length > 0 ? empNameSelection : undefined
      objToReturn.trainingIds =
        getCheckedValues(trainings).length > 0 ? getCheckedValues(trainings) : undefined
      objToReturn.empGroupIds =
        Array.from(selectedIds.values()).flat(1).length > 0
          ? // @ts-ignore
            Array.from(selectedIds.values()).flat(1)
          : undefined
      return objToReturn
    case 'Daily/Monthly':
      objToReturn.dateHierarchy = dateHierarchy
      objToReturn.opsHierarchy = opsHierarchy
      objToReturn.dateKey = dateKey.value
      objToReturn.employeeStatus = employeeStatus
      objToReturn.empGroupIds =
        Array.from(selectedIds.values()).flat(1).length > 0
          ? // @ts-ignore
            Array.from(selectedIds.values()).flat(1)
          : undefined
      return objToReturn
    default:
      return []
  }
}

const mapTagsToDropdownOptions = (tags: string[]) =>
  tags.map((tag: string = 'Test') => ({
    value: tag,
    id: tag,
    display: tag,
    checked: false,
  }))

const deriveFiltersFromReportType = (state: IReportingState, handlers: any) => {
  return <ReportForm {...derivePropsForForm(state, handlers)} />
}

const parseData = ({ data }: any, state: IReportingState) => {
  const headersFromData = data[0]
  const mappedData = mapHeadersToData(buildHeadersForTable(headersFromData), data)
  return { headersFromData, data: mappedData }
}

const filterDataByStatus = (data: IMappedAssignments[]) =>
  data.filter(
    ({ training }: { training: IMappedTraining }) =>
      (training && training?.status === 'started') || training?.status === 'created',
  )

const addReportingStatusAsProp = (data: IMappedAssignments[]) =>
  data.map((data: IMappedAssignments) => {
    const datum: any = { ...data }
    datum.reportingStatus = data?.training?.status
    return datum
  })

const getBusinessUnitsFromData = (data: any[] = []): string[] => [
  ...new Set(data.map((row: any) => row['Business Unit'])),
]

const headers = [
  { value: 'businessUnit', id: 'businessUnit', display: 'Business Unit' },
  { value: 'dateHierarchy', id: 'dateHierarchy', display: '$dateHierarchy' },
  { value: 'opsHierarchy', id: 'opsHierarchy', display: '$opsHierarchy' },
  { value: 'agentCount', id: 'agentCount', display: 'Agent Count' },
  { value: 'completionPercent', id: 'completionPercent', display: 'Completion %' },
  { value: 'acceptedPercent', id: 'acceptedPercent', display: 'Accepted %' },
  { value: 'warningIgnoredPercent', id: 'warningIgnoredPercent', display: 'Warning Ignored %' },
]

const doRollup = (dataSet: any[], comparator: any) => {
  const doAggregation = (datum: any, mapKey: string) =>
    datum.map((row: any) => Number(row[mapKey])).reduce((a: any, b: any) => a + b)
  const allComplete = doAggregation(dataSet, 'Completed')
  const allAssigned = doAggregation(dataSet, 'Offered')
  const allEjected = doAggregation(dataSet, 'Ejected')
  const allAccepted = doAggregation(dataSet, 'Accepted')
  const allWarningIgnored = doAggregation(dataSet, 'Warning Ignored')

  const completionPercent = allComplete / (allAccepted - allEjected)
  const acceptedPercent = allAccepted / allAssigned
  const warningIgnoredPercent = allWarningIgnored / allAccepted

  return {
    completionPercent,
    acceptedPercent,
    warningIgnoredPercent,
  }
}

const groupData = (data: any[] = [], state: IReportingState) => {
  const dateChosen = state.dateHierarchy
  const opChosen = state.opsHierarchy

  const groupedHeaders = JSON.parse(
    JSON.stringify(headers)
      .replace('$dateHierarchy', dateChosen.display || 'Year')
      .replace('$opsHierarchy', state.opsHierarchy.display || 'Agent'),
  ) // replaces variable column display with selection chosen, data should output to reflect choice; however, key should map to header value

  const groupedData: any[] = []

  const uniqueBusinessUnitsFromData = getBusinessUnitsFromData(data) // ['Soluto', 'Retail', ...etc]

  for (const unit of uniqueBusinessUnitsFromData) {
    const dataForCurrentUnit = data.filter((row: any) => row['Business Unit'] === unit)
    const { completionPercent, acceptedPercent, warningIgnoredPercent } = doRollup(
      dataForCurrentUnit,
      unit,
    )
    groupedData.push({
      businessUnit: unit.length ? unit : 'Unknown',
      dateHierarchy: '',
      opsHierarchy: '',
      agentCount: '',
      completionPercent,
      acceptedPercent,
      warningIgnoredPercent,
    })

    const uniqueDatesForCurrentBusinessUnit = [
      ...new Set(dataForCurrentUnit.map((row: any) => row['Date'])),
    ]
    const sortedDates = uniqueDatesForCurrentBusinessUnit
      .map((date: any) => parseInt(date.replace(/[-]/g, '')))
      .sort((a: any, b: any) => a - b)
      .map((date) => {
        const dateToString = date + ''
        const year = dateToString.substr(0, 4)
        const month = dateToString.substr(4, 2)
        const day = dateToString.substr(6, 2)
        return year + '-' + month + '-' + day
      })

    const getDatesByDateHierarchySelected = (dates: string[], dateChosen: string) => {
      switch (dateChosen) {
        case 'month':
          return [...new Set(dates.map((date) => date.substr(0, 7)))]
        case 'year':
          return [...new Set(dates.map((date) => date.substr(0, 4)))]
        default:
          return dates
      }
    }

    const datesByDateHierarchySelected = getDatesByDateHierarchySelected(
      sortedDates,
      dateChosen.value,
    )

    for (const date of datesByDateHierarchySelected) {
      const dataForCurrentDate = dataForCurrentUnit.filter((row: any) => row['Date'].includes(date))
      const { completionPercent, acceptedPercent, warningIgnoredPercent } = doRollup(
        dataForCurrentDate,
        date,
      )

      groupedData.push({
        businessUnit: '',
        dateHierarchy: date,
        opsHierarchy: '',
        agentCount: '',
        completionPercent,
        acceptedPercent,
        warningIgnoredPercent,
      })

      if (opChosen.value === 'agent') {
        // ...build data for agents under current date
        for (const agent in dataForCurrentDate) {
          const getPositiveInteger = (value: string) => {
            if (value === '0.0%') return '0.0'
            if (isNaN(parseInt(value))) return '0.0'
            return String(parseInt(value.replace('%', '')) / 100)
          }
          groupedData.push({
            businessUnit: '',
            dateHierarchy: '',
            opsHierarchy: dataForCurrentDate[agent]['Employee Name'],
            agentCount: '',
            completionPercent: getPositiveInteger(dataForCurrentDate[agent]['Completed %']),
            acceptedPercent: getPositiveInteger(dataForCurrentDate[agent]['Accepted %']),
            warningIgnoredPercent: getPositiveInteger(
              dataForCurrentDate[agent]['Warning Ignored %'],
            ),
          })
        }
      } else {
        // ... aggregate higher ops data for current date
        const uniqueSupervisorsForCurrentBusinessUnit = [
          ...new Set(dataForCurrentDate.map((row: any) => row['Coach'])),
        ]
        const uniqueManagersForCurrentBusinessUnit = [
          ...new Set(dataForCurrentDate.map((row: any) => row['Manager'])),
        ]
        if (opChosen.value === 'manager') {
          for (const manager of uniqueManagersForCurrentBusinessUnit) {
            const dataForCurrentManager = dataForCurrentDate.filter(
              (row: any) => row['Manager'] === manager,
            )

            const { completionPercent, acceptedPercent, warningIgnoredPercent } = doRollup(
              dataForCurrentManager,
              manager,
            )

            const agentCount = [
              ...new Set(dataForCurrentManager.map((datum: any) => datum['Employee ID'])),
            ]
            groupedData.push({
              businessUnit: '',
              dateHierarchy: '',
              opsHierarchy: manager.length ? manager : 'Unknown',
              agentCount: agentCount.length,
              completionPercent,
              acceptedPercent,
              warningIgnoredPercent,
            })
          }
        }
        if (opChosen.value === 'coach') {
          for (const supervisor of uniqueSupervisorsForCurrentBusinessUnit) {
            const dataForCurrentSupervisor = dataForCurrentDate.filter(
              (row: any) => row['Coach'] === supervisor,
            )

            const agentCount = [
              ...new Set(dataForCurrentSupervisor.map((datum: any) => datum['Employee ID'])),
            ]

            const managerDatum = {
              businessUnit: '',
              dateHierarchy: '',
              opsHierarchy: supervisor.length ? supervisor : 'Unknown',
              agentCount: agentCount.length,
              completionPercent,
              acceptedPercent,
              warningIgnoredPercent,
            }

            groupedData.push(managerDatum)
          }
        }
      }
    }
  }
  return { groupedData, groupedHeaders, allData: data }
}

export {
  addReportingStatusAsProp,
  buildHeadersForTable,
  deriveConfigFromReportType,
  deriveBodyFromState,
  deriveFiltersFromReportType,
  deriveReportToSaveFromReportType,
  derivePropsForForm,
  determineValuesToUpdateForMultipleSelect,
  filterDataByStatus,
  groupData,
  handlePopulateStateFromSavedReport,
  mapHeadersToData,
  mapTagsToDropdownOptions,
  parseData,
}
