import { API } from 'aws-amplify'
import { getAllPagesFunction, awesomeCoolRecursiveScanFunction } from './assignments'
import { getAEWorkersCount } from './index'

export const getFusionExperts = async (expertType?: string) => {
  const type = expertType ? [expertType] : ['AVAILABLE', 'MESSAGING']
  try {
    const params = {
      queryStringParameters: {
        table: 'FusionTasks',
        action: 'scan',
      },
      body: {
        config: {
          status: type,
          page: 0,
          pageSize: 5000,
          method: `getAllAEWorkers`,
        },
      },
    }
    const {
      response: [{ count }],
    } = await getAEWorkersCount()
    return await getAllPagesFunction({ params, count })
  } catch (e) {
    console.error(e)
    return []
  }
}

export const getFusionExpertsByEmpGroupId = async (
  empGroupIds: string[][],
  expertType?: string,
) => {
  const type = expertType ? [expertType] : ['AVAILABLE', 'MESSAGING']
  try {
    const params = {
      queryStringParameters: {
        table: 'FusionTasks',
        action: 'scan',
      },
      body: {
        config: {
          empGroupIds,
          empGroupsCount: empGroupIds.length,
          status: type,
          page: 0,
          pageSize: 5000,
          method: `getAllAEWorkersForSelectedFilter`,
        },
      },
    }
    const {
      response: [{ count }],
    } = await getAEWorkersCount('empGroupIds', empGroupIds.flat())
    return await getAllPagesFunction({ params, count })
  } catch (e) {
    console.error('Error in getFusionExpertsByEmpGroupId', e)
    return []
  }
}

export const postFusionExperts = async (channel: string, workerSids: string[]) => {
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const params = {
      queryStringParameters: {
        table: 'FusionTasks',
      },
      body: {
        channel,
        workerSids,
      },
    }
    return await API.post(apiName, apiPath, params)
  } catch (e) {
    console.error(e)
    return []
  }
}
export const postFusionExpertsNumber = async (
  noOfExperts: string,
  taskQueues: any[],
  channel: string,
) => {
  try {
    const taskQueueSids = taskQueues.map((each) => each.taskqueueSid)
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const params = {
      queryStringParameters: {
        table: 'FusionTasks',
      },
      body: {
        channel,
        taskQueueSids,
        noOfExperts: Number(noOfExperts),
      },
    }
    return await API.post(apiName, apiPath, params)
  } catch (e) {
    console.error(e)
    return []
  }
}

export const getTaskQueueSids = async () => {
  interface IParams {
    queryStringParameters: {
      table: string
      action: string
    }
    body: {
      limit: number
      queryName: string
      token?: string
    }
  }
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const taskQueueArray = []
    let token

    const params: IParams = {
      queryStringParameters: {
        table: `TaskQueueDetails`,
        action: `graphQLQuery`,
      },
      body: {
        limit: 700,
        queryName: `allTaskQueues`,
      },
    }
    do {
      const result = await API.post(apiName, apiPath, params)
      token = result.nextToken
      params.body.token = result.nextToken
      taskQueueArray.push(...result.items)
    } while (token)
    return taskQueueArray
  } catch (e) {
    console.error(e)
    return []
  }
}
