import React from 'react'
import '@asurion/atomic-ui-library'
import '@asurion/sass-design-system/build/asurion/asurion.css'
import '@rmwc/data-table/styles'
import { ThemeProvider } from 'styled-components'

import SideNav from '../Organisms/SideNav/SideNav'
import {
  AdminConfigProvider,
  GroupsProvider,
  RouteProvider,
  AssignTrainingProvider,
  UIProvider,
  ReportingDistributionProvider,
  FusionProvider,
} from '../../../context/'
import Renderer from './Renderer'
import Compose from './compose'
import { useSelector } from 'react-redux'
import '../../../ttd.scss'
import 'react-toastify/dist/ReactToastify.css'
import themes from '../../../themes/schema'

const TTD = () => {
  const {
    userSettings: { darkMode },
  } = useSelector((state: any) => state?.user)
  const isItDarkMode = (darkMode: boolean) => (darkMode ? 'darkMode' : 'originalFlavor')
  const selectedTheme = isItDarkMode(darkMode)
  const currentTheme = themes[selectedTheme]

  return (
    <div className={`ttd-container ${isItDarkMode(darkMode)}`}>
      <Compose
        components={[
          RouteProvider,
          UIProvider,
          GroupsProvider,
          AssignTrainingProvider,
          AdminConfigProvider,
          ReportingDistributionProvider,
          FusionProvider,
        ]}
      >
        <ThemeProvider theme={currentTheme}>
          <SideNav />
          <div
            style={{
              height: '100%',
              width: '100%',
              overflow: 'auto',
              padding: '26px 16px',
            }}
          >
            <Renderer />
          </div>
        </ThemeProvider>
      </Compose>
    </div>
  )
}

export default TTD
