import React, { useCallback, useState } from 'react'
import { IconComponent, Flex, Tooltip } from '../../'
import { useSelector } from 'react-redux'
import themes from '../../../../themes/schema'

interface IAccordion {
  children: any
  label: string
  count?: any
  tooltip?: any
}

const Accordion = ({ children, label, count, tooltip }: IAccordion) => {
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const handleClick = useCallback(() => {
    setShowDialog(!showDialog)
  }, [showDialog])
  const {
    userSettings: { darkMode },
  } = useSelector((state: any) => state?.user)
  const customDisplayType = darkMode ? 'darkMode' : 'originalFlavor'
  const getStyle: Record<string, any> = {
    accordion_darkMode: themes.darkMode.accordion.color,
    accordion_originalFlavor: themes.originalFlavor.accordion.color,
  }

  return (
    <React.Fragment>
      <Flex config={['alignCenter']} style={{ height: '60px' }}>
        <button
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.1rem',
            color: getStyle[`accordion_${customDisplayType}`],
          }}
          className={'ttd-bare-button'}
          onClick={handleClick}
        >
          {label}
          {tooltip && (
            <Tooltip selector={tooltip}>
              <IconComponent className={'ttd-tooltip'} icon={'info'} />
            </Tooltip>
          )}
          {count ? `(${count})` : ''}
          <IconComponent icon={!showDialog ? 'keyboard_arrow_down' : 'keyboard_arrow_up'} />
        </button>
      </Flex>
      {showDialog && children}
    </React.Fragment>
  )
}

export default Accordion
