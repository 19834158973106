// THESE ARE ONLY DATATYPES, MEANING A STRICTLY TYPED CONTAINER FOR PROPERTIES.
// DO NOT ADD ANY FUNCTIONS HERE

export const DEFAULT_FUNCTION = () => {}
export const EXPERT_DATA = {
  id: 1234567,
  name: 'Firstname Lastname',
  skill: 'English, Spanish, Mobility, HZNATT',
  status: 'Online',
}

/**
 * @param {string} title
 * @param {string} content
 */

export class GenericText {
  constructor(text = '') {
    this.text = text
  }
}

export class IconName {
  constructor(icon = '') {
    this.icon = icon
  }
}

export class Metric {
  constructor(value = 0, label = '') {
    this.value = value
    this.label = label
  }
}

export class Core {
  constructor(payOutputs = []) {
    this.payOutputs = payOutputs
  }
}

export class Checkbox {
  constructor(name = '', id = '', isChecked = false, toggleCheckbox = DEFAULT_FUNCTION) {
    this.name = name
    this.isChecked = isChecked
    this.id = id
    this.toggleCheckbox = toggleCheckbox
  }
}

export class TableRow {
  constructor(
    id = 1234567,
    expertData = EXPERT_DATA,
    rowStatus = false,
    isChecked = false,
    toggleCheckbox = DEFAULT_FUNCTION,
  ) {
    this.expertData = expertData
    this.isChecked = isChecked
    this.id = id
    this.toggleCheckbox = toggleCheckbox
    this.rowStatus = rowStatus
  }
}

export class ReduxAction {
  constructor(type = '', payload = {}) {
    this.type = type
    this.payload = payload
  }
}

export class ActionCreator {
  constructor(type = '', payload = '') {
    this.type = type
    this.payload = payload
  }
}

export class CheckedStatusObservable {
  constructor(emitCheckedStatus = DEFAULT_FUNCTION, getCheckedStatus = DEFAULT_FUNCTION) {
    this.emitCheckedStatus = emitCheckedStatus
    this.getCheckedStatus = getCheckedStatus
  }
}

export class User {
  constructor(
    given_name = '',
    family_name = '',
    email = '',
    userId = '',
    empId = '',
    userRoleName = '',
    userRoleDefinition = {},
  ) {
    this.givenName = given_name
    this.familyName = family_name
    this.email = email
    this.userId = userId
    this.empId = empId
    this.userRoleName = userRoleName
    this.userRoleDefinition = userRoleDefinition
  }
}

export class LocalStorageItem {
  constructor(value = false, expiry = 0, ttl = 0) {
    this.value = value
    this.expiry = expiry
    this.ttl = ttl
  }
}

export class HelixRouteData {
  constructor(renderFunc = () => {}, rest = {}) {
    this.renderFunc = renderFunc
    this.rest = rest
  }
}

export class GroupQueueData {
  constructor({
    agentAdminDetails = { priority: 0, timeout: 0 },
    taskqueueSid = '',
    taskqueueTitle = '',
    taskqueueSubTitle = '',
  } = {}) {
    this.agentAdminDetails = agentAdminDetails
    this.taskqueueSid = taskqueueSid
    this.taskqueueSubTitle = taskqueueSubTitle
    this.taskqueueTitle = taskqueueTitle
  }
}

export class GroupWorkForceDetail {
  constructor({
    taskqueueSid,
    queueDetails = {},
    serviceLevelDetails = {},
    ExpertDetails = {},
  } = {}) {
    this.taskqueueSid = taskqueueSid
    this.ExpertDetails = ExpertDetails
    this.serviceLevelDetails = serviceLevelDetails
    this.queueDetails = queueDetails
  }
}

export class GroupWorkForceEventStat {
  constructor({
    EventStats = {
      ciCRT: 0,
      CRT: 0,
    },
  } = {}) {
    this.EventStats = EventStats
  }
}

class GroupSummaryStat {
  constructor({
    ciCRT = null,
    cicallsInQueue = 0,
    callsInQueue = 0,
    expertAvailable = 0,
    CRT = 0,
    monthlySLA = 0,
    ASA = 0,
    ciexpertAvailable = 0,
    longestWait = 0,
    ciASA = null,
    workflowSid = 0.0,
    cilongestWait = 0,
    ciSLA = 0,
    SLA = 100,
    abandonRate = 0.0,
  } = {}) {
    this.ciCRT = ciCRT
    this.cicallsInQueue = cicallsInQueue
    this.callsInQueue = callsInQueue
    this.expertAvailable = expertAvailable
    this.CRT = CRT
    this.monthlySLA = monthlySLA
    this.ASA = ASA
    this.ciexpertAvailable = ciexpertAvailable
    this.longestWait = longestWait
    this.ciASA = ciASA
    this.workflowSid = workflowSid
    this.workflowSid = workflowSid
    this.cilongestWait = cilongestWait
    this.ciSLA = ciSLA
    this.SLA = SLA
    this.abandonRate = abandonRate
  }
}

export class GroupWorkForceGlobalStat {
  constructor({
    summary_stats = new GroupSummaryStat(),
    summary_routing = {
      routing: {
        Soluto: {},
        language: [],
      },
    },
    skill_names = [],
  } = {}) {
    this.summary_stats = summary_stats
    this.summary_routing = summary_routing
    this.skill_names = skill_names
  }
}
