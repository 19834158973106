import { optionConfig } from './index'
import { IDropdownOption, IFilterDropdownOption } from '../components/common/Atoms/Dropdown'
import { IHeaders } from '../interfaces'

const getFilters = (data: any, headers: IHeaders[], prevFilters: { [index: string]: any } = {}) => {
  const filters: Record<any, any> = {}

  for (const header in headers) {
    if (headers[header].value !== 'checkbox') {
      filters[headers[header].value] = []
    }
  }

  data &&
    data.length > 0 &&
    data.forEach((row: any) => {
      for (const prop in row) {
        const prevPropFilter = prevFilters[prop] ?? []
        switch (prop) {
          case 'isActive':
            if (filters[prop]) {
              filters[prop].push(
                ...optionConfig[prop].map((option: any) => {
                  return {
                    ...option,
                    checked:
                      prevPropFilter.find((prevOption: any) => prevOption.value === option.value)
                        ?.checked ?? true,
                  }
                }),
              )
            }
            break
          case 'priority':
            if (filters[prop]) {
              filters[prop].push(
                ...optionConfig[prop].map((option: any) => {
                  return {
                    ...option,
                    checked:
                      prevPropFilter.find((prevOption: any) => prevOption.value === option.value)
                        ?.checked ?? true,
                  }
                }),
              )
            }
            break
          default:
            filters[prop]?.push({
              id: prop,
              value: row[prop],
              display: row[prop],
              checked:
                prevPropFilter.find((prevRow: any) => prevRow.value === row[prop])?.checked ?? true,
            })
            break
        }
      }
    })

  for (const filterKey in filters) {
    filters[filterKey] = filters[filterKey].filter(
      (v: IDropdownOption, i: number, arr: IDropdownOption[]) =>
        arr.findIndex((t) => t.value === v.value) === i,
    )
  }

  for (const filterKey in filters) {
    filters[filterKey] = filters[filterKey].sort(
      (a: IFilterDropdownOption, b: IFilterDropdownOption) => {
        if (a.value > b.value) {
          return 1
        }
        if (a.value < b.value) {
          return -1
        }
        return 0
      },
    )
  }

  return filters
}

export { getFilters }
