import React, { useContext, useEffect, useState } from 'react'
import { Button, Flex, IconComponent, Loading, Modal, Tab } from '../common'
import styled from 'styled-components'
import {
  getEjectRules,
  postEjectRule,
  getDispatchRules,
  postDispatchRule,
  postTrainingRule,
  getTrainingRules,
} from '../../actions'
import { dispatchRuleHeaders, ejectRuleHeaders, trainingRulesHeaders } from '../../constants'
import FilterableTable from '../common/Organisms/FilterableTable'
import { RouteContext } from '../../context/RouteContext'
import AddTrainingRule from './TrainingRules/AddTrainingRule'
import { AssignTrainingContext } from '../../context/AssignTrainingContext'
import { checkPermissions } from '../../utils'

const Rules = () => {
  const { resetAllChecks } = useContext(AssignTrainingContext)
  const { setSelectedLink, setSelectedLinkProps } = useContext(RouteContext)
  const [selectedTab, setSelectedTab] = useState<string>('trainingRules')

  const [loading, setLoading] = useState<boolean>(false)

  const [ejectRules, setEjectRules] = useState<any[]>([])
  const [dispatchRules, setDispatchRules] = useState<any[]>([])

  const [addNewEjectRule, setAddNewEjectRule] = useState<boolean>(false)
  const [addNewDispatchRule, setAddNewDispatchRule] = useState<boolean>(false)
  const [addNewTrainingRule, setAddNewTrainingRule] = useState<boolean>(false)

  const [trainingRules, setTrainingRules] = useState<any[]>([])
  const [headers, setHeaders] = useState<any[]>([])
  const [data, setData] = useState<any[]>([])

  const [editDispatchRule, setEditDispatchRule] = useState<any>({})
  const [editEjectRule, setEditEjectRule] = useState<any>({})

  useEffect(() => {
    ;(async () => {
      await getTrainingRules(setTrainingRules, setLoading)
      return
    })()
  }, [])

  useEffect(() => {
    const getHeadersForTab = (tab: string) => {
      const headersFor: Record<any, any> = {
        dispatch: dispatchRuleHeaders,
        eject: ejectRuleHeaders,
        trainingRules: trainingRulesHeaders,
      }

      return headersFor[tab]
    }

    const getDataForTab = (tab: string) => {
      const dataFor: Record<any, any> = {
        dispatch: dispatchRules,
        eject: ejectRules,
        trainingRules,
      }

      return dataFor[tab]
    }

    setHeaders(getHeadersForTab(selectedTab))
    setData(getDataForTab(selectedTab))
    return
  }, [selectedTab, dispatchRules, ejectRules, trainingRules])

  const handleAddNewRule = (tab: string) => {
    const setStateFor: Record<string, any> = {
      dispatch: setAddNewDispatchRule,
      eject: setAddNewEjectRule,
      trainingRules: setAddNewTrainingRule,
    }

    return setStateFor[tab](true)
  }

  const onUpdate = async (thing: any) => {
    await getTrainingRules(setTrainingRules, setLoading)
    return
  }

  const handlePostTrainingRule = async (rule: any, method: string) => {
    return await postTrainingRule(rule).then(async () => {
      return await getTrainingRules(setTrainingRules, setLoading).then(() => {
        resetAllChecks(true)
        setAddNewTrainingRule(false)
      })
    })
  }

  const editRulePermissions = checkPermissions('rules', 'edit')

  return loading ? (
    <Loading size={'md'} />
  ) : (
    <div>
      <Flex config={['alignCenter']} style={{ marginBottom: 25 }}>
        <Tab
          onTabSelect={() => {}}
          key={'TrainingRules'}
          display={'Training Rules'}
          value={'trainingRules'}
          isSelected={true}
        />
        {editRulePermissions && (
          <Button
            displayType="outline"
            size={'sm'}
            handleClick={() => {
              handleAddNewRule(selectedTab)
              setSelectedLinkProps('')
              setEditDispatchRule({})
              setEditEjectRule({})
            }}
            style={{ marginTop: 2.5, marginLeft: 15 }}
          >
            Add new {selectedTab === 'trainingRules' ? 'training' : selectedTab} rule
            <IconComponent style={{ marginLeft: 5 }} icon={'add_circle_outline'} />
          </Button>
        )}
      </Flex>

      <div style={{ height: '100%' }}>
        <FilterableTable
          width={'100%'}
          headers={headers}
          data={data}
          ruleType={selectedTab}
          onUpdate={(link: string) => onUpdate(link)}
          handleRowClick={(row: any) => {
            if (row.dispatchRuleId) {
              setAddNewDispatchRule(true)
              setEditDispatchRule(row)
            } else if (row.ejectRuleId) {
              setAddNewEjectRule(true)
              setEditEjectRule(row)
            } else {
              setAddNewTrainingRule(true)
            }

            const allEmpGroupIdsNotBelongingToThisRule =
              trainingRules
                .filter((rule) => rule.groupId !== row.groupId)
                .map((rule) => rule.empGroupIds)
                .flat(1) || []
            setSelectedLinkProps({ ...row, allEmpGroupIdsNotBelongingToThisRule })
          }}
          postEjectRule={(rule: any) => {
            postEjectRule(rule).then(() => getEjectRules(setEjectRules, setLoading))
          }}
          postDispatchRule={(rule: any) => {
            postDispatchRule(rule).then(() => getDispatchRules(setDispatchRules, setLoading))
          }}
        />
      </div>
      {selectedTab === 'trainingRules' && addNewTrainingRule && (
        <Modal width={'1150px'} close={() => setAddNewTrainingRule(false)}>
          <AddTrainingRule
            existingDispatchRules={dispatchRules}
            cancelAddTrainingRule={() => {
              resetAllChecks(true)
              setSelectedLinkProps()
              setAddNewTrainingRule(false)
              getTrainingRules(setTrainingRules, setLoading).then(() => {
                resetAllChecks(true)
              })
            }}
            postTrainingRule={handlePostTrainingRule}
          />
        </Modal>
      )}
    </div>
  )
}

const StyledTab = styled.div`
  width: max-content;
  padding: 15px;
  font-weight: 700;
  font-size: 24px;
  color: '5px solid #000';
  cursor: pointer;
`

export default Rules
