import React from 'react'
import { Dropdown, Flex, FormLabel } from '../../index'
import { IDropdownOption } from '../../Atoms/Dropdown'

const Tags = ({ selectedTags = [], setSelectedTags, formattedTags, deleteTag }: any) => {
  return (
    <FormLabel id="tags" value="Tags">
      <Flex style={{ width: '100%' }}>
        <Flex
          config={['wrap']}
          style={{
            width: 500,
          }}
        >
          <Dropdown
            type="rolled"
            style={{ width: 175 }}
            multipleSelect={true}
            options={formattedTags}
            selected={{
              value: 'Add Tags',
              display: 'Add Tags',
              id: 'none',
            }}
            emitDropdownOption={(tag = [{}]) => {
              let uniqueIds = [...new Set(selectedTags.map((tag: IDropdownOption) => tag.id))]
              if (uniqueIds.includes(tag.id)) {
                return
              } else {
                let sortedTags = [...selectedTags, tag].sort(({ value: a }, { value: b }) =>
                  a > b ? 1 : -1,
                )
                setSelectedTags(sortedTags)
              }
            }}
          />
          <div
            id={'test'}
            style={
              selectedTags && selectedTags.length > 0
                ? {
                    display: 'flex',
                    justifyContent: 'space-around',
                    width: '50%',
                    flexWrap: 'wrap',
                    border: '1px solid gray',
                    boxSizing: 'border-box',
                    padding: '0.5rem',
                    maxHeight: '12rem',
                    margin: '0 0.5rem',
                    borderRadius: '4px',
                  }
                : { display: 'none' }
            }
          >
            {selectedTags &&
              selectedTags.map(({ value, id }: any, i: any) => {
                if (value === '') return
                return (
                  <div
                    key={id}
                    style={{
                      border: '1px solid gray',
                      height: '2rem',
                      display: 'flex',
                      padding: '5px 8px',
                      borderRadius: '14px',
                      background: '#777',
                      color: 'whitesmoke',
                      margin: '2px',
                    }}
                  >
                    <p
                      style={{
                        alignSelf: 'center',
                      }}
                      key={id}
                    >
                      {value}
                    </p>
                    <span
                      style={{
                        alignSelf: 'center',
                        margin: '0 0 0 0.5rem',
                      }}
                      onClick={() => {
                        deleteTag(id)
                      }}
                    >
                      x
                    </span>
                  </div>
                )
              })}
          </div>
        </Flex>
      </Flex>
    </FormLabel>
  )
}
export default Tags
