import React, { SyntheticEvent } from 'react'
import {
  add,
  sub,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  subMonths,
  subWeeks,
  subQuarters,
  startOfQuarter,
  startOfDay,
  endOfQuarter,
} from 'date-fns'
import { IAutoAssignment, IMappedTraining } from '../interfaces'
import { validate, validationRules } from './validate'
import { keys } from '@material-ui/core/styles/createBreakpoints'
import { useSelector } from 'react-redux'

export const sort = (arr: any[], key: string, direction: string) => {
  if (!arr || !arr.length) return []
  const directionAsBool = direction === 'asc'
  return [
    ...arr.sort((a, b) => {
      if (!Boolean(a[key])) {
        // deals with undefined and null values
        return 1
      }
      if (!Boolean(b[key])) {
        // deals with undefined and null values
        return -1
      }
      const aKey = !isNaN(a[key]) ? +a[key] : a[key].trim() ? a[key].toLowerCase().trim() : ''
      const bKey = !isNaN(b[key]) ? +b[key] : b[key].trim() ? b[key].toLowerCase().trim() : ''
      if (aKey > bKey) {
        return directionAsBool ? 1 : -1
      } else if (aKey < bKey) {
        return directionAsBool ? -1 : 1
      } else {
        return 0
      }
    }),
  ]
}

export const parseQueryString = (queryString: string): any => {
  try {
    const rawStringParams = queryString.replace(/^[?]/, '')
    const splitParams = rawStringParams.split('&')
    const params: Record<string, any> = {}

    for (const param in splitParams) {
      const key = splitParams[param].substr(0, splitParams[param].indexOf('='))
      const value = splitParams[param].substr(splitParams[param].indexOf('=') + 1)
      params[key] = decodeURI(value)
    }

    return params
  } catch (ex) {
    console.error(ex)
    return {}
  }
}

export const checkInput = async (
  inputName: string,
  value: any,
  trigger: any,
  clearErrors: any,
  setFormValid?: any,
) => {
  const foundRule = validationRules[inputName]
  const isItValid: boolean = validate[foundRule](value)
  if (!isItValid) {
    setFormValid(isItValid)
    return await trigger(inputName)
  } else {
    clearErrors(inputName)
    setFormValid(isItValid)
    return isItValid
  }
}

export const handleInput = (e: SyntheticEvent<HTMLInputElement>, cb: any) => {
  const val = e?.currentTarget?.value
  return cb(val)
}

export const searchList = (list: any[], key: string, text: string) => {
  return list.filter((data) => data[key] && data[key].toLowerCase().includes(text.toLowerCase()))
}

export const addToList = (object: any, list: any[], cb: any) => {
  return cb([...list, object])
}

export const removeFromList = (list: any[], key: string, value: string, cb: any) => {
  const newList = list.filter((data) => data[key] !== value)
  return cb(newList)
}

export const filterList = (list: any[], key: string, value: string) => {
  return list.length > 0 && list.filter((data) => data[key] === value)
}

export const nestedMapSize = (map: Map<any, any> | any[]): number => {
  return (Array.isArray(map) ? map : Array.from(map.values())).reduce((acc: number, value: any) => {
    // switch not working use if else for now
    if (Array.isArray(value) || value instanceof Map) {
      return nestedMapSize(value) + acc
    } else {
      return acc + 1
    }
  }, 0)
}

export const getBadgeStyle = (status: string) => {
  const style: Record<string, any> = {
    draft: {
      background: '#000000',
      color: 'white',
    },
    active: {
      background: '#37E7A7',
    },
    inactive: {
      background: '#E6E6EB',
      border: '1px solid #A5AAAF !important',
      boxSizing: 'border-box',
      borderRadius: '4px !important',
    },
    badge: {
      fontWeight: 'bolder',
      maxWidth: 'max-content',
      padding: '4px',
      margin: '10px 0px',
      boxSizing: 'border-box',
      borderRadius: '4px',
      fontFamily: 'sans-serif',
      fontSize: '14px',
      alignItems: 'center',
    },
  }

  return { ...style[status], ...style.badge }
}

export function isMouseEvent<T>(e: any | React.MouseEvent<T>): e is React.MouseEvent<T> {
  let eMouse = e as React.MouseEvent<T>
  // Can test for other properties as well
  return eMouse && typeof eMouse.pageX === 'number' && typeof eMouse.pageY === 'number'
}

export const evaluateType = (actionTypes: Record<string, boolean>): string => {
  let actionType = 'none'
  for (const prop in actionTypes) {
    if (actionTypes[prop]) {
      actionType = prop
    }
  }
  return actionType
}

export const callNull = () => null

const addADayToToday = (now: Date) => {
  return startOfDay(add(now, { hours: 24 }))
}

const getToday = () => {
  const now = Date.now()
  const today = startOfDay(now)
  const tomorrow = addADayToToday(today)
  return { end: tomorrow.toISOString(), start: today.toISOString() }
}

const getYesterday = () => {
  const now = new Date()
  const today = startOfDay(now)
  const yesterday = startOfDay(sub(now, { hours: 24 }))
  return { start: yesterday.toISOString(), end: today.toISOString() }
}

const getWeekToDate = () => {
  const now = Date.now()
  const today = startOfDay(now)
  const tomorrow = addADayToToday(today)
  const weekStart = startOfWeek(Date.now())
  return { start: weekStart.toISOString(), end: tomorrow.toISOString() }
}
const getMonthToDate = () => {
  const now = Date.now()
  const today = startOfDay(now)
  const tomorrow = addADayToToday(today)
  const monthStart = startOfMonth(now)
  return { start: monthStart.toISOString(), end: tomorrow.toISOString() }
}
const getLastFullWeek = () => {
  const now = Date.now()
  const lastWeek = subWeeks(now, 1)
  const weekStart = startOfWeek(lastWeek)
  const weekEnd = addADayToToday(endOfWeek(weekStart))
  return { start: weekStart.toISOString(), end: weekEnd.toISOString() }
}
const getLastFullMonth = () => {
  const now = Date.now()
  const lastMonth = subMonths(now, 1)
  const monthStart = startOfMonth(lastMonth)
  const monthEnd = addADayToToday(endOfMonth(monthStart))
  return { start: monthStart.toISOString(), end: monthEnd.toISOString() }
}
const getQuarterToDate = () => {
  const now = Date.now()
  const today = startOfDay(now)
  const tomorrow = addADayToToday(today)
  const quarterStart = startOfQuarter(now)
  return { start: quarterStart.toISOString(), end: tomorrow.toISOString() }
}
const getLastFullQuarter = () => {
  const now = Date.now()
  const lastQuarter = subQuarters(now, 1)
  const quarterStart = startOfQuarter(lastQuarter)
  const quarterEnd = addADayToToday(endOfQuarter(quarterStart))
  return { start: quarterStart.toISOString(), end: quarterEnd.toISOString() }
}
export const getDateRanges = (): Record<string, { start: string; end: string }> => {
  const dateObj: Record<string, { start: string; end: string }> = {
    yesterday: getYesterday(),
    today: getToday(),
    weekToDate: getWeekToDate(),
    lastFullWeek: getLastFullWeek(),
    monthToDate: getMonthToDate(),
    lastFullMonth: getLastFullMonth(),
    quarterToDate: getQuarterToDate(),
    lastFullQuarter: getLastFullQuarter(),
  }

  return dateObj
}

export const shortDateToUTC = (date: string) => {
  const minuteToMs = 60000
  const localDate = new Date(date)
  const offset = localDate.getTimezoneOffset() * minuteToMs
  return new Date(localDate.valueOf() + offset).toISOString()
}

export const mapTrainingToBundle = (bund: any) => {
  return (trainings: any) => {
    if (bund && bund?.trainingIds?.length === 0) return bund
    const trainingsForThisBundle = trainings.filter((item: any) =>
      bund?.trainingIds?.some((id: string) => item?.trainingId === id),
    )
    const orderedTrainings: any[] = []
    bund?.trainingIds?.forEach((id: any) =>
      orderedTrainings.push(
        trainingsForThisBundle.find((training: any) => training?.trainingId === id),
      ),
    )
    bund.trainings = orderedTrainings
    return bund
  }
}

export const mapTrainingToAutoAssignments = (autoAssignment: IAutoAssignment) => {
  return (trainings: any) => {
    if (
      (autoAssignment && autoAssignment?.trainingIds?.length === 0) ||
      !autoAssignment.trainingIds
    ) {
      autoAssignment.trainingNames = ''
      return autoAssignment
    } else {
      const trainingsForThisAutoAssignment =
        (trainings &&
          trainings.length > 0 &&
          trainings.filter((item: any) =>
            autoAssignment?.trainingIds?.some((id: string) => item.trainingId === id),
          )) ||
        []
      const orderedTrainings: any[] = []
      autoAssignment.trainingIds &&
        autoAssignment.trainingIds.length > 0 &&
        autoAssignment.trainingIds?.forEach((id: any) =>
          orderedTrainings.push(
            trainingsForThisAutoAssignment.find((training: any) => training.trainingId === id),
          ),
        )
      autoAssignment.trainings = orderedTrainings
      autoAssignment.trainingCount = orderedTrainings.length
      autoAssignment.trainingNames = orderedTrainings
        .map((training: IMappedTraining) => training?.trainingName || '')
        .join(', ')

      return autoAssignment
    }
  }
}

export const spliceAndReturn = (itemsToGet: any[], arrayToSearch: any[], lookupKey?: string) => {
  const tempArray = [...arrayToSearch]
  let notFoundCount = 0
  const splicedElements: any[] = []
  itemsToGet.forEach((item) => {
    const index = tempArray.findIndex((el) => {
      return lookupKey ? el[lookupKey] === item : el === item
    })
    if (index !== -1) {
      splicedElements.push(tempArray.splice(index, 1)[0])
    } else {
      notFoundCount += 1
    }
  })
  return { splicedElements, splicedArray: tempArray, notFoundCount }
}

export const playSound = function ({
  tone,
  ramp,
  duration,
}: {
  ramp: number
  tone: number
  duration: number
}) {
  let context = new AudioContext()
  let oscillator = context.createOscillator()
  oscillator.frequency.value = tone
  let gain = context.createGain()
  oscillator.connect(gain)
  gain.connect(context.destination)
  gain.gain.exponentialRampToValueAtTime(0.00001, context.currentTime + ramp)
  oscillator.start(0)
  oscillator.stop(context.currentTime + duration)
  return true
}

export function* processAspectData(findThis: string, data: any): any {
  if (!data) {
    return
  }
  for (let i = 0; i < data.length; i++) {
    if (data[i].groupId === findThis) {
      yield data[i]
    } else if (data[i].children) {
      yield* processAspectData(findThis, data[i].children)
    }
  }
}

export const formatCamelCase = (word: string) => {
  let capRe = /[A-Z]/
  if (typeof word !== 'string') {
    throw new Error('The "word" parameter must be a string.')
  }
  const output = []
  for (let i = 0; i < word.length; i += 1) {
    if (i === 0) {
      output.push(word[i].toUpperCase())
    } else {
      if (capRe.test(word[i])) {
        output.push(' ')
      }
      output.push(word[i])
    }
  }
  return output.join('')
}

export const reduceToTables = (acc: any, curr: any) => {
  const tableName = curr.tableName
  if (!acc[tableName]) {
    acc[tableName] = [curr]
    return acc
  }

  const alreadyInTable = acc[tableName].some((group: any) => {
    return group.groupId === curr.groupId
  })

  if (!alreadyInTable) {
    acc[tableName].push(curr)
  } else {
    acc[tableName].filter((each: any) => each.groupId === curr.groupId)
  }
  return acc
}

// keyBlock[0] = user's general access ['dev.redis', 'reporting', 'trainings.autoassignments'] etc
// keyBlock[1] = user's ability as defined in the DB '*', 'read' etc
// refactor as an object in future for code clarity
const buildKeyBlock = (route: string[], ability: string) => {
  if (!route) return
  if (route.join() === '*') return [['*'], ability]
  return route.reduce(
    (acc: any[], curr: any) => {
      curr.split('.').forEach((key: string) => {
        if (!acc[0].includes(key)) acc[0].push(key)
      })
      if (ability && !acc[1].length) acc[1] = ability
      return acc
    },
    [[], ''],
  )
}

// The path parameter should be the most specific permission.
// e.g. checkPermissions('reportingdistribution', 'read')
// NOT checkPermissions('reporting.reportingdistribution', 'read')
/**
 * @param {string} path - user is asking for permissions to this path
 * @param {string?} requiredAction - *, read, edit, etc
 * @return {Boolean}
 * **/
export const checkPermissions = (path: string, requiredAction?: string) => {
  const formatPath = path.toLowerCase()
  // const formatPath = 'trainings'
  const { user } = useSelector((state: any) => state?.user)
  const {
    userRoleDefinition: { route, ability },
  } = user
  const keyBlock = buildKeyBlock(route, ability) || []
  if (!keyBlock.length) return false
  const keyBlockAbility = keyBlock[1]
  const checkAction = (ability: string, requiredAction?: string) => {
    // If we add more than the two conditions (* and read), then we'll need a switch/case
    const allowedAbilities = ['*']
    // explicit wildcard on component / in the allowed list
    if (!requiredAction || requiredAction === '*' || allowedAbilities.includes(ability)) return true
    // return false unless identical
    return keyBlockAbility === requiredAction
  }
  // Check general access (or wildcard), then check ability, otherwise false
  const finalCheck =
    keyBlock[0].includes(formatPath) || keyBlock[0].includes('*')
      ? checkAction(keyBlockAbility, requiredAction)
      : false
  return finalCheck
}
