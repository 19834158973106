import React, { useEffect, useState } from 'react'
import styled, { StyledComponent } from 'styled-components'
import { IInput } from '../Input'
import './index'
// import './datepicker-overrides.css'
import { Flex } from '../../index'
import themes from '../../../../themes/schema'
import { useSelector } from 'react-redux'

interface ITextArea extends IInput {
  handleChange: any
  selected?: any
  dateType?: 'datetime-local' | 'date'
  id?: string
}

const DatePicker = ({
  id,
  handleChange,
  value = '',
  selected,
  dateType = 'date',
  disabled,
}: Partial<ITextArea>) => {
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false)
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const handleShowDatePicker = () => {
    setShowDatePicker(!showDatePicker)
  }

  const {
    userSettings: { darkMode },
  } = useSelector((state: any) => state?.user)
  const customDisplayType = darkMode ? 'darkMode' : 'originalFlavor'

  useEffect(() => {
    document.addEventListener('mousedown', (e) => {
      const selector = e.target as HTMLDivElement
      if (!selector.closest('.dropdown-container-check')) {
        setShowDatePicker(false)

        return () => {
          document.removeEventListener('mousedown', () => {})
        }
      }
    })
    document.addEventListener('mouseout', (e) => {
      const selector = e.target as HTMLDivElement
      if (!selector.closest('.dropdown-container-check')) {
        setShowDatePicker(false)

        return () => {
          document.removeEventListener('mouseout', () => {})
        }
      }
    })
    return
  }, [setShowDatePicker])
  const getDatePickerStyle: Record<string, any> = {
    default_originalFlavor: themes.originalFlavor.datePicker.border.default,
    show_originalFlavor: themes.originalFlavor.datePicker.border.show,
    hover_originalFlavor: themes.originalFlavor.datePicker.border.hover,
    backgroundColor_original: themes.originalFlavor.datePicker.backgroundColor,
    defaultLabel_originalFlavor: themes.originalFlavor.datePicker.label.default,
    showLabel_originalFlavor: themes.originalFlavor.datePicker.label.show,
    hoverLabel_originalFlavor: themes.originalFlavor.datePicker.label.hover,
    inputColor_originalFlavor: themes.originalFlavor.datePicker.color,
    backgroundColor_originalFlavor: themes.originalFlavor.datePicker.backgroundColor,

    default_darkMode: themes.darkMode.datePicker.border.default,
    show_darkMode: themes.darkMode.datePicker.border.show,
    hover_darkMode: themes.darkMode.datePicker.border.hover,
    defaultLabel_darkMode: themes.darkMode.datePicker.label.default,
    showLabel_darkMode: themes.darkMode.datePicker.label.show,
    hoverLabel_darkMode: themes.darkMode.datePicker.label.hover,
    inputColor_darkMode: themes.darkMode.datePicker.color,
    backgroundColor_darkMode: themes.darkMode.datePicker.backgroundColor,
  }

  return (
    <DatePickerContainer className={'dropdown-container-check'}>
      <div
        data-testid={`${id}-button`}
        style={{
          backgroundColor: getDatePickerStyle[`backgroundColor_${customDisplayType}`],
          border: showDatePicker
            ? getDatePickerStyle[`show_${customDisplayType}`]
            : isHovered
            ? getDatePickerStyle[`hover_${customDisplayType}`]
            : getDatePickerStyle[`default_${customDisplayType}`],
          borderRadius: showDatePicker ? `4px 4px 0 0` : `4px`,
          padding: '0px 5px',
          display: 'flex',
          flexDirection: showDatePicker || (value && value?.length) ? 'column' : 'row',
          justifyContent:
            showDatePicker || (value && value?.length) ? 'flex-start' : 'space-between',
          alignItems: showDatePicker || (value && value?.length) ? 'flex-start' : 'center',
        }}
        tabIndex={0}
        role="button"
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            if (selected) {
              selected && selected(true)
            }
            return setShowDatePicker(!showDatePicker)
          }
        }}
        onClick={() => {
          if (selected) {
            selected && selected(true)
          }
          setShowDatePicker(!showDatePicker)
        }}
      >
        <label
          style={{
            color: showDatePicker
              ? getDatePickerStyle[`showLabel_${customDisplayType}`]
              : value || isHovered
              ? getDatePickerStyle[`hoverLabel_${customDisplayType}`]
              : getDatePickerStyle[`defaultLabel_${customDisplayType}`],
          }}
          className="genericLabelText"
        >
          {'Date'}
        </label>
        <Flex config={['justifyBetween', 'alignCenter']} style={{ width: '100%' }}>
          <DateInput
            disabled={disabled}
            darkMode={darkMode}
            data-testid={`${id}-input`}
            style={{
              width: '95%',
              color: getDatePickerStyle[`inputColor_${customDisplayType}`],
            }}
            value={value}
            onChange={(val) => {
              if (dateType === 'datetime-local') {
                handleChange(val.currentTarget.value)
              } else if (val.currentTarget.value.length === 10) {
                handleChange(val.currentTarget.value)
              }
            }}
            type={dateType}
          />
        </Flex>
      </div>
    </DatePickerContainer>
  )
}

const DatePickerContainer: StyledComponent<'div', any, {}, never> = styled.div`
  position: relative;
  width: 100%;
`

type DatesStuff = {
  id?: any
  value?: any
  val?: any
  handleChange?: any
  dateType?: any
  darkMode: boolean
}
const DateInput = styled.input<DatesStuff>`
  ::-webkit-calendar-picker-indicator {
    background-color: ${(props) => (props.darkMode ? 'grey' : 'transparent')};
    border-radius: ${(props) => (props.darkMode ? '100px' : '')};
    cursor: pointer;
  }
`

type Custom = {
  showDatePicker?: boolean
  isHovered?: boolean
  hasValue?: boolean
}

const StyledActionableIcon = styled.button<Custom>`
  cursor: pointer;
  background: none;
  color: ${({ isHovered, showDatePicker, hasValue }) =>
    isHovered || hasValue ? '#000' : showDatePicker ? '#8223D2' : '#a5aaaf'};
  border: none;

  :focus {
    background: none;
  }
`

type InputContainer = {
  className: string | undefined
  type: string
  isActive: boolean
}

const getBorderRadius = (type: string, isActive: boolean) => {
  const getType: Record<string, string> = {
    box: '0',
    pill: '16px',
    rolled: '4px',
  }

  if (isActive) {
    return '4px 4px 0 0'
  } else {
    return getType[type]
  }
}

const StyledInput: StyledComponent<'input', any, {}, never> = styled.input`
  margin: 0;
  width: 100%;
  height: 26px;
  border: none;
  font-size: 1.08em;
  outline: none;

  &:focus {
    outline: none;
    border: none;
  }

  &:hover {
    outline: none;
    border: none;
  }
`

export default DatePicker
