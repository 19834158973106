import React, { SyntheticEvent, useEffect, useMemo, useState, ChangeEvent } from 'react'
import '@asurion/sass-design-system/build/asurion/asurion.css'
import '@asurion/atomic-ui-library'
import {
  Button,
  ButtonSet,
  DatePicker,
  Dropdown,
  Flex,
  FormLabel,
  IconComponent,
  Input,
  SubmitButton,
  Tooltip,
} from '../../../common'
import { checkInput, checkPermissions, handleInput } from '../../../../utils'
import { format } from 'date-fns'
import { IDropdownOption } from '../../Atoms/Dropdown'
import { useForm } from 'react-hook-form'
import { tooltipMap } from 'src/constants/configurations'

const DispatchRuleDialog = ({
  rule,
  postDispatchRule,
  cancelAddDispatchRules,
  onEditInTrainingRule,
  existingDispatchRules,
  editDispatchRule,
  formValidation,
}: {
  rule: any
  postDispatchRule: any
  cancelAddDispatchRules: any
  onEditInTrainingRule?: (e: any) => void
  existingDispatchRules?: any[]
  editDispatchRule?: any
  formValidation?: any
}) => {
  const useExistingOptions = useMemo(
    () => [
      { value: 'yes', id: 'useExisting', display: 'Yes' },
      { value: 'no', id: 'useExisting', display: 'No' },
    ],
    [],
  )

  const isAnEdit = rule && 'dispatchRuleId' in rule
  const [useExisting, setUseExisting] = useState<IDropdownOption>(
    isAnEdit ? useExistingOptions[0] : useExistingOptions[1],
  )
  const [dispatchRuleOptions, setDispatchRuleOptions] = useState<IDropdownOption[]>([])
  const [selectedDispatchRule, setSelectedDispatchRule] = useState<IDropdownOption>()
  const [dispatchRuleName, setDispatchRuleName] = useState<string>(rule.name || '')
  const { taskQueueMaxPercent: maxExperts = 0, threshold = 0 } = rule
  const [taskQueueMaxPercent, setTaskQueueMaxPercent] = useState(`${maxExperts * 100}`)
  const [thresholdValue, setThresholdValue] = useState(`${threshold * 100}`)
  const [outageStartTime, setOutageStartTime] = useState(
    (rule.outageStartTime && format(new Date(rule.outageStartTime), "yyyy-MM-dd'T'HH:mm")) ||
      format(new Date(), "yyyy-MM-dd'T'HH:mm"),
  )
  const [outageEndTime, setOutageEndTime] = useState(
    (rule.outageEndTime && format(new Date(rule.outageEndTime), "yyyy-MM-dd'T'HH:mm")) ||
      format(new Date(), "yyyy-MM-dd'T'HH:mm"),
  )
  const [isActive, setIsActive] = useState(
    rule.isEnabled === '1' ? 'Active' : 'Inactive' || 'Active',
  )
  const [formValid, setFormValid] = useState<boolean>(true)
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
    clearErrors,
  } = useForm({
    defaultValues: {
      dispatchRuleName: dispatchRuleName || '',
      taskQueueMaxPercent: String(Number(taskQueueMaxPercent) / 100) || '',
      thresholdValue: String(Number(thresholdValue) / 100) || '',
      isEnabled: isActive === 'Active' ? '1' : '0',
      outageStartTime: (outageStartTime && new Date(outageStartTime).toISOString()) || '',
      outageEndTime: (outageEndTime && new Date(outageEndTime).toISOString()) || '',
    },
  })

  useEffect(() => {
    if (formValidation) {
      formValidation(formValid)
    }
  }, [formValid])

  useEffect(() => {
    setDispatchRuleOptions(
      (existingDispatchRules &&
        existingDispatchRules?.length > 0 &&
        existingDispatchRules.map((dRule: any) => ({
          value: dRule.dispatchRuleId,
          id: dRule.dispatchRuleId,
          display: dRule.name,
        }))) ||
        [],
    )
  }, [existingDispatchRules])

  useEffect(() => {
    if (useExisting.value === 'yes') {
      setSelectedDispatchRule(dispatchRuleOptions[0])
    }
  }, [useExisting])

  useEffect(() => {
    if (onEditInTrainingRule) {
      const buildRule = existingDispatchRules?.find((dRule) => {
        return dRule.dispatchRuleId === selectedDispatchRule?.id
      })
      onEditInTrainingRule(buildRule)
    }
  }, [selectedDispatchRule])

  useEffect(() => {
    if (onEditInTrainingRule) {
      const buildRule: any = {
        taskQueueMaxPercent: String(Number(taskQueueMaxPercent) / 100),
        threshold: String(Number(thresholdValue) / 100),
        isEnabled: isActive === 'Active' ? '1' : '0',
        outageStartTime: (outageStartTime && new Date(outageStartTime).toISOString()) || '',
        outageEndTime: (outageEndTime && new Date(outageEndTime).toISOString()) || '',
        ...(rule?.dispatchConfigId && {
          dispatchConfigId: rule.dispatchConfigId,
        }),
      }
      if (isAnEdit && useExisting.value === 'yes') {
        buildRule.dispatchRuleId = rule.dispatchRuleId
        buildRule.name = dispatchRuleName || ''
      } else {
        if ('dispatchRuleId' in rule) {
          delete rule.dispatchRuleId
        }
        if ('name' in rule) {
          delete rule.name
        }
      }
      return onEditInTrainingRule(buildRule)
    }
  }, [
    dispatchRuleName,
    taskQueueMaxPercent,
    thresholdValue,
    isActive,
    outageStartTime,
    outageEndTime,
  ])

  const handleFormSubmit = () => {
    const buildRule = {
      dispatchRuleId: rule.dispatchRuleId,
      name: dispatchRuleName || 'Custom Dispatch Rule',
      taskQueueMaxPercent: String(Number(taskQueueMaxPercent) / 100),
      threshold: String(Number(thresholdValue) / 100),
      isEnabled: isActive === 'Active' ? '1' : '0',
      outageStartTime: new Date(outageStartTime).toISOString() || '',
      outageEndTime: new Date(outageEndTime).toISOString() || '',
    }
    if (formValid) postDispatchRule(buildRule)
  }
  const permission = checkPermissions('rules', 'edit')
  return (
    <Flex config={['column']}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        {onEditInTrainingRule && (
          <FormLabel id={'dispatchRules'} value={'Dispatch Rule'}>
            &nbsp;
          </FormLabel>
        )}
        <Flex
          config={['column']}
          style={{
            borderRadius: '4px',
            padding: '15px 25px',
            border: '1px solid #a5aaaf',
          }}
        >
          <Flex config={['column']}>
            {!onEditInTrainingRule && (
              <FormLabel id="dispatchRuleName" value="Name of Dispatch Rule">
                <Input
                  {...register('dispatchRuleName', {
                    required: { value: true, message: 'This field is required.' },
                    validate: (value) => {
                      if (value.trim().length === 0) return 'This field is required.'
                    },
                  })}
                  error={errors.dispatchRuleName || null}
                  type="rolled"
                  value={dispatchRuleName}
                  handleChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleInput(e, setDispatchRuleName)
                    setValue('dispatchRuleName', e.target.value)
                    checkInput(
                      'dispatchRuleName',
                      e.target.value,
                      trigger,
                      clearErrors,
                      setFormValid,
                    )
                  }}
                />
              </FormLabel>
            )}

            <FormLabel id="status" value="Status">
              <div
                style={{ display: 'flex', cursor: 'pointer', width: 150 }}
                tabIndex={0}
                role="button"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    return setIsActive('Active')
                  }
                }}
                onClick={() => setIsActive('Active')}
              >
                <IconComponent
                  icon={isActive === 'Active' ? 'radio_button_checked' : 'radio_button_unchecked'}
                />
                <span style={{ marginLeft: 15 }}>Active</span>
              </div>
              <div
                style={{ display: 'flex', cursor: 'pointer', width: 150 }}
                tabIndex={0}
                role="button"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    return setIsActive('Inactive')
                  }
                }}
                onClick={() => setIsActive('Inactive')}
              >
                <IconComponent
                  icon={isActive === 'Inactive' ? 'radio_button_checked' : 'radio_button_unchecked'}
                />
                <span style={{ marginLeft: 15 }}>Inactive</span>
              </div>
            </FormLabel>
          </Flex>
        </Flex>
        <Flex>
          <Flex
            config={['column']}
            style={{
              marginRight: 50,
              flex: '1 1 350px',
            }}
          >
            <FormLabel id="outageStartTime" value="Outage Start Time">
              <Flex config={['alignCenter']}>
                <DatePicker
                  dateType={'datetime-local'}
                  type={'rolled'}
                  handleChange={setOutageStartTime}
                  value={outageStartTime}
                  placeholder={'Select an outage start time'}
                />
              </Flex>
            </FormLabel>
          </Flex>
          <Flex
            config={['column']}
            style={{
              marginRight: 50,
              flex: '1 1 350px',
            }}
          >
            <FormLabel id="outageEndTime" value="Outage End Time">
              <Flex config={['alignCenter']}>
                <DatePicker
                  dateType={'datetime-local'}
                  type={'rolled'}
                  handleChange={setOutageEndTime}
                  value={outageEndTime}
                  placeholder={'Select an outage end time'}
                />
              </Flex>
            </FormLabel>
          </Flex>
        </Flex>
        <Flex>
          <div style={{ width: 150, marginRight: 50 }}>
            <FormLabel
              id="thresholdInput"
              value={`Threshold*`}
              tooltip={{ selector: 'rules.trainingRules.threshold' }}
            >
              <Input
                {...register('thresholdValue', {
                  required: { value: true, message: 'This field is required.' },
                  validate: (value) => {
                    if (isNaN(Number(value))) return 'Threshold requires a number.'
                    if (value.trim().length === 0) return 'This field is required.'
                  },
                })}
                error={errors.thresholdValue || null}
                type="rolled"
                value={thresholdValue}
                handleChange={async (e: ChangeEvent<HTMLInputElement>) => {
                  handleInput(e, setThresholdValue)
                  setValue('thresholdValue', e.target.value)
                  checkInput('thresholdValue', e.target.value, trigger, clearErrors, setFormValid)
                }}
              >
                %
              </Input>
            </FormLabel>
          </div>
          <div style={{ width: 150, marginRight: 50 }}>
            <FormLabel
              style={{ width: '100%' }}
              id="thresholdInput"
              value={`Max Experts Limit*`}
              tooltip={{ selector: 'rules.trainingRules.maxExpertsLimit' }}
            >
              <Input
                {...register('taskQueueMaxPercent', {
                  required: { value: true, message: 'This field is required.' },
                  validate: (value) => {
                    if (isNaN(Number(value))) return 'Max Experts requires a number.'
                    if (value.trim().length === 0) return 'This field is required.'
                  },
                })}
                error={errors.taskQueueMaxPercent || null}
                type="rolled"
                value={taskQueueMaxPercent}
                handleChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handleInput(e, setTaskQueueMaxPercent)
                  setValue('taskQueueMaxPercent', e.target.value)
                  checkInput(
                    'taskQueueMaxPercent',
                    e.target.value,
                    trigger,
                    clearErrors,
                    setFormValid,
                  )
                }}
              >
                %
              </Input>
            </FormLabel>
          </div>
        </Flex>
        {!onEditInTrainingRule && (
          <Flex config={['justifyStart']}>
            {permission && <SubmitButton marginTop={25}>Save Changes</SubmitButton>}

            <Button
              displayType="outline"
              size="lg"
              handleClick={cancelAddDispatchRules}
              className="is-primary is-ghost"
              style={{ margin: 25 }}
            >
              Cancel
            </Button>
          </Flex>
        )}
      </form>
    </Flex>
  )
}

export default DispatchRuleDialog
