import React from 'react'
import { IconComponent, FormLabel } from '../../index'

const Status = ({ isActive, setIsActive }: any) => {
  return (
    <FormLabel id="status" value="Status">
      <button
        className={'ttd-bare-button'}
        style={{ display: 'flex', cursor: 'pointer', width: 170 }}
        onClick={() => {
          setIsActive('1')
        }}
      >
        <IconComponent
          icon={isActive === '1' ? 'radio_button_checked' : 'radio_button_unchecked'}
        />
        <span style={{ marginLeft: 15 }}>Active</span>
      </button>
      <button
        className={'ttd-bare-button'}
        style={{ display: 'flex', cursor: 'pointer', width: 170 }}
        onClick={() => {
          setIsActive('0')
        }}
      >
        <IconComponent
          icon={isActive === '0' ? 'radio_button_checked' : 'radio_button_unchecked'}
        />
        <span style={{ marginLeft: 15 }}>Inactive</span>
      </button>
    </FormLabel>
  )
}

export default Status
