import React, { useState, useContext, Fragment } from 'react'
import { Button, Dropdown, Flex, FormLabel, Modal } from '../../index'
import { AdminConfigContext } from '../../../../context/AdminConfigContext'
import { GroupsContext } from '../../../../context/GroupsContext'
import { updateBulkTrainings } from '../../../../actions'
import { IBulkEdit, IBulkEditModal } from '../../../../interfaces'

import Status from './Status'
import Priority from './Priority'
import Duration from './Duration'
import MaxDelivery from './MaxDelivery'
import MaxDeliveryType from './MaxDeliveryType'
import MonitorMethod from './MonitorMethod'
import Tags from './Tags'
import Notes from './Notes'

import { optionConfig, bulkEditOptions } from '../../../../constants'
import { handleInput } from '../../../../utils'
import { getTrainings } from '../../../../actions/trainings'

export const BulkEditModal = ({ handleCancel, data }: IBulkEditModal) => {
  const {
    priority: priorityOptions,
    maxDispatchLimitTypes: maxDispatchLimitTypesOptions,
    monitor: monitorOptions,
  } = optionConfig

  const {
    setTrainings,
    setLoading,
    setTrainingAspectData,
    optimisticallyUpdateTrainingAssignment,
  } = useContext(GroupsContext)

  const [currentlyEditing, setCurrentlyEditing] = useState<string[]>([])
  const [isActive, setIsActive] = useState(undefined)
  const [priority, setPriority] = useState({ value: '', display: '', id: '' })
  const [trainingLength, setTrainingLength] = useState(undefined)
  const [maxAttempts, setMaxAttempts] = useState(undefined)
  const [maxDispatchLimitType, setMaxDispatchLimitType] = useState({
    value: '',
    display: '',
    id: '',
  })
  const [monitor, setMonitor] = useState({ value: '', display: '', id: '' })
  const [selectedTags, setSelectedTags] = useState([
    {
      value: '',
      display: '',
      id: '',
    },
  ])
  const [trainingNotes, setTrainingNotes] = useState(undefined)
  const [overwrite, setOverwrite] = useState('Inactive')

  const { tags: unformattedTags } = useContext(AdminConfigContext)
  const formatTags = (unformattedTags: string[]) => {
    return unformattedTags?.map((tag: string) => ({
      display: tag,
      id: tag,
      value: tag,
    }))
  }
  const formattedTags =
    unformattedTags &&
    formatTags(unformattedTags).sort(
      ({ value: a }: { value: string }, { value: b }: { value: string }) => (a > b ? 1 : -1),
    )

  const deleteTag = (id: string) => {
    const newTags = selectedTags?.filter((tag: any) => tag.id !== id)
    setSelectedTags(newTags)
  }

  const addSelectionToEdit = () => {
    let currentArray: string[] = [...currentlyEditing] as string[]
    const nextSelection = bulkEditOptions.filter((item) => {
      if (!currentArray.includes(item.id)) {
        return item.id
      }
    })
    if (!nextSelection.length) return
    currentArray.push(nextSelection[0].id)
    if (currentArray.length) {
      return setCurrentlyEditing(currentArray)
    }
  }

  const findSelected = (item: string) => {
    return bulkEditOptions.find((el) => {
      if (el.id === item) {
        return el
      }
    })
  }

  const changeDropdown = (newItem: any, oldItem: any, position: any) => {
    if (!currentlyEditing.includes(newItem.id)) {
      let copy = currentlyEditing
      copy.splice(position, 1, newItem.id)
      setCurrentlyEditing([...copy])
    }
  }

  const applyBulkEdit = async () => {
    const id = data.map((each: any) => each.trainingId)
    const appendTrainingNotes = overwrite === 'Active' ? true : false
    let maxDispatchLimit: any = {}
    if (maxDispatchLimitType.value !== '')
      maxDispatchLimit['unitOfMeasurement'] = maxDispatchLimitType.value
    if (maxAttempts) {
      maxDispatchLimit['value'] =
        maxDispatchLimit['unitOfMeasurement'] === 'percent'
          ? String(Number(maxAttempts) / 100)
          : maxAttempts
    }
    const body: IBulkEdit = {
      isActive,
      priority: priority ? priority.value : '',
      trainingLength,
      maxDispatchLimit:
        maxDispatchLimit.value && maxDispatchLimit.unitOfMeasurement ? maxDispatchLimit : '',
      monitorType: monitor ? monitor.value : '',
      tags: selectedTags
        ? selectedTags
            .map(({ value }) => {
              if (value !== '') return value
            })
            .filter(Boolean)
        : '',
      trainingNotes,
    }
    const cleanBody: any = { ...body }
    Object.keys(cleanBody).forEach((key: any) => {
      return cleanBody[key] === undefined ||
        cleanBody[key] === '' ||
        cleanBody[key].length === 0 ||
        (Object.keys(cleanBody[key]).length === 0 && cleanBody.constructor === Object)
        ? delete cleanBody[key]
        : {}
    })
    if (Object.keys(cleanBody).length === 0 && cleanBody.constructor === Object) return
    await updateBulkTrainings(id, appendTrainingNotes, cleanBody).then(async () => {
      for (const trainingId of id) {
        optimisticallyUpdateTrainingAssignment(trainingId, cleanBody)
      }
      await getTrainings(setTrainings, setTrainingAspectData, setLoading)
      handleCancel()
    })
  }

  const componentNeeded: Record<string, any> = {
    status: <Status isActive={isActive} setIsActive={setIsActive} />,
    duration: (
      <Duration
        trainingLength={trainingLength}
        handleInput={(e: any) => {
          handleInput(e, setTrainingLength)
        }}
      />
    ),
    priority: (
      <Priority priorityOptions={priorityOptions} priority={priority} setPriority={setPriority} />
    ),
    maxDelivery: (
      <Fragment>
        <MaxDelivery
          maxAttempts={maxAttempts}
          setMaxAttempts={setMaxAttempts}
          maxDispatchLimitType={maxDispatchLimitType}
          handleInput={handleInput}
        />
        <MaxDeliveryType
          maxDispatchLimitType={maxDispatchLimitType}
          setMaxDispatchLimitType={setMaxDispatchLimitType}
          maxDispatchLimitTypesOptions={maxDispatchLimitTypesOptions}
        />
      </Fragment>
    ),
    monitorMethod: (
      <MonitorMethod monitor={monitor} setMonitor={setMonitor} monitorOptions={monitorOptions} />
    ),
    tags: (
      <Tags
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        formattedTags={formattedTags}
        deleteTag={deleteTag}
      />
    ),
    notes: (
      <Notes
        trainingNotes={trainingNotes}
        setTrainingNotes={setTrainingNotes}
        overwrite={overwrite}
        setOverwrite={setOverwrite}
      />
    ),
  }

  return (
    <Modal header="Edit trainings" close={handleCancel}>
      <Flex
        config={['column']}
        style={{
          width: '100%',
        }}
      >
        {currentlyEditing.map((item, ind) => {
          const selected = findSelected(item)

          return (
            <Flex key={JSON.stringify(item)} config={['column']}>
              <FormLabel id="" value={'Select Category'}>
                <Dropdown
                  type={'rolled'}
                  options={bulkEditOptions}
                  selected={selected || bulkEditOptions[0]}
                  emitDropdownOption={(e) => changeDropdown(e, item, ind)}
                />
              </FormLabel>
              {componentNeeded[item]}
            </Flex>
          )
        })}

        <Button handleClick={addSelectionToEdit} size={'sm'} displayType={'outline'}>
          Add Additional Category
        </Button>
        <Flex style={{ paddingTop: 10 }}>
          <Button
            handleClick={applyBulkEdit}
            size={'sm'}
            displayType={'flat'}
            style={{ marginRight: 10 }}
          >
            Apply Changes
          </Button>
          <Button handleClick={handleCancel} size={'sm'} displayType={'outline'}>
            Cancel
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

export default BulkEditModal
