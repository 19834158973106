import React, { ChangeEvent } from 'react'
import { Input, FormLabel } from '../../index'

const MaxDelivery = ({ maxAttempts, setMaxAttempts, maxDispatchLimitType, handleInput }: any) => {
  return (
    <FormLabel id="maxDelivery" value="Max Delivery">
      <Input
        style={{ width: 170 }}
        type="rolled"
        value={maxAttempts}
        placeholder={
          maxDispatchLimitType?.value.charAt(0).toUpperCase() + maxDispatchLimitType?.value.slice(1)
        }
        handleChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleInput(e, setMaxAttempts)
        }}
      >
        {maxDispatchLimitType?.value === 'percent' ? '%' : ''}
      </Input>
    </FormLabel>
  )
}
export default MaxDelivery
