import React from 'react'
import { Dropdown, FormLabel } from '../../index'
import { IDropdownOption } from '../../Atoms/Dropdown'

const MonitorMethod = ({ monitor, setMonitor, monitorOptions }: any) => {
  return (
    <FormLabel id="monitorMethod" value="Monitor Method">
      <FormLabel id="monitor" value="Monitor">
        <Dropdown
          type="rolled"
          style={{ width: 270 }}
          options={monitorOptions}
          selected={monitor}
          emitDropdownOption={(e: IDropdownOption) => {
            setMonitor(e)
          }}
        />
      </FormLabel>
    </FormLabel>
  )
}

export default MonitorMethod
