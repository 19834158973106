import { API } from 'aws-amplify'
import { format } from 'date-fns'
import { Dispatch, SetStateAction } from 'react'
import { IMappedTraining } from '../interfaces'
import { IDropdownOption } from '../components/common/Atoms/Dropdown'
import { optionConfig } from '../constants/optionConfig'
import { awesomeCoolRecursiveScanFunction, getAllPagesFunction } from './assignments'

const getTrainings = async (
  setTrainings: Dispatch<SetStateAction<IMappedTraining[]>>,
  setAspectData: Dispatch<SetStateAction<IDropdownOption[]>>,
  setLoading: Dispatch<SetStateAction<boolean>>,
) => {
  setLoading(true)
  try {
    const params = {
      queryStringParameters: {
        table: 'Trainings',
        action: 'scan',
      },
      body: {
        config: {
          page: 0,
          pageSize: 5000,
          method: `getAllTrainings`,
        },
      },
    }
    const trainings = (await getAllPagesFunction({ params })) || []
    if (trainings.length) {
      setAspectData(mapAspectData(trainings, 'training'))
      return setTrainings(trainings)
    }
  } catch (e) {
    console.error(e)
  } finally {
    setLoading(false)
  }
}

const mapAspectData = (list: any[], type: string) => {
  switch (type) {
    case 'training':
      return list.map((group: any) => {
        return {
          checked: false,
          display: group.trainingName,
          id: group.trainingId,
          value: group.trainingId,
        }
      })
    default:
      return []
  }
}

const updateBulkTrainings = async (id: any[], appendTrainingNotes: boolean = false, set: any) => {
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    return await API.put(apiName, apiPath, {
      queryStringParameters: {
        table: 'Trainings',
      },
      body: {
        appendTrainingNotes,
        trainingIds: id,
        set,
      },
    })
  } catch (e) {
    console.error(e)
  }
}

export { getTrainings, updateBulkTrainings }
