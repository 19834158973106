import React, { useState, createRef, useEffect, CSSProperties, useRef } from 'react'
import styled, { StyledComponent } from 'styled-components'
import { DatePicker, IconComponent, Search } from '../../index'
import { Checkbox } from '@material-ui/core'
import { handleInput } from '../../../../utils'
import { format } from 'date-fns'
import {
  maskReportingPriority,
  maskReportFilter,
  maskTimeSpent,
  maskReportStatus,
} from './renderData'
import { IDropdownTable } from '../../../../interfaces'
import { combinedDateTimeKeys as needsDatePicker } from '../../../../constants'
import { useSelector } from 'react-redux'
import themes from '../../../../themes/schema'

const DropdownTable = ({
  // type,
  dateFilters = {},
  display,
  boundingBoxHeight,
  header,
  style,
  children,
  displayFilter = true,
  filters,
  emitFilters,
  handleSort,
  emitDateFilter,
  blurred,
  setChildMousedown = () => {},
  setBlurred = () => {},
}: IDropdownTable) => {
  const [selectAll, setSelectAll] = useState<boolean>(true)
  const [showDropdownTableOptions, setShowDropdownTableOptions] = useState<boolean>(false)
  const [scrollEnd, setScrollEnd] = useState<boolean>(false)
  const [showSortByOptions, setShowSortByOptions] = useState<boolean>(false)
  const [showFilterByOptions, setShowFilterByOptions] = useState<boolean>(false)
  const [datePickerSelected, setDatePickerSelected] = useState<boolean>(true)
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [searchValue, setSearchValue] = useState<string>('')
  const [searchedFilters, setSearchedFilters] = useState<any[]>([])
  const [tags, setTags] = useState<any[]>([])
  const {
    userSettings: { darkMode },
  } = useSelector((state: any) => state?.user)
  const customDisplayType = darkMode ? 'darkMode' : 'originalFlavor'
  const getStyle: Record<string, any> = {
    header_backgroundColor_originalFlavor: themes.originalFlavor.header.backgroundColor,
    header_backgroundColor_darkMode: themes.darkMode.header.backgroundColor,
  }
  const handleDropDown = (showDropdown = !showDropdownTableOptions) => {
    if (showSortByOptions) {
      setShowSortByOptions(false)
    }
    if (showFilterByOptions) {
      setShowFilterByOptions(false)
    }
    setShowDropdownTableOptions(showDropdown)
  }

  const scrollRef = createRef<HTMLDivElement>()
  const consolidateTags = (tagList: any) => {
    const consolidatedTags = tagList.map((each: any) => {
      return each.value
    })
    // Set a flat and unique list of tags to state
    setTags([...new Set(consolidatedTags.flat())])
  }
  useEffect(() => {
    if (blurred) {
      setBlurred(false)
      handleDropDown(false)
    }
  }, [blurred])
  useEffect(() => {
    const keys = Object.keys(dateFilters)
    if (keys.includes(header)) {
      setFromDate(dateFilters[header].from)
      setToDate(dateFilters[header].to)
    }
  }, [dateFilters])

  useEffect(() => {
    if (!searchValue) {
      if (header === 'tags' && filters[header]) consolidateTags(filters[header])
      return setSearchedFilters(filters[header])
    } else {
      setSearchedFilters(
        filters[header].filter(({ value, display }: { value: string; display: string }) => {
          if (['priority'].includes(header)) {
            return display.toLowerCase().includes(searchValue.toLowerCase())
          } else {
            return value.toLowerCase().includes(searchValue.toLowerCase())
          }
        }),
      )
    }
  }, [setSearchedFilters, filters, header, searchValue])

  useEffect(() => {
    const currentRef = scrollRef.current
    if (currentRef) {
      currentRef.addEventListener('scroll', () => {
        return setScrollEnd(currentRef.scrollTop + 55 >= 165)
      })
    }
    return
  }, [scrollRef, scrollEnd])

  const showFilterByOptionsFor = [
    'empId',
    'fullName',
    'trainingName',
    'trainingLink',
    'trainingLength',
    'timeSpent',
    'completed',
    'completionRate',
    'outageStartDate',
    'outageEndDate',
    'startDate',
    'endDate',
    'dueDate',
    'lastModifiedBy',
    'groupName',
    'outageEndTime',
    'outageStartTime',
  ]

  const sortByRef = useRef<any>(null)
  const [text, icon] = children

  return (
    <DropdownTableContainer style={{ ...style }} className="dropdown-container-check">
      <div className="ttd-flex-center">
        <span>{text}</span>
        {displayFilter && (
          <StyledActionableIcon
            tabIndex={0}
            role="button"
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                return handleDropDown()
              }
            }}
            onClick={() => handleDropDown()}
          >
            {icon}
          </StyledActionableIcon>
        )}
      </div>

      {showSortByOptions && showDropdownTableOptions && (
        <div
          style={{
            position: 'absolute',
            color: 'black',
            zIndex: 9999,
            top: 39 * 3,
            left: 219,
            width: 200,
            border: '1px solid #8223D2',
          }}
          role="button"
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              return handleDropDown()
            }
          }}
          onMouseDown={() => {
            setChildMousedown(true)
          }}
          onClick={() => {
            handleDropDown()
          }}
        >
          <Option>Some Option</Option>
        </div>
      )}
      {showFilterByOptions && showDropdownTableOptions && (
        <div
          aria-hidden={true}
          style={{
            minWidth: 200,
            position: 'absolute',
            color: 'black',
            zIndex: 9999,
            top: 76 * 3,
            left: datePickerSelected && needsDatePicker.includes(header) ? 399 : 219,
            padding: 15,
            maxWidth: 400,
            backgroundColor: getStyle[`header_backgroundColor_${customDisplayType}`],
            border: '1px solid #8223D2',
          }}
          onMouseDown={() => {
            setChildMousedown(true)
          }}
        >
          {showFilterByOptionsFor.includes(header) && (
            <div style={{ width: '100%' }}>
              <Search
                searchValue={searchValue}
                placeholder={'Search list'}
                handleInput={(e) => handleInput(e, setSearchValue)}
              />
              <Option tabIndex={0} onClick={() => null}>
                <Checkbox
                  checked={selectAll}
                  onClick={() => {
                    setSelectAll(!selectAll)
                    if (selectAll) {
                      searchedFilters.forEach(({ id, value, display, checked }: any) => {
                        emitFilters({ id, value, display, checked }, 'remove')
                      })
                    } else {
                      searchedFilters.forEach(({ id, value, display, checked }: any) => {
                        emitFilters({ id, value, display, checked }, 'add')
                      })
                    }
                  }}
                />
                All
              </Option>
              {searchedFilters.map(({ id, value, display, checked }: any, index: number) => {
                return (
                  <Option tabIndex={0} key={JSON.stringify({ id, value, display }) + index}>
                    <Checkbox
                      checked={checked}
                      onClick={() => {
                        if (checked) {
                          emitFilters({ id, value, display, checked }, 'remove')
                        } else {
                          emitFilters({ id, value, display, checked }, 'add')
                        }
                      }}
                    />
                    <div
                      style={{
                        width: '100%',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {needsDatePicker.includes(header)
                        ? format(new Date(display), 'MM/dd/yyyy')
                        : header === 'timeSpent' || header === 'Time Taken'
                        ? maskTimeSpent(searchedFilters[index], header)
                        : id.toLowerCase() === 'priority'
                        ? searchedFilters[index].display
                        : display}
                    </div>
                  </Option>
                )
              })}
            </div>
          )}
        </div>
      )}
      {showDropdownTableOptions && (
        <OptionContainer
          datePickerSelected={datePickerSelected}
          isDate={needsDatePicker.includes(header)}
          boundingBoxHeight={boundingBoxHeight}
          ref={scrollRef}
          onMouseDown={() => {
            setChildMousedown(true)
          }}
        >
          <Option
            onClick={() => {
              handleSort(header, 'asc')
              handleDropDown()
            }}
          >
            Sort A to Z
          </Option>
          <Option
            onClick={() => {
              handleSort(header, 'desc')
              handleDropDown()
            }}
          >
            Sort Z to A
          </Option>
          {/*<Option*/}
          {/*  tabIndex={0}*/}
          {/*  ref={sortByRef}*/}
          {/*  onClick={() => {*/}
          {/*    setShowSortByOptions(!showSortByOptions)*/}
          {/*  }}*/}
          {/*>*/}
          {/*  Sort by*/}
          {/*  <IconComponent icon="arrow_right" />*/}
          {/*</Option>*/}
          <hr />
          <Option
            tabIndex={0}
            onClick={() => {
              filters[header].forEach((filter: any) => {
                emitFilters(filter, 'add')
              })
            }}
          >
            <span
              style={{
                color: filters[header]?.filter((filter: any) => !filter.checked).length
                  ? 'inherit'
                  : 'lightgrey',
              }}
            >
              Clear filters from &ldquo;{display}&ldquo;
            </span>
          </Option>
          <Option
            tabIndex={0}
            onClick={() => {
              setShowFilterByOptions(!showFilterByOptions)
            }}
          >
            Filter By
            <IconComponent icon="arrow_right" />
          </Option>
          <hr />
          {!needsDatePicker.includes(header) && !showFilterByOptionsFor.includes(header) && (
            <div>
              <Search
                searchValue={searchValue}
                placeholder={'Search list'}
                handleInput={(e) => handleInput(e, setSearchValue)}
              />
              <Option tabIndex={0} onClick={() => null}>
                <Checkbox
                  checked={selectAll}
                  onClick={() => {
                    setSelectAll(!selectAll)
                    if (selectAll) {
                      searchedFilters.forEach(({ id, value, display, checked }: any) => {
                        emitFilters({ id, value, display, checked }, 'remove')
                      })
                    } else {
                      searchedFilters.forEach(({ id, value, display, checked }: any) => {
                        emitFilters({ id, value, display, checked }, 'add')
                      })
                    }
                  }}
                />
                All
              </Option>
              {header !== 'tags' &&
                searchedFilters.map(({ id, value, display, checked }: any, index: number) => {
                  if (display === null || !display.length) return
                  return (
                    <Option
                      tabIndex={0}
                      onClick={() => null}
                      key={JSON.stringify({ id, value, display }) + index}
                    >
                      <Checkbox
                        checked={checked}
                        onClick={() => {
                          if (checked) {
                            setSelectAll(false)
                            emitFilters({ id, value, display, checked }, 'remove')
                          } else {
                            searchedFilters.filter(({ checked }: { checked: boolean }) => checked)
                              .length +
                              1 ===
                            searchedFilters.length
                              ? setSelectAll(true)
                              : null
                            emitFilters({ id, value, display, checked }, 'add')
                          }
                        }}
                      />
                      {needsDatePicker.includes(header)
                        ? format(new Date(display), 'MM/dd/yyyy')
                        : (header === 'startedTraining' || header === 'taskEndTime') && display
                        ? format(new Date(display), 'MM/dd/yyyy p')
                        : id.toLowerCase() === 'priority'
                        ? searchedFilters[index].display
                        : header === 'End Status'
                        ? maskReportStatus(display)
                        : maskReportFilter(display, header) || display}
                    </Option>
                  )
                })}
              {tags &&
                tags.length > 0 &&
                header === 'tags' &&
                searchedFilters.map(
                  ({ id, value, display, checked }: any, index: number, array: any[]) => {
                    if (
                      (index &&
                        Array.isArray(value) &&
                        JSON.stringify(array[index - 1].value.sort()) ===
                          JSON.stringify(value.sort())) ||
                      !display.length
                    )
                      return
                    return (
                      <Option
                        tabIndex={0}
                        onClick={() => null}
                        key={JSON.stringify({ id, value, display }) + index}
                      >
                        <Checkbox
                          checked={checked}
                          onClick={() => {
                            if (checked) {
                              setSelectAll(false)
                              emitFilters({ id, value, display, checked }, 'remove')
                            } else {
                              searchedFilters.filter(({ checked }: { checked: boolean }) => checked)
                                .length +
                                1 ===
                              searchedFilters.length
                                ? setSelectAll(true)
                                : null
                              emitFilters({ id, value, display, checked }, 'add')
                              // checkSearchFilters(arr,value, 'add')
                            }
                          }}
                        />
                        {display}
                      </Option>
                    )
                  },
                )}
            </div>
          )}
          {needsDatePicker.includes(header) && (
            <StyledDatePicker>
              <div style={{ padding: '5px 15px', height: 'auto' }}>
                <h6>From:</h6>
                <DatePicker
                  selected={(e: boolean) => {
                    setDatePickerSelected(e)
                  }}
                  value={fromDate}
                  handleChange={(e: any) => {
                    setFromDate(e)
                    emitDateFilter && emitDateFilter({ key: header, type: 'from', value: e })
                  }}
                />
              </div>
              <div style={{ padding: '5px 15px', height: 'auto' }}>
                <h6>To:</h6>
                <DatePicker
                  selected={(e: boolean) => {
                    setDatePickerSelected(e)
                  }}
                  value={toDate}
                  handleChange={(e: any) => {
                    setToDate(e)
                    emitDateFilter && emitDateFilter({ value: e, key: header, type: 'to' })
                  }}
                />
              </div>
            </StyledDatePicker>
          )}
        </OptionContainer>
      )}
    </DropdownTableContainer>
  )
}

export default DropdownTable

const DropdownTableContainer: StyledComponent<
  'div',
  any,
  { style: CSSProperties | null | undefined },
  never
> = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;
  z-index: 2;
  span {
    cursor: move;
    z-index: 4;
  }
`

const StyledActionableIcon: StyledComponent<'button', any, {}, never> = styled.button`
  cursor: pointer;
  background: none;
  color: #5c1697;
  border: none;
  :active {
    background: none;
  }
  :focus {
    background: none;
  }
`
const StyledDatePicker = styled.div`
  display: flex;
  flex-direction: column;
`

const OptionContainer = styled.div<{
  boundingBoxHeight: number
  isDate: boolean
  datePickerSelected: boolean
}>`
  position: absolute;
  height: ${({ isDate, datePickerSelected }) => (isDate && datePickerSelected ? '650px' : '325px')};
  width: ${({ isDate }) => (isDate ? '400px' : '220px')};
  overflow-y: scroll;
  color: black;
  top: ${({ boundingBoxHeight }) => boundingBoxHeight - 36}px;
  box-shadow: 0px 2px 4px 0.4px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  border: 1px solid var(--grimace);
  background-color: ${(props) => props.theme.dropdown.option.backgroundColor};
  z-index: 9999;
`

const Option: StyledComponent<'div', any, {}, never> = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 25px;
  height: 47px;
  cursor: pointer;
  outline: none;
  justify-content: flex-start;
  &:hover {
    background: #dedede;
    color: ${(props) => props.theme.dropdown.option.hoverText};
  }
  background-color: ${(props) => props.theme.dropdown.option.backgroundColor};
  color: ${(props) => props.theme.dropdown.option.color};
`
