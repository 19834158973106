import React, { useContext } from 'react'
import { GroupsContext } from '../../../../context/GroupsContext'
import { FilterableTable, Flex } from '../../index'
import { trainingHeaders } from '../../../../constants'

const AddTrainingModal = ({
  bundle,
  onUpdate,
  close,
  loading = false,
}: {
  bundle: any
  onUpdate?: (map: any) => void
  close?: any
  loading?: boolean
}) => {
  const { trainings } = useContext(GroupsContext)

  return (
    <Flex config={['column']} style={{ overflow: 'auto' }}>
      <FilterableTable
        width={1000}
        displayEllipse={false}
        loading={loading}
        data={trainings.filter((each: any) => !each.bundleId)}
        headers={trainingHeaders}
        type={{ name: 'bundle', data: bundle }}
        handleTrainingUpdate={onUpdate}
        close={close}
        noExport={true}
      />
    </Flex>
  )
}

export default AddTrainingModal
