import React, { useContext, Dispatch, SetStateAction } from 'react'
import AspectTree from './AspectTree'
import { AssignTrainingContext } from '../../../context/AssignTrainingContext'

const AspectTreeSideBar = ({
  objMap,
  setObjMap,
}: {
  objMap: Record<string, []>
  setObjMap: Dispatch<SetStateAction<Record<string, any>>>
}) => {
  const { root } = useContext(AssignTrainingContext)
  const markedObjects = [
    ...new Set(
      Object.keys(objMap)
        .map((key) => {
          return objMap[key]
            .map(({ parentGroupIds }: { parentGroupIds: string[] }) => parentGroupIds)
            .flat()
        })
        .flat(),
    ),
  ]
  return (
    <>
      {root.map((node) => {
        const { groupName } = node
        return (
          <AspectTree
            key={groupName}
            node={node}
            parentId="root"
            objMap={objMap}
            setObjMap={setObjMap}
            rootName={groupName}
            root={root}
            markedObjects={markedObjects}
            marked={objMap[groupName] ? true : false}
          />
        )
      })}
    </>
  )
}

export default AspectTreeSideBar
