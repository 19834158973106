export const ARROW_DOWN_CODE = 'keyboard_arrow_down',
  ARROW_UP_CODE = 'keyboard_arrow_up',
  TABLE_ROW_CLASS = 'tableRow',
  TABLE_ROW_SELECTED_CLASS = 'tableRow tableRowSelected',
  DEFAULT_LENGTH = 0

export const rowSelected = (selected = false, id = '') => selected === id

export const valueToArrowDirection = (rowIsSelected = false) =>
  rowIsSelected ? ARROW_UP_CODE : ARROW_DOWN_CODE

export const valueToRowClass = (rowIsSelected = false) =>
  rowIsSelected ? TABLE_ROW_SELECTED_CLASS : TABLE_ROW_CLASS

// stringAndStringToBoolean :: String, String -> Boolean
export const stringAndStringToBoolean = (string = '') => (string2 = '') => string === string2

export const isEqual = (value, value2) => value === value2

export const valueToIsEqual = (value1) => (value2) => isEqual(value1, value2)

export const valueToHasLengthGreaterThanZero = (value = '') => valueToLength(value) > DEFAULT_LENGTH

export const valueToLength = (text = '') => (text && text.length ? text.length : DEFAULT_LENGTH)

export const getMinutesElapsed = (currentTime, statusChangeTime) => {
  let totalMilliseconds = currentTime - statusChangeTime
  let totalSeconds = totalMilliseconds / 1000
  let minutesAgo = Math.floor(totalSeconds / 60)
  return minutesAgo
}

export const transformFullName = (fullName) => {
  let modifiedName = fullName.split(/,|\s/).reverse().join(' ')
  return modifiedName
}

export const addLessThanSymbol = (value) => (value > 0 ? value : '< 1')

export const removeDotFormatFullNameWithSpace = (name = '') => name.split('.').join(' ')

export const getSkillsFromRoutingObj = ({
  Soluto = {},
  langProficiency: { language: userNestedLanguage = [] } = {},
  language = [],
} = {}) => {
  const languageUse = language.length ? language : userNestedLanguage
  const combinedSkills = Object.keys(Soluto).reduce((acc, group) => {
    const { skills = [] } = Soluto[group]
    return acc.concat(skills)
  }, [])
  return [...new Set(languageUse.concat(combinedSkills))]
}

export const areObjectsEqual = (object1, object2) => {
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false
    }
  }

  return true
}

export const integerToBoolean = (int) => !!int

export const sortObjectByKeys = (sorter) => (o) =>
  Object.keys(o)
    .sort(sorter)
    .reduce((r, k) => ((r[k] = o[k]), r), {})

export const numberToString = (num) => (num += '')

export const selectedMetric = (selectedInterval, metric1, metric2) =>
  selectedInterval === 'Today' ? getMetricValue(metric1) : getMetricValue(metric2)

const getMetricValue = (value) => (value !== null && value !== undefined ? value : '--')

const partnerStatsData = (
  {
    callsInQueue,
    cicallsInQueue,
    longestWait,
    cilongestWait,
    expertsAvailable,
    ciExpertsAvailable,
    abandonRate,
    ciabandonRate,
    ASA,
    ciASA,
    CRT,
    ciCRT,
  },
  selectedInterval,
) => {
  const crtSelected = selectedMetric(selectedInterval, CRT, ciCRT)
  const asaSelected = selectedMetric(selectedInterval, ASA, ciASA)
  const abandonRateSelected = selectedMetric(selectedInterval, abandonRate, ciabandonRate)
  const expertAvailSelected = selectedMetric(selectedInterval, expertsAvailable, ciExpertsAvailable)
  const longestWaitSelected = selectedMetric(selectedInterval, longestWait, cilongestWait)
  const callsInQueueSelected = selectedMetric(selectedInterval, callsInQueue, cicallsInQueue)

  const partnerStatLabels = [
    'Calls in Queue',
    'Longest Wait',
    /*'Experts Available',*/ 'Abandon Rate',
    'ASA',
    'CRT',
  ]
  const partnerStatValues = [
    callsInQueueSelected,
    longestWaitSelected,
    /*expertAvailSelected,*/ abandonRateSelected,
    asaSelected,
    crtSelected,
  ]
  const reduceStats = (obj, key, i) => ({ ...obj, [key]: partnerStatValues[i] })
  const partnerStatsObject = partnerStatLabels.reduce(reduceStats, {})
  return partnerStatsObject
}

export const removeUnderscores = (string) => string.replace(/_/g, ' ')

export const modifiedPartnerMapping = (partner, interval) => {
  const { globalStats } = partner
  const { monthlySLA, SLA, ciSLA, workflowSid } = globalStats

  const partnerStats = partnerStatsData(globalStats, interval)

  const partnerDataObj = {
    serviceLevel: SLA,
    ciServiceLevel: ciSLA,
    monthlyServiceLevel: monthlySLA,
    partnerStats,
    workflowSid,
    ...partner,
  }
  return partnerDataObj
}

export const dataFormatToPartnerData = (interval) => (wfData) => {
  const newObj = {}
  const workforceGroupTitle = Object.keys(wfData)[0]
  const formattedTitle = removeUnderscores(workforceGroupTitle)
  const globalStats = wfData[workforceGroupTitle].summary_stats
  const { summary_routing: { routing = {} } = {} } = wfData[workforceGroupTitle]
  newObj.displayName = formattedTitle
  newObj.syncName = workforceGroupTitle
  newObj.globalStats = globalStats
  newObj.routing = routing

  const combinedData = modifiedPartnerMapping(newObj, interval)

  return combinedData
}

export const nestedObjectsArrayToNestedObject = (data) =>
  data.reduce((acc, val) => ({ ...acc, ...val }), {})
