import {IConfigProps} from "../interfaces";
import {API} from "aws-amplify";

export const postBroadcast = async (body: any) => {
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const table = 'Notifications'

    const params: IConfigProps = {
      queryStringParameters: {table},
      body,
    }
    console.log('params', params)
    return await API.post(apiName, apiPath, params)
  } catch (ex) {
    console.error(ex)
  }
}
