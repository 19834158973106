import React, { useContext, useReducer, useState } from 'react'
import { Flex, Modal, Tab, Tooltip } from '../common'
import { IDropdownOption } from '../common/Atoms/Dropdown'
import Reports from './Reports'
import ReportingDistribution from './ReportingDistribution'
import {
  reportingInitialState,
  reportingReducer,
} from '../../reducers/TTDReducers/reportingReducer'
import SaveReportModal from './SaveReportModal'
import { AssignTrainingContext } from '../../context/AssignTrainingContext'
import { handlePopulateStateFromSavedReport } from './functions'
import { handleSetSelectedIdsIfAnyGroupsWereSaved } from '../../actions/reporting'
import { GroupsContext } from '../../context/GroupsContext'

const Reporting = () => {
  const { selectedIds, setSelectedIds } = useContext(AssignTrainingContext)
  const { assignments, setAssignments } = useContext(GroupsContext)
  const [state, dispatch] = useReducer(reportingReducer, reportingInitialState)
  const [selectedTab, setSelectedTab] = useState<string>('reporting')
  const [showSaveReport, setShowSaveReport] = useState<boolean>(false)

  const tabList: IDropdownOption[] = [
    { value: 'reporting', display: 'Reports', id: '0' },
    { value: 'reportingDistribution', display: 'Saved Reports', id: '1' },
  ]
  const handleShowSaveReport = async (
    runReport: boolean = false,
    payload: any = {},
    state: any,
  ) => {
    if (runReport) {
      setSelectedTab('reporting')
      if (payload?.departmentGroupIds && payload?.departmentGroupIds.length) {
        await handleSetSelectedIdsIfAnyGroupsWereSaved({
          payload,
          selectedIds,
          setSelectedIds,
        })
      }
      handlePopulateStateFromSavedReport(payload, dispatch, state)
    }
    if (!runReport && showSaveReport) {
      dispatch({ type: 'setEditSavedReport', payload: {} })
    }
    setShowSaveReport(!showSaveReport)
  }

  const renderSelectedTab = (selectedTab: string) => {
    switch (selectedTab) {
      case 'reporting':
        return (
          <Reports state={state} dispatch={dispatch} handleShowSaveReport={handleShowSaveReport} />
        )
      case 'reportingDistribution':
        return (
          <ReportingDistribution
            state={state}
            dispatch={dispatch}
            handleShowSaveReport={handleShowSaveReport}
          />
        )
    }
  }
  return (
    <Flex config={['column']} style={{ height: '100%' }}>
      <Flex config={['alignCenter']} style={{ marginBottom: 25 }}>
        {tabList.map(({ value, display }: IDropdownOption) => (
          <Tab
            onTabSelect={(tab) => {
              setSelectedTab(tab)
            }}
            key={value}
            display={display}
            value={value}
            isSelected={selectedTab === value}
          />
        ))}
      </Flex>
      <Flex style={{ height: '100%', overflow: 'scroll' }}>{renderSelectedTab(selectedTab)}</Flex>
      {showSaveReport && (
        <Modal close={() => handleShowSaveReport(false, {}, {})}>
          <SaveReportModal
            selectedIds={selectedIds}
            state={state}
            handleClose={handleShowSaveReport}
          />
        </Modal>
      )}
    </Flex>
  )
}

export default Reporting
