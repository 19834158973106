import React, { useState } from 'react'
import { ITrainingDialogConfigProps } from '../../../../../interfaces'
import { Flex } from '../../../../common'
import { Fusion } from '../../Forms'
import { checkPermissions } from '../../../../../utils'

const FusionConfig = ({
  config,
  dispatch,
  audioOptions,
  audioConfig,
  popToast,
}: {
  config: Record<string, ITrainingDialogConfigProps>
  dispatch: any
  audioOptions: any
  audioConfig: any
  popToast: any
}) => {
  const permission = checkPermissions('flex', 'edit')

  const handleChange = (key: string, value: {}, keyMap: string) => {
    if (!permission) popToast('You do not have permission to change Flex Configurations', 'error')
    const updatedConfig: Record<string, any> = { ...config }
    if (key === 'audioAssigned') {
      updatedConfig[key] = { ...updatedConfig[key], value }
    } else {
      updatedConfig[key] = { ...updatedConfig[key], ...value }
    }
    dispatch({ type: 'setFusionConfig', payload: { ...config, ...updatedConfig } })
    dispatch({ type: 'setKeyMap', payload: { [keyMap]: Object.values(value)[0] } })
  }
  return (
    <React.Fragment>
      <Flex config={['row', 'wrap']} style={{ padding: 5 }}>
        <Fusion
          config={config}
          audioOptions={audioOptions}
          audioConfig={audioConfig}
          handleChange={handleChange}
        />
      </Flex>
    </React.Fragment>
  )
}

export default FusionConfig
