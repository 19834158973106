import React, { ChangeEvent } from 'react'
import { Input, FormLabel } from '../../index'

const Duration = ({ trainingLength, handleInput }: any) => {
  return (
    <FormLabel id="duration" value="Duration (Minutes)">
      <Input
        style={{ width: 170 }}
        type="rolled"
        value={trainingLength}
        handleChange={handleInput}
      >
        min
      </Input>
    </FormLabel>
  )
}

export default Duration
