import React, {
  createContext,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react'
import {
  getAssignments,
  getAutoAssignments,
  getEmpGroupsData,
  getGroups,
  getTrainingBundles,
  mapAssignments,
  getAssignmentsForTraining,
} from '../actions'
import { getTrainings } from '../actions/trainings'
import { IDropdownOption } from '../components/common/Atoms/Dropdown'
import { IMappedAssignments } from '../interfaces'
import { mapTrainingToAutoAssignments, mapTrainingToBundle } from '../utils/index'
import { RouteContext } from './RouteContext'

interface GroupsContextProps {
  bundles: any[]
  loadAutoAssignments: any
  loadBundles: any
  setBundles: Dispatch<SetStateAction<any>>
  assignments: any[]
  setAssignments: Dispatch<SetStateAction<any>>
  setTrainingGroups: Dispatch<SetStateAction<any>>
  empGroups: any[]
  autoAssignments: any[]
  getAssignmentsForBundleContext: (x: string) => IMappedAssignments[]
  getAssignmentsForTrainingContext: (x: string) => IMappedAssignments[]
  trainings: any[]
  loading: boolean
  assignmentsLoading: boolean
  optimisticallyDeleteAssignment: (x: string[]) => void
  optimisticallyUpdateTrainingAssignment: (x: string, y: any) => void
  optimisticallyUpdateAssignmentDates: (x: any) => void
  optimisticallyUpdateAssignmentStatus: (x: any) => void
  setLoading: Dispatch<SetStateAction<boolean>>
  trainingGroups: any[]
  setEmpGroups: Dispatch<SetStateAction<any>>
  trainingAspectData: IDropdownOption[]
  trainingGroupsAspectData: IDropdownOption[]
  empGroupsAspectData: IDropdownOption[]
  setAutoAssignments: Dispatch<SetStateAction<any>>
  setTrainings: Dispatch<SetStateAction<any>>
  setTrainingAspectData: Dispatch<SetStateAction<IDropdownOption[]>>
  setTrainingGroupsAspectData: Dispatch<SetStateAction<IDropdownOption[]>>
}

export const GroupsContext = createContext<GroupsContextProps>({
  assignments: [],
  assignmentsLoading: false,
  autoAssignments: [],
  bundles: [],
  empGroups: [],
  empGroupsAspectData: [],
  getAssignmentsForBundleContext: () => [],
  getAssignmentsForTrainingContext: () => [],
  loading: false,
  loadAutoAssignments: () => null,
  loadBundles: () => null,
  optimisticallyDeleteAssignment: () => null,
  optimisticallyUpdateTrainingAssignment: () => null,
  optimisticallyUpdateAssignmentDates: () => null,
  optimisticallyUpdateAssignmentStatus: () => null,
  setLoading: () => false,
  setAssignments: () => null,
  setAutoAssignments: () => null,
  setBundles: () => null,
  setEmpGroups: () => null,
  setTrainings: () => null,
  setTrainingAspectData: () => null,
  setTrainingGroups: () => null,
  setTrainingGroupsAspectData: () => null,
  trainings: [],
  trainingAspectData: [],
  trainingGroupsAspectData: [],
  trainingGroups: [],
})

const GroupsProvider = ({ children }: { children: any }) => {
  const [assignments, setAssignments] = useState<any[]>([])
  const [assignmentsLoading, setAssignmentsLoading] = useState<boolean>(false)
  const [autoAssignments, setAutoAssignments] = useState<any[]>([])
  const [bundles, setBundles] = useState<any[]>([])
  const [empGroups, setEmpGroups] = useState<any[]>([]) // all employee groups
  const [empGroupsAspectData, setEmpGroupsAspectData] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [trainings, setTrainings] = useState<any[]>([])
  const [trainingAspectData, setTrainingAspectData] = useState<any[]>([])
  const [trainingGroups, setTrainingGroups] = useState<any[]>([]) // all training groups
  const [trainingGroupsAspectData, setTrainingGroupsAspectData] = useState<any[]>([])
  const [trainingAssignments, setTrainingAssignments] = useState<any[]>()
  const { isAdmin } = useContext(RouteContext)
  const hash: Record<string, any> = {}

  for (const item in trainingGroups) {
    hash[trainingGroups[item].groupId] = trainingGroups[item].groupName || 'undefined'
  }

  const optimisticallyDeleteAssignment = (deleted: string[]) => {
    const updated = assignments.filter((assignment) => !deleted.includes(assignment.assignmentId))
    setAssignments(updated)
  }

  useEffect(() => {
    if (isAdmin) {
      ;(async () => {
        try {
          await getTrainings(setTrainings, setTrainingAspectData, setLoading)
        } catch (e) {
          console.error(e)
        }
      })()
    }
  }, [isAdmin])

  useEffect(() => {
    if (isAdmin) {
      ;(async () => {
        try {
          setAssignmentsLoading(true)
          const asssignmentLoad = await getAssignments()
          setAssignments(asssignmentLoad || [])
        } catch (e) {
          console.error(e)
        } finally {
          setAssignmentsLoading(false)
        }
      })()
    }
  }, [isAdmin])

  useEffect(() => {
    if (isAdmin) {
      ;(async () => {
        try {
          await getGroups(
            setTrainingGroups,
            {
              queryStringParameters: {
                table: 'Groups',
              },
            },
            setTrainingGroupsAspectData,
          )
        } catch (e) {
          console.error(e)
        }
      })()
    }
  }, [isAdmin])

  useEffect(() => {
    if (isAdmin) {
      if (empGroups.length === 0) {
        ;(async () => {
          try {
            await getGroups(
              setEmpGroups,
              {
                queryStringParameters: {
                  table: 'EmpGroups',
                },
              },
              setEmpGroupsAspectData,
            )
          } catch (e) {
            console.error(e)
          }
        })()
      }
    }
  }, [empGroups, isAdmin])

  useEffect(() => {
    if (isAdmin) {
      ;(async () => {
        const allBundles = await loadBundles()
        return setBundles(allBundles || [])
      })()
    }
  }, [trainings, isAdmin])

  useEffect(() => {
    if (isAdmin) {
      ;(async () => {
        return await loadAutoAssignments()
      })()
    }
  }, [trainings, isAdmin])

  const loadBundles = async () => {
    try {
      return await getTrainingBundles()
    } catch (err) {
      console.error(err)
      return
    }
  }

  const loadAutoAssignments = async () => {
    try {
      const justAutoAssignments = await getAutoAssignments()
      const mappedTrainingsToAutoAssignments = await justAutoAssignments.map(
        (autoAssignment: any) => mapTrainingToAutoAssignments(autoAssignment)(trainings),
      )
      const promises: any[] = []

      mappedTrainingsToAutoAssignments.forEach((autoAssignment: any) => {
        if (autoAssignment?.empGroupIds?.length) {
          promises.push(getEmpGroupsData(autoAssignment?.empGroupIds))
        }
      })

      const allEmpGroupsForAutoAssignments = await Promise.all(promises)

      const mapEmpGroupsToAutoAssignment = mappedTrainingsToAutoAssignments.map(
        (autoAssignment: any) => {
          const empGroupsForThisAutoAssignment = allEmpGroupsForAutoAssignments
            .flat()
            .filter((empGroup: any) => {
              return (
                autoAssignment.empGroupIds && autoAssignment.empGroupIds.includes(empGroup?.groupId)
              )
            })

          autoAssignment.employeeGroups =
            empGroupsForThisAutoAssignment.map((empGroup: any) => empGroup?.groupName).join(', ') ||
            ''
          return autoAssignment
        },
      )

      const filteredEmpIds = mapEmpGroupsToAutoAssignment.map((each: any) => {
        const uniqueObjects = [...new Set(each['employeeGroups'].split(', '))]
        each['employeeGroups'] = uniqueObjects.join(',')
        return each
      })
      return setAutoAssignments(filteredEmpIds)
    } catch (err) {
      console.error(err)
      return
    }
  }

  const getAssignmentsForTrainingContext = (trainingId: string) =>
    assignments.filter((assignment) => assignment.trainingId === trainingId)

  const getAssignmentsForBundleContext = (bundleId: string) => {
    return assignments.filter((assignment) => assignment.trainingBundleId === bundleId)
  }

  const optimisticallyUpdateTrainingAssignment = (trainingId: string, updatedTrainingData: any) => {
    const updatedAssignments = assignments.map((assignment) => {
      if (assignment?.trainingId.includes(trainingId)) {
        Object.assign(assignment, updatedTrainingData)
      }
      return assignment
    })
    setAssignments(updatedAssignments)
  }

  const optimisticallyUpdateAssignmentDates = (assignmentDatesToUpdate: any) => {
    const newDates = assignmentDatesToUpdate
      .reduce((acc: any, cur: any) => {
        const dueDate = new Date(new Date(cur.startDate).toISOString())
        dueDate.setDate(dueDate.getDate() + Number(cur.dueDays))
        const endDate = new Date(new Date(cur.startDate).toISOString())
        endDate.setDate(endDate.getDate() + Number(cur.endDays))
        const updateAssignments = cur.empId.map((empId: any) => {
          return {
            assignmentId: `${cur.trainingId}:${empId}`,
            startDate: cur.startDate,
            dueDays: cur.dueDays,
            dueDate: dueDate.toISOString(),
            endDate: endDate.toISOString(),
            endDays: cur.endDays,
          }
        })
        acc.push(updateAssignments)
        return acc
      }, [])
      .flat()

    const updatedAssignments = assignments.map((assignments: any) => {
      const found = newDates.find((date: any) => date.assignmentId === assignments.assignmentId)
      if (found) {
        Object.assign(assignments, found)
      }
      return assignments
    })
    setAssignments(updatedAssignments)
  }

  const optimisticallyUpdateAssignmentStatus = (assignmentData: any) => {
    const listOfAssignmentIds: string[] = []
    assignmentData.forEach(
      ({ empId, trainingId }: { empId: string | string[]; trainingId: string }) => {
        if (typeof empId === 'string') {
          listOfAssignmentIds.push(`${trainingId}:${empId}`)
        } else {
          empId.forEach((_empId) => {
            listOfAssignmentIds.push(`${trainingId}:${_empId}`)
          })
        }
      },
    )
    const updatedAssignments = assignments.map((assignment) => {
      const { assignmentId }: { assignmentId: string } = assignment
      const isInAssignments: boolean = listOfAssignmentIds.includes(assignmentId)
      if (isInAssignments) {
        assignment.status = 'Incomplete'
        delete assignment.timeOfCompletion
      }
      return assignment
    })
    setAssignments(updatedAssignments)
  }

  return (
    <GroupsContext.Provider
      value={{
        autoAssignments,
        assignments,
        bundles,
        empGroups,
        empGroupsAspectData,
        getAssignmentsForBundleContext,
        getAssignmentsForTrainingContext,
        loading,
        loadAutoAssignments,
        loadBundles,
        optimisticallyDeleteAssignment,
        optimisticallyUpdateTrainingAssignment,
        optimisticallyUpdateAssignmentDates,
        optimisticallyUpdateAssignmentStatus,
        trainings,
        trainingGroups,
        trainingAspectData,
        trainingGroupsAspectData,
        setAssignments,
        setAutoAssignments,
        setBundles,
        setEmpGroups,
        setLoading,
        setTrainings,
        setTrainingGroups,
        setTrainingAspectData,
        setTrainingGroupsAspectData,
        assignmentsLoading,
      }}
    >
      {children}
    </GroupsContext.Provider>
  )
}

export default GroupsProvider
