import React from 'react'
import { ButtonSet, Flex } from '../../index'

interface IConfirm {
  handleCancel: () => void
  handleConfirm: () => void
}
const Confirm = ({ handleCancel, handleConfirm }: IConfirm) => {
  return (
    <Flex config={['column', 'justifyBetween']} style={{ height: '100%' }}>
      <Flex config={['justifyCenter', 'alignCenter']} style={{ height: '100%' }}>
        <span style={{ display: 'block', marginBottom: '25px' }}>
          You are attempting to navigate away, all unsaved changes will be lost, continue?
        </span>
      </Flex>
      <Flex>
        <ButtonSet
          formValid={true}
          handleSave={handleConfirm}
          handleCancel={handleCancel}
          labels={['Cancel', 'Confirm']}
        />
      </Flex>
    </Flex>
  )
}

export default Confirm
