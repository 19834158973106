import React, { ReactElement, SyntheticEvent } from 'react'
import { CustomDateRange, Dropdown, Flex, FormLabel, Input, LookupInput } from '../../common'
import SideBar from '../../Trainings/TrainingAssignmentModal/SideBar'
import { optionConfig } from '../../../constants'

const ReportForm = (props: any) => {
  const handleEmpIdInput = (e: SyntheticEvent<HTMLInputElement>) => {
    if (e.currentTarget.value === '') {
      props.dispatch({ type: 'setEmpIdsForReport', payload: [] })
      props.dispatch({ type: 'setEmpIdValue', payload: e.currentTarget.value })
    }
    if (e.currentTarget.value.length >= 6 && /\d{0,}/g.test(e.currentTarget.value)) {
      const empIdsAsArray = e.currentTarget.value.split(/\D/g).filter((empId) => empId)
      props.dispatch({ type: 'setEmpIdValue', payload: e.currentTarget.value })
      props.dispatch({ type: 'setEmpIdsForReport', payload: empIdsAsArray })
    } else {
      props.dispatch({ type: 'setEmpIdsForReport', payload: [] })
      props.dispatch({ type: 'setEmpIdValue', payload: e.currentTarget.value })
    }
  }

  const handleEmpNamesInput = (name: string, action: string) => {
    let namesToDispatch = props.empNameSelection || []
    if (action === 'remove') {
      namesToDispatch = props.empNameSelection.filter((selected: string) => name !== selected)
      props.dispatch({ type: 'setEmpNameSelection', payload: namesToDispatch })
    } else {
      props.dispatch({ type: 'setEmpNameSelection', payload: [...namesToDispatch, name] })
    }
  }

  const { config = [] } = props
  const { dateKeyOptions, opsHierarchyOptions, dateHierarchyOptions, employeeStatusOptions } =
    optionConfig
  const renderComponentByConfigKey = (key: string) => {
    const options: Record<string, ReactElement> = {
      date: <CustomDateRange dateIncrement={true} key={key} {...props} />,
      empId: (
        <FormLabel key={key} id="userEventAuditEmpIdInput" value="Enter Employee Id(s)">
          <Input value={props.empIdValue} handleChange={handleEmpIdInput} />
        </FormLabel>
      ),
      training: (
        <FormLabel key={key} id="ttd-training" value="Training">
          <Dropdown
            multipleSelect={true}
            isFilter={true}
            options={props.trainings}
            selected={props.trainingsCount}
            onFilterSelect={(e: any) => props.handleMultipleSelect(e, 'trainings')}
          />
        </FormLabel>
      ),
      dateKey: (
        <FormLabel key={key} id="ttd-date-key" value="Date Key">
          <Dropdown
            options={dateKeyOptions}
            selected={props.dateKey}
            emitDropdownOption={(e: any) => props.handleSelect(e, 'dateKey')}
          />
        </FormLabel>
      ),
      tag: (
        <FormLabel key={key} id="ttd-tags" value="Tags">
          <Dropdown
            multipleSelect={true}
            isFilter={true}
            options={props.tags}
            selected={props.tagCount}
            onFilterSelect={(e: any) => props.handleMultipleSelect(e, 'tags')}
          />
        </FormLabel>
      ),
      status: (
        <FormLabel key={key} id="ttd-status" value="Status">
          <Dropdown
            multipleSelect={true}
            isFilter={true}
            options={props.status}
            selected={props.statusCount}
            onFilterSelect={(e: any) => props.handleMultipleSelect(e, 'status')}
          />
        </FormLabel>
      ),
      department: (
        <Flex
          key={key}
          style={{ maxHeight: 400, overflow: 'auto', width: '100%', marginBottom: 15 }}
        >
          <FormLabel id="ttd-department" value="Department">
            <SideBar />
          </FormLabel>
        </Flex>
      ),
      time: (
        <Flex key={key}>
          <FormLabel id="ttd-from-time" value="From">
            <input
              type="time"
              onChange={(e: SyntheticEvent<HTMLInputElement>) =>
                props.dispatch({ type: 'setFromTime', payload: e.currentTarget.value })
              }
              value={props.fromTime}
            />
          </FormLabel>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          <FormLabel id="ttd-to-time" value="To">
            <input
              type="time"
              onChange={(e: SyntheticEvent<HTMLInputElement>) =>
                props.dispatch({ type: 'setToTime', payload: e.currentTarget.value })
              }
              value={props.toTime}
            />
          </FormLabel>
        </Flex>
      ),
      opsHierarchy: (
        <FormLabel key={key} id="ttd-date-key" value="Ops Hierarchy">
          <Dropdown
            options={opsHierarchyOptions}
            selected={props.opsHierarchy}
            emitDropdownOption={(e: any) => props.handleSelect(e, 'opsHierarchy')}
          />
        </FormLabel>
      ),
      employeeStatus: (
        <FormLabel key={key} id="ttd-employee-status" value="Employee Status">
          <Dropdown
            options={employeeStatusOptions}
            selected={props.employeeStatus}
            emitDropdownOption={(e: any) => props.handleSelect(e, 'employeeStatus')}
          />
        </FormLabel>
      ),
      dateHierarchy: (
        <FormLabel key={key} id="ttd-date-hierarchy" value="Date Hierarchy">
          <Dropdown
            options={dateHierarchyOptions}
            selected={props.dateHierarchy}
            emitDropdownOption={(e: any) => props.handleSelect(e, 'dateHierarchy')}
          />
        </FormLabel>
      ),
      mascot: (
        <FormLabel key={key} id="ttd-mascot" value="Mascot">
          <Dropdown
            multipleSelect={true}
            isFilter={true}
            options={props.mascot}
            selected={props.mascotCount}
            onFilterSelect={(e: any) => props.handleMultipleSelect(e, 'mascot')}
          />
        </FormLabel>
      ),
      site: (
        <FormLabel key={key} id="ttd-site" value="Site">
          <Dropdown
            multipleSelect={true}
            isFilter={true}
            options={props.site}
            selected={props.siteCount}
            onFilterSelect={(e: any) => props.handleMultipleSelect(e, 'site')}
          />
        </FormLabel>
      ),
      businessUnit: (
        <FormLabel key={key} id="ttd-status" value="Business Unit">
          <Dropdown
            multipleSelect={true}
            isFilter={true}
            options={props.businessUnits}
            selected={props.businessUnitsCount}
            onFilterSelect={(e: any) => props.handleMultipleSelect(e, 'businessUnit')}
          />
        </FormLabel>
      ),
      name: (
        <FormLabel key={key} id="ttd-name" value="Name(s)">
          <LookupInput selections={props.empNameSelection} handleSelect={handleEmpNamesInput} />
        </FormLabel>
      ),
    }
    return options[key]
  }
  return (
    <Flex config={['column']}>{config.map((key: string) => renderComponentByConfigKey(key))}</Flex>
  )
}

export default ReportForm
