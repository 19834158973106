import React, { useEffect, useState, useContext } from 'react'
import { RouteContext } from '../../../context/RouteContext'
import '@asurion/sass-design-system/build/asurion/asurion.css'
import '@asurion/atomic-ui-library'
import { ToastContainer, toast } from 'react-toastify'
import { Button, FormLabel, Flex, IconComponent, Input, Modal } from '../../common/index'
import { DeleteAutoAssignmentModal, GroupAssignmentModal, TrainingModal } from './Modals'
import {
  del,
  getEmpGroupsData,
  updateAutoAssignment,
  updateAutoAssignmentEMPGroupLogic,
  updateAutoAssignmentTraining,
  getCurrentAutoAssignment,
} from '../../../actions'
import { checkPermissions, handleInput } from '../../../utils'
import UseDebounce from '../../../hooks/UseDebounce'
import AssignedGroups from './AssignedGroups'
import { AssignTrainingContext, IData } from '../../../context/AssignTrainingContext'
import { GroupsContext } from '../../../context/GroupsContext'
import { ContainerFlex, DeleteAutoAssignmentFlex, TrainingContainerFlex } from './styles'
import { IAutoAssignment, IMappedTraining } from '../../../interfaces'

const AutoAssignment = () => {
  const { handleNavigation, selectedLinkProps, hasEdited, setHasEdited } = useContext(RouteContext)
  const { loadAutoAssignments } = useContext(GroupsContext)
  const { selectedIds, resetAllChecks, setSelectedIds } = useContext(AssignTrainingContext)
  const autoAssignment = selectedLinkProps

  const [autoAssignmentName, setAutoAssignmentName] = useState<string>(
    autoAssignment.autoAssignmentName || '',
  )
  const [isActive, setIsActive] = useState('1')
  const [automationType, setAutomationType] = useState('1')
  const [dueDays, setDueDays] = useState('')
  const [endDays, setEndDays] = useState('')

  const [trainings, setTrainings] = useState<IMappedTraining[]>(
    autoAssignment.autoAssignmentTrainings || [],
  )
  const [showAutoAssignmentDelete, setShowAutoAssignmentDelete] = useState(false)
  const [showAddTrainingModal, setShowAddTrainingModal] = useState<boolean>(false)
  const [showAssignmentModal, setShowAssignmentModal] = useState<boolean>(false)
  const debouncedNameValue = UseDebounce(autoAssignmentName, 750)
  const debouncedAutomativeValue = UseDebounce(automationType, 750)
  const debouncedDueDaysValue = UseDebounce(dueDays, 750)
  const debouncedEndDaysValue = UseDebounce(endDays, 750)
  const debouncedIsActiveValue = UseDebounce(isActive, 750)

  const [empGroupIds, setEmpGroupIds] = useState<string[]>(autoAssignment.empGroupIds || [])
  const [selectedGroupsData, setSelectedGroupsData] = useState<IData[]>([])

  const popToast = (msg: string) => toast(msg)

  useEffect(() => {
    updateAutoAssignmentState(autoAssignment)
    if (empGroupIds && empGroupIds.length > 0) {
      mapIdsToSelectedIds(empGroupIds)
    }
  }, [autoAssignment])

  useEffect(() => {
    if (selectedIds.size > 0) {
      const ids = Array.from([...selectedIds.values()]).flat()
      mapIdsToSelectedIds(ids)
      updateAutoAssignmentState(autoAssignment)
    }
  }, [selectedIds])

  const handleUpdate = async () => {
    const options = {
      autoAssignmentName: debouncedNameValue,
      automationType: debouncedAutomativeValue,
      dueDays: debouncedDueDaysValue,
      endDays: debouncedEndDaysValue,
      isActive: debouncedIsActiveValue,
    }
    try {
      await updateAutoAssignment(options, autoAssignment.assignmentRuleId).then(async () => {
        return await loadAutoAssignments()
      })
      setHasEdited(false)
      popToast('Successfully Saved')
    } catch (ex) {
      console.error('Error saving: ', ex)
      popToast('Error Saving.  See log')
      return
    }
  }

  /**
   * Updates state of component based off autoAssignment
   * updates the following in state:
   * autoAssignmentName,
   * automationType,
   * dueDays,
   * empGroupIds,
   * endDays,
   * isActive,
   * trainings,
   * @param autoAssignment destructured from selectedLinkProps
   */
  const updateAutoAssignmentState = (autoAssignment: IAutoAssignment) => {
    const {
      autoAssignmentName = '',
      automationType = '',
      dueDays = '',
      empGroupIds = [],
      endDays = '',
      isActive = '0',
      trainings = [],
    } = autoAssignment

    setAutoAssignmentName(autoAssignmentName)
    setAutomationType(automationType)
    setEmpGroupIds(empGroupIds)
    setDueDays(dueDays)
    setEndDays(endDays)
    setIsActive(isActive)
    setTrainings(trainings)
  }

  /**
   * resets selectedIds and selectedIGroupData based in ids
   * @param ids array of groupIds
   */
  const mapIdsToSelectedIds = async (ids: string[] = []) => {
    try {
      const empGroupsData = (await getEmpGroupsData(ids)) || []
      const mappedGroups = empGroupsData.map(
        ({ groupId, groupName, parentGroupId, groupTreeId }: IData) => ({
          groupId,
          groupName,
          parentGroupId,
          groupTreeId,
        }),
      )

      for (const group of mappedGroups) {
        const { groupTreeId, groupId } = group
        if (selectedIds.get(groupTreeId)) {
          const existingIds = selectedIds.get(groupTreeId) || []
          selectedIds.set(groupTreeId, [...new Set([...existingIds, groupId])])
        } else {
          selectedIds.set(groupTreeId, [groupId])
        }
      }
      await setSelectedGroupsData(mappedGroups)

      const existingGroupTreeIds = mappedGroups.map(
        ({ groupTreeId }: { groupTreeId: string }) => groupTreeId,
      )
      const selectedIdsKeys = Array.from(selectedIds.keys())

      const selectedIdsToRemove = selectedIdsKeys.filter(
        (key) => !existingGroupTreeIds.includes(key),
      )
      // removed selectedIds no being mapped
      if (selectedIdsToRemove.length > 0) {
        selectedIdsToRemove.forEach((id) => {
          selectedIds.delete(id)
        })
      }
      setSelectedIds(selectedIds)
    } catch (error) {
      console.log('Error while getting EMP Group Data: ', error)
    }
  }

  const deleteTraining = async (trainingId: string) => {
    if (trainings.length > 0 && trainingId) {
      try {
        const { assignmentRuleId } = autoAssignment
        const trainingsToKeep = trainings.filter(
          ({ trainingId: id }: { trainingId: string }) => id !== trainingId,
        )
        const updatedTrainingIds = trainingsToKeep.map(
          ({ trainingId }: { trainingId: string }) => trainingId,
        )
        const deduplicatedTrainingIds = [...new Set(updatedTrainingIds)]
        await updateAutoAssignmentTraining(assignmentRuleId, deduplicatedTrainingIds).then(
          async () => {
            await loadAutoAssignments()
            setTrainings(trainingsToKeep)
          },
        )
        popToast('Successfully Saved')
        setHasEdited(false)
      } catch (err) {
        console.log('Error deleting training: ', err)
      }
    }
  }
  /**
   * updates training table in add training modal
   * @param id assignmentRuleId
   */
  const handleAutoAssignmentTrainingUpdate = async (data: IMappedTraining[]) => {
    setTrainings([...trainings, ...data])
    setShowAddTrainingModal(false)
    popToast('Successfully Saved')
  }

  /**
   * Refreshes data for all autoAssingments and resets state for autoAssignment of id passed
   * @param id of autoAssignment that is being updated
   */
  const updateThisAssignmentRule = async () => {
    const { assignmentRuleId } = autoAssignment
    await getCurrentAutoAssignment(assignmentRuleId).then(async (res) => {
      await mapIdsToSelectedIds(res[0].empGroupIds)
      await updateAutoAssignmentState(res[0])
    })
  }

  const handleAssignedGroupsUpdate = async (data: string[][] = []) => {
    try {
      await updateAutoAssignmentEMPGroupLogic(autoAssignment.assignmentRuleId, data).then(
        async (res) => {
          const payload = await JSON.parse(res.results[0].body)
          const {
            response: {
              Attributes: { empGroupIdLogic = [] },
            },
          } = payload
          popToast('Successfully Saved')
          setTrainings(trainings)
          setShowAssignmentModal(false)
          const ids = data.flat()
          mapIdsToSelectedIds(ids)
        },
      )
    } catch (err) {
      console.log('Error updating employee group: ', err)
    }
  }

  const handleDeleteAutoAssignment = async () => {
    setHasEdited(false)
    setShowAutoAssignmentDelete(false)
    await del('autoAssignments', [autoAssignment])
    popToast('Successfully Saved')
    handleNavigate()
    return
  }

  const handleNavigate = async () => {
    await loadAutoAssignments().then(() => {
      setSelectedIds(new Map())
      setSelectedGroupsData([])
      handleNavigation('autoAssignments')
    })
  }

  const permission = checkPermissions('autoassignments', 'edit')
  return (
    <ContainerFlex config={['column']}>
      <ToastContainer position="bottom-right" />
      <button
        className={'navigate-back-button ttd-text-link ttd-bare-button'}
        onClick={handleNavigate}
      >
        &lt; Back to Auto Assignments
      </button>
      <Flex className={'top-section'}>
        <Flex style={{ width: '50%' }} config={['column']}>
          <FormLabel id="autoAssignmentName" value="Name">
            <Input
              value={autoAssignmentName}
              type={'rolled'}
              handleChange={(e) => {
                handleInput(e, setAutoAssignmentName)
                setHasEdited(true)
              }}
            />
          </FormLabel>
          <FormLabel id="status" value="Status">
            <button
              className={'ttd-bare-button'}
              style={{ display: 'flex', cursor: 'pointer', width: 170 }}
              onClick={() => {
                setIsActive('1')
                setHasEdited(true)
              }}
            >
              <IconComponent
                icon={isActive === '1' ? 'radio_button_checked' : 'radio_button_unchecked'}
              />
              <span style={{ marginLeft: 15 }}>Active</span>
            </button>
            <button
              className={'ttd-bare-button'}
              style={{ display: 'flex', cursor: 'pointer', width: 170 }}
              onClick={() => {
                setIsActive('0')
                setHasEdited(true)
              }}
            >
              <IconComponent
                icon={isActive === '0' ? 'radio_button_checked' : 'radio_button_unchecked'}
              />
              <span style={{ marginLeft: 15 }}>Inactive</span>
            </button>
          </FormLabel>
        </Flex>
        <Flex style={{ width: '50%', paddingLeft: '24px' }} config={['column']}>
          <FormLabel id="automationType" value="Automation type">
            <button
              className={'ttd-bare-button'}
              style={{ display: 'flex', cursor: 'pointer', width: 170 }}
              onClick={() => {
                setAutomationType('1')
                setHasEdited(true)
              }}
            >
              <IconComponent
                icon={automationType === '1' ? 'radio_button_checked' : 'radio_button_unchecked'}
              />
              <span style={{ marginLeft: 15 }}>New</span>
            </button>
            <button
              className={'ttd-bare-button'}
              style={{ display: 'flex', cursor: 'pointer', width: 170 }}
              onClick={() => {
                setAutomationType('2')
                setHasEdited(true)
              }}
            >
              <IconComponent
                icon={automationType === '2' ? 'radio_button_checked' : 'radio_button_unchecked'}
              />
              <span style={{ marginLeft: 15 }}>All</span>
            </button>
          </FormLabel>
          <FormLabel id="dueDays" value="Due Days">
            <Input
              style={{ width: '50%' }}
              value={dueDays}
              type={'rolled'}
              handleChange={(e) => {
                handleInput(e, setDueDays)
                setHasEdited(true)
              }}
            />
          </FormLabel>
          <FormLabel id="endDays" value="End Days">
            <Input
              style={{ width: '50%' }}
              value={endDays}
              type={'rolled'}
              handleChange={(e) => {
                handleInput(e, setEndDays)
                setHasEdited(true)
              }}
            />
          </FormLabel>
        </Flex>
        <Flex style={{ width: '100%' }} config={['column']}>
          <h5>
            <b>Trainings</b>
          </h5>
          <Flex style={{ marginLeft: '0.5rem' }} config={['column']}>
            {trainings &&
              trainings.map((training, i) => (
                <TrainingContainerFlex key={training?.trainingId + i}>
                  <p>{training?.trainingName}</p>
                  {permission && (
                    <button
                      className={'ttd-bare-button'}
                      onClick={() => {
                        deleteTraining(training?.trainingId)
                      }}
                    >
                      <IconComponent className={'delete-training-icon'} icon="close" />
                    </button>
                  )}
                </TrainingContainerFlex>
              ))}
            {permission && (
              <Button
                displayType={'outline'}
                size={'sm'}
                handleClick={() => setShowAddTrainingModal(true)}
              >
                Add Training
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
      {permission && (
        <DeleteAutoAssignmentFlex>
          <Flex>
            <div className={'button-wrapper'}>
              <Button
                handleClick={() => {
                  setShowAutoAssignmentDelete(true)
                }}
                displayType="ghost"
                size="lg"
                style={{ width: '100%' }}
              >
                Delete Auto Assignment
              </Button>
            </div>
          </Flex>
        </DeleteAutoAssignmentFlex>
      )}
      <Flex config={['column']} style={{ margin: '25px 0px' }}>
        <h5>Assigned Employee Groups</h5>
        <AssignedGroups
          selectedGroupsData={selectedGroupsData}
          close={() => setShowAssignmentModal(false)}
        />
        {permission && (
          <Button
            handleClick={() => setShowAssignmentModal(true)}
            displayType="outline"
            size="sm"
            style={{ width: '100%' }}
          >
            Edit Group Assignments
          </Button>
        )}
      </Flex>
      {permission && (
        <Button displayType={'flat'} size={'sm'} handleClick={handleUpdate}>
          Save
        </Button>
      )}
      {showAddTrainingModal && (
        <TrainingModal
          toggle={setShowAddTrainingModal}
          options={{ autoAssignment, trainings, handleAutoAssignmentTrainingUpdate }}
        />
      )}
      {showAssignmentModal && (
        <GroupAssignmentModal toggle={setShowAssignmentModal}>
          <AssignedGroups
            update={handleAssignedGroupsUpdate}
            selectedGroupsData={selectedGroupsData}
            close={() => {
              updateThisAssignmentRule()
              setShowAssignmentModal(false)
              resetAllChecks(true)
            }}
            showButtons={true}
          />
        </GroupAssignmentModal>
      )}
      {showAutoAssignmentDelete && (
        <DeleteAutoAssignmentModal
          toggle={(show: boolean) => setShowAutoAssignmentDelete(show)}
          deleteAssignment={handleDeleteAutoAssignment}
        />
      )}
    </ContainerFlex>
  )
}

export default AutoAssignment
