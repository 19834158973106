import React, { useState } from 'react'
import { IMonitorConfig } from '../../../../../interfaces'
import { Flex } from '../../../../common'
import { Monitoring } from '../../Forms'
import { checkInput, checkPermissions } from '../../../../../utils'

const MonitoringConfig = ({
  config,
  dispatch,
  popToast,
}: {
  config: IMonitorConfig
  dispatch: any
  popToast: any
}) => {
  const { warnings, inactivityTime, focusGracePeriod } = config
  const { inactivity, lostfocus } = warnings
  const [formValid, setFormValid] = useState<boolean>(true)
  const permission = checkPermissions('flex', 'edit')

  const handleChange = async (key: string, value: {}, keyMap: string) => {
    if (!permission) popToast('You do not have permission to change Flex Configurations', 'error')
    const validForm = await checkInput(
      keyMap,
      Object.values(value)[0],
      () => {},
      () => {},
      setFormValid,
    )
    if (validForm) {
      const updatedConfig: Record<string, any> = { ...config.warnings }
      updatedConfig[key] = { ...updatedConfig[key], ...value }
      await dispatch({
        type: 'setMonitorConfig',
        payload: { ...config, warnings: { ...updatedConfig } },
      })
      await dispatch({ type: 'setKeyMap', payload: { [keyMap]: Object.values(value)[0] } })
    }
  }

  const lostFocusProps = { ...lostfocus, graceperiod: focusGracePeriod }
  const inactivityProps = { ...inactivity, graceperiod: inactivityTime }
  return (
    <React.Fragment>
      <Flex config={['row', 'wrap']} style={{ padding: 5 }}>
        <Flex config={['column']}>
          <Monitoring
            name={'lostfocus'}
            label={'Lost Focus'}
            {...lostFocusProps}
            handleChange={handleChange}
          />
        </Flex>
        <Flex config={['column']}>
          <Monitoring
            name={'inactivity'}
            label={'Inactivity'}
            {...inactivityProps}
            handleChange={handleChange}
          />
        </Flex>
      </Flex>
    </React.Fragment>
  )
}

export default MonitoringConfig
