import React, { useEffect, useState } from 'react'
import '@asurion/atomic-ui-library'
import '@asurion/sass-design-system/build/asurion/asurion.css'
import '@rmwc/data-table/styles'
import { Dropdown, IconComponent, Search } from '../..'
import { IDropdownOption } from '../../Atoms/Dropdown'
import { useSelector } from 'react-redux'
import themes from '../../../../themes/schema'

interface IFilterBar {
  options: IDropdownOption[]
  searchValue: string
  onFilter: any
  keyToFilter?: IDropdownOption
  setKeyToFilter?: any
  onFilterSelect?: any
  defaultDropdownValue?: any
}

const FilterBar = ({
  onFilterSelect,
  keyToFilter,
  setKeyToFilter,
  options,
  searchValue,
  defaultDropdownValue,
  onFilter,
}: IFilterBar) => {
  useEffect(() => {
    return setFilterPlaceholder(keyToFilter?.display || defaultDropdownValue?.display)
  }, [defaultDropdownValue, keyToFilter])
  const {
    userSettings: { darkMode },
  } = useSelector((state: any) => state?.user)
  const customDisplayType = darkMode ? 'darkMode' : 'originalFlavor'
  const getStyle: Record<string, any> = {
    dropdown_backgroundColor_originalFlavor: themes.originalFlavor.dropdown.backgroundColor,
    dropdown_color_originalFlavor: themes.originalFlavor.dropdown.color,
    dropdown_backgroundColor_darkMode: themes.darkMode.dropdown.backgroundColor,
    dropdown_color_darkMode: themes.darkMode.dropdown.color,
  }
  const [filterPlaceholder, setFilterPlaceholder] = useState<string>(
    keyToFilter?.display || defaultDropdownValue?.display || '',
  )

  return (
    <div style={{ width: '100%', zIndex: 600 }}>
      <div style={{ display: 'flex' }}>
        <Dropdown
          onFilterSelect={onFilterSelect}
          isFilter={true}
          style={{
            borderRadius: '4px 0 0 4px ',
            backgroundColor: getStyle[`dropdown_backgroundColor_${customDisplayType}`],
            color: getStyle[`dropdown_color_${customDisplayType}`],
          }}
          options={options}
          selected={options.find((option) => option.display === filterPlaceholder)!}
          emitDropdownOption={(e) => {
            setKeyToFilter(e)
            setFilterPlaceholder(e.display)
          }}
        >
          <IconComponent className="ttd-placeholder" icon={'search'} />
        </Dropdown>
        <Search
          style={{ borderRadius: '0 4px 4px 0', borderLeft: 'none', width: '100%' }}
          searchValue={searchValue}
          placeholder={`Search by ${filterPlaceholder}`}
          handleInput={(e) => onFilter(e, keyToFilter?.value || '')}
        />
      </div>
    </div>
  )
}

export default FilterBar
