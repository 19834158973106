import React, { useEffect, useContext, useReducer } from 'react'
import { Button, FilterableTable, Flex } from '../common'
import { fusionReportingHeaders } from '../../constants'
import { AssignTrainingContext } from '../../context/AssignTrainingContext'
import { ExpertNumber, ExpertNumberNotification, Selection } from './Modals'
import { fusionInitialState, fusionReducer } from '../../reducers/TTDReducers/fusionReducer'
import { API, graphqlOperation } from 'aws-amplify'
import { onWorkerStatusUpdate } from '../../subscriptions'
import { getFusionExperts } from '../../actions/fusion'

const Fusion = () => {
  const [state, dispatch] = useReducer(fusionReducer, fusionInitialState)
  const {
    reportingState = [],
    showSelectionModal,
    showExpertNumberModal,
    showExpertNumberNotification,
    statusChange,
  }: any = state

  const { resetFusion } = useContext(AssignTrainingContext)
  const dispatcher = (dispatch: any, type: string, payload: any) => {
    dispatch({ type, payload })
  }
  const clearFusion = async () => {
    dispatcher(dispatch, 'setClearChecks', true)
    dispatcher(dispatch, 'setWorkersSelected', [])
    dispatcher(dispatch, 'setPayload', [])
    await resetFusion()
    dispatcher(dispatch, 'setClearChecks', false)
  }
  const formatTaskQueuesForDropdown = (list: []) => {
    const compare = (a: any, b: any) => {
      if (a.taskqueueName < b.taskqueueName) {
        return -1
      }
      if (a.taskqueueName > b.taskqueueName) {
        return 1
      }
      return 0
    }
    const mapTaskQueues = (obj: any) => {
      const { taskqueueName, taskqueueSid } = obj
      return taskqueueName
    }
    return list.sort(compare).map(mapTaskQueues)
  }
  const toggleModal = (modal: string, bool: boolean) => {
    if (modal === 'expertNumberModal') dispatcher(dispatch, 'setShowExpertNumberModal', bool)
    if (modal === 'expertNumberNotification')
      dispatcher(dispatch, 'setShowExpertNumberNotification', bool)
    if (modal === 'selection') {
      dispatcher(dispatch, 'setShowSelectionModal', bool)
      dispatcher(dispatch, 'setAssignmentState', [])
      clearFusion()
    }
  }

  //on load
  useEffect(() => {
    ;(async () => {
      const fusionExperts = await getFusionExperts()
      dispatcher(dispatch, 'setReportingState', fusionExperts)
    })()
  }, [])

  // Real Time Subscription
  useEffect(() => {
    const subscription: any = API.graphql(
      graphqlOperation(onWorkerStatusUpdate, {
        latestAction: 'FusionTransition',
      }),
    )
    if ('subscribe' in subscription) {
      subscription.subscribe({
        next: ({
          value: {
            data: { onAddAEWorker },
          },
        }: any) => {
          dispatcher(dispatch, 'setStatusChange', onAddAEWorker)
        },
      })
    }
    return () => {
      if ('unsubscribe' in subscription) {
        subscription.unsubscribe()
      }
    }
  }, [])

  // Set state for incoming subscription
  useEffect(() => {
    if (reportingState.length) {
      const reportMap: any = reportingState.filter((each: any) => each.empId !== statusChange.empId)
      for (const key in statusChange) {
        if (statusChange[key] === 'null') statusChange[key] = ''
      }
      reportMap.push(statusChange)
      dispatcher(dispatch, 'setReportingState', reportMap)
    }
  }, [statusChange])
  const { taskQueueList, requestedExperts } = state
  return (
    <Flex
      style={{
        height: '100%',
      }}
      config={['column']}
    >
      <Flex config={['column']}>
        <Flex config={['justifyEnd', 'row']}>
          <Flex style={{ margin: '8px' }}>
            <Button
              displayType={'outline'}
              size={'lg'}
              handleClick={() => toggleModal('selection', true)}
            >
              Expert Selection
            </Button>
          </Flex>
          <Flex style={{ margin: '8px' }}>
            {/*Temporarily disabled until deployment ready*/}
            <Button
              // disabled={disabled}
              displayType={'outline'}
              size={'lg'}
              handleClick={() => {
                dispatcher(dispatch, 'setShowExpertNumberModal', true)
              }}
            >
              Number of Experts
            </Button>
          </Flex>
        </Flex>
        <FilterableTable
          width={'100%'}
          data={reportingState}
          headers={fusionReportingHeaders}
          filterIndex={0}
          noExport={true}
          fusion={true}
          reportType={'fusion'}
        />
      </Flex>
      {showSelectionModal && (
        <Selection
          modalActions={{
            clearFusion,
            toggleModal,
          }}
          modalName={'selection'}
          state={state}
          dispatch={dispatch}
        />
      )}
      {showExpertNumberModal && (
        <ExpertNumber
          modalActions={{
            toggleModal,
          }}
          modalName={'expertNumberModal'}
          taskQueueList={taskQueueList}
          requestedExperts={requestedExperts}
          dispatch={dispatch}
        />
      )}
      {showExpertNumberNotification && (
        <ExpertNumberNotification
          modalActions={{ toggleModal }}
          modalName={'expertNumberNotification'}
          state={state}
          dispatch={dispatch}
        />
      )}
    </Flex>
  )
}
export default Fusion
