import * as React from 'react'

const refs = new Set<React.RefObject<any>>()

function setRef<T>(): React.RefObject<T> {
  const ref = React.createRef<T>()
  refs.add(ref)
  return ref
}

function getRefs<T>(): React.RefObject<T>[] {
  return Array.from(refs.values())
    .map((ref: React.RefObject<{ getState: any }>) => ref.current?.getState())
    .filter((ref) => ref)
}

function useDynamicRefs<T>(): [() => React.RefObject<T>[], () => React.RefObject<T>] {
  return [getRefs, setRef]
}

export default useDynamicRefs
