import React, {
  createContext,
  useState,
  useEffect,
  SetStateAction,
  Dispatch,
  useContext,
} from 'react'
import {getChildren, getAspectRoots, getAspectTree, getWorkersForGroup} from '../actions'
import {IDropdownOption, IFilterDropdownOption} from '../components/common/Atoms/Dropdown'
import {sort} from '../utils'
import {RouteContext} from './RouteContext'

interface AssignTrainingProps {
  businessUnits: IFilterDropdownOption[]
  selectedIds: Map<string, string[]>
  // eslint-disable-next-line no-unused-vars
  handleCheckboxClick: (arg: Partial<INode>, action?: string, type?: string) => void
  empGroups: any[]
  root: any[]
  loading: boolean
  // eslint-disable-next-line no-unused-vars
  resetAllChecks: (arg: boolean) => void
  // eslint-disable-next-line no-unused-vars
  setWorkers: Dispatch<SetStateAction<any>>
  // eslint-disable-next-line no-unused-vars
  setShouldGetGroupsOnly: Dispatch<SetStateAction<boolean>>
  // eslint-disable-next-line no-unused-vars
  setEmpGroupsAsTableData: Dispatch<SetStateAction<any[]>>
  // eslint-disable-next-line no-unused-vars
  setSelectedIds: Dispatch<SetStateAction<any>>
  workers: any[]
  supervisorAspectData: IDropdownOption[]
  siteAspectData: IDropdownOption[]
  mascotAspectData: IDropdownOption[]
  empGroupsAsTableData: any[]
  shouldGetGroupsOnly: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  setAllWorkers: Dispatch<SetStateAction<any>>
  setSearchedWorkers: Dispatch<SetStateAction<any>>
  setAspectTree: Dispatch<SetStateAction<any[]>>
  aspectTree: any[]
  resetFusion: () => void
  broadcastData: any[]
  setBroadcastData: Dispatch<SetStateAction<any>>
}

export interface IData {
  groupId: string
  groupName: string
  groupTreeId: string
  parentGroupId: string
  childGroupIds?: any
  tableName?: string
}

export interface INode {
  groupTreeId: string
  parentId: string
  groupId: string
  groupName: string
  isChecked: boolean
  children: Set<string>
  checkedChildren: Set<string>
}

export const AssignTrainingContext = createContext<AssignTrainingProps>({
  selectedIds: new Map(),
  loading: false,
  empGroups: [],
  root: [],
  resetAllChecks: () => null,
  workers: [],
  handleCheckboxClick: () => null,
  empGroupsAsTableData: [],
  setWorkers: () => null,
  businessUnits: [],
  supervisorAspectData: [],
  siteAspectData: [],
  mascotAspectData: [],
  shouldGetGroupsOnly: false,
  setShouldGetGroupsOnly: () => null,
  setEmpGroupsAsTableData: () => null,
  setSelectedIds: () => null,
  setLoading: () => null,
  setAllWorkers: () => null,
  setSearchedWorkers: () => null,
  setAspectTree: () => null,
  aspectTree: [],
  resetFusion: () => null,
  broadcastData: [],
  setBroadcastData: () => null
})

const AssignTrainingProvider = ({children}: { children: any }) => {
  const [selectedIds, setSelectedIds] = useState<Map<string, string[]>>(new Map())
  const [searchedWorkers, setSearchedWorkers] = useState<any[]>([])
  const [allWorkers, setAllWorkers] = useState<any[]>([])
  const [root, setRoot] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [supervisorAspectData, setSupervisorAspectData] = useState<IFilterDropdownOption[]>([])
  const [siteAspectData, setSiteAspectData] = useState<IFilterDropdownOption[]>([])
  const [mascotAspectData, setMascotAspectData] = useState<IFilterDropdownOption[]>([])
  const [empGroupsAsTableData, setEmpGroupsAsTableData] = useState<any[]>([])
  const [broadcastData, setBroadcastData] = useState<any[]>([])
  const [shouldGetGroupsOnly, setShouldGetGroupsOnly] = useState<boolean>(false)
  const [businessUnits, setBusinessUnits] = useState<IFilterDropdownOption[]>([])
  const [empGroups, setEmpGroups] = useState<any[]>([])

  const {selectedLink} = useContext(RouteContext)

  const resetAllChecks = (bool: boolean) => {
    if (bool) {
      setEmpGroupsAsTableData([])
      setSelectedIds(new Map())
      setBroadcastData([])
    }
  }

  const resetFusion = () => {
    setSelectedIds(new Map())
  }

  const [aspectTree, setAspectTree] = useState<any[]>([])

  const getRoot = (fetchedData: any) => {
    const nodeArray: any[] = []
    const rootArray = fetchedData.map(({groupId, groupName, groupTreeId}: IData) => {
      const deserialized: INode = {
        groupId,
        groupName,
        groupTreeId,
        isChecked: true,
        parentId: 'root',
        children: new Set(),
        checkedChildren: new Set(),
      }
      nodeArray.push([groupId, deserialized])
      return deserialized
    })
    const sortedRoots = sort(rootArray, 'groupName', 'asc')
    setRoot(sortedRoots)
  }

  const mapGroupIds = (id: any, data: any) => {
    return data.map(({groupId}: { groupId: string }) => groupId)
  }

  const mapGroups = (id: any, data: any) => {
    return data.map(
      ({
         groupId,
         groupName,
         groupTreeId,
       }: {
        groupName: string
        groupId: string
        groupTreeId?: string
      }) => ({
        groupId,
        groupName,
        groupTreeId,
      }),
    )
  }

  const handleCheckboxClick = async (node: Partial<INode>, action?: string, type?: string) => {
    setLoading(true)
    if (type === 'broadcast') {
      if (action === 'remove') {
        const filteredBroadcast = broadcastData.filter((each: any) => each.groupId !== node.groupId)
        setBroadcastData([...filteredBroadcast])
      } else {
        setBroadcastData([node, ...broadcastData])
      }
    }

    const tempMap = new Map(selectedIds)
    let newIds: any = (node.groupTreeId && Array.from(tempMap?.get(node?.groupTreeId) || [])) || []
    if (newIds.length > 0 && newIds.includes(node.groupId)) {
      if (shouldGetGroupsOnly) {
        if (node.groupId === node.groupTreeId) {
          newIds = []
        } else {
          const getAllChildrenForTable = await getChildren(node.groupId, 'parentGroupId', mapGroups)
          let filteredList = empGroupsAsTableData.filter(
            (group) =>
              !getAllChildrenForTable.some(
                (existingGroup: any) => existingGroup.groupId === group.groupId,
              ),
          )
          if (action === 'remove') {
            filteredList = filteredList.filter((each: any) => each.groupId !== node.groupId)
          }
        }
        newIds = newIds.filter((id: string) => id !== node.groupId)
      } else {
        newIds = newIds.filter((id: string) => id !== node.groupId)
      }
    } else {
      if (shouldGetGroupsOnly) {
        const getAllChildrenForTable = await getChildren(node.groupId, 'parentGroupId', mapGroups)
        if (getAllChildrenForTable.length > 0) {
          setEmpGroupsAsTableData([...empGroupsAsTableData, ...getAllChildrenForTable])
        } else {
          setEmpGroupsAsTableData([...empGroupsAsTableData, node])
        }
      }
      newIds = [...newIds, node.groupId]
    }
    if (node.groupTreeId) tempMap.set(node.groupTreeId, [...newIds])
    setSelectedIds(tempMap)
  }

  const shouldRefreshData = () => {
    return Array.from(selectedIds.values()).flat().length > 0
  }

  useEffect(() => {
    ;(async () => await getAspectRoots(getRoot))()
    ;(async () => await getAspectTree(setAspectTree))()
  }, [])

  useEffect(() => {
    setLoading(false)
  }, [searchedWorkers])

  useEffect(() => {
    if (!selectedIds.size) return
    if (shouldGetGroupsOnly) return
    if (shouldRefreshData() && selectedLink !== 'fusion') {
      ;(async () => {
        setLoading(true)
        //  [[OR] and [OR]]
        const queryArray = Array.from(selectedIds.values())
        const workersForGroup = (await getWorkersForGroup(queryArray)) || []
        setSearchedWorkers(workersForGroup)
      })()
    } else {
      setSearchedWorkers(allWorkers)
    }
  }, [selectedIds, shouldGetGroupsOnly])

  return (
    <AssignTrainingContext.Provider
      value={{
        businessUnits,
        empGroups,
        loading,
        selectedIds,
        setSelectedIds,
        handleCheckboxClick,
        resetAllChecks,
        workers: searchedWorkers,
        setWorkers: setSearchedWorkers,
        root,
        empGroupsAsTableData,
        shouldGetGroupsOnly,
        setShouldGetGroupsOnly,
        supervisorAspectData,
        setEmpGroupsAsTableData,
        mascotAspectData,
        siteAspectData,
        setLoading,
        setAllWorkers,
        setSearchedWorkers,
        setAspectTree,
        aspectTree,
        resetFusion,
        broadcastData,
        setBroadcastData
      }}
    >
      {children}
    </AssignTrainingContext.Provider>
  )
}

export default AssignTrainingProvider
