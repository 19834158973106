import React from 'react'
import IconComponent from '../IconComponent'
import { Flex } from '../../index'
import themes from '../../../../themes/schema'
import { useSelector } from 'react-redux'

const Modal = ({
  width,
  children,
  close,
  height,
  header,
  style = {},
}: {
  width?: string | number
  style?: any
  children: any
  close: any
  height?: string
  header?: string
}) => {
  const modalHeight = height ? height : ''
  const {
    userSettings: { darkMode },
  } = useSelector((state: any) => state?.user)
  const customDisplayType = darkMode ? 'darkMode' : 'originalFlavor'
  const getStyle: Record<string, any> = {
    modalColor_darkMode: themes.darkMode.modal.backgroundColor,
    modalColor_originalFlavor: themes.originalFlavor.modal.backgroundColor,
    modalScreenColor_darkMode: themes.darkMode.modal.screenColor,
    modalScreenColor_originalFlavor: themes.originalFlavor.modal.screenColor,
  }
  return (
    <Flex
      config={['column', 'alignCenter', 'justifyCenter']}
      style={{
        height: '100vh',
        width: '100%',
        backgroundColor: getStyle[`modalScreenColor_${customDisplayType}`],
        position: 'fixed',
        left: 0,
        top: 0,
        zIndex: 10000,
        padding: '1.32rem',
      }}
    >
      <Flex
        config={['column']}
        style={{
          overflow: 'auto',
          ...style,
          height: modalHeight,
          minHeight: modalHeight || 440,
          minWidth: 410,
          width: width || 'max-content',
          background: getStyle[`modalColor_${customDisplayType}`],
          borderRadius: style && style.borderRadius ? style.borderRadius : '4px',
          padding: '1.32rem',
          maxHeight: 'calc(100vh - 250px)',
        }}
      >
        <Flex style={{ marginBottom: '0.5rem' }} config={['row', 'alignStart', 'justifyBetween']}>
          <h5 style={{ margin: '0' }}>{header}</h5>
          <Flex config={['row', 'alignStart']} style={{ cursor: 'pointer' }}>
            <button className={'ttd-bare-button'} onClick={close}>
              <IconComponent icon={'close'} />
            </button>
          </Flex>
        </Flex>
        {children}
      </Flex>
    </Flex>
  )
}

export default Modal
