import React, { useContext, useEffect, useState } from 'react'
import IconComponent from '../IconComponent'
import { Flex } from '../../index'
import { useSelector } from 'react-redux'
import themes from '../../../../themes/schema'
import { RouteContext } from '../../../../context/RouteContext'
import { useNavigate } from 'react-router-dom'

interface SizeProps {
  fontSize: number
  marginTop: number
}

interface SizesProps {
  [key: string]: SizeProps
}

const Loading = ({ size = 'md' }: { size: string | undefined }) => {
  const {
    userSettings: { darkMode },
  } = useSelector((state: any) => state?.user)
  const [timer, setTimer] = useState<number>(0)
  const navigate = useNavigate()

  const navigateAway = () => {
    navigate('/error')
    setTimer(0)
  }

  useEffect(() => {
    const timerToClear = setTimeout(() => {
      let newTime = timer + 1
      setTimer(newTime)
      if (timer > 60) {
        navigateAway()
      }
      return
    }, 1000)
    return () => clearTimeout(timerToClear)
  })

  const customDisplayType = darkMode ? 'darkMode' : 'originalFlavor'
  const getStyle: Record<string, any> = {
    loadColor_darkMode: themes.darkMode.loading.color,
    loadColor_originalFlavor: themes.originalFlavor.loading.color,
  }

  const sizes: SizesProps = {
    xs: { fontSize: 25, marginTop: 5 },
    sm: {
      fontSize: 35,
      marginTop: 3,
    },
    md: {
      fontSize: 50,
      marginTop: 0,
    },
    lg: {
      fontSize: 75,
      marginTop: 0,
    },
    xl: {
      fontSize: 100,
      marginTop: 0,
    },
  }
  const fontSize: number = sizes[size].fontSize
  const marginTop: number = sizes[size].marginTop
  return (
    <div>
      <IconComponent
        style={{
          fontSize,
          cursor: 'pointer',
          color: getStyle[`loadColor_${customDisplayType}`],
          marginTop,
        }}
        icon={'cached'}
        className={'ttd-loading-animation'}
      />
    </div>
  )
}

export default Loading
