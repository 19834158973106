import { IHeaderComponent, IHeaders } from '../../../../interfaces'
import React from 'react'
import { DisplayHeader } from './DisplayHeader'
import { SortableContainer } from 'react-sortable-hoc'
const sortableStyles = { color: '#8223D2', cursor: 'pointer' }
import styled from 'styled-components'
export const Headers = SortableContainer((props: IHeaderComponent) => {
  const {
    columnData,
    dateFilters,
    tableRef,
    selected,
    headers,
    filters,
    handleSort,
    canEdit,
    canRemove,
    displayFilter = true,
    canAddToStaging,
    toggleAll = false,
    handleToggleAll = () => {},
    emitFilters,
    emitDateFilter,
    setColumnData,
  } = props
  const log = tableRef.current

  return (
    <React.Fragment>
      <THead>
        <Row>
          {headers.map(({ value = '', display, sortable }: Partial<IHeaders>, index) => (
            <DisplayHeader
              columnData={columnData}
              index={index}
              dateFilters={dateFilters}
              displayFilter={displayFilter}
              disabled={value === 'checkbox'}
              collection={value === 'checkbox' ? 1 : 0}
              emitDateFilter={emitDateFilter}
              filters={filters}
              emitFilters={emitFilters}
              tableRef={tableRef}
              key={JSON.stringify({ value, display, sortable })}
              selected={selected}
              value={value}
              display={display}
              sortable={sortable}
              handleSort={handleSort}
              toggleAll={toggleAll}
              styles={sortable && selected === value ? sortableStyles : {}}
              handleToggleAll={handleToggleAll}
              showResizer={index !== headers.length - 1 || canAddToStaging || canRemove || canEdit}
              setColumnData={setColumnData}
            />
          ))}
          {(canRemove || canAddToStaging || canEdit) && <th>Action</th>}
        </Row>
      </THead>
    </React.Fragment>
  )
})

const THead = styled.thead`
  background-color: ${(props) => props.theme.header.backgroundColor};
  position: relative;
  z-index: 99;
`
const Row = styled.tr.attrs(() => ({
  className: 'header-row',
}))`
  position: sticky;
  top: 0;
  background-color: ${(props) => props.theme.header.backgroundColor};
  border: none;
`
