import React, { useCallback, useContext, useEffect, useReducer, useState } from 'react'
import Papa from 'papaparse'
import {
  Dropdown,
  FormLabel,
  Button,
  Loading,
  FilterableTable,
  Flex,
  IconComponent,
} from '../../common'
import { assignmentHeadersForReporting, defaultCount } from '../../../constants'
import { AssignTrainingContext } from '../../../context/AssignTrainingContext'
import { fetchAssignmentsData, fetchReport } from '../../../actions'
import { GroupsContext } from '../../../context/GroupsContext'
import { IFilterDropdownOption } from '../../common/Atoms/Dropdown'
import { IHeader, IReportingState } from '../../../interfaces'
import useInterval from '../../../hooks/UseInterval'
import { checkPermissions, getDateRanges } from '../../../utils/'
import { optionConfig } from '../../../constants'
import {
  addReportingStatusAsProp,
  buildHeadersForTable,
  deriveBodyFromState,
  deriveFiltersFromReportType,
  determineValuesToUpdateForMultipleSelect,
  filterDataByStatus,
  groupData,
  mapTagsToDropdownOptions,
  parseData,
} from '../functions'
import { AdminConfigContext } from '../../../context/AdminConfigContext'
import { setWithExpiry } from '../../../hooks/UseLocalStorage'

const Reports = ({ state, dispatch, handleShowSaveReport }: any) => {
  const { reportListOptions, dateKeyOptions, employeeStatusOptions } = optionConfig
  const { selectedIds, resetAllChecks, businessUnits, siteAspectData, mascotAspectData } =
    useContext(AssignTrainingContext)
  const { trainingAspectData } = useContext(GroupsContext)
  const { tags = [] } = useContext(AdminConfigContext)
  const { dateRangeOptions } = optionConfig
  const dateRanges = getDateRanges()

  useEffect(() => {
    if (state.dateSelectionDropdown && state.dateSelectionDropdown.value === 'custom') {
      dispatch({ type: 'setShowCustomDates', payload: true })
    }
    if (
      state.dateSelectionDropdown &&
      state.dateSelectionDropdown.value !== 'custom' &&
      state.dateSelectionDropdown.value !== 'selectOne'
    ) {
      dispatch({
        type: 'setShowCustomDates',
        payload: false,
      })
      dispatch({
        type: 'setFromDate',
        payload: dateRanges[state.dateSelectionDropdown.value].start,
      })
      dispatch({
        type: 'setToDate',
        payload: dateRanges[state.dateSelectionDropdown.value].end,
      })
    }
  }, [state.dateSelectionDropdown])

  useEffect(() => {
    if (state.isRealtime) {
      const filteredData = filterDataByStatus(state.data)
      const updatedDataForReportingStatus = addReportingStatusAsProp(filteredData)
      dispatch({
        type: 'setFilteredData',
        payload: updatedDataForReportingStatus,
      })
    } else {
      dispatch({ type: 'setFilteredData', payload: state.data })
    }
  }, [state.data, state.isRealtime])

  useEffect(() => {
    if (state.trainings && state.trainings.length > 0) return
    if (trainingAspectData.length > 0) {
      dispatch({ type: 'setTrainings', payload: trainingAspectData })
    }
    return
  }, [trainingAspectData])
  useEffect(() => {
    if (state.businessUnits && state.businessUnits.length > 0) return
    if (businessUnits.length > 0) {
      dispatch({ type: 'setBusinessUnits', payload: businessUnits })
    }
    return
  }, [businessUnits])

  useEffect(() => {
    if (state.mascot && state.mascot.length > 0) return
    if (mascotAspectData.length > 0) {
      dispatch({ type: 'setMascot', payload: mascotAspectData })
    }
    return
  }, [mascotAspectData])

  useEffect(() => {
    if ((state.site && state.site.length > 0) || siteAspectData.length === 0) return
    if (siteAspectData.length > 0) {
      dispatch({ type: 'setSite', payload: siteAspectData })
    }
    return
  }, [siteAspectData])

  useEffect(() => {
    if (state.tags && state.tags.length > 0) return
    if (tags.length > 0) {
      dispatch({ type: 'setTags', payload: mapTagsToDropdownOptions(tags) })
    }
    return
  }, [tags])

  useInterval(() => {
    if (state.isRealtime) {
      ;(async () => {
        const setLoading = (something: boolean) => something
        try {
          const setData = (dataFromFetch: any[]) =>
            dispatch({ type: 'setData', payload: dataFromFetch })
          await fetchAssignmentsData('ReportingAssignments', '', setLoading, setData)
        } catch (ex) {
          console.error(ex, 'No assignments for Workers')
        }
      })()
    }
  }, 5000)

  const handleSelect = (e: any, key: string) => {
    const setValue: Record<string, any> = {
      reportType: 'setReportType',
      department: 'setDepartment',
      dateRangeSelection: 'setDateSelectionDropdown',
      dateKey: 'setDateKey',
      opsHierarchy: 'setOpsHierarchy',
      employeeStatus: 'setEmployeeStatus',
      dateHierarchy: 'setDateHierarchy',
      // Remove me
      dbSelection: 'setDBSelection',
    }

    return key && e && dispatch({ type: setValue[key], payload: e })
  }

  const handleMultipleSelect = (e: any, key: string) => {
    const [setCount, updatedCount, setValue, updatedValue] =
      determineValuesToUpdateForMultipleSelect(state, e, key)

    dispatch({ type: setCount, payload: updatedCount })
    return key && e && dispatch({ type: setValue, payload: updatedValue })
  }

  const handleReportList = (e: { id: string; value: string; display: string }) => {
    dispatch({ type: 'setData', payload: [] })
    dispatch({ type: 'setHeaders', payload: [] })
    dispatch({ type: 'setReportType', payload: e })
    dispatch({ type: 'setStartDays', payload: 0 })
    dispatch({ type: 'setEndDays', payload: 0 })
    handleClearFilters()
    // @ts-ignore
    setWithExpiry('realTime-unchecked-filters', [])
    if (e.id === 'Assignments') {
      const assignmentHeadersWithNewStatusKey = assignmentHeadersForReporting.map(
        (header: IHeader) => {
          if (header.value === 'status') {
            header.value = 'reportingStatus'
          }
          return header
        },
      )
      dispatch({ type: 'setHeaders', payload: assignmentHeadersWithNewStatusKey })
      dispatch({ type: 'setShowReportTypesDropdown', payload: true })
      dispatch({ type: 'setIsRealtime', payload: true })
    } else {
      dispatch({ type: 'setShowReportTypesDropdown', payload: false })
      dispatch({ type: 'setIsRealtime', payload: false })
    }
  }

  const handleGetReport = async (_state: IReportingState) => {
    dispatch({ type: 'setGeneratingReport', payload: true })
    const body: any = deriveBodyFromState(_state, selectedIds)
    try {
      dispatch({ type: 'setLoading', payload: true })
      const { results } = await fetchReport(body)

      if (_state.reportType.display === 'Daily/Monthly') {
        const { groupedData, groupedHeaders } = groupData(results, _state)
        dispatch({
          type: 'setGroupedHeaders',
          payload: buildHeadersForTable(Object.keys(results[0])),
        })
        dispatch({ type: 'setGroupedData', payload: results })
        dispatch({
          type: 'setHeaders',
          payload: groupedHeaders,
        })
        dispatch({ type: 'setData', payload: groupedData })
      } else {
        dispatch({
          type: 'setHeaders',
          payload: buildHeadersForTable(Object.keys(results[0])),
        })
        dispatch({ type: 'setData', payload: results })
      }

      // Papa.parse(reportUrl, {
      //   download: true,
      //   complete: (data) => {
      //     const parsedData = parseData(data, _state)
      //     if (_state.reportType.display === 'Daily/Monthly') {

      //     } else {
      //       dispatch({
      //         type: 'setHeaders',
      //         payload: buildHeadersForTable(parsedData.headersFromData),
      //       })
      //
      //     }
      //   },
      // })
    } catch (e) {
      console.error(e)
    } finally {
      dispatch({ type: 'setLoading', payload: false })
      dispatch({ type: 'setGeneratingReport', payload: false })
    }
  }

  const handleClearFilters = () => {
    dispatch({ type: 'setDateSelectionDropdown', payload: dateRangeOptions[4] })
    const unCheckAll = (arr: IFilterDropdownOption[]) => {
      return arr.map((item) => {
        item.checked = false
        return item
      })
    }
    dispatch({ type: 'setTrainings', payload: unCheckAll(state.trainings) })
    dispatch({ type: 'setTrainingsCount', payload: defaultCount })
    dispatch({ type: 'setSiteCount', payload: defaultCount })
    dispatch({ type: 'setMascotCount', payload: defaultCount })
    dispatch({ type: 'setFromDate', payload: '' })
    dispatch({ type: 'setToDate', payload: '' })
    dispatch({ type: 'setEmpIdsForReport', payload: [] })
    dispatch({ type: 'setTags', payload: unCheckAll(state.tags) })
    dispatch({ type: 'setTagCount', payload: defaultCount })
    dispatch({ type: 'setStatus', payload: unCheckAll(state.status) })
    dispatch({ type: 'setStatusCount', payload: defaultCount })
    dispatch({ type: 'setDateKey', payload: dateKeyOptions[0] })
    dispatch({ type: 'setEmployeeStatus', payload: employeeStatusOptions[1] })
    dispatch({ type: 'setEmpIdValue', payload: '' })
    dispatch({ type: 'setBusinessUnits', payload: unCheckAll(state.businessUnits) })
    dispatch({ type: 'setMascot', payload: unCheckAll(state.mascot) })
    dispatch({ type: 'setSite', payload: unCheckAll(state.site) })
    dispatch({ type: 'setEmpNameSelection', payload: [] })
    dispatch({ type: 'setEditSavedReport', payload: {} })
    resetAllChecks(true)
  }

  const hasFilters = () => {
    const selectedIdsArray = selectedIds ? Array.from(selectedIds.values()).flat(1) : []
    return (
      !!state.trainings.filter(({ checked }: Partial<IFilterDropdownOption>) => checked).length ||
      !!selectedIdsArray.length ||
      state.reportType.id !== 'selectOne' ||
      Boolean(state.fromDate) ||
      Boolean(state.toDate)
    )
  }

  const handleFoldFilters = () => {
    return dispatch({ type: 'setFoldFilters', payload: !state.foldFilters })
  }

  const handlers = {
    handleSelect,
    handleMultipleSelect,
    handleReportList,
    handleClearFilters,
    handleFoldFilters,
    dispatch,
  }
  const saveReportPermissions = checkPermissions('reporting', 'edit')
  return (
    <React.Fragment>
      <Flex
        className={`ttd-reporting-filter ${state.foldFilters ? 'ttd-reporting-filter-expand' : ''}`}
        style={{
          overflow: 'auto',
          borderRight: '1px solid #dfdfdf',
          paddingRight: 15,
          marginRight: 15,
        }}
        config={['column']}
      >
        {!state.foldFilters && (
          <FormLabel id="ttd-report-list" value="Report List">
            <Dropdown
              options={reportListOptions}
              selected={state.reportType}
              emitDropdownOption={handleReportList}
            />
          </FormLabel>
        )}

        {!state.isRealtime && !state.foldFilters && deriveFiltersFromReportType(state, handlers)}

        <Flex config={['column']}>
          {hasFilters() && !state.foldFilters && (
            <Button
              displayType={'ghost'}
              size={'sm'}
              style={{ marginTop: 50 }}
              handleClick={() => {
                handleClearFilters()
              }}
            >
              Clear
            </Button>
          )}
          {!state.generatingReport && state.url.length > 0 && (
            <span>
              If your report did not automatically download,{' '}
              <a href={state.url} target={'_blank'}>
                click here
              </a>
            </span>
          )}
        </Flex>

        <Flex config={['alignCenter', 'justifyBetween']} style={{ marginTop: 'auto' }}>
          {!state.foldFilters && (
            <Flex config={['justifyBetween']} style={{ width: '55%' }}>
              {!state.isRealtime && (
                <Button
                  displayType={'outline'}
                  size={'sm'}
                  handleClick={() => {
                    handleGetReport(state)
                  }}
                >
                  {state.generatingReport ? <Loading size={'sm'} /> : 'Generate Report'}
                </Button>
              )}
              {!state.isRealtime &&
                state.reportType.value !== 'selectOne' &&
                saveReportPermissions && (
                  <Button
                    displayType={'outline'}
                    size={'sm'}
                    handleClick={() => {
                      handleShowSaveReport(false, {})
                    }}
                  >
                    Save Report
                  </Button>
                )}
            </Flex>
          )}
          <button
            style={{ marginTop: 5 }}
            className={`ttd-bare-button`}
            onClick={handleFoldFilters}
          >
            <IconComponent
              icon={state.foldFilters ? 'keyboard_arrow_right' : 'keyboard_arrow_left'}
            />
          </button>
        </Flex>
      </Flex>
      <Flex
        config={['column']}
        style={{
          width: '100%',
          height: state.reportType.value === 'Daily/Monthly' ? window.innerHeight / 2 : '100%',
        }}
      >
        <FilterableTable
          loading={state.loading}
          canDownload={true}
          displayFilter={state.reportType.value !== 'Daily/Monthly'}
          reportType={state.reportType?.display}
          width={2500}
          headers={state.headers}
          data={state.filteredData}
        />
        {state.reportType.value === 'Daily/Monthly' && (
          <FilterableTable
            loading={state.loading}
            isRealTime={true}
            reportType={state.reportType?.display}
            width={2500}
            headers={state.groupedHeaders}
            data={state.groupedData}
          />
        )}
      </Flex>
    </React.Fragment>
  )
}

export default Reports
