import React, {useEffect, useState, CSSProperties, useContext, useRef} from 'react'
import {Checkbox, Flex} from '../../common'
import styled, {StyledComponent} from 'styled-components'
import {IData} from '../../../context/AssignTrainingContext'
import {AssignTrainingContext} from '../../../context/AssignTrainingContext'
import {processAspectData} from '../../../utils'
import {RouteContext} from '../../../context/RouteContext'

export interface INodeProps {
  name: string
  groupId: string
  parentId: string
  checked?: boolean
  groupTreeId: string
  mustDisable?: string[]
  style?: CSSProperties
  // eslint-disable-next-line no-unused-vars
  bubbleCheckUp?: (name: string, isChecked: boolean) => void
  nodeType?: string
}

const DataNode = ({
                    name,
                    groupId,
                    parentId,
                    checked,
                    groupTreeId,
                    mustDisable = [],
                    style,
                    bubbleCheckUp = () => {
                    },
                    nodeType
                  }: INodeProps) => {
  const {handleCheckboxClick, selectedIds, aspectTree, broadcastData} = useContext(AssignTrainingContext)
  const [children, setChildren] = useState<Map<string, IData>>(new Map())
  const [checkedChildren, setCheckedChildren] = useState<Set<string>>(new Set())
  const [showChildren, setShowChildren] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const processChildren = () => {
    let generator = processAspectData(groupId, [...Object.values(aspectTree)])
    const parent = generator.next().value
    if (parent?.children?.length) {
      const newChildren: Array<[string, IData]> = parent.children.map((node: IData) => [
        node.groupName,
        node,
      ])
      const combineChildren = new Map([...children, ...newChildren])
      const updatedChildren = new Map([...combineChildren.entries()].sort())
      return setChildren(updatedChildren)
    }
  }
  const hasNodes = processAspectData(groupId, [...Object.values(aspectTree)]).next().value.children
  const {selectedLink} = useContext(RouteContext)
  const handleIsChecked = () => {
    handleCheckboxClick({groupId, parentId, groupTreeId, groupName: name}, '', selectedLink)
    bubbleCheckUp(name, true)
    setCheckedChildren(new Set(Array.from(children.values()).map(({groupName}) => groupName)))
  }
  const handleIsUnchecked = () => {
    handleCheckboxClick({groupId, parentId, groupTreeId, groupName: name}, 'remove', selectedLink)
    bubbleCheckUp(name, false)
    setCheckedChildren(new Set())
  }
  const handleBubbleUp = (childName: string, isChecked: boolean) => {
    const updatedChildren = new Set(checkedChildren)
    isChecked ? updatedChildren.add(childName) : updatedChildren.delete(childName)
    setCheckedChildren(updatedChildren)
  }
  const useIsMount = () => {
    const isMountRef = useRef(true)
    useEffect(() => {
      isMountRef.current = false
    }, [])
    return isMountRef.current
  }

  const isMounting = useIsMount()

  useEffect(() => {
    if (isMounting) return
    isChecked ? handleIsChecked() : handleIsUnchecked()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked])

  // useEffect(() => {
  //     console.log('resetFusion')
  //     if (!resetFusion) return
  //     // resetFusion(true)
  // }, [resetFusion])

  useEffect(() => {
    if (isMounting) return
    setIsChecked(children.size > 0 && children.size === checkedChildren.size)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children.size, checkedChildren.size])

  useEffect(() => {
    if (isMounting) return
    if (showChildren) {
      const fetchHelper = async () => {
        processChildren()
      }
      fetchHelper()
    }
  }, [showChildren])

  const parentIsChecked = selectedIds?.get(groupTreeId)?.includes(groupId) || false
  const isParent = parentId === 'root'
  const cantSelect = mustDisable.some((id) => id === groupId)
  return (
    <div style={{...style}}>
      <Flex
        config={['alignCenter']}
        style={{
          width: '100%',
          padding: '15px 0',
          borderBottom: '1px solid #dfdfdf',
          marginRight: '2px',
          fontStyle: cantSelect ? 'italic' : '',
          color: cantSelect ? 'grey' : '',
          cursor: cantSelect ? 'not-allowed' : 'auto',
        }}
      >
        <StyledActionableIcon
          type="button"
          style={{cursor: cantSelect ? 'not-allowed' : 'pointer'}}
          className="material-icons"
          onClick={() => (cantSelect ? null : setShowChildren(!showChildren))}
        >
          {hasNodes ? (showChildren ? 'keyboard_arrow_down' : 'keyboard_arrow_up') : 'a'}
        </StyledActionableIcon>

        {!isParent && (
          <Checkbox
            style={{cursor: cantSelect ? 'not-allowed' : 'auto'}}
            checked={parentIsChecked}
            onClick={() => (cantSelect ? null : setIsChecked(!isChecked))}
          />
        )}
        <span>{name}</span>
      </Flex>
      {showChildren &&
      Array.from(children.values()).map(
        ({groupName, groupId, parentGroupId: parentId = ''}) => (
          <DataNode
            mustDisable={mustDisable}
            checked={parentIsChecked}
            key={groupName}
            parentId={parentId}
            groupId={groupId}
            groupTreeId={groupTreeId}
            name={groupName}
            bubbleCheckUp={handleBubbleUp}
            style={{marginLeft: 30}}
          />
        ),
      )}
    </div>
  )
}

export default DataNode

const StyledActionableIcon: StyledComponent<'button', any, {}, never> = styled.button`
  cursor: pointer;
  background: none;
  color: ${(props) => props.theme.iconComponent.colors.dataNode};
  border: none;

  :active {
    background: none;
  }

  :focus {
    background: none;
  }
`
