import React, {
  useEffect,
  CSSProperties,
  ChangeEventHandler,
  ReactNode,
  MouseEventHandler,
  useContext,
  useState,
} from 'react'
// test
import styled, { StyledComponent } from 'styled-components'
import { ClickHandler } from '../../interfaces'
import '@asurion/sass-design-system/build/asurion/asurion.css'
import '@asurion/atomic-ui-library'
import { assignmentHeaders } from '../../constants'
import FilterableTable from '../common/Organisms/FilterableTable'
import { mapSelectionsByTraining } from './utils'
import { GroupsContext } from '../../context/GroupsContext'

const Assignments = () => {
  const { assignments, assignmentsLoading } = useContext(GroupsContext)
  const [workersSelected, setWorkersSelected] = useState<Map<string, string[]>>(new Map())
  return (
    <div style={{ height: '100%' }}>
      <FilterableTable
        loading={assignmentsLoading}
        width={2500}
        workersSelected={workersSelected}
        addWorkerToWorkerSelected={mapSelectionsByTraining(setWorkersSelected)}
        data={assignments}
        headers={assignmentHeaders}
        useEditModal={true}
      />
    </div>
  )
}

export interface IMenuItem {
  children: ReactNode[] | ReactNode
  style: CSSProperties
  className: string | undefined
  handleChange: ChangeEventHandler<HTMLInputElement>
  value: string
  display: string
  placeholder?: string | undefined
  onMenuSelect: MouseEventHandler
}

export const StyledMenuContainer: StyledComponent<
  'div',
  { onClick: ClickHandler; value: string; display: string },
  any
> = styled.div`
  padding: 15px;
  &:hover {
    border-color: green;
  }
  &:active {
    border-color: #8223d2;
  }
`
export default Assignments
