import React, { useState, useEffect, ChangeEvent, useContext } from 'react'
import { Input, IconComponent, Flex, SVGIconComponent, FormLabel, Tooltip } from '../../common'
import ListItemOptions from './ListItemOptions'
import { tooltipMap } from '../../../constants/configurations'
import { checkPermissions } from '../../../utils'

const List = (props: {
  data: string[]
  title: string
  type: string
  addItem: (type: string, value: string) => void
  deleteItem: (data: string[], type: string, value: string) => void
  editItem: (data: string[], type: string, value: string, item: string) => void
}) => {
  const [searchValue, setSearchValue] = useState('')
  const [listData, setListData] = useState([''])
  const [newItem, setNewItem] = useState('')
  const [showEditOptions, setShowEditOptions] = useState(false)
  const { data, title, type, addItem, deleteItem, editItem } = props
  const permission = checkPermissions('domainstitles', 'edit')
  useEffect(() => {
    setListData(data)
  }, [data])
  useEffect(() => {
    const filteredData = data.filter((item) =>
      item.toLowerCase().includes(searchValue.toLowerCase()),
    )
    setListData(filteredData)
  }, [searchValue])
  const tooltip =
    title === 'Domains'
      ? 'configuration.Domains/Titles.Domains'
      : 'configuration.Domains/Titles.WindowTitles'
  return (
    <Flex config={['column']}>
      <Flex
        style={{
          border: 'lightgray solid 1px',
          height: '3rem',
          width: '100%',
          alignSelf: 'flex-start',
          borderRadius: '4px',
        }}
      >
        <IconComponent
          style={{
            alignSelf: 'center',
          }}
          icon={'search'}
        />
        <Input
          handleChange={(e) => {
            setSearchValue(e.target.value)
          }}
          type="input"
          style={{
            alignSelf: 'center',
            width: '80%',
            display: 'flex',
            border: 'none',
          }}
          value={searchValue}
          placeholder={`Search ${title.toLowerCase()}`}
        />
      </Flex>
      <Flex config={['column']}>
        <Flex>
          <h5 style={{ width: '33%' }}>{title}</h5>
          <FormLabel id="adminConfig-system-flag" value="" tooltip={{ selector: tooltip }}>
            {' '}
          </FormLabel>
          {permission && (
            <p
              onClick={() => setShowEditOptions(!showEditOptions)}
              style={{ color: 'rgba(168, 57, 243, 1)', textDecoration: 'underline' }}
            >
              {showEditOptions ? 'Done' : 'Edit'}
            </p>
          )}
        </Flex>
        {permission && (
          <Flex
            config={['justifyBetween']}
            style={{
              border: 'lightgray solid 1px',
              height: '2.4rem',
              width: '18rem',
              alignSelf: 'flex-start',
              borderRadius: '6px',
            }}
          >
            <Input
              handleChange={(e) => {
                setNewItem(e.target.value)
              }}
              type="input"
              style={{
                alignSelf: 'center',
                border: 'none',
                padding: '0 0.2rem',
              }}
              value={newItem}
              placeholder={`Add new ${title.toLowerCase()}`}
            />
            <div
              onClick={() => {
                if (newItem.length > 0) {
                  addItem(type, newItem)
                  setNewItem('')
                }
              }}
              style={{ display: 'flex' }}
            >
              <IconComponent
                style={{
                  alignSelf: 'center',
                  cursor: 'pointer',
                }}
                icon={'add'}
              />
            </div>
          </Flex>
        )}
      </Flex>
      <Flex config={['column']}>
        {listData.map((item: string, idx: number) => (
          <Flex config={['alignCenter']} key={`${item}${idx}`}>
            {<ListItemOptions item={item} showEditOptions={showEditOptions} {...props} />}
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}
export default List
