import React, { useContext, useRef, useState } from 'react'
import { IDisplayHeader } from '../../../../interfaces'
import { Checkbox, IconComponent, Tooltip } from '../../index'
import { RouteContext } from '../../../../context/RouteContext'
import DropdownTable from './TableDropdown'
import { SortableElement } from 'react-sortable-hoc'
import { useSelector } from 'react-redux'
import themes from '../../../../themes/schema'

export const DisplayHeader = SortableElement(
  ({
    columnData,
    display,
    dateFilters,
    handleSort,
    styles = {},
    emitFilters,
    filters,
    displayFilter = true,
    emitDateFilter = () => {},
    value = '',
    toggleAll,
    handleToggleAll,
    setColumnData,
  }: IDisplayHeader) => {
    const [blurred, setBlurred] = useState(false)
    const [childMousedown, setChildMousedown] = useState(false)
    const thRef = useRef<any>(null)
    const {
      userSettings: { darkMode },
    } = useSelector((state: any) => state?.user)
    const customDisplayType = darkMode ? 'darkMode' : 'originalFlavor'
    const getStyle: Record<string, any> = {
      header_color_originalFlavor: themes.originalFlavor.header.color,
      header_color_darkMode: themes.darkMode.header.color,
    }
    let x = 0
    let w = 0
    const boundingBoxHeight = 60
    const mouseDownHandler = function (e: any) {
      e.stopPropagation()
      setChildMousedown(true)
      // Get the current mouse position
      x = e.clientX

      // Calculate the current width of column
      const styles = window.getComputedStyle(thRef.current)
      w = parseInt(styles.width, 10)

      // Attach listeners for document's events
      document.addEventListener('mousemove', mouseMoveHandler)
      document.addEventListener('mouseup', mouseUpHandler)
    }

    const mouseMoveHandler = function (e: any) {
      e.stopPropagation()
      // Determine how far the mouse has been moved
      const dx = e.clientX - x
      // updates width of column in UI
      const updatedColumnData = { ...columnData, [value]: columnData[value] + dx }
      setColumnData(updatedColumnData)

      // const key = `columnsData-${selectedLink}`
      // updates width of column in localStorage
      // setWithExpiry(key,updatedColumnData)
      // Update the width of column
      thRef.current.style.width = `${w + dx}px`
    }

    // When user releases the mouse, remove the existing event listeners
    const mouseUpHandler = function () {
      document.removeEventListener('mousemove', mouseMoveHandler)
      document.removeEventListener('mouseup', mouseUpHandler)
    }

    return (
      <>
        <th
          className="ttd-th"
          onBlur={() => {
            if (childMousedown) {
              setChildMousedown(false)
            } else {
              setBlurred(true)
            }
          }}
          ref={thRef}
          key={display}
          style={{ ...styles, color: getStyle[`header_color_${customDisplayType}`] }}
        >
          <div
            style={{
              width: 'max-content',
              minWidth: '5px',
              textOverflow: 'ellipsis',
            }}
            className="ttd-flex-center"
          >
            {value === 'checkbox' && <Checkbox checked={toggleAll} onClick={handleToggleAll} />}
            {value !== 'checkbox' && value !== 'dropdown' && (
              <div className="ttd-flex-center" style={{ width: '100%' }}>
                <DropdownTable
                  blurred={blurred}
                  displayFilter={displayFilter}
                  setChildMousedown={setChildMousedown}
                  setBlurred={setBlurred}
                  dateFilters={dateFilters}
                  emitDateFilter={emitDateFilter}
                  filters={filters}
                  emitFilters={emitFilters}
                  handleSort={handleSort}
                  display={display}
                  header={value || ''}
                  boundingBoxHeight={boundingBoxHeight}
                  emitDropdownTableOption={() => {
                    'clicked'
                  }}
                >
                  {display}
                  {display === 'Completion %' ? (
                    <Tooltip selector={'assignments.percentComplete'}>
                      <IconComponent className={'ttd-tooltip'} icon={'info'} />
                    </Tooltip>
                  ) : display === 'Time Spent %' ? (
                    <Tooltip selector={'assignments.completionPercent'}>
                      <IconComponent className={'ttd-tooltip'} icon={'info'} />
                    </Tooltip>
                  ) : (
                    <i
                      style={{ color: getStyle[`header_color_${customDisplayType}`] }}
                      className={`material-icons`}
                    >
                      {'arrow_drop_down'}
                    </i>
                  )}
                </DropdownTable>
              </div>
            )}
            <div
              tabIndex={0}
              role="button"
              onKeyPress={() => null}
              onMouseDown={mouseDownHandler}
              className="resizer"
              style={{
                height: thRef?.current?.offsetHeight,
                borderRight: '1px solid lightgrey',
              }}
            />
          </div>
        </th>
      </>
    )
  },
)
