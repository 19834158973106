export const keyMap: Record<string, string> = {
  inactivityTime: 'config.flexConfig.monitorConfig.inactivityTime',
  popupInactivityTimer: 'config.flexConfig.monitorConfig.warnings.inactivity.timer',
  popupInactivityMessage: 'config.flexConfig.monitorConfig.warnings.inactivity.message',
  popupInactivityTitle: 'config.flexConfig.monitorConfig.warnings.inactivity.title',
  popupLostFocusGracePeriod: 'config.expertMonitoringConfig.focusGracePeriod',
  popupLostFocusMessage: 'config.flexConfig.monitorConfig.warnings.lostfocus.message',
  popupLostFocusTimer: 'config.flexConfig.monitorConfig.warnings.lostfocus.timer',
  popupLostFocusTitle: 'config.flexConfig.monitorConfig.warnings.lostfocus.title',
  flexInactivityDuration: 'config.flexConfig.timerConfig.inactivity.duration',
  flexInactivityMessage: 'config.flexConfig.timerConfig.inactivity.message',
  flexInactivityReason: 'config.flexConfig.timerConfig.inactivity.reason',
  flexLostFocusDuration: 'config.flexConfig.timerConfig.lostfocus.duration',
  flexLostFocusMessage: 'config.flexConfig.timerConfig.lostfocus.message',
  flexLostFocusReason: 'config.flexConfig.timerConfig.lostfocus.reason',
}

export const tooltipMap: Record<string, string> = {
  'trainings.trainings.tab': 'Individual trainings used for assignments',
  'trainings.trainingBundles.tab':
    'Training bundles comprised of individual trainings and can be assigned as a bundle',
  'trainings.trainingAutoAssignment.tab': 'Auto assignment rules for trainings',
  'trainings.AddTraining.TrainingID': 'https:// will automatically be added to the URL',
  'trainings.AddTraining.Tags': 'Select from existing tags. To add new tag, go to Configuration',
  'trainings.AddTraining.Priority':
    'When due dates are the same, priority will determine delivery order',
  'trainings.AddTraining.MaxDelivery':
    'Works in conjection with what is selected on Max Delivery Type',
  'trainings.AddTraining.MonitorMethodMonitor':
    'Determine if and what type of monitoring is used for training',
  'trainings.AddTraining.MaxDeliveryType':
    'Attempts is defined as starting a training. Percentage is total time allowed for the training based on duration.',
  'rules.trainingRules.threshold': 'Based on queue',
  'rules.trainingRules.maxExpertsLimit': 'Based on expert count',
  'configuration.Aspect.TrainingBuffer': 'Number of minutes added to each Training Segment',
  'configuration.Dispatch.Cooldown':
    'Elapse time after a training is completed before another training can be assigned.',
  'configuration.Dispatch.EjectCushion':
    'Amount of time before expert is ejected after receiving ejection warning',
  'configuration.Dispatch.MaximumDeliveryPerDay':
    'Number of trainings an expert can receive per day',
  'configuration.Dispatch.TrainingTimer':
    'When training ends, amount of time expert is given to indicate if training was completed',
  'configuration.Dispatch.GlobalOutageSettings': 'This is for setting a total system outage window',
  'configuration.Dispatch.Tags': 'Tags to be added to trainings for reporting purposes',
  'configuration.Dispatch.SystemFlag': 'Shut Off will turn the TTD system off completely',
  'configuration.Domains/Titles.Domains':
    'URLs (other than trainings) experts are allowed to access while in training',
  'configuration.Domains/Titles.WindowTitles':
    'Window names of applications experts are allowed to access while in training',
  'configuration.Flex.Audio': 'Listen to the audio files available for configuration.',
  'configuration.Flex.Monitoring.lostfocusgraceperiod':
    "The amount of time you're allowed to be on an unapproved site",
  'configuration.Flex.Monitoring.lostfocustitle':
    'Name that will appear as the title of the screen',
  'configuration.Flex.Monitoring.lostfocustimer':
    'Amount of time (in seconds) that will show for the countdown on message',
  'configuration.Flex.Monitoring.lostfocusmessage':
    'Customizable messages that appears on the screen regarding the alert.',
  'configuration.Flex.Monitoring.inactivitygraceperiod':
    "The amount of time you're allowed to be on an unapproved application",
  'configuration.Flex.Monitoring.inactivitytitle':
    'Name that will appear as the title of the screen',
  'configuration.Flex.Monitoring.inactivitytimer':
    'Amount of time (in seconds) that will show for the countdown on message',
  'configuration.Flex.Monitoring.inactivitymessage':
    'Customizable messages that appears on the screen regarding the alert.',
  'configuration.ChromeNotifications.Title':
    'Name that will appear as the title of the notification',
  'configuration.ChromeNotifications.Duration':
    'Time, in seconds, which the notification will remain on screen',
  'configuration.ChromeNotifications.PlayAudio': 'Determines if notification will have audio file',
  'configuration.ChromeNotifications.AudibleDing': 'Allows audio file selection',
  'configuration.ChromeNotifications.Text': 'Message in notification (180 character limit)',
  'configuration.TrainingDialog.Title': 'Name of dialog box (not visible to expert)',
  'configuration.TrainingDialog.Duration':
    'Time, in seconds, which the notification will remain on screen',
  'configuration.TrainingDialog.Reason': 'Reason for the dialog box (not visible to expert)',
  'configuration.TrainingDialog.AudibleDing': 'Allows audio file selection',
  'configuration.TrainingDialog.Message':
    'Customizable messages that appears on the screen regarding the alert.',
  'assignments.percentComplete':
    'How much of training we calculate is complete based on time spent in training vs duration',
  'assignments.completionPercent':
    'What percent of the time the expert finishes training on first attempt. Ejection is not included.',
  'broadcast.type1': 'Citrix notifications disrupt expert activity.',
  'broadcast.type2': 'Task notifications wait until Experts are available.',
}
