import React from 'react'
import { INotificationConfigProps } from '../../../../../interfaces'
import { Flex } from '../../../../common'
import { ChromeNotifications } from '../../Forms'
import { checkPermissions } from '../../../../../utils'

const ChromeConfig = ({
  config,
  dispatch,
  audioOptions,
  audioConfig,
  popToast,
}: {
  config: Record<string, INotificationConfigProps>
  dispatch: any
  audioOptions: any
  audioConfig: any
  popToast: any
}) => {
  const permission = checkPermissions('flex', 'edit')
  const handleChange = (key: string, value: {}, keyMap: string) => {
    if (!permission) popToast('You do not have permission to change Flex Configurations', 'error')
    const updatedConfig: Record<string, any> = { ...config }
    updatedConfig[key] = { ...updatedConfig[key], ...value }
    dispatch({ type: 'setNotificationConfig', payload: { ...config, ...updatedConfig } })
    dispatch({ type: 'setKeyMap', payload: { [keyMap]: Object.values(value)[0] } })
  }
  return (
    <React.Fragment>
      <Flex config={['row', 'wrap']} style={{ padding: 5 }}>
        {Object.keys(config).map((prop) => {
          return (
            <ChromeNotifications
              {...config[prop]}
              name={prop}
              audioConfig={audioConfig}
              audioOptions={audioOptions}
              handleChange={handleChange}
            />
          )
        })}
      </Flex>
    </React.Fragment>
  )
}

export default ChromeConfig
