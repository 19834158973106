import React, { ChangeEvent } from 'react'
import { IconComponent, FormLabel, TextArea } from '../../index'

const Notes = ({ trainingNotes, setTrainingNotes, overwrite, setOverwrite }: any) => {
  return (
    <FormLabel id="trainingNotes" value="Training Notes">
      <TextArea
        style={{ width: 400 }}
        value={trainingNotes}
        placeholder="Training notes"
        handleChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
          setTrainingNotes(e.currentTarget.value)
        }}
      />
      <button
        className={'ttd-bare-button'}
        style={{ display: 'flex', cursor: 'pointer', width: 270 }}
        onClick={() => {
          setOverwrite('Inactive')
        }}
      >
        <IconComponent
          icon={overwrite === 'Inactive' ? 'radio_button_checked' : 'radio_button_unchecked'}
        />
        <span style={{ marginLeft: 15 }}>Override existing notes</span>
      </button>
      <button
        className={'ttd-bare-button'}
        style={{ display: 'flex', cursor: 'pointer', width: 270 }}
        onClick={() => {
          setOverwrite('Active')
        }}
      >
        <IconComponent
          icon={overwrite === 'Active' ? 'radio_button_checked' : 'radio_button_unchecked'}
        />
        <span style={{ marginLeft: 15 }}>Add notes in addition</span>
      </button>
    </FormLabel>
  )
}

export default Notes
