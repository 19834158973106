import React from 'react'
import { ToggleSwitchStyled, ToggleSwitchDivStyled, ToggleSwitchDivTop } from './styles'
import { ToggleSwitchProps } from './interfaces'

const ToggleSwitch = ({ onClick, selected, primaryLabel, secondaryLabel }: ToggleSwitchProps) => {
  const primarySelected = selected === primaryLabel
  const secondarySelected = selected === secondaryLabel
  return (
    <ToggleSwitchStyled onClick={onClick}>
      <ToggleSwitchDivTop selectedStyle={Boolean(secondarySelected)} />
      <ToggleSwitchDivStyled selectedStyle={Boolean(primarySelected)}>
        {primaryLabel}
      </ToggleSwitchDivStyled>
      <ToggleSwitchDivStyled selectedStyle={Boolean(secondarySelected)}>
        {secondaryLabel}
      </ToggleSwitchDivStyled>
    </ToggleSwitchStyled>
  )
}

export default ToggleSwitch
