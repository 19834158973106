import React from 'react'
import { Dropdown, FormLabel } from '../../index'
import { IDropdownOption } from '../../Atoms/Dropdown'

const Priority = ({ priorityOptions, priority, setPriority }: any) => {
  return (
    <FormLabel id="priority" value="Priority">
      <Dropdown
        type="rolled"
        style={{ width: 170 }}
        options={priorityOptions}
        selected={priority}
        emitDropdownOption={(e: IDropdownOption) => {
          setPriority(e)
        }}
      />
    </FormLabel>
  )
}

export default Priority
