import styled from 'styled-components'

export const FusionModalContent = styled.div`
  display: flex;
  min-height: 100%;
  max-width: 100%;
  justify-content: center;

  .sidebar-wrapper {
    width: 400;
    min-height: auto;
    max-height: 750;
    overflow: auto;
    border-right: 1px solid #efefef;
    margin-right: 15;
  }

  h5 {
    color: var(--grimace);
  }
`
