import { API } from 'aws-amplify'
import { format } from 'date-fns'
import { IReportingDistributionRecord } from '../context/ReportingDistributionContext'

export const fetchReport = async (body: any) => {
  let date = new Date()
  body.timezoneOffsetMinutes = date.getTimezoneOffset() * -1
  try {
    const apiName = 'ADMIN'
    const apiPath = '/sqlreporting'
    const params = {
      queryStringParameters: {},
      body,
    }
    const reportUrl = await API.post(apiName, apiPath, params)
    return reportUrl
  } catch (e) {
    return console.error(e)
  }
}

export const saveReport = async (body: any) => {
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const table = 'ReportingDistribution'
    const params = {
      queryStringParameters: {
        table,
      },
      body,
    }

    return await API.post(apiName, apiPath, params)
  } catch (e) {
    return console.error(e)
  }
}
export const updateSavedReport = async (body: any, id: string) => {
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const table = 'ReportingDistribution'
    const params = {
      queryStringParameters: {
        table,
        id,
      },
      body: {
        set: { ...body },
      },
    }

    return await API.put(apiName, apiPath, params)
  } catch (e) {
    return console.error(e)
  }
}

export const getSavedReports = async (empId: string) => {
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const table = 'ReportingDistribution'
    const params = {
      queryStringParameters: {
        table,
        action: 'scan',
      },
      body: {
        scan: {
          empId,
        },
      },
    }

    return await API.post(apiName, apiPath, params)
  } catch (e) {
    return console.error(e)
  }
}

export const mapSavedReportsForTable = (data: IReportingDistributionRecord[]) => {
  const frequencyMap: Record<string, string> = {
    daily: 'Daily',
    last7: 'Weekly',
    last30: 'Monthly',
  }
  return (
    data &&
    data.map((savedReport: any) => {
      const { frequency } = savedReport

      let freq = ''
      let lastRunTime = ''

      for (const key in frequency) {
        if (frequency[key]['isActive'] === '1') {
          freq = frequencyMap[key]
          if (frequency[key]['lastRunTime']) {
            lastRunTime = format(new Date(frequency[key]['lastRunTime']), 'MM/dd/yyyy p')
          }
        }
      }

      return {
        ...savedReport,
        freq,
        lastRunTime,
      }
    })
  )
}

export const handleSetSelectedIdsIfAnyGroupsWereSaved = async ({
  payload,
  selectedIds,
  setSelectedIds,
}: {
  payload: any
  selectedIds: any
  setSelectedIds: any
}) => {
  try {
    const promises: Promise<any>[] = []
    payload.departmentGroupIds.forEach((groupId: string) => {
      const params: any = {
        queryStringParameters: { table: 'EmpGroups', id: groupId },
      }
      const apiName = 'ADMIN'
      const apiPath = '/admin'
      promises.push(API.get(apiName, apiPath, params))
    })
    const records = await Promise.all(promises)
    const tempMap: Map<any, any> = new Map(selectedIds)
    records.forEach(({ item }: any) => {
      const existingRecords: string[] = tempMap.get(item.groupTreeId) || []
      tempMap.set(item.groupTreeId, Array.from(new Set([...existingRecords, item.groupId])))
    })
    return await setSelectedIds(tempMap)
  } catch (e) {
    console.log(e)
    return
  }
}
