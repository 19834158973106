import React, { useState, useEffect, ChangeEvent, useContext, useMemo } from 'react'
import { API, Storage } from 'aws-amplify'
import '@asurion/atomic-ui-library'
import '@asurion/sass-design-system/build/asurion/asurion.css'

import { max, min } from 'date-fns'
import { ToastContainer, toast } from 'react-toastify'
import { useForm, Controller } from 'react-hook-form'

import { assignmentHeaders, optionConfig, baseURLs } from '../../../constants'
import { checkPermissions, handleInput, sort } from '../../../utils'
import {
  batchGetAssignmentsByAssignmentId,
  getAssignmentsForTraining,
  mapAssignments,
} from '../../../actions'

import { AdminConfigContext } from '../../../context/AdminConfigContext'
import { AssignTrainingContext } from '../../../context/AssignTrainingContext'
import { RouteContext } from '../../../context/RouteContext'
import { GroupsContext } from '../../../context/GroupsContext'

import TrainingAssignmentModal from '../TrainingAssignmentModal'
import {
  Button,
  DragAndDrop,
  Dropdown,
  FilterableTable,
  Flex,
  FormLabel,
  Input,
  IconComponent,
  Loading,
  Modal,
  Confirm,
  SubmitButton,
  TextField,
  TextArea,
  WarningModal,
} from '../../common'

import { IAttachment, IDropdownOption, IMappedAssignments } from '../../../interfaces'

import RenderConfetti from './RenderConfetti'
import { getEnvironment } from '../../../utils/applicationEnvironment'
import { useSelector } from 'react-redux'
import themes from '../../../themes/schema'

export default function AddTraining() {
  const api: any = API

  const {
    priority: priorityOptions,
    maxDispatchLimitTypes: maxDispatchLimitTypesOptions,
    monitor: monitorOptions,
  } = optionConfig

  const {
    handleNavigation,
    selectedLink,
    setSelectedLink,
    selectedLinkProps,
    setSelectedLinkProps,
    setHasEdited,
    showAlert,
    setShowAlert,
  } = useContext(RouteContext)

  const { resetAllChecks, setSelectedIds } = useContext(AssignTrainingContext)
  const { tags: unformattedTags } = useContext(AdminConfigContext)
  const { assignments, setAssignments, optimisticallyUpdateTrainingAssignment } =
    useContext(GroupsContext)
  const formatTags = (unformattedTags: string[] = []) => {
    if (unformattedTags) {
      return unformattedTags?.map((tag: string) => ({
        display: tag,
        id: tag,
        value: tag,
      }))
    } else {
      return []
    }
  }

  const formattedTags =
    unformattedTags &&
    formatTags(unformattedTags).sort(
      ({ value: a }: { value: string }, { value: b }: { value: string }) => (a > b ? 1 : -1),
    )

  // const [assignments, setAssignments] = useState<any[]>([])
  const [attachments, setAttachments] = useState<Map<string, IAttachment>>(new Map())
  const [assignmentTasks, setAssignmentTasks] = useState<any[]>([])
  const [dueDate, setDueDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [loading, setLoading] = useState(false)
  const [assLoad, setAssLoad] = useState<boolean>(false)
  const [fileToDelete, setFileToDelete] = useState<any>({})
  const [isActive, setIsActive] = useState('Inactive')
  const [isUpdating, setIsUpdating] = useState<boolean>(false)
  const [maxAttempts, setMaxAttempts] = useState('5')
  const [maxDispatchLimitType, setMaxDispatchLimitType] = useState(maxDispatchLimitTypesOptions[0])
  const [monitor, setMonitor] = useState(monitorOptions[0])
  const [myAbsorbId, setMyAbsorbId] = useState<string>('')
  const [priority, setPriority] = useState(priorityOptions[0])
  const [selectedTags, setSelectedTags] = useState<IDropdownOption[]>([])
  const [showAssignmentModal, setShowAssignmentModal] = useState<boolean>(false)
  const [showAttachmentModal, setShowAttachmentModal] = useState<boolean>(false)
  const [showDeleteWarning, setShowDeleteWarning] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [trainingLength, setTrainingLength] = useState('')
  const [trainingLink, setTrainingLink] = useState('')
  const [trainingName, setTrainingName] = useState('')
  const [trainingNotes, setTrainingNotes] = useState('')
  const [formType, setFormType] = useState<string>(
    selectedLinkProps.trainingId ? 'edit' : 'newTraining',
  )
  const [assignmentType, setAssignmentType] = useState<string>('')
  const [assignmentTrainings, setAssignmentTrainings] = useState<IMappedAssignments[]>([])
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      trainingName: '',
      duration: '',
      priority: { display: 'Medium', id: 'priority', value: '1' },
      isActive: 'Active',
      maxAttempts: '5',
      maxDispatchLimitType: { display: 'Attempts', id: 'attempts', value: 'attempts' },
      trainingLink: '',
      trainingId: '',
      monitorMethod: { display: '', id: '', value: '' },
      trainingNotes: '',
      tags: [{ id: '', value: '', display: '' }],
    },
  })

  const completion = selectedLinkProps.trainingPercentComplete
  const getDatesFromAssignments = (assignments: Record<string, any>) => {
    const minStartDate = min(
      assignments.map(({ startDate }: { startDate: string }) => new Date(startDate)),
    )
    const maxDueDate = max(assignments.map(({ dueDate }: { dueDate: string }) => new Date(dueDate)))
    const maxEndDate = max(assignments.map(({ endDate }: { endDate: string }) => new Date(endDate)))
    return [
      minStartDate.toLocaleDateString(),
      maxDueDate.toLocaleDateString(),
      maxEndDate.toLocaleDateString(),
    ]
  }
  const {
    userSettings: { darkMode },
  } = useSelector((state: any) => state?.user)
  const customDisplayType = darkMode ? 'darkMode' : 'originalFlavor'
  const getStyle: Record<string, any> = {
    labelColor_darkMode: themes.darkMode.label.color,
    color_darkMode: themes.darkMode.secondColor,
    labelColor_originalFlavor: themes.originalFlavor.label.color,
    color_originalFlavor: themes.originalFlavor.secondColor,
  }

  const popToast = (msg: string) => toast(msg)
  const getAssignmentsByTrainingId = async (trainingId: string) => {
    if (trainingId) {
      try {
        setAssLoad(true)
        const assignmentTrainingCall = await getAssignmentsForTraining(trainingId)
        setAssignmentTrainings(assignmentTrainingCall || [])
      } finally {
        setAssLoad(false)
      }
    }
  }
  useEffect(() => {
    if (selectedLinkProps.trainingId) {
      const {
        isActive,
        maxDispatchLimit,
        maxDueDate,
        maxEndDate,
        minStartDate,
        myAbsorbId,
        monitorType,
        priority,
        tags,
        trainingAttachments = [],
        trainingLength,
        trainingLink: trainingLinkProps,
        trainingName,
        trainingNotes,
      } = selectedLinkProps
      const start = new Date(minStartDate).toLocaleDateString()
      const due = new Date(maxDueDate).toLocaleDateString()
      const end = new Date(maxEndDate).toLocaleDateString()
      setStartDate(start)
      setDueDate(due)
      setEndDate(end)
      const monitorMethodSelected = monitorOptions.find(
        (method: IDropdownOption) => method.value === monitorType,
      )
      const renderedAttachments: any =
        trainingAttachments?.length > 0
          ? new Map(trainingAttachments.map((attachment: any) => [attachment.title, attachment]))
          : attachments
      const maxAttemptMatch = maxDispatchLimitTypesOptions?.find(
        (option: IDropdownOption) => option.value === maxDispatchLimit?.unitOfMeasurement,
      )
      const monitorMatch = monitorOptions.find(
        (option: IDropdownOption) => option.value === monitor,
      )
      const priorityMatch = priorityOptions.find(
        (option: IDropdownOption) => option.value === priority,
      )
      const formattedMaxAttempts =
        maxDispatchLimit?.unitOfMeasurement === 'percent'
          ? `${Number(maxDispatchLimit.value) * 100}`
          : maxDispatchLimit?.value

        ; (async () => {
          try {
            await getAssignmentsByTrainingId(selectedLinkProps.trainingId)
            //const assignmentTrainingsMapped = mapAssignments(assignmentTrainingCall)
          } catch (e) {
            console.error('Error when loading the Training Assignments: ', e)
          }
        })()

      setAttachments(renderedAttachments)
      setIsActive((Number(isActive) ? 'Active' : 'Inactive') || 'Inactive')
      setIsUpdating(true)
      setMaxAttempts(formattedMaxAttempts)
      setMaxDispatchLimitType(maxAttemptMatch)
      setMyAbsorbId(myAbsorbId)
      setPriority(priorityMatch)
      setSelectedTags(formatTags(tags))
      setTrainingLink(trainingLinkProps)
      setTrainingName(trainingName)
      setTrainingNotes(trainingNotes)
      setTrainingLength(trainingLength)
      setMonitor(monitorMethodSelected)
      setValue('duration', trainingLength)
      setValue('maxAttempts', formattedMaxAttempts)
      setValue('trainingName', trainingName)
      setValue('trainingLink', trainingLinkProps)
    } else {
      setAttachments(new Map())
      setIsActive('Active')
      setMonitor(monitorOptions[0])
      setMyAbsorbId('')
      setPriority(priorityOptions[0])
      setSelectedTags([])
      setTrainingName('')
      setTrainingLink('')
      setTrainingNotes('')
      setMaxAttempts('5')
      setMaxDispatchLimitType(maxDispatchLimitTypesOptions[0])
      setTrainingLength('3')
      setValue('duration', '3')
      setValue('maxAttempts', '5')
    }
  }, [selectedLinkProps])

  /**
   * returns [strings] this array is stored in DynamoDB
   */
  // eslint-disable-next-line no-unused-vars
  const uploadFilesToS3: (trainingId: string) => any[] = (trainingId) => {
    let updatedAttachments: any[] = []
    Array.from(attachments.values()).map((file) => {
      try {
        const { filename: existingFilename, title, type, wasPreviouslyUploaded = false } = file
        const filename = wasPreviouslyUploaded
          ? existingFilename
          : title
            ? title.replace(/\s/g, '-')
            : ''
        const filepath = `${trainingId}/${filename}`
        let url
        if (!wasPreviouslyUploaded) {
          Storage.put(`${filepath}`, file, {
            contentType: file.type,
          })
            .then((result: any) => console.log(result))
            .catch((err: any) => console.log(err))
          const timestamp = Date.now()
          const env: string = getEnvironment()
          const baseURL: string = baseURLs[env]
          url = `${baseURL}/${filepath}?timestamp=${timestamp}`
        } else {
          url = file.url
        }
        updatedAttachments = [
          ...updatedAttachments,
          { title, type, filename, url, wasPreviouslyUploaded: true },
        ]
      } catch (err) {
        console.error('err', err)
      }
    })
    const attachmentsSetToMap = new Map(
      updatedAttachments.map((attachment: any) => [attachment.title, attachment]),
    )
    setAttachments(attachmentsSetToMap)
    return updatedAttachments
  }

  const handleFormSubmit = async (formData: any) => {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const method: Record<string, any> = {
      edit: 'put',
      newTraining: 'post',
    }
    const formattedMaxAttempts =
      maxDispatchLimitType?.value === 'percent' ? `${Number(maxAttempts) / 100}` : maxAttempts
    const prependTrainingLink = (link: string) =>
      link.slice(0, 8) === 'https://' ? link : `https://${link}`
    const prependedTrainingLink = prependTrainingLink(trainingLink)
    const checkProtocol = (str: string) => str.includes('https://')
    const addProtocol = (link: string) => (checkProtocol(link) ? link : 'https://' + link)
    const data: Record<string, any> = {
      tags: selectedTags?.map(({ value }: any) => value),
      trainingName: trainingName,
      monitorType: monitor?.value,
      priority: priority?.value,
      trainingLink: addProtocol(prependedTrainingLink),
      maxDispatchLimit: {
        unitOfMeasurement: maxDispatchLimitType?.value,
        value: formattedMaxAttempts ? formattedMaxAttempts : '3',
      },
      trainingNotes: trainingNotes,
      trainingLength: trainingLength ? trainingLength : '5',
      myAbsorbId: myAbsorbId || '',
      isActive: isActive === 'Active' ? '1' : '0',
    }
    const options: Record<string, any> = {
      put: {
        queryStringParameters: {
          table: 'Trainings',
          id: selectedLinkProps.trainingId,
        },
        body: { set: data, rdsSet: data },
      },
      post: {
        queryStringParameters: {
          table: 'Trainings',
        },
        body: data,
      },
    }
    //removes any attachments that were uploaded to the S3bucket
    if (selectedLinkProps.trainingId) {
      const { filename } = fileToDelete
      Storage.remove(`${selectedLinkProps.trainingId}/${filename}`)
        .then((result: any) => console.log(result))
        .catch((err: any) => console.log(err))
    } else {
      setFormType('newTraining')
    }
    const isNewTraining =
      selectedLinkProps &&
      Object.keys(selectedLinkProps).length === 0 &&
      selectedLinkProps.constructor === Object
    const formCheck = isNewTraining ? 'newTraining' : 'edit'
    return await api[method[formCheck]](apiName, apiPath, options[method[formCheck]])
      .then(async ({ response: result }: any) => {
        const idToSend = formCheck === 'edit' ? selectedLinkProps.trainingId : result.trainingId
        const responseAttributes = result
        let updatedData = { ...responseAttributes, trainingId: idToSend }
        if (attachments.size) {
          const links = uploadFilesToS3(idToSend)
          options.put.body.set.trainingAttachments = links
          options.put.body.rdsSet.trainingAttachments = links
          options.put.queryStringParameters.id = idToSend
          const response = await api.put(apiName, apiPath, options.put)
          Object.assign(updatedData, {
            ...JSON.parse(response.results[0].body).response.Attributes,
          })
          setHasEdited(false)
        } else {
          options.put.body.set.trainingAttachments = []
          options.put.body.rdsSet.trainingAttachments = []
          options.put.queryStringParameters.id = idToSend
          const response = await api.put(apiName, apiPath, options.put)
          Object.assign(updatedData, {
            ...JSON.parse(response.results[0].body).response.Attributes,
          })
          setHasEdited(false)
        }
        if (formType === 'edit') {
          const {
            queryStringParameters: { id },
            body: { set },
          } = options.put
          optimisticallyUpdateTrainingAssignment(id, set)
        }
        setSelectedLinkProps(updatedData)
      })
      .then(() => {
        if (formCheck === 'newTraining') setFormType('edit')
        popToast('Successfully Saved')
      })
  }

  const closeModal = () => {
    setShowAttachmentModal(false)
  }

  const handleUpload = (uploadedFiles: Map<string, IAttachment>) => {
    const updatedAttachments = new Map([...attachments, ...uploadedFiles])
    setAttachments(updatedAttachments)
  }

  const renameAttachment = (prevTitle: string, newTitle: string) => {
    setHasEdited(true)
    const updatedAttachments = new Map(attachments)
    const tempFile = updatedAttachments.get(prevTitle) || ({} as IAttachment)
    updatedAttachments.delete(prevTitle)
    updatedAttachments.set(newTitle, { ...tempFile, title: newTitle })
    setAttachments(updatedAttachments)
  }

  const deleteAttachment = () => {
    setHasEdited(true)
    setShowDeleteWarning(false)
    const { title } = fileToDelete
    const tempAttachments = new Map(attachments)
    tempAttachments.delete(title)
    setAttachments(tempAttachments)
  }

  const handleDeleteClick = (file: IAttachment) => {
    setFileToDelete(file)
    setShowDeleteWarning(true)
  }
  const deleteTag = (id: string) => {
    const newTags = selectedTags.filter((tag) => tag.id !== id)
    setSelectedTags(newTags)
    return newTags
  }

  const filteredAssignments = selectedLinkProps.trainingId
    ? sort(assignmentTrainings, `workerName`, `asc`)
    : []
  const permission = checkPermissions(selectedLink, 'edit')
  return loading ? (
    <Loading size={'md'} />
  ) : (
    <div style={{ padding: '0 26px' }}>
      {selectedLinkProps.assignmentsCompleted === selectedLinkProps.assignmentsOffered &&
        selectedLinkProps.assignmentsOffered > 0 && <RenderConfetti />}
      <ToastContainer position="bottom-right" />
      {loading ? (
        <Loading size={'md'} />
      ) : (
        <Flex config={['column']} style={{ padding: '0 26px' }}>
          <button
            style={{ cursor: 'pointer', color: getStyle[`color_${customDisplayType}`] }}
            onClick={() => handleNavigation('trainings')}
            className="ttd-text-link ttd-bare-button"
          >
            &lt; Back to trainings
          </button>

          <div style={{ fontSize: 25, fontWeight: 500 }}>
            {selectedLinkProps.trainingId ? 'Edit Training' : 'Add Training'}
          </div>

          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div style={{ margin: '15px 0' }}>
              <FormLabel id="trainingName" value="Name of Training">
                <Input
                  {...register('trainingName', {
                    required: { value: true, message: 'This field is required.' },
                  })}
                  error={errors.trainingName || null}
                  style={{ width: 500, position: 'relative' }}
                  type="rolled"
                  placeholder="Name of Training"
                  value={trainingName}
                  handleChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleInput(e, setTrainingName)
                    setValue('trainingName', e.target.value)
                    setHasEdited(true)
                  }}
                />
              </FormLabel>
            </div>
            {/* Column A */}
            <Flex config={['justifyBetween']}>
              <Flex
                config={['column', 'justifyBetween']}
                style={{
                  height: 350,
                }}
              >
                <FormLabel id="status" value="Status">
                  <button
                    type="button"
                    className={'ttd-bare-button'}
                    style={{ display: 'flex', cursor: 'pointer', width: 170 }}
                    onClick={() => {
                      setIsActive('Active')
                      setHasEdited(true)
                    }}
                  >
                    <IconComponent
                      icon={
                        isActive === 'Active' ? 'radio_button_checked' : 'radio_button_unchecked'
                      }
                    />
                    <span
                      style={{ marginLeft: 15, color: getStyle[`labelColor_${customDisplayType}`] }}
                    >
                      Active
                    </span>
                  </button>
                  <button
                    type="button"
                    className={'ttd-bare-button'}
                    style={{ display: 'flex', cursor: 'pointer', width: 170 }}
                    onClick={() => {
                      setIsActive('Inactive')
                      setHasEdited(true)
                    }}
                  >
                    <IconComponent
                      icon={
                        isActive === 'Inactive' ? 'radio_button_checked' : 'radio_button_unchecked'
                      }
                    />
                    <span
                      style={{ marginLeft: 15, color: getStyle[`labelColor_${customDisplayType}`] }}
                    >
                      Inactive
                    </span>
                  </button>
                </FormLabel>
                <FormLabel
                  id="priority"
                  value="Priority"
                  tooltip={{ selector: 'trainings.AddTraining.Priority' }}
                >
                  <Dropdown
                    type="rolled"
                    style={{ width: 170 }}
                    selected={priority}
                    options={priorityOptions}
                    emitDropdownOption={(e: IDropdownOption) => {
                      setPriority(e)
                      setHasEdited(true)
                      setValue('priority', e)
                    }}
                  />
                </FormLabel>
                <FormLabel id="duration" value="Duration (Minutes)">
                  <Input
                    {...register('duration', {
                      required: { value: true, message: 'This field is required.' },
                      validate: (value) => !isNaN(Number(value)) || 'Duration requires a number.',
                    })}
                    error={errors.duration || null}
                    style={{ width: 170, position: 'relative' }}
                    type="rolled"
                    value={trainingLength}
                    handleChange={(e: ChangeEvent<HTMLInputElement>) => {
                      handleInput(e, setTrainingLength)
                      setValue('duration', e.target.value)
                      setHasEdited(true)
                    }}
                  >
                    min
                  </Input>
                </FormLabel>
                <FormLabel
                  id="maxDelivery"
                  value="Max Delivery"
                  tooltip={{ selector: 'trainings.AddTraining.MaxDelivery' }}
                >
                  <Input
                    {...register('maxAttempts', {
                      required: { value: true, message: 'This field is required.' },
                      validate: (value) =>
                        !isNaN(Number(value)) || 'Max Delivery needs to be a number',
                    })}
                    style={{ width: 170, position: 'relative' }}
                    type="rolled"
                    value={maxAttempts}
                    handleChange={(e: ChangeEvent<HTMLInputElement>) => {
                      handleInput(e, setMaxAttempts)
                      setValue('maxAttempts', e.target.value)
                      setHasEdited(true)
                    }}
                    error={errors.maxAttempts || null}
                  >
                    {maxDispatchLimitType?.value === 'percent' ? '%' : ''}
                  </Input>
                </FormLabel>
                <FormLabel
                  id="maxDispatchLimitType"
                  value="Max Delivery Type"
                  tooltip={{ selector: 'trainings.AddTraining.MaxDeliveryType' }}
                >
                  <Dropdown
                    type="rolled"
                    style={{ width: 170 }}
                    options={maxDispatchLimitTypesOptions}
                    selected={maxDispatchLimitType}
                    emitDropdownOption={(e: IDropdownOption) => {
                      setMaxDispatchLimitType(e)
                      setValue('maxDispatchLimitType', e)
                      setHasEdited(true)
                    }}
                  />
                </FormLabel>
              </Flex>
              {/* Column B*/}
              <Flex
                config={['column', 'justifyAround']}
                style={{
                  height: 350,
                }}
              >
                <FormLabel
                  id="trainingLink"
                  value="Training Link"
                  tooltip={{ selector: 'trainings.AddTraining.TrainingID' }}
                >
                  <div style={{ position: 'relative', height: 'auto' }}>
                    <Input
                      {...register('trainingLink', {
                        validate: (value) =>
                          (value !== 'https://' && value !== '') ||
                          attachments.size !== 0 ||
                          `Training Link or Attachment is required.`,
                      })}
                      style={trainingLink || attachments ? { width: 300 } : { width: 300 }}
                      type="rolled"
                      value={trainingLink}
                      handleChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleInput(e, setTrainingLink)
                        setValue('trainingLink', e.target.value)
                        setHasEdited(true)
                      }}
                      error={errors.trainingLink || null}
                    />
                  </div>
                </FormLabel>
                <FormLabel id="myAbsorbId" value="Training ID (MyAbsorb)">
                  <Controller
                    name="trainingId"
                    control={control}
                    defaultValue={''}
                    rules={{ required: false }}
                    render={() => (
                      <Input
                        style={{ width: 300 }}
                        type="rolled"
                        value={myAbsorbId}
                        placeholder="Training ID from MyAbsorb"
                        handleChange={(e: ChangeEvent<HTMLInputElement>) => {
                          handleInput(e, setMyAbsorbId)
                          setValue('trainingId', e.target.value)
                          setHasEdited(true)
                        }}
                      />
                    )}
                  />
                </FormLabel>
                <FormLabel id="percentComplete" value="% Complete">
                  <div style={{ padding: '15px 0' }}>{completion}</div>
                </FormLabel>
                <FormLabel
                  id="monitorMethod"
                  value="Monitor Method"
                  tooltip={{ selector: 'trainings.AddTraining.MonitorMethodMonitor' }}
                >
                  <Dropdown
                    type="rolled"
                    style={{ width: '100%' }}
                    options={monitorOptions}
                    selected={monitor}
                    emitDropdownOption={(e: IDropdownOption) => {
                      setMonitor(e)
                      setHasEdited(true)
                      setValue('monitorMethod', e)
                    }}
                  />
                </FormLabel>
              </Flex>
              {/* Column C */}
              <Flex
                config={['column', 'justifyBetween']}
                style={{
                  height: 350,
                }}
              >
                <FormLabel id="startDate" value="Start Date">
                  <div style={{ padding: '15px 0' }}>{startDate}</div>
                </FormLabel>
                <FormLabel id="dueDate" value="Due Date">
                  <div style={{ padding: '15px 0' }}>{dueDate}</div>
                </FormLabel>
                <FormLabel id="endDate" value="End Date">
                  <div style={{ padding: '15px 0' }}>{endDate}</div>
                </FormLabel>
                <FormLabel id="lastModified" value="Last Modified">
                  <div style={{ padding: '15px 0' }}>{selectedLinkProps.lastModified}</div>
                </FormLabel>
              </Flex>
              {/* Column D */}
              <Flex
                config={['column', 'justifyBetween']}
                style={{
                  height: 350,
                  width: 500,
                }}
              >
                <FormLabel
                  id="tags"
                  value="Tags"
                  tooltip={{ selector: 'trainings.AddTraining.Tags' }}
                >
                  <Flex style={{ width: '100%' }}>
                    <Flex
                      config={['wrap']}
                      style={{
                        width: 500,
                      }}
                    >
                      <Dropdown
                        type="rolled"
                        style={{ width: 175 }}
                        multipleSelect={true}
                        options={formattedTags}
                        selected={{
                          value: 'Add Tags',
                          display: 'Add Tags',
                          id: 'none',
                        }}
                        emitDropdownOption={(tag = [{}]) => {
                          setHasEdited(true)
                          let uniqueIds = [
                            ...new Set(selectedTags.map((tag: IDropdownOption) => tag.id)),
                          ]
                          if (uniqueIds.includes(tag.id)) {
                            return
                          } else {
                            let sortedTags = [...selectedTags, tag].sort(
                              ({ value: a }, { value: b }) => (a > b ? 1 : -1),
                            )
                            setSelectedTags(sortedTags)
                            setValue('tags', sortedTags)
                          }
                        }}
                      />
                      <div
                        id={'test'}
                        style={
                          // these were undefined for some reason
                          selectedTags && selectedTags.length > 0
                            ? {
                              display: 'flex',
                              justifyContent: 'space-around',
                              width: '50%',
                              flexWrap: 'wrap',
                              border: '1px solid gray',
                              boxSizing: 'border-box',
                              padding: '0.5rem',
                              maxHeight: '12rem',
                              margin: '0 0.5rem',
                              borderRadius: '4px',
                            }
                            : { display: 'none' }
                        }
                      >
                        {selectedTags &&
                          selectedTags.map(({ value, id }, i) => {
                            return (
                              <div
                                key={id}
                                style={{
                                  border: '1px solid gray',
                                  height: '2rem',
                                  display: 'flex',
                                  padding: '5px 8px',
                                  borderRadius: '14px',
                                  background: '#777',
                                  color: 'whitesmoke',
                                  margin: '2px',
                                }}
                              >
                                <p
                                  style={{
                                    alignSelf: 'center',
                                  }}
                                  key={id}
                                >
                                  {value}
                                </p>
                                <span
                                  style={{
                                    alignSelf: 'center',
                                    margin: '0 0 0 0.5rem',
                                  }}
                                  onClick={() => {
                                    setHasEdited(true)
                                    deleteTag(id)
                                    const newTags = deleteTag(id)
                                    setValue('tags', newTags) // [{dispaly, id, value}]
                                  }}
                                >
                                  x
                                </span>
                              </div>
                            )
                          })}
                      </div>
                    </Flex>
                  </Flex>
                </FormLabel>

                <FormLabel id="trainingNotes" value="Training Notes">
                  <Controller
                    name="trainingNotes"
                    control={control}
                    defaultValue={''}
                    rules={{ required: false }}
                    render={({ field }: any) => (
                      <TextArea
                        style={{ width: 400 }}
                        value={trainingNotes}
                        placeholder="Training notes"
                        handleChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                          setHasEdited(true)
                          setValue('trainingNotes', e.currentTarget.value)
                          setTrainingNotes(e.currentTarget.value)
                        }}
                      />
                    )}
                  />
                </FormLabel>
              </Flex>
            </Flex>
            <Flex config={['justifyStart']} style={{ margin: '55px 0' }}>
              {permission && <SubmitButton>Save Training</SubmitButton>}
              <Button
                displayType="outline"
                size="lg"
                handleClick={(e: any) => {
                  e.preventDefault()
                  setSelectedLink('trainings')
                }}
                className="is-primary is-ghost"
                style={{ marginLeft: 25 }}
              >
                Cancel
              </Button>
            </Flex>
          </form>
          {/* Attachments */}
          <div>
            <hr style={{ marginTop: 50 }} />
            <div>
              <Flex style={{ marginBottom: 17 }}>
                <div style={{ fontSize: 25, fontWeight: 500 }}> Attachments</div>
                {permission && (
                  <Button
                    displayType="outline"
                    size={'sm'}
                    handleClick={() => setShowAttachmentModal(true)}
                    style={{ marginTop: 2.5, marginLeft: 15, width: 250 }}
                  >
                    Add Attachment
                    <IconComponent style={{ marginLeft: 5 }} icon={'add_circle_outline'} />
                  </Button>
                )}
              </Flex>
              <Flex config={['column']}>
                {Array.from(attachments.values()).map((attachment: IAttachment) => (
                  <Flex config={['alignItems', 'alignContentCenter']} key={attachment.title}>
                    <TextField
                      link={attachment.url}
                      isUpdating={isUpdating}
                      deleteField={() => handleDeleteClick(attachment)}
                      saveChanges={renameAttachment}
                      value={attachment.title}
                    />
                  </Flex>
                ))}
              </Flex>
            </div>

            <hr style={{ marginTop: 50 }} />

            {/* Assignments */}
            <Flex style={{ marginBottom: 25 }}>
              <div style={{ fontSize: 25, fontWeight: 500 }}> Assignments</div>

              {permission && (
                <Button
                  displayType="outline"
                  size={'sm'}
                  handleClick={() => {
                    setAssignmentType('individual')
                    setShowAssignmentModal(true)
                  }}
                  style={{ marginTop: 2.5, marginLeft: 15 }}
                >
                  Assign Individual Training
                  <IconComponent style={{ marginLeft: 5 }} icon={'add_circle_outline'} />
                </Button>
              )}
              {permission && (
                <Button
                  displayType="outline"
                  size={'sm'}
                  handleClick={() => {
                    setAssignmentType('group')
                    setShowAssignmentModal(true)
                  }}
                  style={{ marginTop: 2.5, marginLeft: 15 }}
                >
                  Assign Group Training
                  <IconComponent style={{ marginLeft: 5 }} icon={'add_circle_outline'} />
                </Button>
              )}
            </Flex>
            {
              <FilterableTable
                loading={assLoad}
                onUpdate={async () => {
                  await getAssignmentsByTrainingId(selectedLinkProps.trainingId)
                }}
                width={1100}
                headers={assignmentHeaders}
                data={filteredAssignments}
                useEditModal
              />
            }
          </div>
        </Flex>
      )}
      {/* Modals */}

      {showAttachmentModal && (
        <Modal header="Add attachment" close={closeModal}>
          <DragAndDrop saveAttachments={handleUpload} closeModal={closeModal} />
        </Modal>
      )}
      {showDeleteWarning && (
        <Modal height="250px" header="Delete attachment" close={() => setShowDeleteWarning(false)}>
          <WarningModal confirm={deleteAttachment} closeModal={() => setShowDeleteWarning(false)} />
        </Modal>
      )}
      {showAssignmentModal && (
        <Modal
          header="Assign training"
          close={() => {
            resetAllChecks(true)
            setSelectedIds(new Map())
            setShowAssignmentModal(false)
          }}
        >
          <TrainingAssignmentModal
            handleRefreshAssignment={async (assignmentIds) => {
              resetAllChecks(true)
              setShowAssignmentModal(false)
              if (
                !assignments.some(({ assignmentId }: { assignmentId: string }) =>
                  assignmentIds.includes(assignmentId),
                )
              ) {
                const localAssignments = await batchGetAssignmentsByAssignmentId(assignmentIds)
                setAssignments([...assignments, ...localAssignments])
                await getAssignmentsByTrainingId(selectedLinkProps.trainingId)
              }
            }}
            trainingId={selectedLinkProps.trainingId}
            assignmentType={assignmentType}
            handleCancel={() => {
              setSelectedIds(new Map())
              resetAllChecks(true)
              setShowAssignmentModal(false)
            }}
            showsHeader={true}
          />
        </Modal>
      )}
      {showAlert && (
        <Modal
          header="Warning"
          close={() => {
            setShowAlert(false)
          }}
        >
          <Confirm
            handleConfirm={() => {
              setSelectedLink('trainings')
              setHasEdited(false)
              setShowAlert(false)
            }}
            handleCancel={() => {
              setShowAlert(false)
            }}
          />
        </Modal>
      )}
    </div>
  )
}
