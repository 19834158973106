import React, { useEffect, useState, useContext } from 'react'
import { AssignTrainingContext, IData, INode } from '../../../context/AssignTrainingContext'
import { ButtonSet, Flex, IconComponent } from '../../common/index'
import { ToastContainer, toast } from 'react-toastify'
import { Divider, Item } from './styles'
import { IGroup, RootNames } from '../../../interfaces'

const AssignedGroups = ({
  selectedGroupsData,
  close,
  showButtons = false,
  update,
}: {
  selectedGroupsData: IData[]
  close: (x: string[]) => void
  showButtons?: boolean
  update?: (data: string[][]) => void
}) => {
  const { selectedIds, root, setSelectedIds } = useContext(AssignTrainingContext)
  const [assignedGroups, setAssignedGroups] = useState<{ [key: string]: IGroup[] }>({})
  const [toDelete, setToDelete] = useState<string[]>([])

  useEffect(() => {
    try {
      const rootNames: RootNames = mapRootNames(root)
      const displayData = selectedGroupsData.reduce((acc: any, cur: IData) => {
        const { groupName, groupId, groupTreeId = '', parentGroupId } = cur
        const toBeDeleted = toDelete.includes(groupId)
        const rootName = rootNames[groupTreeId] || ''
        if (acc[groupTreeId]) {
          acc[groupTreeId] = [
            ...acc[groupTreeId],
            {
              rootName,
              groupName,
              groupId,
              parentGroupId,
              toBeDeleted,
            },
          ]
        } else {
          acc[groupTreeId] = [
            {
              rootName: rootNames[groupTreeId],
              groupName,
              groupId,
              parentGroupId,
              toBeDeleted,
            },
          ]
        }
        return acc
      }, {})
      setAssignedGroups(displayData)
    } catch (error) {
      console.log('Error while getting EMP Group Data: ', error)
    }
  }, [selectedGroupsData])

  const mapRootNames = (root: INode[]) => {
    const rootNames = root.reduce((acc: any, cur: INode) => {
      if (cur.hasOwnProperty('groupName')) {
        acc[cur.groupTreeId] = cur.groupName
      }
      return acc
    }, {})
    return rootNames
  }

  const handleSave = async () => {
    const updatedData: string[][] = Array.from(selectedIds).reduce((acc: any, cur) => {
      const [, value = []] = cur
      return [...acc, value]
    }, [])
    if (toDelete.length > 0) {
      for (let i = 0; i < updatedData.length; i++) {
        for (let j = 0; j < toDelete.length; j++) {
          let hasIndexOf = updatedData[i].indexOf(toDelete[j])
          if (hasIndexOf >= 0) {
            updatedData[i].splice(hasIndexOf, 1)
          }
        }
      }
    }
    const emptyArraysRemoved = updatedData.filter((arr) => arr.length > 0) || []
    try {
      update && (await update(emptyArraysRemoved))
      setToDelete([])
    } catch (err) {
      console.log('Error updating assigned groups: ', err)
    }
  }
  /**
   * Updates toDelete array and assignedGroups
   * @param groupId group to be deleted or remove from to be delete
   * @param parentGroupId parent of group to be deleted
   */
  const handleDeleteGroups = (group: IGroup) => {
    const { groupId } = group
    let updatedToDelete = []

    if (toDelete.includes(groupId)) {
      updatedToDelete = toDelete.filter((id) => id !== groupId)
    } else {
      updatedToDelete = [...toDelete, groupId]
    }
    for (const groups in assignedGroups) {
      assignedGroups[groups].forEach((group: IGroup) => {
        if (group.groupId === groupId) {
          group.toBeDeleted = !group.toBeDeleted
        }
      })
    }
    setToDelete(updatedToDelete)
    setAssignedGroups(assignedGroups)
  }
  const handleCancel = () => {
    close([])
    setAssignedGroups({})
  }
  let selectedGroups = []
  const lastKey = Object.keys(assignedGroups)[Object.keys(assignedGroups).length - 1]
  for (const rootGroup in assignedGroups) {
    const { rootName } = assignedGroups[rootGroup][0]
    selectedGroups.push(
      <Flex key={JSON.stringify(rootName)} config={['column']}>
        <Flex
          config={['justifyBetween']}
          style={{ borderBottom: '1px solid gray', marginBottom: '1rem' }}
        >
          <Flex
            config={['column']}
            style={{
              boxSizing: 'border-box',
              margin: '0 1.5rem',
              minWidth: '18rem',
            }}
          >
            <h6>
              <strong>Tree</strong>
            </h6>
            <span>{rootName}</span>
          </Flex>
          <Flex
            config={['column']}
            style={{
              boxSizing: 'border-box',
              minWidth: '20rem',
              width: '55%',
            }}
          >
            <h6>
              <strong>Employee Groups</strong>
            </h6>
            {assignedGroups[rootGroup].map((group: IGroup, index: number) => {
              const { groupId, groupName, toBeDeleted } = group
              return (
                <Item key={groupId + `${index}`} toBeDeleted={toBeDeleted}>
                  <span>{groupName}</span>
                  {showButtons && (
                    <button
                      className={'ttd-bare-button'}
                      onClick={() => {
                        handleDeleteGroups(group)
                      }}
                    >
                      {!toBeDeleted ? (
                        <IconComponent
                          style={{
                            width: '100px',
                            fontSize: 17,
                            alignSelf: 'center',
                            marginLeft: '1rem',
                          }}
                          icon="close"
                        />
                      ) : (
                        <p>Undo</p>
                      )}
                    </button>
                  )}
                </Item>
              )
            })}
          </Flex>
        </Flex>
        {rootGroup !== lastKey && <Divider>AND</Divider>}
      </Flex>,
    )
  }
  return (
    <div style={{ margin: '1.5rem 0' }}>
      <ToastContainer
        position="bottom-right"
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss={false}
        pauseOnHover={true}
      />
      {selectedGroups}
      {selectedGroups.length > 0 && showButtons && (
        <ButtonSet
          style={{ justifyContent: 'flex-end' }}
          formValid={true}
          labels={['Cancel', 'Update']}
          handleSave={handleSave}
          handleCancel={handleCancel}
        />
      )}
    </div>
  )
}

export default AssignedGroups
