import { IFlexUIConfig, IReducer } from '../../interfaces'
import { defaultDropdown } from '../../constants'
import {
  defaultFusionConfig,
  defaultMonitorConfig,
  defaultNotificationConfig,
  defaultTimerConfig,
} from '../../constants/defaults'

export const FlexUIConfigInitialState: IFlexUIConfig = {
  audioOptions: [defaultDropdown],
  fusionConfig: defaultFusionConfig,
  notificationConfig: defaultNotificationConfig,
  monitorConfig: defaultMonitorConfig,
  timerConfig: defaultTimerConfig,
  audioConfig: {},
}

export const FlexUIConfigReducer = (state: any, action: IReducer) => {
  switch (action.type) {
    case 'setKeyMap':
      const { requestObj } = state
      return { ...state, requestObj: { ...requestObj, ...action.payload } }
    // case 'unsetKeyMap':
    //   return { ...state, requestObj: undefined }
    case 'setNotificationConfig':
      return { ...state, notificationConfig: action.payload }
    case 'setFusionConfig':
      return { ...state, fusionConfig: action.payload }
    case 'setAudioConfig':
      return { ...state, audioConfig: action.payload }
    case 'setMonitorConfig':
      action.payload.inactivityTime = action.payload.inactivityTime
      action.payload.focusGracePeriod = action.payload.focusGracePeriod
      return { ...state, monitorConfig: action.payload }
    case 'setTimerConfig':
      return { ...state, timerConfig: action.payload }
    case 'setAudioOptions':
      return { ...state, audioOptions: action.payload }
  }
}
