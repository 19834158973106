import React, {useState, useEffect, useContext, ChangeEvent} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import '@asurion/atomic-ui-library'
import '@asurion/sass-design-system/build/asurion/asurion.css'
import '@rmwc/data-table/styles'
import {handleInput} from '../../../../utils'
import {Search, IconComponent, Button, SVGIconComponent, Modal, Confirm, Flex} from '../../'
import {IDropdownOption} from '../../Atoms/Dropdown'
import {RouteContext} from '../../../../context/RouteContext'
import themes from '../../../../themes/schema'
import {checkPermissions} from '../../../../utils'
import {selectorToUserDarkMode, valueToSaveDarkMode} from '../../../../reducers/UserReducer'
import Brightness2TwoToneIcon from '@material-ui/icons/Brightness2TwoTone'
import Brightness2OutlinedIcon from '@material-ui/icons/Brightness2Outlined'

const SideNav = () => {
  const dispatch = useDispatch()
  const DarkModeMoonIcon = ({dispatch}: any) => {
    const clickDarkMode = () => {
      return dispatch(valueToSaveDarkMode(!darkMode))
    }
    return darkMode ? (
      <Brightness2TwoToneIcon color="primary" fontSize="medium" onClick={clickDarkMode}/>
    ) : (
      <Brightness2OutlinedIcon color="primary" fontSize="medium" onClick={clickDarkMode}/>
    )
  }
  const {
    userSettings: {darkMode},
  } = useSelector((state: any) => state?.user)
  const customDisplayType = darkMode ? 'darkMode' : 'originalFlavor'
  const getStyle: Record<string, any> = {
    sideNavActive_darkMode: themes.darkMode.sideNav.active,
    sideNavActive_originalFlavor: themes.originalFlavor.sideNav.active,
    sideNavInactive_originalFlavor: themes.originalFlavor.sideNav.inactive,
  }

  const {
    isAdmin,
    handleNavigation,
    selectedLink,
    setSelectedLink,
    setSelectedLinkProps,
    validRoutes,
  } = useContext(RouteContext)
  const [searchValue, setSearchValue] = useState('')
  const [filteredData, setFilteredData] = useState<IDropdownOption[]>(validRoutes)
  const [foldSideNav, setFoldSideNav] = useState<boolean>(false)
  const highlightRoute: Record<string, string[]> = {
    dev: ['dev'],
    fusion: ['fusion'],
    trainings: ['trainings', 'newTraining'],
    newTraining: ['trainings', 'newTraining'],
    assignments: ['assignments'],
    reporting: ['reporting'],
    rules: ['rules', 'addTrainingRule'],
    addTrainingRule: ['rules', 'addTrainingRule'],
    configuration: ['configuration'],
    singleBundle: ['singleBundle'],
    userRoles: ['userRoles'],
    broadcast: ['broadcast'],
  }
  useEffect(() => {
    setFilteredData(validRoutes)
  }, [validRoutes])

  useEffect(() => {
    if (!searchValue) {
      return setFilteredData(validRoutes)
    } else if (validRoutes.length > 0 && searchValue) {
      const fData = validRoutes.filter(
        ({value}: any) => value && value.toLowerCase().includes(searchValue.toLocaleLowerCase()),
      )
      return setFilteredData(fData)
    }
  }, [searchValue])

  const handleFoldSideNav = () => {
    setFoldSideNav(!foldSideNav)
  }
  const permission = checkPermissions('trainings', 'edit')
  return (
    <aside className={`ttd-side-nav ${foldSideNav ? 'ttd-fold-side-nav' : ''}`}>
      <div className="ttd-side-nav-padding">
        {foldSideNav ? (
          <button
            style={{marginLeft: 10}}
            className={'ttd-bare-button'}
            onClick={handleFoldSideNav}
          >
            <IconComponent icon={'search'}/>
          </button>
        ) : (
          <Search
            handleInput={(e: ChangeEvent<HTMLInputElement>) => {
              return handleInput(e, setSearchValue)
            }}
            searchValue={searchValue}
            placeholder="Quick search"
          />
        )}
      </div>
      <div className="ttd-side-nav-links">
        {filteredData &&
        filteredData.length > 0 &&
        filteredData.map((menuItem: IDropdownOption) => {
          return (
            <div
              style={
                highlightRoute[selectedLink] &&
                highlightRoute[selectedLink].includes(menuItem.value)
                  ? {backgroundColor: getStyle[`sideNavActive_${customDisplayType}`]}
                  : {opacity: getStyle[`sideNavActive_${customDisplayType}`]}
              }
              className={`ttd-text ttd-side-nav-link ${
                highlightRoute[selectedLink] &&
                highlightRoute[selectedLink].includes(menuItem.value)
                  ? 'ttd-side-nav-link-is-active'
                  : 'ttd-side-nav-link-is-inactive'
              }`}
              key={JSON.stringify(menuItem)}
              tabIndex={0}
              role="button"
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  return setSelectedLink(menuItem.value)
                }
              }}
              onClick={() => handleNavigation(menuItem.value)}
            >
              <SVGIconComponent icon={menuItem.value}/>
              {menuItem.display}
            </div>
          )
        })}
      </div>
      <Flex config={['column', 'justifyEnd']} style={{height: '100%', cursor: 'pointer'}}>
        <Flex config={['row', 'justifyEnd']}>
          <DarkModeMoonIcon dispatch={dispatch}/>
        </Flex>
      </Flex>
      {validRoutes && validRoutes.length > 1 && (
        <Flex
          config={['alignCenter']}
          style={{marginTop: 'auto', alignSelf: 'end', marginBottom: 26}}
        >
          {!foldSideNav && isAdmin && (
            <Button
              disabled={!permission}
              size={'sm'}
              handleClick={() => {
                const flushProps = async () => {
                  return await setSelectedLinkProps({})
                }
                flushProps().then(() => {
                  setSelectedLink('newTraining')
                  window.localStorage.setItem('selectedLink', 'newTraining')
                })
              }}
              style={{color: 'white'}}
            >
              New training
              <IconComponent style={{marginLeft: 5}} icon={'add_circle_outline_anotherFlavor'}/>
            </Button>
          )}

          <button
            style={{marginTop: 5}}
            className={`ttd-bare-button`}
            onClick={handleFoldSideNav}
          >
            <IconComponent icon={foldSideNav ? 'keyboard_arrow_right' : 'keyboard_arrow_left'}/>
          </button>
        </Flex>
      )}
    </aside>
  )
}

export default SideNav
