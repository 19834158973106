/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { DragEvent, useEffect, useState, useRef, MutableRefObject } from 'react'
import { Chip, Button } from '../../index'
import { IAttachment } from '../../../../interfaces'
import themes from '../../../../themes/schema'
import { useSelector } from 'react-redux'

interface IDragAndDrop {
  closeModal: () => void
  // eslint-disable-next-line no-unused-vars
  saveAttachments: (attachments: any) => void
}
const processNewFiles = (files: { [key: number]: any }) => {
  const newFilesArray: Array<[string, IAttachment]> = Object.keys(files).map((key) => {
    const file = files[+key]
    file.title = file.name
    file.wasPreviouslyUploaded = false
    file.url = ''
    return [file.name, file]
  })
  return newFilesArray
}

const DragAndDrop = ({ closeModal, saveAttachments }: IDragAndDrop) => {
  const {
    userSettings: { darkMode },
  } = useSelector((state: any) => state?.user)

  const [dragging, setDragging] = useState<boolean>(false)
  const [dragDepth, setDragDepth] = useState<number>(0)
  const [files, setFiles] = useState<Map<string, IAttachment>>(new Map())

  const fileInputRef = useRef<HTMLInputElement>(null)

  const openFileSystem = () => {
    fileInputRef?.current?.click()
  }

  const onFilesAdded = (event: any) => {
    const newFiles = processNewFiles(event.target.files)
    const updatedFiles = new Map([...files, ...newFiles])
    setFiles(updatedFiles)
  }

  const handleDrag = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDragIn = (e: DragEvent) => {
    const { dataTransfer } = e
    e.preventDefault()
    e.stopPropagation()
    if (dataTransfer.items && dataTransfer.items.length > 0) {
      setDragDepth(dragDepth + 1)
      setDragging(true)
    }
  }

  const handleDragOut = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setDragDepth(dragDepth - 1)
  }

  const handleDrop = (e: DragEvent) => {
    const { dataTransfer } = e
    e.preventDefault()
    e.stopPropagation()
    if (dataTransfer.items && dataTransfer.items.length > 0) {
      const newFiles = processNewFiles(dataTransfer.files)
      setFiles(new Map([...files, ...newFiles]))
      dataTransfer.clearData()
    }
    setDragging(false)
  }

  const handleChipClick = (file: IAttachment) => {
    const title = file.title ? file.title : ''
    const updatedFiles = new Map([...files])
    updatedFiles.delete(title)
    setFiles(updatedFiles)
  }

  const handleUpload = async () => {
    saveAttachments(files)
    closeModal()
  }

  const handleModalClose = () => {
    setFiles(new Map())
    closeModal()
  }

  useEffect(() => {
    const isDragging = dragDepth > 0
    setDragging(isDragging)
  }, [dragDepth])

  return (
    <div
      onDragOver={handleDrag}
      onDragEnter={handleDragIn}
      onDragLeave={handleDragOut}
      onDrop={handleDrop}
      style={{
        boxSizing: 'border-box',
        backgroundColor: darkMode
          ? themes.darkMode.dragAndDrop.backgroundColor
          : themes.originalFlavor.dragAndDrop.backgroundColor,
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'space-between',
        // padding: '24px 24px 15px',
      }}
    >
      {dragging && (
        <div
          style={{
            border: 'dashed #5c1697 4px',
            backgroundColor: darkMode
              ? themes.darkMode.dragAndDrop.dragging.backgroundColor
              : themes.originalFlavor.dragAndDrop.dragging.backgroundColor,
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 9999,
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '50%',
              right: 0,
              left: 0,
              textAlign: 'center',
              color: 'grey',
              fontSize: 36,
              zIndex: 9999,
            }}
          />
        </div>
      )}

      <>
        <div
          style={{
            marginTop: 16,
            width: 360,
            height: 186,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#f3e9fb',
            color: 'black',
          }}
        >
          <span>Drag and drop file(s) here</span>
          <span>or</span>
          <Button size="sm" displayType="outline" handleClick={openFileSystem}>
            Select file(s)
          </Button>
        </div>
        <div
          style={{
            minHeight: 60,
            boxSizing: 'inherit',
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 'auto',
          }}
        >
          {Array.from(files.values()).map((result) => {
            const title = result.title ? result.title : ''
            return (
              <Chip key={title} value={title} handleClick={handleChipClick} data={result}></Chip>
            )
          })}
        </div>
        <div style={{ display: 'flex' }}>
          <Button style={{ marginRight: 5 }} handleClick={handleUpload}>
            Upload
          </Button>
          <Button displayType="outline" handleClick={handleModalClose}>
            Cancel
          </Button>
        </div>
      </>
      <input
        ref={fileInputRef}
        type="file"
        multiple
        style={{ display: 'none' }}
        onChange={onFilesAdded}
      />
    </div>
  )
}

export default DragAndDrop
