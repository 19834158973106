import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

const Tab = ({
  onTabSelect,
  disabled,
  isSelected,
  value,
  display,
}: {
  // eslint-disable-next-line no-unused-vars
  onTabSelect: (e: string) => void
  isSelected: boolean
  value: string
  disabled?: boolean
  display: string
}) => {
  return (
    <button disabled={disabled} className={'ttd-bare-button'} onClick={() => onTabSelect(value)}>
      <StyledTab active={isSelected}>{display}</StyledTab>
    </button>
  )
}

type CustomStyledTab = {
  active: boolean
}

// const StyledTab = styled.div<CustomStyledTab>`
//   width: max-content;
//   padding: 15px;
//   font-weight: 700;
//   font-size: 24px;
//   color: ${({ active }: { active: boolean }) => (active ? '5px solid #000' : '5px solid #a5aaaf')};
//   cursor: pointer;
//   border-bottom: ${({ active }: { active: boolean }) =>
//     active ? '5px solid #000' : '5px solid #F0F0F5'};
//   &:active {
//     color: ${({ active }: { active: boolean }) =>
//       active ? '5px solid #000' : '5px solid #F0F0F5'};
//     outline: none;
//     box-shadow: none;
//   }
//   &:hover {
//     outline: none;
//     box-shadow: none;
//   }
// `

const StyledTab = styled.div<CustomStyledTab>`
  width: max-content;
  padding: 15px;
  font-weight: 700;
  font-size: 24px;
  color: ${(props) =>
    props.active ? props.theme.tab.active.color : props.theme.tab.inactive.color};
  cursor: pointer;
  border-bottom: ${(props) =>
    props.active ? props.theme.tab.active.borderBottom : props.theme.tab.inactive.borderBottom};
  &:active {
    color: ${(props) =>
      props.active ? props.theme.tab.active.color : props.theme.tab.inactive.color};
    outline: none;
    box-shadow: none;
  }
  &:hover {
    outline: none;
    box-shadow: none;
  }
`
export default Tab
