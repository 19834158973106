import React, { CSSProperties } from 'react'
import { IFlex } from '../../../../interfaces'

/**
 * 
 * @config {
 *  column: { flexDirection: 'column' },
    alignCenter: { alignItems: 'center' },
    alignContentCenter: { alignContent: 'center' },
    alignEnd: { alignSelf: 'flex-end' },
    justifyAround: { justifyContent: 'space-around' },
    justifyBetween: { justifyContent: 'space-between' },
    justifyCenter: { justifyContent: 'center' },
    justifyEnd: { justifyContent: 'flex-end' },
    alignStart: { alignItems: 'flex-start' },
    wrap: { flexWrap: 'wrap' },
 * } 
 * @returns 
 */
const Flex = ({ children, style = {}, className = 'ttd-flex', config }: IFlex) => {
  const styles: Record<string, CSSProperties> = {
    column: { flexDirection: 'column' },
    row: { flexDirection: 'row' },
    alignCenter: { alignItems: 'center' },
    alignContentCenter: { alignContent: 'center' },
    alignEnd: { alignSelf: 'flex-end' },
    justifyAround: { justifyContent: 'space-around' },
    justifyBetween: { justifyContent: 'space-between' },
    justifyCenter: { justifyContent: 'center' },
    justifyEnd: { justifyContent: 'flex-end' },
    alignStart: { alignItems: 'flex-start' },
    wrap: { flexWrap: 'wrap' },
  }

  const getStyles = (list: string[] = []) => {
    if (!list.length) return {}
    return list
      .map((property: string) => styles[property])
      .reduce((obj: CSSProperties, currentObj: CSSProperties) => {
        obj = { ...obj, ...currentObj }
        return obj
      })
  }

  return (
    <div className={className} style={{ ...style, ...getStyles(config || []) }}>
      {children}
    </div>
  )
}

export default Flex
