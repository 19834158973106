import React, { useEffect, useState } from 'react'
import { DatePicker, Dropdown, Input, Flex, FormLabel } from '../../index'
import { optionConfig } from '../../../../constants'
import { addDays, subDays, differenceInDays } from 'date-fns'

const CustomDateRange = (props: any) => {
  const [fromDateInc, setFromDateInc] = useState<string>('')
  const [toDateInc, setToDateInc] = useState<string>('')
  const [endDaysInc, setEndDaysInc] = useState<string>('0')
  const [startDaysInc, setStartDaysInc] = useState<string>('0')

  useEffect(() => {
    const fromDate = addDays(new Date(), Number(startDaysInc)).toISOString()
    const toDate = addDays(new Date(), Number(endDaysInc)).toISOString()
    setFromDateInc(fromDate.split('T')[0])
    setToDateInc(toDate.split('T')[0])
    setEndDaysInc('0')
    setStartDaysInc('0')
    if (props.dateSelectionDropdown.id === 'custom') {
      props.dispatch({
        type: 'setFromDate',
        payload: fromDate,
      })
      props.dispatch({
        type: 'setToDate',
        payload: toDate,
      })
    }
  }, [props.reportType.id, props.dateSelectionDropdown.id])

  const { dateRangeOptions } = optionConfig
  const handleDateConversion = (e: string, setter: any, dispatchKey: string) => {
    let date = new Date(e) // to date is apr 01

    if (dispatchKey === 'setToDate') {
      const dateDiff = differenceInDays(date, addDays(new Date(), 1)) // 9
      setter(dateDiff.toString())
      props.dispatch({ type: 'setEndDays', payload: dateDiff })
      setToDateInc(makeSimpleDate(date.toISOString()))
    } else {
      const dateDiff = differenceInDays(date, new Date())
      setter(dateDiff.toString())
      props.dispatch({ type: 'setStartDays', payload: dateDiff })
      setFromDateInc(makeSimpleDate(date.toISOString()))
    }
    props.dispatch({ type: dispatchKey, payload: date.toISOString() })
  }
  const makeSimpleDate = (date: string) => {
    return date.split('T')[0]
  }
  const incDate = (e: any, setter: any, daysSetter: any, key: string, dispatchKey: string) => {
    if (Number(e.currentTarget.value) || Number(e.currentTarget.value) === 0) {
      if (Number(e.currentTarget.value) > 0) {
        setter(makeSimpleDate(addDays(new Date(), e.currentTarget.value).toISOString()))
        props.dispatch({
          type: dispatchKey,
          payload: addDays(new Date(), e.currentTarget.value).toISOString(),
        })
      } else {
        setter(
          makeSimpleDate(
            subDays(new Date(), Number(e.currentTarget.value.substring(1))).toISOString(),
          ),
        )
        props.dispatch({
          type: dispatchKey,
          payload: subDays(new Date(), Number(e.currentTarget.value.substring(1))).toISOString(),
        })
      }
      daysSetter(e.currentTarget.value)
      props.dispatch({ type: key, payload: Number(e.currentTarget.value) })
    } else if (e.currentTarget.value === '-' || e.currentTarget.value === '') {
      daysSetter(e.currentTarget.value)
      props.dispatch({ type: key, payload: 0 })
    }
  }

  return (
    <Flex config={['column']}>
      <FormLabel id="ttd-date-options" value="Date Range">
        <Dropdown
          selected={props.dateSelectionDropdown}
          options={dateRangeOptions}
          emitDropdownOption={(e: any) => props.handleSelect(e, 'dateRangeSelection')}
        />
      </FormLabel>
      {props.showCustomDates && props.dateIncrement && (
        <Flex>
          <FormLabel id="ttd-from-days" value={'From Days'}>
            <Input
              value={startDaysInc}
              handleChange={(e) =>
                incDate(e, setFromDateInc, setStartDaysInc, 'setStartDays', 'setFromDate')
              }
            />
          </FormLabel>
          <FormLabel id="ttd-to-days" value={'To Days'}>
            <Input
              value={endDaysInc}
              handleChange={(e) =>
                incDate(e, setToDateInc, setEndDaysInc, 'setEndDays', 'setToDate')
              }
            />
          </FormLabel>
        </Flex>
      )}
      {props.showCustomDates && (
        <FormLabel id="ttd-from-date" value="From">
          <DatePicker
            dateType={'date'}
            type={'rolled'}
            handleChange={(e: string) => handleDateConversion(e, setStartDaysInc, 'setFromDate')}
            value={startDaysInc === '' || startDaysInc === '-' ? '' : fromDateInc}
            placeholder={''}
          />
        </FormLabel>
      )}
      {props.showCustomDates && !props.foldFilters && (
        <FormLabel id="ttd-to-date" value="To">
          <DatePicker
            dateType={'date'}
            type={'rolled'}
            value={endDaysInc === '' || endDaysInc === '-' ? '' : toDateInc}
            handleChange={(e: string) => handleDateConversion(e, setEndDaysInc, 'setToDate')}
            placeholder={''}
          />
        </FormLabel>
      )}
    </Flex>
  )
}

export default CustomDateRange
