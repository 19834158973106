import React from 'react'

const Trainings = ({ colorConfig }: { colorConfig: string }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 14V11H13V14H16V16H13V19H11V16H8V14H11Z" fill={colorConfig} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 5H19C20.103 5 21 5.898 21 7V21C21 22.103 20.103 23 19 23H5C3.897 23 3 22.103 3 21V7C3 5.898 3.897 5 5 5H7V3H8.142C8.589 1.281 10.142 0 12 0C13.858 0 15.411 1.281 15.858 3H17V5ZM13.731 3C13.385 2.402 12.739 2 12 2C11.261 2 10.615 2.402 10.268 3H13.731ZM15 7V5H9V7H15ZM5 7V21H19V7H17V9H7V7H5Z"
      fill={colorConfig}
    />
  </svg>
)

const Rules = ({ colorConfig }: { colorConfig: string }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 6.09V7.909H20.506C20.425 8.221 20.303 8.513 20.148 8.786L21.089 9.728L19.726 11.091L18.784 10.151C18.511 10.306 18.22 10.427 17.907 10.509V12H16.09V10.508C15.778 10.427 15.486 10.305 15.214 10.15L14.272 11.09L12.908 9.727L13.85 8.785C13.695 8.512 13.573 8.22 13.491 7.908H12V6.09H13.492C13.574 5.778 13.696 5.486 13.851 5.214L12.909 4.272L14.273 2.909L15.215 3.85C15.487 3.694 15.779 3.573 16.091 3.492V2H17.908V3.492C18.221 3.573 18.513 3.694 18.785 3.85L19.728 2.909L21.091 4.272L20.15 5.214C20.305 5.486 20.427 5.778 20.508 6.09H22ZM15 7C15 8.104 15.895 9 17 9C18.105 9 19 8.104 19 7C19 5.896 18.105 5 17 5C15.895 5 15 5.896 15 7Z"
      fill={colorConfig}
    />
    <path
      d="M17.65 14.0002H19.726C19.492 14.8992 19.124 15.7452 18.614 16.4932L20.132 18.0112L18.011 20.1332L16.493 18.6152C15.744 19.1252 14.901 19.5042 14 19.7372V22.0002H10V19.7372C9.099 19.5032 8.256 19.1252 7.507 18.6152L5.989 20.1332L3.868 18.0112L5.386 16.4932C4.875 15.7442 4.496 14.9032 4.264 14.0002H2V10.0002H4.264C4.496 9.09816 4.875 8.25616 5.386 7.50716L3.868 5.98916L5.989 3.86816L7.507 5.38516C8.255 4.87516 9.101 4.50816 10 4.27416V6.35016C7.674 7.17716 6 9.39416 6 12.0002C6 15.3102 8.691 18.0002 12 18.0002C14.606 18.0002 16.824 16.3282 17.65 14.0002Z"
      fill={colorConfig}
    />
  </svg>
)

const Reporting = ({ colorConfig }: { colorConfig: string }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 3V19H21V21H4C3.448 21 3 20.552 3 20V3H5Z" fill={colorConfig} />
    <path
      d="M13.293 14.706L11 12.414L7.70697 15.707L6.29297 14.293L10.293 10.293C10.684 9.90199 11.316 9.90199 11.707 10.293L14 12.586L19.293 7.29199L20.707 8.70599L14.707 14.706C14.316 15.097 13.684 15.097 13.293 14.706Z"
      fill={colorConfig}
    />
  </svg>
)

const Assignments = ({ colorConfig }: { colorConfig: string }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 5C2 3.345 3.346 2 5 2C6.654 2 8 3.346 8 5C8 6.654 6.654 8 5 8C3.346 8 2 6.655 2 5ZM4 5C4 5.551 4.449 6 5 6C5.552 6 6 5.551 6 5C6 4.449 5.552 4 5 4C4.449 4 4 4.449 4 5Z"
      fill={colorConfig}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 10.346 3.346 9 5 9C6.654 9 8 10.346 8 12C8 13.654 6.654 15 5 15C3.346 15 2 13.654 2 12ZM4 12C4 12.551 4.449 13 5 13C5.552 13 6 12.551 6 12C6 11.449 5.552 11 5 11C4.449 11 4 11.449 4 12Z"
      fill={colorConfig}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 16C3.346 16 2 17.346 2 19C2 20.654 3.346 22 5 22C6.654 22 8 20.654 8 19C8 17.346 6.654 16 5 16ZM5 20C4.449 20 4 19.551 4 19C4 18.449 4.449 18 5 18C5.552 18 6 18.449 6 19C6 19.551 5.552 20 5 20Z"
      fill={colorConfig}
    />
    <path d="M10 4H22V6H10V4Z" fill={colorConfig} />
    <path d="M22 11H10V13H22V11Z" fill={colorConfig} />
    <path d="M10 18H22V20H10V18Z" fill={colorConfig} />
  </svg>
)

const Configuration = ({ colorConfig }: { colorConfig: string }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.5 5.99902C14.5 5.65402 14.547 5.32202 14.621 4.99902H2V6.99902H14.621C14.547 6.67602 14.5 6.34402 14.5 5.99902Z"
      fill={colorConfig}
    />
    <path
      d="M14.5 17.999C14.5 17.654 14.547 17.322 14.621 16.999H2V18.999H14.621C14.547 18.676 14.5 18.344 14.5 17.999Z"
      fill={colorConfig}
    />
    <path
      d="M4.5 11.999C4.5 11.654 4.547 11.322 4.621 10.999H2V12.999H4.621C4.547 12.676 4.5 12.344 4.5 11.999Z"
      fill={colorConfig}
    />
    <path
      d="M13.379 10.999C13.453 11.322 13.5 11.654 13.5 11.999C13.5 12.344 13.453 12.676 13.379 12.999H22V10.999H13.379Z"
      fill={colorConfig}
    />
    <path
      d="M19 8.99902C20.6569 8.99902 22 7.65588 22 5.99902C22 4.34217 20.6569 2.99902 19 2.99902C17.3431 2.99902 16 4.34217 16 5.99902C16 7.65588 17.3431 8.99902 19 8.99902Z"
      fill={colorConfig}
    />
    <path
      d="M9 14.999C10.6569 14.999 12 13.6559 12 11.999C12 10.3422 10.6569 8.99902 9 8.99902C7.34315 8.99902 6 10.3422 6 11.999C6 13.6559 7.34315 14.999 9 14.999Z"
      fill={colorConfig}
    />
    <path
      d="M19 20.999C20.6569 20.999 22 19.6559 22 17.999C22 16.3422 20.6569 14.999 19 14.999C17.3431 14.999 16 16.3422 16 17.999C16 19.6559 17.3431 20.999 19 20.999Z"
      fill={colorConfig}
    />
  </svg>
)

const Filter = () => (
  <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 0H2C0.896 0 0 0.898 0 2V6C0 6.256 0.0980002 6.512 0.293 6.707L5 11.415V19C5 19.404 5.244 19.769 5.617 19.924C5.741 19.976 5.871 20 6 20C6.26 20 6.516 19.898 6.707 19.707L10.707 15.707C10.895 15.52 11 15.266 11 15V11.414L15.707 6.707C15.902 6.512 16 6.257 16 6V2C16 0.898 15.104 0 14 0ZM9.293 10.293C9.105 10.481 9 10.735 9 11V14.586L7 16.586V11C7 10.735 6.895 10.48 6.707 10.293L2 5.586V2H14V5.586L9.293 10.293Z"
      fill="#8223D2"
    />
  </svg>
)
const Edit = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.0811 2.91865C19.8961 1.73465 17.8311 1.73365 16.6451 2.91865L5.31409 14.2496C5.23809 14.3266 5.17409 14.4136 5.12609 14.5106L2.10509 20.5536C1.91309 20.9387 1.98809 21.4046 2.29309 21.7066C2.59609 22.0117 3.06209 22.0877 3.44609 21.8946L9.48909 18.8736C9.58509 18.8256 9.67409 18.7626 9.74909 18.6856L21.0811 7.35365C22.2661 6.16865 22.2651 4.10365 21.0811 2.91865ZM8.45009 17.1566L5.23509 18.7646L6.84309 15.5486L15.8421 6.55165L17.4491 8.15965L8.45009 17.1566ZM19.6671 5.94165L18.8641 6.74565L17.2571 5.13765L18.0611 4.33365C18.4911 3.90365 19.2391 3.90465 19.6681 4.33365C20.1101 4.77665 20.1101 5.49865 19.6671 5.94165Z"
      fill="#8223D2"
    />
  </svg>
)

const Close = () => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9998 10.002L14.9488 13.002L12.9998 14.951L9.99978 12.002L6.99978 14.951L5.05078 13.002L7.99978 10.002L5.05078 7.00195L6.99978 5.00195L9.99978 8.00195L12.9998 5.05295L14.9488 7.00195L11.9998 10.002Z"
      fill="#8223D2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 10.002C0 15.516 4.486 20.002 10 20.002C15.514 20.002 20 15.516 20 10.002C20 4.48795 15.514 0.00195312 10 0.00195312C4.486 0.00195312 0 4.48795 0 10.002ZM2 10.002C2 5.59095 5.589 2.00195 10 2.00195C14.411 2.00195 18 5.59095 18 10.002C18 14.413 14.411 18.002 10 18.002C5.589 18.002 2 14.413 2 10.002Z"
      fill="#8223D2"
    />
  </svg>
)
const Save = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24">
    <path
      d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.393 7.5l-5.643 5.784-2.644-2.506-1.856 1.858 4.5 4.364 7.5-7.643-1.857-1.857z"
      fill="#8223D2"
    />
  </svg>
)

const Dev = ({ colorConfig }: { colorConfig: string }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.207 4.5C19.3946 4.68749 19.4999 4.9418 19.5 5.207V22.5C19.5 23.0523 19.0523 23.5 18.5 23.5H1.5C0.947715 23.5 0.5 23.0523 0.5 22.5V1.5C0.5 0.947715 0.947715 0.5 1.5 0.5H14.793C15.0592 0.501908 15.3137 0.609881 15.5 0.8L19.207 4.5Z"
      stroke={colorConfig}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 8.5H7.906C7.54516 8.47048 7.18835 8.59276 6.92148 8.83743C6.65461 9.08209 6.50186 9.42696 6.5 9.789V11.03C6.48185 11.4885 6.2128 11.8997 5.8 12.1L4.5 13L5.8 13.9C6.21295 14.1001 6.48206 14.5115 6.5 14.97V16.21C6.50158 16.5722 6.65421 16.9174 6.92111 17.1622C7.18802 17.4071 7.54499 17.5295 7.906 17.5H8.5"
      stroke={colorConfig}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5 8.5H12.092C12.4532 8.46988 12.8105 8.59191 13.0778 8.83664C13.3452 9.08137 13.4982 9.42658 13.5 9.789V11.03C13.5182 11.4885 13.7872 11.8997 14.2 12.1L15.5 13L14.2 13.9C13.787 14.1001 13.5179 14.5115 13.5 14.97V16.21C13.4985 16.5726 13.3456 16.9181 13.0782 17.163C12.8109 17.408 12.4533 17.5301 12.092 17.5H11.5"
      stroke={colorConfig}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
const Fusion = ({ colorConfig }: { colorConfig: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M19.406 4.087L10.406 0.087003C10.147 -0.026997 9.852 -0.026997 9.593 0.087003L0.593 4.087C0.232 4.248 0 4.605 0 5.001V15.001C0 15.397 0.232 15.755 0.594 15.915L9.594 19.915C9.723 19.972 9.862 20.001 10 20.001C10.138 20.001 10.277 19.972 10.406 19.915L19.406 15.915C19.768 15.755 20 15.396 20 15.001V5.001C20 4.605 19.768 4.248 19.406 4.087ZM10 2.096L16.537 5.001L10 7.907L3.463 5.001L10 2.096ZM2 6.54L9 9.651V17.463L2 14.351V6.54ZM11 17.463V9.651L18 6.54V14.351L11 17.463Z"
      fill={colorConfig}
    />
  </svg>
)

const UserRoles = ({ colorConfig }: { colorConfig: string }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 6.09V7.909H20.506C20.425 8.221 20.303 8.513 20.148 8.786L21.089 9.728L19.726 11.091L18.784 10.151C18.511 10.306 18.22 10.427 17.907 10.509V12H16.09V10.508C15.778 10.427 15.486 10.305 15.214 10.15L14.272 11.09L12.908 9.727L13.85 8.785C13.695 8.512 13.573 8.22 13.491 7.908H12V6.09H13.492C13.574 5.778 13.696 5.486 13.851 5.214L12.909 4.272L14.273 2.909L15.215 3.85C15.487 3.694 15.779 3.573 16.091 3.492V2H17.908V3.492C18.221 3.573 18.513 3.694 18.785 3.85L19.728 2.909L21.091 4.272L20.15 5.214C20.305 5.486 20.427 5.778 20.508 6.09H22ZM15 7C15 8.104 15.895 9 17 9C18.105 9 19 8.104 19 7C19 5.896 18.105 5 17 5C15.895 5 15 5.896 15 7Z"
      fill={colorConfig}
    />
    <path
      d="M17.65 14.0002H19.726C19.492 14.8992 19.124 15.7452 18.614 16.4932L20.132 18.0112L18.011 20.1332L16.493 18.6152C15.744 19.1252 14.901 19.5042 14 19.7372V22.0002H10V19.7372C9.099 19.5032 8.256 19.1252 7.507 18.6152L5.989 20.1332L3.868 18.0112L5.386 16.4932C4.875 15.7442 4.496 14.9032 4.264 14.0002H2V10.0002H4.264C4.496 9.09816 4.875 8.25616 5.386 7.50716L3.868 5.98916L5.989 3.86816L7.507 5.38516C8.255 4.87516 9.101 4.50816 10 4.27416V6.35016C7.674 7.17716 6 9.39416 6 12.0002C6 15.3102 8.691 18.0002 12 18.0002C14.606 18.0002 16.824 16.3282 17.65 14.0002Z"
      fill={colorConfig}
    />
  </svg>
)

const Broadcast = ({ colorConfig }: { colorConfig: string }) => (
  <svg width="24" height="24" viewBox="0 0 29 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.9093 8.22171C23.7969 5.65439 19.0606 4.09411 14.3689 4.09411C9.67716 4.09411 4.96905 5.67827 1.8558 8.24559L0.715179 7.13298C4.27448 4.28212 9.07818 2.53076 14.3689 2.53076C19.6596 2.53076 24.4624 4.28289 28.0217 7.13375L26.9093 8.22171ZM14.3688 7.15379C18.1648 7.15379 21.7869 8.52528 24.2238 10.6704L23.035 11.756C21.0587 9.88677 17.5677 8.70327 14.3679 8.70327C11.1682 8.70327 7.67716 9.88677 5.70089 11.756L4.51293 10.6704C6.94983 8.52528 10.5729 7.15379 14.3688 7.15379ZM14.3687 11.7768C16.5043 11.7807 19.2252 12.6806 20.4796 14.15L19.3381 15.2218C18.3959 14.1369 16.0837 13.3109 14.3687 13.3109C12.6537 13.3109 10.3697 14.1369 9.42663 15.2218L8.22958 14.15C9.48398 12.6814 12.2886 11.7707 14.3687 11.7768ZM14.369 16.4031C15.8783 16.4031 17.1017 17.4371 17.1017 18.7131C17.1017 19.9875 15.8783 21.0231 14.369 21.0231C12.8597 21.0231 11.6362 19.9875 11.6362 18.7131C11.6362 17.4371 12.8597 16.4031 14.369 16.4031ZM14.3687 17.9398C14.8712 17.9398 15.279 18.2842 15.279 18.7103C15.279 19.1363 14.8712 19.4808 14.3687 19.4808C13.8653 19.4808 13.4584 19.1363 13.4584 18.7103C13.4584 18.2842 13.8653 17.9398 14.3687 17.9398Z"
      fill={colorConfig}
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.369 16.4036C15.8783 16.4036 17.1017 17.4376 17.1017 18.7135C17.1017 19.988 15.8783 21.0235 14.369 21.0235C12.8597 21.0235 11.6362 19.988 11.6362 18.7135C11.6362 17.4376 12.8597 16.4036 14.369 16.4036ZM14.3687 17.9402C14.8712 17.9402 15.2791 18.2846 15.2791 18.7107C15.2791 19.1368 14.8712 19.4812 14.3687 19.4812C13.8653 19.4812 13.4584 19.1368 13.4584 18.7107C13.4584 18.2846 13.8653 17.9402 14.3687 17.9402Z"
      fill={colorConfig}
    ></path>
  </svg>
)

export {
  Assignments,
  Close,
  Configuration,
  Dev,
  Edit,
  Filter,
  Fusion,
  Broadcast,
  Reporting,
  Rules,
  Save,
  Trainings,
  UserRoles,
}
