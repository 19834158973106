export const validate: Record<string, any> = {
  blankOkay: () => {
    return true
  },
  onlyNumbers: (input: string) => {
    if (input === undefined) input = ''
    return /^[0-9\s]+$/g.test(input)
  },
  noNumbers: (input: string) => {
    if (input === undefined) input = ''
    return !/\d/g.test(input)
  },
  neverBlank: (input: string) => {
    if (input === undefined) input = ''
    return /\S/g.test(input)
  },
}

export const validationRules: Record<string, any> = {
  dispatchRuleName: 'neverBlank',
  ejectRuleName: 'neverBlank',
  taskQueueMaxPercent: 'onlyNumbers',
  thresholdValue: 'onlyNumbers',
  trainingRuleName: 'neverBlank',
  unitValue: 'onlyNumbers',
  currentGracePeriod: 'onlyNumbers',
  currentTimer: 'onlyNumbers',
  'config.flexConfig.monitorConfig.inactivityTime': 'neverBlank',
  'config.flexConfig.monitorConfig.warnings.inactivity.timer': 'onlyNumbers',
  'config.flexConfig.monitorConfig.warnings.inactivity.message': 'blankOkay',
  'config.flexConfig.monitorConfig.warnings.inactivity.title': 'blankOkay',
  'config.flexConfig.monitorConfig.focusGracePeriod': 'onlyNumbers',
  'config.flexConfig.monitorConfig.warnings.lostfocus.message': 'blankOkay',
  'config.flexConfig.monitorConfig.warnings.lostfocus.timer': 'onlyNumbers',
  'config.flexConfig.monitorConfig.warnings.lostfocus.title': 'blankOkay',
  'config.flexConfig.notificationConfig.ejectionReceived.timeoutDuration': 'onlyNumbers'
}
