import React, { ReactElement, SyntheticEvent } from 'react'
import styled, { StyledComponent } from 'styled-components'
const JSONDisplay = ({ json = '', jkey = '' }: { json: any; jkey?: string }) => {
  function parseRedisObject(obj: any) {
    if (Array.isArray(obj)) return
    Object.keys(obj).forEach((jkey: string) => {
      if (/[{}\[\]]/g.test(obj[jkey])) {
        const newObj: any = JSON.parse(obj[jkey])
        obj[jkey] = newObj
      }
    })
  }
  const typeOfInput = (input: any, key: string) => {
    if (typeof input === 'string') {
      return (
        <div>
          {key ? (
            <div>
              {`"${key}" : `}
              <InputColor>{`"${input}"`}</InputColor>,
            </div>
          ) : (
            <div>
              <InputColor>{`"${input}"`}</InputColor>,
            </div>
          )}
        </div>
      )
    } else {
      return (
        <div>
          {key ? (
            <div>
              {`"${key}" : `}
              <InputColor>{`${input}`}</InputColor>,
            </div>
          ) : (
            <div>
              <InputColor>{`${input}`}</InputColor>,
            </div>
          )}
        </div>
      )
    }
  }
  parseRedisObject(json)

  if (Array.isArray(json)) {
    return (
      <ObjectContainer>
        {jkey ? <div>{`"${jkey}" : [`}</div> : <div>{`[`}</div>}
        {json.map((data: any) => (
          <div key={JSON.stringify(data)}>{JSONDisplay({ json: data })}</div>
        ))}
        <div>{'],'}</div>
      </ObjectContainer>
    )
  } else if (typeof json === 'object') {
    if (!Object.keys(json).length) {
      return (
        <ObjectContainer>
          <div>{'{ },'}</div>
        </ObjectContainer>
      )
    } else {
      return (
        <ObjectContainer>
          {jkey ? <div>{`"${jkey}" : {`}</div> : <div>{`${jkey}{`}</div>}
          {Object.keys(json).map((data: string) => (
            <div key={JSON.stringify(data)}>{JSONDisplay({ json: json[data], jkey: data })}</div>
          ))}
          <div>{'},'}</div>
        </ObjectContainer>
      )
    }
  } else {
    return <ObjectContainer>{typeOfInput(json, jkey)}</ObjectContainer>
  }
}

export default JSONDisplay

const FormText: StyledComponent<'div', any, {}, never> = styled.div`
  overflow-wrap: break-word;
  width: 200px;
  font-size: 18px;
`
const ButtonHolder: StyledComponent<'div', any, { inputOrTextArea: boolean }, never> = styled.div`
  margin-top: ${({ inputOrTextArea }: { inputOrTextArea: boolean }) =>
    inputOrTextArea ? '35px' : '25px'};
  display: flex;
`
const PrettyContainer: StyledComponent<'div', any, {}, never> = styled.div`
  display: flex;

  flex-direction: row;
  max-width: 780px;
`
const ObjectContainer: StyledComponent<'div', any, {}, never> = styled.div`
  margin-left: 10px;
`
const InputColor: StyledComponent<'span', any, {}, never> = styled.span`
  color: red;
`
