import React, { ChangeEvent, useContext, useState } from 'react'
import Sidebar from '../Trainings/TrainingAssignmentModal/SideBar'
import {
  Flex,
  FormLabel,
  IconComponent,
  Input,
  SubmitButton,
  Tag,
  TextArea,
  Tooltip,
} from '../common'
import { handleInput } from '../../utils'
import { AssignTrainingContext } from '../../context/AssignTrainingContext'
import { postBroadcast } from '../../actions'

const Broadcast = () => {
  const [title, setTitle] = useState<string>('')
  const [broadcastMessage, setBroadcastMessage] = useState<string>('')
  const [broadcastPriority, setBroadcastPriority] = useState<string>('P2') // please change when more option are needed
  const { resetAllChecks, broadcastData, setBroadcastData } = useContext(AssignTrainingContext)
  const submitBroadcast = () => {
    const empGroupIds = broadcastData.map((each: any) => each.groupId)
    postBroadcast({ title, empGroupIds, broadcastPriority, broadcastMessage })
  }
  return (
    <div>
      <Flex config={['row']}>
        <Flex
          config={['column']}
          style={{ overflowY: 'scroll', height: '95vh', minWidth: '15em', marginRight: '5em' }}
        >
          <Sidebar />
        </Flex>
        <Flex config={['column', 'justifyCenter']} style={{ maxHeight: '100em' }}>
          <Flex config={['row', 'wrap']}>
            {broadcastData.length
              ? broadcastData.map((each: any) => (
                  <Tag
                    type={'readOnly'}
                    key={JSON.stringify(each)}
                    value={each.groupName}
                    disabled={true}
                    onClear={() => {
                      const filteredBroadcast = broadcastData.filter(
                        (eachCast: any) => each.groupId !== eachCast.groupId,
                      )
                      setBroadcastData(filteredBroadcast)
                    }}
                  />
                ))
              : 'Nothing selected'}
          </Flex>
          <Flex config={['row']}>
            <FormLabel id={'title'} value={'Broadcast Title'}>
              <Input
                value={title}
                type={'rolled'}
                placeholder={'Title'}
                handleChange={(e) => handleInput(e, setTitle)}
              />
            </FormLabel>
          </Flex>
          <Flex config={['row']}>
            <FormLabel id="type" value="Broadcast Type" style={{ marginBottom: '10px' }}>
              {/* <button
                className={'ttd-bare-button'}
                style={{ display: 'flex', cursor: 'pointer', width: 270 }}
                onClick={() => {
                  setBroadcastPriority('P1')
                }}
              >
                <IconComponent
                  icon={
                    broadcastPriority === 'P1' ? 'radio_button_checked' : 'radio_button_unchecked'
                  }
                />
                <span style={{ marginLeft: 15 }}> Citrix Notification </span>
                <Tooltip selector={'broadcast.type1'}>
                  <IconComponent className={'ttd-tooltip'} icon={'info'} />
                </Tooltip>
              </button> */}
              <button
                className={'ttd-bare-button'}
                style={{ display: 'flex', cursor: 'pointer', width: 270 }}
                onClick={() => {
                  setBroadcastPriority('P2')
                }}
              >
                <IconComponent
                  icon={
                    broadcastPriority === 'P2' ? 'radio_button_checked' : 'radio_button_unchecked'
                  }
                />

                <span style={{ marginLeft: 15 }}> Task Notification </span>
                <Tooltip selector={'broadcast.type2'}>
                  <IconComponent className={'ttd-tooltip'} icon={'info'} />
                </Tooltip>
              </button>
            </FormLabel>
            <FormLabel id="type" value="Broadcast Message">
              <TextArea
                style={{ width: 400 }}
                value={broadcastMessage}
                placeholder="Message"
                handleChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                  setBroadcastMessage(e.target.value)
                }}
              />
            </FormLabel>
          </Flex>
          <Flex config={['row']}>
            <SubmitButton onClick={() => submitBroadcast()}>Broadcast This Message</SubmitButton>
          </Flex>
        </Flex>
      </Flex>
    </div>
  )
}

export default Broadcast
