import React, { useState, useRef, createRef, useEffect, SyntheticEvent } from 'react'
import styled, { StyledComponent } from 'styled-components'
import { Flex, IconComponent } from '../../../common'
import useDebounce from '../../../../hooks/UseDebounce'

const LookupInputDev = ({
  selections,
  handleSelect,
  objectKey,
  getSelections,
}: {
  selections: any[]
  handleSelect: any
  objectKey: string
  getSelections: (x: string) => Promise<[]>
}) => {
  const [scrollEnd, setScrollEnd] = useState<boolean>(false)
  const [showDropdownOptions, setShowDropdownOptions] = useState<boolean>(!close || false)
  const [searchedOptions, setSearchedOptions] = useState<any>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [top, setTop] = useState<number>(53)

  const debouncedValue = useDebounce(searchValue, 750)

  useEffect(() => {
    if (!searchValue) {
      setSearchedOptions([])
    } else {
      setShowDropdownOptions(true)
      ;(async () => {
        try {
          const data = await getSelections(searchValue)
          setSearchedOptions(data)
          return
        } catch (e) {
          console.error(e)
        }
      })()
    }
  }, [debouncedValue])

  const handleDropDown: () => void = () => {
    setShowDropdownOptions(true)
  }

  const scrollRef = createRef<HTMLDivElement>()
  const boundingBox = createRef<HTMLDivElement>()

  useEffect(() => {
    const currentRef = scrollRef.current
    if (currentRef) {
      currentRef.addEventListener('scroll', () => {
        return setScrollEnd(currentRef.scrollTop + 55 >= 165)
      })
    }
    return
  }, [scrollRef, scrollEnd])

  useEffect(() => {
    const currentRef = boundingBox.current
    if (currentRef) {
      const boundingClient = currentRef.getBoundingClientRect()
      setTop(boundingClient.height)
    }
    return
  }, [boundingBox])

  useEffect(() => {
    document.addEventListener('mousedown', (e) => {
      const selector = e.target as HTMLDivElement
      if (!selector.closest('.dropdown-container-check')) {
        setShowDropdownOptions(false)

        return () => {
          document.removeEventListener('mousedown', () => {})
        }
      }
    })
    document.addEventListener('mouseout', (e) => {
      const selector = e.target as HTMLDivElement
      if (!selector.closest('.dropdown-container-check')) {
        setShowDropdownOptions(false)

        return () => {
          document.removeEventListener('mouseout', () => {})
        }
      }
    })
    return
  }, [showDropdownOptions, setShowDropdownOptions])

  return (
    <DropdownContainer className="dropdown-container-check">
      <StyledInputContainer ref={boundingBox} onClick={handleDropDown}>
        <Flex config={['wrap']} style={{ marginRight: 5 }}>
          {selections.map((name) => (
            <StyledChip key={name}>
              {name[objectKey]}
              <button
                style={{ color: 'white' }}
                className={'ttd-bare-button'}
                onClick={() => handleSelect(name, 'remove')}
              >
                <IconComponent icon={'close'} />
              </button>
            </StyledChip>
          ))}
          <StyledInput
            onChange={(e: SyntheticEvent<HTMLInputElement>) => {
              setSearchValue(e.currentTarget.value)
            }}
            value={searchValue}
            placeholder={objectKey === 'full_name' ? 'Search Workers' : 'Search Department'}
          />
        </Flex>
      </StyledInputContainer>
      {showDropdownOptions && (
        <OptionContainer top={top} ref={scrollRef}>
          <div style={{ overflow: 'auto' }}>
            {searchedOptions.map((data: any) => {
              const isInState = selections.some(
                (selectedWorker: string) => selectedWorker === data[objectKey],
              )
              return (
                <Option
                  tabIndex={0}
                  onClick={() => {
                    handleSelect(data, isInState ? 'remove' : 'add')
                    setSearchValue('')
                    setSearchedOptions([])
                  }}
                  key={JSON.stringify(data)}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    {data[objectKey]}
                  </div>
                </Option>
              )
            })}
          </div>
        </OptionContainer>
      )}
      {/*{!scrollEnd && showDropdownOptions && options.length > 3 && <WhiteOverlay />}*/}
    </DropdownContainer>
  )
}

export default LookupInputDev

const DropdownContainer: StyledComponent<'div', any, {}, never> = styled.div`
  position: relative;
`

const OptionContainer: StyledComponent<'div', any, { top: number }, never> = styled.div`
  position: absolute;
  min-width: 100%;
  width: max-content;
  overflow-y: scroll;
  max-height: 300px;
  box-shadow: 0px 2px 4px 0.4px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  background-color: white;
  z-index: 5;
  top: ${({ top }: { top: number }) => top}px;
`

const Option: StyledComponent<'div', any, {}, never> = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 25px;
  height: 47px;
  min-width: 100%;
  width: max-content;
  border-bottom: 1px solid #dedede;
  cursor: pointer;
  background: white;
  &:hover {
    background: #dedede;
  }
`
const WhiteOverlay: StyledComponent<'div', any, {}, never> = styled.div`
  height: 20px;
  width: 100%;
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 5;
  position: absolute;
  bottom: -165px;
`

const StyledInputContainer: StyledComponent<
  'div',
  {
    onClick: SyntheticEvent
  },
  {},
  never
> = styled.div`
  background-color: white;
  min-height: 48px;
  border: 1px solid #a5aaaf;
  border-radius: 4px;
  padding: 4px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    border-color: #000;
  }
  &:active {
    border-color: #8223d2;
  }
`

const StyledInput: StyledComponent<'input', any, {}, never> = styled.input`
  margin: 0;
  width: 100%;
  height: 26px;
  border: none;
  font-size: 1.08em;
  outline: none;
  &:focus {
    outline: none;
    border: none;
  }
  &:hover {
    outline: none;
    border: none;
  }
`

const StyledChip: StyledComponent<
  'div',
  {
    onClick: SyntheticEvent
  },
  {},
  never
> = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  background: black;
  color: white;
  border-radius: 32px;
  padding: 1px 5px;
`
