import React from 'react'
import { IDateFilter } from '../../../../../interfaces'
import styled from 'styled-components'
import DateFilter from './DateFilter'
interface IProps {
  dateFilters: IDateFilter
  setDateFilters: (dateFilters: IDateFilter) => void
}

const SelectedDateFilterContainer = ({ dateFilters, setDateFilters }: IProps) => {
  const selectedFilters = []
  for (let name in dateFilters) {
    const { from, to } = dateFilters[name]
    if (from && to) {
      selectedFilters.push(
        <DateFilter
          dateFilters={dateFilters}
          setDateFilters={setDateFilters}
          name={name}
          to={to}
          from={from}
        />,
      )
    }
  }
  return <Container>{selectedFilters}</Container>
}

export default SelectedDateFilterContainer

const Container = styled.div`
  display: flex;
`
