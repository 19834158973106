import React, { useEffect, useState } from 'react'
import '@asurion/sass-design-system/build/asurion/asurion.css'
import '@asurion/atomic-ui-library'
import { Button, Chip, Dropdown, Modal, Loading } from '../../common'
import { checkPermissions } from '../../../utils'
import { getAllWorkers, queryAllRoles, attachRoles } from '../../../actions'
import { Logger } from 'aws-amplify'

interface IModalProps {
  currentlyOpen: boolean
  role: IRoleProps
  count: number
  empIdList?: string
  setOpenModal: Function
  selectedUsers: Array<[]>
  updateVisibleWorkers: Function
  setRoleName: Function
  setAttachConfirmed: Function
}

interface IRoleProps {
  value: string
  display: string
  id: string
}

interface IRoleDB {
  createdAt?: string
  idUserRoleDetails: number
  updatedAt?: string
  userRoleDefinition: any
  userRoleName: string
}

interface IBetterText {
  boldText: string
  count: number
}

//Helper Component to Render better looking text before confirmation of role attachment
const BetterModalText = ({ boldText, count }: IBetterText) => {
  return (
    <>
      <p style={{ fontSize: '1.3em' }}>
        Attach role <b>{boldText}</b> to <b>{count}</b> worker{count > 1 ? 's' : ''} ?
      </p>
    </>
  )
}

//Opens when "attach" button is clicked
const UserRolesModal = ({
  currentlyOpen,
  role,
  count,
  setOpenModal,
  selectedUsers,
  updateVisibleWorkers,
  setRoleName,
  setAttachConfirmed,
}: IModalProps) => {
  const [isUpdating, setIsUpdating] = useState(false)

  const delayTransition = function (cb: Function, dur: number) {
    console.log(' transition please')
    return new Promise((resolve) => {
      cb(true)
      setTimeout(() => resolve(() => true), dur)
    })
  }
  return (
    <Modal
      width="50%"
      height="50%"
      close={() => {
        setOpenModal(!currentlyOpen)
        return console.log(' currentlyOpen? ', currentlyOpen, ' Close? ', close)
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '90%',
          boxShadow: '2px 2px 10px grey',
          borderRadius: '14px',
          textAlign: 'center',
          padding: '25px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <>
          {isUpdating ? (
            <Loading size="md" />
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div>
                <BetterModalText boldText={role.display} count={count} />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '18px',
                }}
              >
                <div>
                  <Button
                    handleClick={async () => {
                      await attachRoles({ selectedUsers, role: role?.display })
                      await delayTransition(setIsUpdating, 1500)
                      await updateVisibleWorkers([]) //remove users from currently visible list
                      await setRoleName({
                        value: 'Select Role',
                        display: 'Select Role',
                        id: 'Role1',
                      })
                      await setOpenModal(false)
                      await setAttachConfirmed(true)
                    }}
                  >
                    Yes
                  </Button>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Button displayType="outline" handleClick={() => setOpenModal(!currentlyOpen)}>
                    No
                  </Button>
                </div>
              </div>
            </div>
          )}
        </>
      </div>
    </Modal>
  )
}

//Main Config Modal
const UserRolesConfig = () => {
  const [selectedWorkers, setSelectedWorkers] = useState<any[]>([])
  const [reconstructedWorkers, setReconstructedWokers] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [openModal, setOpenModal] = useState(false)

  //We reset this back to this default value once we click the "YES" button in the modal
  //TODO: There is maybe a better way to do this: https://reactjs.org/docs/hooks-reference.html#lazy-initialization
  const [roleName, setRoleName] = useState<IRoleProps>({
    value: 'Select Role',
    display: 'Select Role',
    id: 'Role1',
  })

  const [reconstructedRoles, setReconstructedRoles] = useState<any[]>([])

  //We are not using the ability to remove roles. We can toggle this when we're ready. :)
  const [removeEnabled, setRemovedEnabled] = useState(false)

  //We use this to generate a refresh after the update of a worker role.
  const [attachConfirmed, setAttachConfirmed] = useState(false)

  //Reconstruct Data for Dropdown Component input
  useEffect(() => {
    console.log(' DO WE WANT TO REFRESH DATA NOW? ', attachConfirmed)
    ;(async () => {
      if (attachConfirmed) {
        await setLoading(true)
        const retrievedExperts: any = await getAllWorkers()
        await console.log('Number of retrieved Experts : ', retrievedExperts?.workers.length)
        //This function gives the <Option> component the <Dropdown> in a format it requires.
        const wrkctrl = retrievedExperts?.workers.map((i: any) => {
          try {
            return {
              value: i.empId,
              display: `${i.empId} - ${i.full_name} - ${i.userRoleNames ? i.userRoleNames : 'N/A'}`,
              id: i.empId,
            }
          } catch (e) {
            Logger.error(' Incorrect data to display selected workers', e)
          }
        })
        await setReconstructedWokers(wrkctrl)
        //Wait until all experts are loaded before allowing a search
        await setLoading(false)
        await setAttachConfirmed(false)
      }
    })()
    // Once closed we'd like to refresh the list of users.
  }, [attachConfirmed])

  //Get Roles
  useEffect(() => {
    ;(async () => {
      const retrievedRoles: any = await queryAllRoles()
      const roleCtrl = retrievedRoles.map((i: IRoleDB) => {
        return { value: i.userRoleName, display: i.userRoleName, id: i.idUserRoleDetails }
      })
      setReconstructedRoles(roleCtrl)
    })()
  }, [])

  //Get Workers
  useEffect(() => {
    ;(async () => {
      const retrievedExperts: any = await getAllWorkers()
      await console.log('Number of retrieved Experts : ', retrievedExperts?.workers.length)
      //This function gives the <Option> component the <Dropdown> in a format it requires.
      const wrkctrl = retrievedExperts?.workers.map((i: any) => {
        try {
          return {
            value: i.empId,
            display: `${i.empId} - ${i.full_name} - ${i.userRoleNames ? i.userRoleNames : 'N/A'}`,
            id: i.empId,
          }
        } catch (e) {
          Logger.error(' Incorrect data to display selected workers', e)
        }
      })
      await setReconstructedWokers(wrkctrl)
      //Wait until all experts are loaded before allowing a search
      await setLoading(false)
    })()
  }, [])

  const permission = checkPermissions('userroles', 'edit')

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateRows: 'auto 1fr auto',
        maxWidth: '90%',
        boxShadow: '2px 2px 10px grey',
        borderRadius: '14px',
      }}
    >
      <div style={{ maxHeight: '5vh', padding: '30px', margin: '2px' }}>
        <h4 style={{ color: '#8223d2' }}>User Roles</h4>
      </div>

      {/** Main Container */}
      <main style={{ maxHeight: '65vh', padding: '30px', margin: '2px' }}>
        {/** End Subtitle */}

        {/** Begin Search Section */}
        <div style={{ display: 'flex', gap: '10px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', flex: '1 0 0' }}>
            <div>
              <p>Employee ID:</p>
              {loading ? (
                <Loading size="sm" />
              ) : (
                <Dropdown
                  isFilter={false}
                  multipleSelect={false}
                  emitDropdownOption={(worker) => {
                    const workerExists = selectedWorkers.some(
                      (selected: any) => selected?.id === worker?.id,
                    )
                    if (!workerExists) {
                      setSelectedWorkers([...selectedWorkers, worker])
                    }
                  }}
                  selected={{
                    value: 'Select Worker',
                    display: 'Click to Search',
                    id: 'NA',
                  }}
                  options={reconstructedWorkers}
                />
              )}
            </div>
            <div>
              <p>Employee Role:</p>
              <Dropdown
                style={{ width: '100%' }}
                emitDropdownOption={(evt) => {
                  setRoleName(evt)
                }}
                selected={roleName}
                options={reconstructedRoles}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              flex: '1 0 0',
            }}
          >
            <div>
              <p>Selected Employees:</p>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                border: '1px solid #5d5d5db5',
                borderRadius: '4px',
                maxHeight: '200px',
                flex: '2 0 0',
                padding: '10px',
                gap: '3px',
                overflowY: 'scroll',
              }}
            >
              {selectedWorkers.map((i: any, idx: number) => {
                return (
                  <Chip
                    key={idx}
                    backgroundColor="#FFF"
                    handleClick={(evt) => {
                      //Remove worker from list of Selected Workers
                      const filtered = selectedWorkers.filter((currentWorker) => {
                        if (currentWorker.id !== evt.id) {
                          return currentWorker
                        }
                      })
                      setSelectedWorkers(filtered)
                      return
                    }}
                    value={i?.display}
                    data={i}
                  />
                )
              })}
            </div>
          </div>
        </div>
        {/**End Search Section */}
      </main>
      {/**Begin Add/Remove Section */}

      {permission && (
        <div
          style={{
            display: 'flex',
            maxHeight: '10vh',
            margin: '18px',
            padding: '20px',
            justifyContent: 'flex-end',
            gap: '15px',
          }}
        >
          <Button
            id="addUserRole"
            handleClick={() => {
              return setOpenModal(!openModal)
            }}
            disabled={
              roleName?.value === 'Select Role' || selectedWorkers.length == 0 ? true : false
            }
          >
            Attach
          </Button>

          {removeEnabled ? (
            <Button
              id="removeUserRole"
              handleClick={() => console.log(" THIS DOESN'T DO ANYTHING")}
            >
              Remove
            </Button>
          ) : null}
        </div>
      )}
      {/** End Add/Remove Section */}
      {openModal ? (
        <UserRolesModal
          currentlyOpen={openModal}
          setOpenModal={setOpenModal}
          count={selectedWorkers.length}
          role={roleName}
          selectedUsers={selectedWorkers}
          updateVisibleWorkers={setSelectedWorkers}
          setRoleName={setRoleName}
          setAttachConfirmed={setAttachConfirmed}
        />
      ) : null}
    </div>
  )
}

export default UserRolesConfig
