import React, { ReactElement, SyntheticEvent, useState } from 'react'
import styled, { StyledComponent } from 'styled-components'
import { IRedisModifyData, IRedisModifyDepartmentsRecord } from '../../../interfaces'
import RedisForm from '../Forms/redisDataForm'
import RedisObjectForm from '../Forms/redisObjectDataForm'
import JSONDisplay from '../devComponents/jsonDisplay'
import { updateRedisData, removeRedisData } from '../devActions/'
import { FormLabel } from 'src/components/common'

const DataFromRedis = ({ state, dispatch }: { state: any; dispatch: any }) => {
  const handleAssignmentMapInput = (
    e: SyntheticEvent<HTMLInputElement>,
    index: number,
    index1: number,
  ) => {
    state.redisData.results[index].trainings[index1].status = e.currentTarget.value
  }
  const handleDepartmentsMonitoredInput = (e: SyntheticEvent<HTMLInputElement>, index: number) => {
    state.redisData.results[index].isMonitored = e.currentTarget.value === 'Monitored' ? '1' : '0'
  }
  const handleDepartmentsRecordInput = (
    e: SyntheticEvent<HTMLInputElement>,
    index: number,
    parentKey: string,
    childKey: string,
    arrayIndex?: number,
  ) => {
    if (arrayIndex) {
      state.redisData.departmentsRecordResults[index][parentKey][arrayIndex][childKey] =
        e.currentTarget.value
    } else if (parentKey && childKey) {
      state.redisData.departmentsRecordResults[index][parentKey][childKey] = e.currentTarget.value
    } else {
      state.redisData.departmentsRecordResults[index][parentKey] = e.currentTarget.value
    }
  }
  const handleDepartmentsRecordUpdate = (
    index: number,
    parentKey: string,
    childKey: string,
    option: string,
    arrayIndex?: number,
    data?: any,
  ) => {
    const hkey = state.redisData.departmentsRecordResults[index].groupId
    let redisRecordValue
    if (option === 'delete') {
      if (
        typeof arrayIndex === 'number' &&
        Array.isArray(state.redisData.departmentsRecordResults[index][parentKey]) &&
        typeof data === 'undefined'
      ) {
        state.redisData.departmentsRecordResults[index][parentKey].splice(arrayIndex, 1)
      } else if (parentKey && childKey) {
        delete state.redisData.departmentsRecordResults[index][parentKey][childKey]
      } else {
        delete state.redisData.departmentsRecordResults[index][parentKey]
      }
    }

    if (parentKey === 'workerSidMap') {
      redisRecordValue = state.redisData.departmentsRecordResults[index][parentKey]?.reduce(
        (acc: any, curr: any) => {
          const [workerSid, empId] = curr[childKey].split(':')
          acc[workerSid] = empId
          return acc
        },
        {},
      )
    } else if (parentKey === 'parentGroupIds') {
      redisRecordValue = state.redisData.departmentsRecordResults[index][parentKey]?.reduce(
        (acc: any[], curr: any) => {
          acc.push(curr.objectValue)
          return acc
        },
        [],
      )
    }

    const body: IRedisModifyDepartmentsRecord = {
      actionType: state.redisActionType.id.replace('get', option),
      objectKey: parentKey,
      hkey,
      redisRecordValue,
      ...(!redisRecordValue && {
        redisRecordValue: state.redisData.departmentsRecordResults[index][parentKey],
      }),
      ...(data && {}),
    }
    if (option === 'update') {
      updateRedisData(body)
    } else {
      removeRedisData(body)
    }
    dispatch({ type: 'setRedisData', payload: state.redisData })
  }
  const handleWorkerRecordUpdate = (
    index: number,
    parentKey: string,
    childKey: string,
    option: string,
    arrayIndex?: number,
    data?: any,
  ) => {
    const hkey = state.redisData.results[index].workerRecordPretty.workerSid
      ? state.redisData.results[index].workerRecordPretty.workerSid
      : state.redisData.results[index].workerRecordPretty.redisWorkerKey

    let redisRecordValue
    if (option === 'delete') {
      if (
        typeof arrayIndex === 'number' &&
        Array.isArray(state.redisData.results[index].workerRecordPretty[parentKey]) &&
        typeof data === 'undefined'
      ) {
        state.redisData.results[index].workerRecordPretty[parentKey].splice(arrayIndex, 1)
      } else {
        delete state.redisData.results[index].workerRecordPretty[parentKey]
      }
    }

    if (parentKey === 'empGroupIds') {
      redisRecordValue = state.redisData.results[index].workerRecordPretty[parentKey]?.reduce(
        (acc: any[], curr: any) => {
          acc.push(curr.objectValue)
          return acc
        },
        [],
      )
    }

    const body: IRedisModifyDepartmentsRecord = {
      actionType: state.redisActionType.id.replace('get', option),
      objectKey: parentKey,
      hkey,
      redisRecordValue,
      ...(!redisRecordValue && {
        redisRecordValue: state.redisData.results[index].workerRecordPretty[parentKey],
      }),
    }
    if (option === 'update') {
      updateRedisData(body)
    } else {
      removeRedisData(body)
    }
    dispatch({ type: 'setRedisData', payload: state.redisData })
  }

  const handleUpdate = (index: number, index1: number, option: string) => {
    const body: IRedisModifyData = {
      actionType: state.redisActionType.id.replace('get', option),
      ...(state.redisActionType.id === 'getAssignmentMap' && {
        empId: state.redisData.results[index].empId,
        assignmentId: Object.keys(state.redisData.results[index].assignmentMap)[Number(index1)],
        ...(option === 'update' && {
          status: state.redisData.results[index].trainings[index1].status,
        }),
      }),
      ...(state.redisActionType.id === 'getDepartmentsMonitored' && {
        departmentMonitored: 'departmentsMonitored',
        departmentGroupId: Object.keys(state.redisData.departmentsMonitored)[index],
        ...(option === 'update' && {
          isMonitored: state.redisData.results[index].isMonitored,
        }),
      }),
    }
    if (option === 'update') {
      updateRedisData(body)
    } else {
      state.redisData.results.splice(index, 1)
      dispatch({ type: 'setRedisData', payload: state.redisData })
      removeRedisData(body)
    }
  }

  const renderDataFromRedis = (key: string) => {
    const options: Record<string, ReactElement> = {
      getAssignmentMap: state.redisData?.results?.map((data: any, assignmentIndex: number) => (
        <Option key={JSON.stringify(data)} id={`getAssignmentMap`}>
          <Label>{`Employee ${data.name} (${data.empId})`}</Label>
          {state.toggleJSON === 'JSON' ? (
            <JSONDisplay json={data.assignmentMap} jkey={''}></JSONDisplay>
          ) : (
            data.trainings &&
            data.trainings.map((training: any, trainingIndex: number) => (
              <RedisForm
                key={JSON.stringify(training)}
                name={training.trainingName}
                nameLabel={'Training Name'}
                input={String(training.status)}
                inputLabel={'Status'}
                index={assignmentIndex}
                index1={trainingIndex}
                handleInput={handleAssignmentMapInput}
                handleButtonClick={handleUpdate}
              ></RedisForm>
            ))
          )}
        </Option>
      )),
      getDepartmentsMonitored:
        state.toggleJSON === 'JSON' ? (
          <Option id={`getDepartmentsMonitored`}>
            <Label>{`Departments Monitored`}</Label>
            <JSONDisplay json={state.redisData.departmentsMonitored} jkey={''}></JSONDisplay>
          </Option>
        ) : (
          state.redisData?.results?.map((data: any, departmentIndex: number) => (
            <Option key={JSON.stringify(data)}>
              <RedisForm
                name={data.groupName}
                nameLabel={`Department`}
                input={data.isMonitored ? 'Monitored' : ''}
                inputLabel={`Status`}
                index={departmentIndex}
                handleInput={handleDepartmentsMonitoredInput}
                handleButtonClick={handleUpdate}
              ></RedisForm>
            </Option>
          ))
        ),
      getDepartmentsRecord: (
        <Option id={`getDepartmentsRecord`}>
          {state.toggleJSON === 'JSON'
            ? state.redisData?.results?.map((data: any) => (
                <div key={JSON.stringify(data)}>
                  <Label>{`Department ${data.groupName}`}</Label>
                  <JSONDisplay json={data} jkey={''}></JSONDisplay>
                </div>
              ))
            : state.redisData?.departmentsRecordResults?.map(
                (dept: any, departmentIndex: number) => (
                  <div key={JSON.stringify(dept)}>
                    <Label>{`Department ${dept.groupName}`}</Label>
                    {Object.keys(dept).map((data: any) => (
                      <Option key={JSON.stringify(data)}>
                        <RedisObjectForm
                          keyName={data}
                          data={dept[data]}
                          index={departmentIndex}
                          handleInput={handleDepartmentsRecordInput}
                          handleButtonClick={handleDepartmentsRecordUpdate}
                        ></RedisObjectForm>
                      </Option>
                    ))}
                  </div>
                ),
              )}
        </Option>
      ),
      getTaskQueueRecord: state.redisData?.results.map((data: any) => (
        <Option key={JSON.stringify(data)}>
          <Label>{`Worker : ${data.name}`}</Label>
          {state.toggleJSON === 'JSON' ? <JSONDisplay json={data} jkey={''}></JSONDisplay> : ``}
        </Option>
      )),
      // getWorkers: state.redisData?.results?.map(
      //   ({ workerRecordJSON, workerRecordPretty }: any, workerIndex: number) => (
      //     <Option key={JSON.stringify(workerRecordJSON)} id={`getAssignmentMap`}>
      //       <Label>{`Worker ${workerRecordJSON.full_name}`}</Label>
      //       {state.toggleJSON === 'JSON' ? (
      //         <JSONDisplay json={workerRecordJSON} key={''}></JSONDisplay>
      //       ) : (
      //         workerRecordPretty &&
      //         Object.keys(workerRecordPretty).map((data: any) => (
      //           <Option key={JSON.stringify(data)}>
      //             <RedisObjectForm
      //               keyName={data}
      //               data={dataTransform(workerRecordPretty[data])}
      //               index={workerIndex}
      //               handleInput={handleWorkerRecordInput}
      //               handleButtonClick={handleWorkerRecordUpdate}
      //             ></RedisObjectForm>
      //           </Option>
      //         ))
      //       )}
      //     </Option>
      //   ),
      // ),
    }
    return options[key]
  }
  return (
    <OptionContainer actionType={state.redisActionType.id}>
      {renderDataFromRedis(state.redisActionType.id)}
    </OptionContainer>
  )
}
export default DataFromRedis

const Option: StyledComponent<'div', any, {}, never> = styled.div`
  margin-top: 25px;
`
const OptionContainer: StyledComponent<'div', any, { actionType: string }, never> = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  height: 95%;
`
const Label: StyledComponent<'div', any, {}, never> = styled.div`
  white-space: nowrap;
  margin-bottom: 20px;
  margin-top: 35px;
`
const ObjectContainer: StyledComponent<'div', any, {}, never> = styled.div`
  margin-left: 10px;
`
