import React, { useContext } from 'react'
import { ReportingDistributionContext } from '../../../context/ReportingDistributionContext'
import { FilterableTable } from '../../common'

const ReportingDistribution = ({ dispatch, handleShowSaveReport }: any) => {
  const { savedReports, updateSavedReportsList } = useContext(ReportingDistributionContext)
  const handleRowClick = async (savedReport: any) => {
    // set state to reflect what would be selected with the request payload
    await dispatch({
      type: 'setEditSavedReport',
      payload: savedReport,
    })

    handleShowSaveReport()
  }

  return (
    <React.Fragment>
      <FilterableTable
        handleRowClick={handleRowClick}
        width={'100%'}
        headers={[
          { value: 'checkbox', display: '', order: 0 },
          { value: 'reportTitle', display: 'Report Title', sortable: true, order: 1 },
          { value: 'cc', display: 'Recipient List', sortable: true, order: 2 },
          { value: 'freq', display: 'Frequency', sortable: true, order: 3 },
          { value: 'lastRunTime', display: 'Last Run Time', sortable: true, order: 4 },
        ]}
        data={savedReports}
        onUpdate={() => {
          updateSavedReportsList()
        }}
        noExport={true}
      />
    </React.Fragment>
  )
}

export default ReportingDistribution
