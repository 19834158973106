import React, { useState } from 'react'
import { Dropdown, Flex, FormLabel, Input, TextArea } from '../../../common'
import { playSound } from '../../../../utils'

const TrainingDialog = ({
  audioOptions,
  audioConfig,
  duration,
  message,
  name,
  keyName,
  reason,
  assignAudio,
  handleChange,
}: any) => {
  const keyMap: Record<string, any> = {
    assignAudio: `config.flexConfig.timerConfig.${keyName}.assignAudio`,
    audio_notification: `config.flexConfig.timerConfig.${keyName}.audio_notification`,
    message: `config.flexConfig.timerConfig.${keyName}.message`,
    duration: `config.flexConfig.timerConfig.${keyName}.duration`,
    reason: `config.flexConfig.timerConfig.${keyName}.reason`,
    name: `config.flexConfig.timerConfig.${keyName}.name`,
  }
  const [title, setTitle] = useState(name)
  const [currentDuration, setCurrentDuration] = useState(duration)
  const [currentReason, setCurrentReason] = useState(reason)
  const [currentMessage, setCurrentMessage] = useState(message)
  if (audioOptions) {
    audioOptions.sort((a: any, b: any) => (a.value > b.value ? 1 : -1))
  }

  const [currentAudio, setCurrentAudio] = useState(
    audioOptions.find((opt: any) => opt.value === assignAudio[0]) ||
      assignAudio || {
        display: 'audio_ding_1',
        id: 'audio_ding_1',
        value: ['audio_ding_1'],
      },
  )

  return (
    <Flex config={['column']} style={{ marginRight: 25 }}>
      <Flex>
        {keyName.substring(0, 1).toUpperCase()}
        {keyName.substring(1, keyName.length)}
      </Flex>
      <Flex>
        <FormLabel
          id={keyName + 'Title'}
          value={'Title'}
          tooltip={{
            selector: 'configuration.TrainingDialog.Title',
          }}
        >
          <Input
            type="rolled"
            value={title}
            handleChange={(e) => {
              setTitle(e.currentTarget.value)
              handleChange(
                keyName,
                {
                  name: e.currentTarget.value,
                },
                keyMap.name,
              )
            }}
          />
        </FormLabel>
        <FormLabel
          id={keyName + 'Duration'}
          value={'Duration'}
          tooltip={{
            selector: 'configuration.TrainingDialog.Duration',
          }}
        >
          <Input
            type="rolled"
            value={currentDuration}
            handleChange={(e) => {
              if (!/\D/g.test(e.currentTarget.value)) {
                setCurrentDuration(e.currentTarget.value)
                handleChange(
                  keyName,
                  {
                    duration: e.currentTarget.value,
                  },
                  keyMap.duration,
                )
              }
            }}
          />
        </FormLabel>
      </Flex>
      <Flex>
        <FormLabel
          id={keyName + 'Reason'}
          value={'Reason'}
          tooltip={{
            selector: 'configuration.TrainingDialog.Reason',
          }}
        >
          <Input
            type="rolled"
            value={currentReason}
            handleChange={(e) => {
              setCurrentReason(e.currentTarget.value)
              handleChange(
                keyName,
                {
                  reason: e.currentTarget.value,
                },
                keyMap.reason,
              )
            }}
          />
        </FormLabel>
        <FormLabel
          id="audioOptions"
          value="Audible Ding"
          tooltip={{
            selector: 'configuration.TrainingDialog.AudibleDing',
          }}
        >
          <Dropdown
            type={'rolled'}
            options={audioOptions.sort()}
            selected={currentAudio}
            emitDropdownOption={(e) => {
              const selected = audioOptions.find((opt: any) => opt.id === e.id)
              const { ramp, tone, duration } = audioConfig[selected.id]

              playSound({ ramp, tone, duration })
              setCurrentAudio(selected)
              handleChange(
                keyName,
                {
                  assignAudio: [selected.value],
                },
                keyMap.assignAudio,
              )
            }}
          />
        </FormLabel>
      </Flex>
      <Flex>
        <FormLabel
          id={keyName + 'Message'}
          value={'Message'}
          containerStyle={{ marginRight: 10 }}
          tooltip={{
            selector: 'configuration.TrainingDialog.Message',
          }}
        >
          <TextArea
            value={currentMessage}
            handleChange={(e: any) => {
              setCurrentMessage(e.currentTarget.value)
              handleChange(
                keyName,
                {
                  message: e.currentTarget.value,
                },
                keyMap.message,
              )
            }}
          />
        </FormLabel>
      </Flex>
    </Flex>
  )
}
export default TrainingDialog
