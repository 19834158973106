import React, { useContext, useEffect, useState } from 'react'
import { Accordion, Button, FilterableTable, Flex } from '../../common'
import AspectTreeSideBar from '../../Trainings/TrainingAssignmentModal/AspectTreeSideBar'
import { AssignTrainingContext } from '../../../context/AssignTrainingContext'
import { AdminConfigContext } from '../../../context/AdminConfigContext'
import { updateConfig } from '../../../actions'
import { exclusionsHeaders } from '../../../constants'
import { checkPermissions, reduceToTables } from '../../../utils'

const Exclusions = ({ popToast }: { popToast: (msg: string) => void }) => {
  const [objMap, setObjMap] = useState<Record<string, []>>({})
  const [loading, setLoading] = useState<boolean>(false)
  const { setEmpGroupsAsTableData } = useContext(AssignTrainingContext)

  const {
    adminConfig: { config: { exclusionConfig = [] } = {} },
    refreshContext,
  } = useContext(AdminConfigContext)

  useEffect(() => {
    if (exclusionConfig.length) {
      const data: [] = exclusionConfig.flat()
      const reducedData = data.reduce(reduceToTables, {})
      if (reducedData) {
        setObjMap(reducedData)
      }
    }
  }, [exclusionConfig])

  const childrenToFilterOut: any[] = []
  const removeChildren = ({ groupId }: { groupId: string }) => {
    const parentIsChecked = childrenToFilterOut.includes(groupId)
    return !parentIsChecked
  }
  const identifyChildrenToRemove = ({ childGroupIds }: { childGroupIds: string[] }) => {
    if (!childGroupIds && !Array.isArray(childGroupIds)) return
    childrenToFilterOut.push(...childGroupIds)
  }
  // removes children of parents that are checked
  const rollUp = (data: any[]) => {
    data.forEach(identifyChildrenToRemove)
    const filteredData = data.filter(removeChildren)
    return filteredData
  }

  const onSubmitHandler = async () => {
    const groups = Object.keys(objMap)
      .map((key) => rollUp(objMap[key]))
      .flat()
    setLoading(true)
    const cleanedGroups = groups.map((group: any) => {
      const {
        childGroupIds = [],
        groupId,
        groupName,
        groupTreeId,
        parentId,
        tableName,
        parentGroupIds,
      } = group
      return {
        childGroupIds,
        groupId,
        groupName,
        groupTreeId,
        parentId,
        tableName,
        parentGroupIds,
      }
    })

    try {
      await updateConfig('config.exclusionConfig', cleanedGroups).then(() => {
        popToast(`Submitted successfully.`)
        return refreshContext && refreshContext('configuration')
      })
    } catch (err) {
      popToast(`Error on submission.`)
    } finally {
      setLoading(false)
    }
  }
  const permission = checkPermissions('exclusions', 'edit')

  return (
    <Flex config={['row']}>
      <Flex style={{ overflow: 'scroll', height: '80vh', width: '33%' }} config={['column']}>
        <AspectTreeSideBar objMap={objMap} setObjMap={setObjMap} />
      </Flex>
      <Flex
        style={{
          overflow: 'auto',
          height: '80vh',
          marginLeft: '25px',
          width: '100%',
        }}
        config={['column']}
      >
        {!loading && permission && (
          <Button
            style={{ zIndex: 1000, alignSelf: 'flex-end', minHeight: '50px', marginRight: '10px' }}
            size={'lg'}
            handleClick={onSubmitHandler}
          >
            Submit Tables
          </Button>
        )}
        {objMap &&
          Object.keys(objMap).map((key: any) => {
            const filteredData = rollUp(objMap[key])
            return (
              key !== 'undefined' && (
                <Accordion label={key} key={key}>
                  <FilterableTable
                    loading={false}
                    width={'100%'}
                    data={filteredData}
                    exclusions={objMap}
                    setData={setEmpGroupsAsTableData}
                    headers={exclusionsHeaders}
                    hideSelectionBar={true}
                    onUpdate={refreshContext}
                    noExport={true}
                  />
                </Accordion>
              )
            )
          })}
      </Flex>
    </Flex>
  )
}

export default Exclusions
