import { IHeader } from '../interfaces'
//Fix orderdfdsfasfasfasfs
const assignmentHeaders: IHeader[] = [
  { value: 'checkbox', display: '', order: 0 },
  { value: 'fullName', display: 'Employee Name', sortable: true, order: 1 },
  { value: 'trainingName', display: 'Task Name', sortable: true, order: 2 },
  { value: 'empId', display: 'Employee ID', order: 3 },
  { value: 'status', display: 'Status', sortable: true, order: 4 },
  { value: 'supervisorName', display: 'Supervisor', sortable: true, order: 5 },
  { value: 'operationsManager', display: 'Ops Manager', sortable: true, order: 6 },
  { value: 'dept', display: 'Dept', sortable: true, order: 7 },
  { value: 'site', display: 'Site', sortable: true, order: 8 },
  { value: 'mascot', display: 'Mascot', sortable: true, order: 9 },
  { value: 'priority', display: 'Priority', sortable: true, order: 10 },
  { value: 'duration', display: 'Duration', sortable: true, order: 11 },
  { value: 'startedTraining', display: 'Time Started', sortable: true, order: 12 },
  { value: 'taskEndTime', display: 'Time Ended', sortable: true, order: 13 },
  { value: 'percentTimeSpent', display: 'Time Spent %', sortable: true, order: 14 },
  { value: 'completionPercent', display: 'Completion %', sortable: true, order: 15 },
  { value: 'timeSpent', display: 'Time Spent', sortable: true, order: 16 },
  { value: 'startDate', display: 'Start Date', order: 17 },
  { value: 'dueDate', display: 'Due Date', order: 18 },
  { value: 'endDate', display: 'End Date', order: 19 },
  { value: 'lastModified', display: 'Last Updated', order: 20 },
  { value: 'tags', display: 'Tags', order: 21 },
  { value: 'timesDelivered', display: 'Times Delivered', order: 22 },
  { value: 'timesEjected', display: 'Times Ejected', order: 23 },
  { value: 'taskEndedDateTime', display: 'Timeout End Time', order: 24 },
]

const assignmentHeadersForReporting: IHeader[] = [
  { value: 'fullName', display: 'Name', sortable: true, order: 1 },
  { value: 'empId', display: 'Employee ID', order: 2 },
  { value: 'trainingName', display: 'Training Name', sortable: true, order: 3 },
  { value: 'startedTraining', display: 'Start Time', sortable: true, order: 4 },
  { value: 'status', display: 'Status', sortable: true, order: 5 },
  { value: 'duration', display: 'Duration', sortable: true, order: 6 },
  { value: 'timesDelivered', display: 'Delivery Count', order: 7 },
  { value: 'mascot', display: 'Mascot', sortable: true, order: 8 },
  { value: 'site', display: 'Site', sortable: true, order: 9 },
  { value: 'dept', display: 'Dept', sortable: true, order: 10 },
  { value: 'operationsManager', display: 'Coach', sortable: true, order: 11 },
  { value: 'businessUnit', display: 'Business Unit', sortable: true, order: 12 },
  { value: 'timeZone', display: 'Time Zone', sortable: true, order: 13 },
]

const autoAssignmentHeaders: IHeader[] = [
  { value: 'autoAssignmentName', sortable: true, display: 'Name', order: 0 },
  { value: 'isActive', sortable: true, display: 'Status', order: 1 },
  { value: 'trainingNames', sortable: true, display: 'Training Names', order: 2 },
  { value: 'trainingCount', display: 'Training Count', order: 3 },
  { value: 'employeeGroups', display: 'Employee Groups', order: 4 },
  { value: 'createdBy', sortable: true, display: 'Created By', order: 5 },
  { value: 'lastUpdatedBy', sortable: true, display: 'Last Modified By', order: 6 },
]

const bundleTrainingsBabyHeaders: Partial<IHeader>[] = [
  { value: 'trainingName', display: 'Training Name' },
  { value: 'trainingPercentComplete', display: '% (Complete/Assigned)' },
  { value: 'priority', display: 'Priority' },
  { value: 'actions', display: '' },
]

const bundleExpertsHeaders: Partial<IHeader>[] = [
  { value: 'fullName', display: 'Name', sortable: true, order: 1 },
  { value: 'empId', display: 'Employee ID', order: 2 },
  { value: 'trainingName', display: 'Training Name', sortable: true, order: 3 },
  { value: 'completionPercent', display: 'Completion', sortable: true, order: 4 },
  { value: 'startedTraining', display: 'Start Time', sortable: true, order: 5 },
  { value: 'status', display: 'Status', sortable: true, order: 6 },
  { value: 'duration', display: 'Duration', sortable: true, order: 7 },
  { value: 'timesDelivered', display: 'Delivery Count', order: 8 },
  { value: 'mascot', display: 'Mascot', sortable: true, order: 9 },
  { value: 'site', display: 'Site', sortable: true, order: 10 },
  { value: 'dept', display: 'Dept', sortable: true, order: 11 },
  { value: 'operationsManager', display: 'Coach', sortable: true, order: 12 },
  { value: 'businessUnit', display: 'Business Unit', sortable: true, order: 13 },
  { value: 'timeZone', display: 'Time Zone', sortable: true, order: 14 },
]
const deptsHeaders: IHeader[] = [
  { value: 'deptGroupName', display: 'Department', sortable: true, order: 0 },
]

const dispatchRuleHeaders: IHeader[] = [
  { value: 'checkbox', display: '', order: 0 },
  { value: 'name', display: 'Rule Name', sortable: true, order: 0 },
  {
    value: 'isEnabled',
    display: 'Status',
    sortable: false,
    isInput: true,
    order: 1,
  },
  {
    value: 'threshold',
    display: 'Threshold',
    sortable: false,
    isInput: true,
    order: 2,
  },
  {
    value: 'taskQueueMaxPercent',
    display: 'Task Queue Max',
    sortable: false,
    isInput: true,
    order: 3,
  },
  {
    value: 'outageStartTime',
    display: 'Outage Start Time',
    sortable: false,
    isInput: true,
    order: 4,
  },
  {
    value: 'outageEndTime',
    display: 'Outage End Time',
    sortable: false,
    isInput: false,
    order: 5,
  },

  {
    value: 'lastModified',
    display: 'Last Updated By',
    sortable: true,
    isInput: true,
    order: 6,
  },
]

const ejectRuleHeaders: IHeader[] = [
  { value: 'checkbox', display: '', order: 0 },
  { value: 'name', display: 'Rule Name', sortable: true, order: 0 },
  {
    value: 'logicalOperation',
    display: 'Logical Operation',
    sortable: false,
    isInput: true,
    order: 1,
  },
  {
    value: 'expertsToEjectDisplay',
    display: 'Experts To Eject',
    sortable: false,
    isInput: true,
    order: 2,
  },
  {
    value: 'readableRules',
    display: 'Rules',
    sortable: false,
    isInput: true,
    order: 3,
  },
  {
    value: 'lastModified',
    display: 'Last Updated By',
    sortable: true,
    isInput: true,
    order: 4,
  },
]

const empGroupsHeaders: IHeader[] = [
  { value: 'checkbox', display: '', order: 0 },
  { value: 'groupName', display: 'Group Name', sortable: true, order: 0 },
  { value: 'empGroupNames', display: 'Employee Groups', sortable: true, order: 1 },
  { value: 'threshold', display: 'Threshold', sortable: true, order: 2 },
  { value: 'taskQueueMaxPercent', display: 'Task Queue Max', sortable: true, order: 3 },
  { value: 'ejectRules', display: 'Eject Rules', sortable: true, order: 4 },
]

const exclusionsHeaders: IHeader[] = [
  { value: 'checkbox', display: '', order: 0 },
  { value: 'groupName', display: 'Group Name', sortable: true, order: 1 },
]

const flexUiHeaders: IHeader[] = [
  { value: 'name', display: 'Name', sortable: true, order: 0 },
  { value: 'url', display: 'URL', sortable: true, order: 1 },
  { value: 'approved', display: 'Approved', order: 2 },
  {
    value: 'dropdown',
    display: '',
    sortable: false,
    isInput: false,
    order: 4,
  },
]

const fusionReportingHeaders: IHeader[] = [
  { value: 'full_name', display: 'Employee Name', sortable: true, order: 1 },
  { value: 'empId', display: 'Employee Id', sortable: true, order: 2 },
  { value: 'department', display: 'Department', sortable: true, order: 3 },
  { value: 'supervisorName', display: 'Supervisor', sortable: true, order: 4 },
  { value: 'operationsManager', display: 'OpsManager', sortable: true, order: 5 },
  { value: 'taskStatus', display: 'Task Status', sortable: true, order: 6 },
]

const fusionAssignmentHeaders: IHeader[] = [
  { value: 'checkbox', display: '', order: 0 },
  { value: 'full_name', display: 'Employee Name', sortable: true, order: 1 },
  { value: 'empId', display: 'Employee Id', sortable: true, order: 2 },
  { value: 'department', display: 'Department', sortable: true, order: 3 },
  { value: 'supervisorName', display: 'Supervisor', sortable: true, order: 4 },
  { value: 'operationsManager', display: 'OpsManager', sortable: true, order: 5 },
  { value: 'taskStatus', display: 'Task Status', sortable: true, order: 6 },
]

const notificationsHeaders: IHeader[] = [
  { value: 'notificationTitle', display: 'Notification', sortable: true, order: 0 },
  { value: 'text', display: 'text', sortable: true, order: 1 },
  { value: 'audioAssigned', display: 'audioAssigned', sortable: true, order: 2 },
  { value: 'audio_notification', display: 'audio_notification', sortable: true, order: 3 },
  { value: 'timeoutDuration', display: 'timeoutDuration', sortable: true, order: 4 },
]

const sitesHeaders: IHeader[] = [{ value: 'site', display: 'Site', sortable: true, order: 0 }]

const supervisorsHeaders: IHeader[] = [
  { value: 'supervisor_name', display: 'Supervisor', sortable: true, order: 0 },
]
const trainingBundlesHeaders: IHeader[] = [
  { value: 'bundleName', display: 'Bundle Name', sortable: true, order: 0 },
  { value: 'trainingCount', display: 'Training Count', sortable: true, order: 1 },
  { value: 'bundleCompletion', display: '% (completed/assigned)', sortable: true, order: 2 },
  { value: 'startDate', display: 'Start Date', sortable: true, order: 3 },
  { value: 'dueDate', display: 'Due Date', sortable: true, order: 4 },
  { value: 'endDate', display: 'End Date', sortable: true, order: 6 },
  { value: 'lastUpdatedBy', display: 'Last Modified By', sortable: true, order: 5 },
  { value: 'expectedDuration', display: 'Expected Duration', sortable: true, order: 7 },
  {
    value: 'details',
    display: 'Details',
    order: 8,
  },
]

const trainingHeaders: IHeader[] = [
  { value: 'checkbox', display: '', order: 0 },
  { value: 'trainingName', display: 'Task', sortable: true, order: 0 },
  { value: 'trainingLink', display: 'Link', sortable: false, order: 1 },
  { value: 'isActive', display: 'Status', sortable: true, order: 2 },
  { value: 'priority', display: 'Priority', sortable: true, order: 3 },
  { value: 'trainingLength', display: 'Duration', sortable: true, order: 4 },
  { value: 'formattedMaxDispatchLimit', display: 'Max Del.', sortable: true, order: 5 },
  { value: 'trainingPercentComplete', display: '% (Complete/Assigned)', sortable: true, order: 6 },
  { value: 'minStartDate', display: 'Start Date', sortable: true, order: 7 },
  { value: 'maxDueDate', display: 'Due Date', sortable: true, order: 8 },
  { value: 'maxEndDate', display: 'End Date', sortable: true, order: 9 },
  { value: 'monitorItem', display: 'Monitor Method', sortable: true, order: 10 },
  { value: 'tags', display: 'Tags', sortable: true, order: 11 },
  { value: 'lastModified', display: 'Last Modified By', sortable: true, order: 12 },
  { value: 'hasAttachments', display: 'Attachments', sortable: false, order: 13 },
]

const trainingRulesHeaders: IHeader[] = [
  { value: 'checkbox', display: '', order: 0 },
  { value: 'trainingRuleName', display: 'Group Name', sortable: true, order: 0 },
  { value: 'empGroupNames', display: 'Employee Groups', sortable: true, order: 1 },
  {
    value: 'trainingRuleEnabled',
    display: 'Status',
    sortable: true,
    order: 2,
  },
  { value: 'threshold', display: 'Threshold', sortable: true, order: 3 },
  { value: 'taskQueueMaxPercent', display: 'Task Queue Max', sortable: true, order: 4 },
  { value: 'ejectRules', display: 'Eject Rules', sortable: true, order: 5 },
  {
    value: 'lastModified',
    display: 'Last Updated By',
    sortable: true,
    order: 6,
  },
]

const workersHeaders: IHeader[] = [
  { value: 'checkbox', display: '', order: 0 },
  { value: 'full_name', display: 'Worker Name', sortable: true, order: 1 },
  { value: 'empId', display: 'Employee ID', sortable: true, order: 2 },
  { value: 'siteName', display: 'Site', sortable: true, order: 3 },
  { value: 'mascot', display: 'Mascot', sortable: true, order: 4 },
  { value: 'supervisorName', display: 'Supervisor', sortable: true, order: 5 },
  { value: 'operationsManager', display: 'Ops Manager', sortable: true, order: 6 },
  { value: 'deptGroupName', display: 'Department', sortable: true, order: 7 },
]

export {
  assignmentHeaders,
  assignmentHeadersForReporting,
  autoAssignmentHeaders,
  bundleTrainingsBabyHeaders,
  bundleExpertsHeaders,
  ejectRuleHeaders,
  deptsHeaders,
  dispatchRuleHeaders,
  empGroupsHeaders,
  exclusionsHeaders,
  flexUiHeaders,
  fusionReportingHeaders,
  fusionAssignmentHeaders,
  notificationsHeaders,
  sitesHeaders,
  supervisorsHeaders,
  trainingRulesHeaders,
  trainingBundlesHeaders,
  trainingHeaders,
  workersHeaders,
}
