import React, { useState, useContext } from 'react'
import { handleInput } from '../../../../utils'
import { Modal, FilterableTable, FormLabel, ButtonSet, DatePicker, Input, Flex } from '../../index'
import { updateAssignments } from '../../../../actions'
import { IAssignmentModal } from '../../../../interfaces'
import { mapSelectionsByTraining } from '../../../Assignments/utils'
import { GroupsContext } from '../../../../context/GroupsContext'

export const AssignmentsModal = ({
  data,
  setVisible,
  onUpdate,
  headers,
  handleOnCancel,
  processSelected = () => {},
}: IAssignmentModal) => {
  const [startDate, setStartDate] = useState<string>('')
  const [endDays, setEndDays] = useState<string>('')
  const [dueDays, setDueDays] = useState<string>('')
  const [workersSelected, setWorkersSelected] = useState<Map<string, string[]>>(new Map())
  const [hasError, setHasError] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { optimisticallyUpdateAssignmentDates } = useContext(GroupsContext)
  return (
    <Modal
      header="Edit Task Details"
      close={() => {
        setVisible(false)
        handleOnCancel()
      }}
    >
      <Flex
        config={['column']}
        style={{
          width: '95vw',
          overflow: 'hidden',
        }}
      >
        {hasError ? (
          <Flex
            config={['justifyCenter', 'alignCenter']}
            style={{
              flex: ' 1 1',
            }}
          >
            <span>{errorMessage}</span>
          </Flex>
        ) : (
          <>
            <Flex config={['justifyAround']}>
              <div style={{ width: '285px' }}>
                <FormLabel id="datepickerStartDate" value="Start Date*">
                  <DatePicker
                    dateType={'datetime-local'}
                    handleChange={setStartDate}
                    placeholder="Please select a date"
                    type="box"
                    value={startDate}
                  />
                </FormLabel>
              </div>
              <div style={{ width: 150 }}>
                <FormLabel id="assignmentsDueDays" value="Due Days*">
                  <Input
                    handleChange={(e) => handleInput(e, setDueDays)}
                    placeholder="Due Days*"
                    value={dueDays}
                  />
                </FormLabel>
              </div>
              <div style={{ width: 150 }}>
                <FormLabel id="assignmentsEndDays" value="End Days*">
                  <Input
                    handleChange={(e) => handleInput(e, setEndDays)}
                    placeholder="End Days*"
                    value={endDays}
                  />
                </FormLabel>
              </div>
            </Flex>
            <div style={{ overflow: 'auto', flex: '1 1', width: '100%' }}>
              <FilterableTable
                workersSelected={workersSelected}
                addWorkerToWorkerSelected={mapSelectionsByTraining(setWorkersSelected)}
                width={'100%'}
                data={data}
                headers={headers}
                hideSelectionBar={true}
              />
            </div>
          </>
        )}
        <Flex
          config={['justifyEnd', 'alignEnd']}
          style={{
            paddingRight: '10px',
          }}
        >
          <ButtonSet
            formValid={!!startDate && !!dueDays && !!endDays && !!workersSelected.size && !hasError}
            labels={['Cancel', 'Apply Changes']}
            handleSave={async () => {
              const toUpdate = Array.from(workersSelected).map(([trainingId, empId]) => ({
                empId,
                trainingId,
                startDate,
                dueDays,
                endDays,
              }))
              handleOnCancel()
              updateAssignments('', toUpdate, 'update')
                .then(() => {
                  onUpdate && onUpdate()
                  optimisticallyUpdateAssignmentDates(toUpdate)
                  setVisible(false)
                })
                .catch((err) => {
                  setHasError(true)
                  setErrorMessage(err.message)
                })
            }}
            handleCancel={() => {
              setVisible(false)
              handleOnCancel()
            }}
          />
        </Flex>
      </Flex>
    </Modal>
  )
}

export default AssignmentsModal
