import React, { ChangeEvent, CSSProperties, ReactNode } from 'react'
import styled, { StyledComponent } from 'styled-components'
import './index'

interface ITextArea {
  handleChange: any
  type: string
  children: ReactNode[] | ReactNode
  style: CSSProperties
  className: string | undefined
  value: string
  placeholder: string | undefined
  link?: string
}

const TextArea = ({
  style,
  handleChange,
  value = '',
  placeholder = 'Training Notes',
}: Partial<ITextArea>) => (
  <StyledInputContainer style={style}>
    <StyledTextArea value={value} placeholder={placeholder} onChange={handleChange} />
  </StyledInputContainer>
)

const StyledInputContainer: StyledComponent<'div', any, {}, never> = styled.div`
  margin: 10px 0;
  border: 1px solid #aaafb4;
  padding: 4px 0px 4px 25px;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  align-items: center;
  &hover {
    border-color: #4a0684;
  }
`

const StyledTextArea: StyledComponent<'textarea', any, {}, never> = styled.textarea`
  margin: 0;
  min-width: 100%;
  height: 26px;
  border: none;
  font-size: 1.08em;
  height: 150px;
  color: ${(props) => props.theme.color};
  :focus {
    outline: none;
  }
  &hover {
  }
`

export default TextArea
