import React, { useContext, useEffect, useState } from 'react'
import SideBar from './SideBar'
import { AssignTrainingContext } from '../../../context/AssignTrainingContext'
import { workersHeaders } from '../../../constants'
import FilterableTable from '../../common/Organisms/FilterableTable'
import { ButtonSet, DatePicker, Flex, FormLabel, Input } from '../../common'
import { handleInput } from '../../../utils'
import { getAllWorkers, postAssignmentsData } from '../../../actions'

const TrainingAssignmentModal = ({
  assignmentType,
  bundleId,
  trainingIds,
  handleCancel,
  handleRefreshAssignment,
  trainingId,
  showsHeader = true,
  ruleType = '',
  filterOut,
}: {
  bundleId?: string
  assignmentType?: string
  trainingIds?: string[]
  trainingId?: string
  handleCancel: () => void
  handleRefreshAssignment: (x: string[]) => void
  showsHeader: boolean
  ruleType?: string
  onUpdate?: any
  handleBundleAssignmentUpdate?: (map: any) => void
  filterOut?: any
}) => {
  const {
    workers,
    loading,
    setShouldGetGroupsOnly,
    resetAllChecks,
    setLoading,
    setAllWorkers,
    setSearchedWorkers,
  } = useContext(AssignTrainingContext)
  const [data, setData] = useState<any[]>([])
  const [startDate, setStartDate] = useState<string>('')
  const [endDays, setEndDays] = useState<string>('')
  const [dueDays, setDueDays] = useState<string>('')
  const [workersSelected, setWorkersSelected] = useState<any[]>([])

  useEffect(() => {
    setData(workers)
  }, [workers])

  useEffect(() => {
    setShouldGetGroupsOnly(false)
  }, [setShouldGetGroupsOnly])

  const handleGetAllWorkers = () => {
    ;(async () => {
      setLoading(true)
      const data = (await getAllWorkers()) || {
        workers: [],
        businessUnits: [],
        mascotAspectData: [],
        siteAspectData: [],
        supervisorAspectData: [],
      }
      setAllWorkers(data?.workers)
      setSearchedWorkers(data?.workers)
    })()
  }
  return (
    <Flex style={{ width: '100%', maxHeight: 744 }}>
      <div
        style={{
          minWidth: 293,
          width: 293,
          height: '100%',
          overflow: 'auto',
          borderRight: '1px solid #dfdfdf',
        }}
      >
        <div style={{ minWidth: '100%', width: 'max-content', height: 572 }}>
          <SideBar />
        </div>
      </div>
      <div style={{ width: '100%', overflow: 'auto' }}>
        {showsHeader && (
          <Flex style={{ marginLeft: 15, width: 750 }} config={['justifyBetween']}>
            <div style={{ width: 400 }}>
              <FormLabel id="datepickerStartDate" value="Start Date*">
                <DatePicker
                  dateType={'datetime-local'}
                  handleChange={setStartDate}
                  placeholder="Please select a date"
                  type="box"
                  value={startDate}
                />
              </FormLabel>
            </div>
            <div style={{ width: 150 }}>
              <FormLabel id="assignmentsDueDays" value="Due Days*">
                <Input
                  handleChange={(e) => handleInput(e, setDueDays)}
                  placeholder="Due Days*"
                  value={dueDays}
                />
              </FormLabel>
            </div>
            <div style={{ width: 150 }}>
              <FormLabel id="assignmentsEndDays" value="End Days*">
                <Input
                  handleChange={(e) => handleInput(e, setEndDays)}
                  placeholder="End Days*"
                  value={endDays}
                />
              </FormLabel>
            </div>
          </Flex>
        )}
        {
          <button
            className="ttd-bare-button"
            style={{
              textDecoration: 'underline',
              fontSize: 13,
              padding: '0 26px',
            }}
            onClick={handleGetAllWorkers}
          >
            Get all workers (3min)
          </button>
        }
        <div
          style={{
            width: '100%',
            padding: '0 15px',
            height: 'auto',
            overflow: 'auto',
          }}
        >
          <FilterableTable
            workersSelected={workersSelected}
            addWorkerToWorkerSelected={(workerList) => setWorkersSelected(workerList)}
            loading={loading}
            width={'100%'}
            data={data}
            headers={workersHeaders}
            hideSelectionBar={true}
            ruleType={ruleType}
            filterOut={filterOut}
            noExport={true}
          />
        </div>
        <Flex config={['justifyEnd']} style={{ paddingRight: '10px' }}>
          <ButtonSet
            formValid={
              (!!startDate && !!dueDays && !!endDays && !!workersSelected.length) ||
              !showsHeader ||
              false
            }
            labels={['Cancel', 'Apply Changes']}
            handleSave={async () => {
              const dataForPost: any = {
                employeeIds: workersSelected,
                assignmentType,
                startDate: new Date(startDate).toISOString(),
                dueDays,
                endDays,
              }
              if (bundleId) {
                dataForPost.bundleId = bundleId
                dataForPost.trainingIds = trainingIds
              } else {
                dataForPost.trainingId = trainingId
              }
              await postAssignmentsData('Assignments', dataForPost).then(() => {
                resetAllChecks(true)
                const assignmentIds = []
                const trainingIds = dataForPost?.trainingIds?.length
                  ? dataForPost.trainingIds
                  : [dataForPost.trainingId]
                for (const trainingId of trainingIds) {
                  assignmentIds.push(
                    ...dataForPost.employeeIds.map((empId: string) => `${trainingId}:${empId}`),
                  )
                }
                return handleRefreshAssignment(assignmentIds)
              })
            }}
            handleCancel={() => {
              resetAllChecks(true)
              return handleCancel()
            }}
          />
        </Flex>
      </div>
    </Flex>
  )
}

export default TrainingAssignmentModal
