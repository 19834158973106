import React, { useState } from 'react'
import { Dropdown, Flex, FormLabel, Input, TextArea } from '../../../common'
import { playSound } from '../../../../utils'

const Fusion = ({ config, audioConfig, audioOptions, handleChange }: any) => {
  const keyMap: Record<string, any> = {
    audioAssigned: `config.flexConfig.monitorConfig.warnings.fusionEjection.audioAssigned`,
    audio_notification: `config.flexConfig.monitorConfig.warnings.fusionEjection.audio_notification`,
    message: `config.flexConfig.monitorConfig.warnings.fusionEjection.message`,
    timer: `config.flexConfig.monitorConfig.warnings.fusionEjection.timer`,
    title: `config.flexConfig.monitorConfig.warnings.fusionEjection.title`,
  }
  const { audioAssigned, audio_notification, message, timer, title } = config
  const [currentDuration, setCurrentDuration] = useState(timer)
  const [currentMessage, setCurrentMessage] = useState(message)

  if (audioOptions) {
    audioOptions.sort((a: any, b: any) => (a.value > b.value ? 1 : -1))
  }

  const [currentAudio, setCurrentAudio] = useState(
    audioOptions.find((opt: any) => opt.value === audioAssigned[0]) ||
      audioAssigned[0] || {
        display: 'audio_ding_1',
        id: 'audio_ding_1',
        value: ['audio_ding_1'],
      },
  )

  return (
    <Flex config={['column']} style={{ marginRight: 25 }}>
      <Flex>{title}</Flex>
      <Flex>
        <FormLabel id={'Duration'} value={'Duration'}>
          <Input
            type="rolled"
            value={currentDuration}
            handleChange={(e) => {
              if (!/\D/g.test(e.currentTarget.value)) {
                setCurrentDuration(e.currentTarget.value)
                handleChange(
                  'timer',
                  {
                    timer: Number(e.currentTarget.value),
                  },
                  keyMap.timer,
                )
              }
            }}
          />
        </FormLabel>
      </Flex>
      {/*<Flex>*/}
      {/*    <FormLabel id="audioOptions" value="Audible Ding">*/}
      {/*        <Dropdown*/}
      {/*            type={'rolled'}*/}
      {/*            options={audioOptions.sort()}*/}
      {/*            selected={currentAudio}*/}
      {/*            emitDropdownOption={(e) => {*/}
      {/*                const selected = audioOptions.find((opt: any) => opt.id === e.id)*/}
      {/*                const {ramp, tone, duration} = audioConfig[config.audioAssigned[0]]*/}
      {/*                console.log('selected.value', selected.value)*/}
      {/*                playSound({ramp, tone, duration})*/}
      {/*                setCurrentAudio(selected)*/}
      {/*                handleChange(*/}
      {/*                    'audioAssigned',*/}
      {/*                    {*/}
      {/*                        audioAssigned: selected.value,*/}
      {/*                    },*/}
      {/*                    keyMap.audioAssigned,*/}
      {/*                )*/}
      {/*            }}*/}
      {/*        />*/}
      {/*    </FormLabel>*/}
      {/*</Flex>*/}
      <Flex>
        <FormLabel id={'Message'} value={'Message'} containerStyle={{ marginRight: 10 }}>
          <TextArea
            value={currentMessage}
            handleChange={(e: any) => {
              setCurrentMessage(e.currentTarget.value)
              handleChange(
                'message',
                {
                  message: e.currentTarget.value,
                },
                keyMap.message,
              )
            }}
          />
        </FormLabel>
      </Flex>
    </Flex>
  )
}
export default Fusion
