import React, {
  CSSProperties,
  ChangeEventHandler,
  useState,
  ReactNode,
  MouseEventHandler,
  SyntheticEvent,
} from 'react'
import styled, { StyledComponent } from 'styled-components'
import { IconComponent } from '../../../common/index'
import { useSelector } from 'react-redux'

export interface IInput {
  type?: string
  children?: ReactNode[] | ReactNode
  style?: CSSProperties
  className?: string | undefined
  handleChange: ChangeEventHandler<HTMLInputElement>
  value: string
  placeholder?: string | undefined
  defaultValue?: string
  disabled?: boolean
  width?: string
  id?: string
  dataType?: string
  error?: any
  validateKey?: string
}

export const Input = ({
  type = 'box',
  children,
  id,
  disabled,
  style,
  className,
  handleChange,
  defaultValue = '',
  value = '',
  placeholder,
  dataType,
  error,
  validateKey,
}: IInput) => {
  const {
    userSettings: { darkMode },
  } = useSelector((state: any) => state?.user)
  const customDisplayType = darkMode ? 'darkMode' : 'originalFlavor'
  const [searchValue, setSearchValue] = useState<string>(value)
  let errorStyle
  const hasOnChangeHandler = handleChange
  if (error?.ref.name === 'trainingLink') {
    errorStyle = { width: 300 }
  } else {
    errorStyle = style
  }
  return (
    <StyledContainer>
      <StyledInputContainer style={style} type={type} className={className}>
        <StyledInput
          data-testid={id}
          className={className}
          onChange={(e) => {
            handleChange(e)
            setSearchValue(e.currentTarget.value)
          }}
          value={searchValue}
          type={dataType}
          placeholder={placeholder}
          disabled={disabled}
        />
        {type === 'pill' && (
          <IconComponent style={style} className="ttd-placeholder" icon={'search'} />
        )}
        {children}
      </StyledInputContainer>
      {error && (
        <StyledErrorContainer style={errorStyle}>
          {error?.ref.name === 'trainingLink' && <StyledError>{error.message}</StyledError>}
          {error && error.ref.name !== 'trainingLink' && <StyledError>{error.message}</StyledError>}
        </StyledErrorContainer>
      )}
    </StyledContainer>
  )
}

const StyledErrorContainer: StyledComponent<
  'div',
  { style: CSSProperties; className: string | undefined; type: string | undefined }
> = styled.div`
  color: white;
  background-color: red;
  position: relative;
  z-index: 5;
`
const StyledError: StyledComponent<
  'div',
  { style: CSSProperties; className: string | undefined; type: string | undefined }
> = styled.div`
  border-radius: 0px 0px 8px 8px;
  color: white;
  background-color: red;
  position: absolute;
  z-index: 5;
  padding: 4px 12px;
  margin: 0px;
  font-size: 14px;
  white-space: nowrap;
  line-height: 130%;
  font-weight: 700;
`

const StyledContainer: StyledComponent<
  'div',
  { style: CSSProperties; className: string | undefined; type: string | undefined }
> = styled.div`
  width: 100%;
`
const StyledInputContainer: StyledComponent<
  'div',
  {
    style: CSSProperties
    onClick: SyntheticEvent
    className: string | undefined
    type: string | undefined
  },
  { type: string | undefined },
  never
> = styled.div`
  background-color: ${(props) => props.theme.input.backgroundColor};
  height: ${({ type }: { type: string }) => (type === 'box' ? '48px' : '32px')};
  border: 1px solid #a5aaaf;
  border-radius: ${({ type }: { type: string }) => (type === 'pill' ? '16' : '4px')};
  padding: 4px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    border-color: #000;
  }
  &:active {
    border-color: #8223d2;
  }
`

const StyledInput: StyledComponent<'input', any, {}, never> = styled.input`
  margin: 0;
  width: 100%;
  height: 26px;
  border: none;
  font-size: 1.08em;
  text-transform: capitalize;
  outline: none;
  color: ${(props) => props.theme.input.color};
  &:disabled {
    color: ${(props) => props.theme.input.color} !important;
    border: none !important;
  }
  &:focus {
    outline: none;
    border: none;
  }
  &:hover {
    outline: none;
    border: none;
  }
`
