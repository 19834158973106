import React, { ChangeEvent, useState } from 'react'
import { Flex, IconComponent } from '../../index'
import { ITag } from '../../../../interfaces/groups'
import { useSelector } from 'react-redux'
import themes from '../../../../themes/schema'

const Tag = ({ type, onApply, onClear, value, inputValue, disabled }: ITag) => {
  const {
    userSettings: { darkMode },
  } = useSelector((state: any) => state?.user)
  const customDisplayType = darkMode ? 'darkMode' : 'originalFlavor'
  const getStyle: Record<string, any> = {
    tagBackgroundColor_darkMode: themes.darkMode.tag.backgroundColor,
    tagBackgroundColor_originalFlavor: themes.originalFlavor.tag.backgroundColor,
    tagColor_darkMode: themes.darkMode.tag.color,
    tagColor_originalFlavor: themes.originalFlavor.tag.color,
  }
  return (
    <Flex
      config={['alignCenter']}
      style={{
        width: 'max-content',
        marginRight: 5,
        padding: '3px 12px',
        marginTop: 5,
        borderRadius: '16px',
        backgroundColor: getStyle[`tagBackgroundColor_${customDisplayType}`],
      }}
    >
      {type === 'readOnly' && value}
      {type === 'input' && (
        <input
          value={inputValue}
          style={{
            margin: '0 !important',
            padding: 0,
            background: 'transparent',
            color: getStyle[`tagColor_${customDisplayType}`],
          }}
          onInput={onApply}
          disabled={disabled}
        />
      )}
      {!disabled && (
        <button className={'ttd-bare-button'} onClick={onClear}>
          <IconComponent style={{ fontSize: 18, cursor: 'pointer' }} icon={'close'} />
        </button>
      )}
    </Flex>
  )
}
export default Tag
