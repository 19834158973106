import { Button, FormLabel } from '../../common'
import React, { ReactElement, SyntheticEvent } from 'react'
import RedisForm from './redisDataForm'
import RedisObjectDisplay from './objectDisplay'
import styled, { StyledComponent } from 'styled-components'
const RedisObjectForm = ({
  handleInput,
  keyName,
  handleButtonClick,
  index,
  data,
}: {
  keyName: string
  index: number
  data: any
  handleInput: (
    e: SyntheticEvent<HTMLInputElement>,
    w: number,
    x: string,
    y: string,
    z?: number,
  ) => void

  handleButtonClick: (u: number, v: string, w: string, x: string, y?: number, z?: any) => void
}) => {
  const isNested = (obj: any) => {
    if (Array.isArray(obj)) {
      for (const o of obj) {
        const test: Boolean = isNested(o)
        return test
      }
    }
    const check = false
    for (const o in obj) {
      if (typeof obj[o] == 'object' && !Array.isArray(obj[o])) {
        return !check
      }
    }
    return check
  }
  if (isNested(data)) {
    return (
      <RedisObjectDisplay
        index={index}
        data={JSON.stringify(data, null, 2)}
        keyName={keyName}
        handleButtonClick={(x: number, y: any, z: string) => {
          handleButtonClick(index, keyName, '', z, 0, y)
        }}
      ></RedisObjectDisplay>
    )
  } else if (
    Array.isArray(data) &&
    ['workerSidMap', 'parentGroupIds', 'empGroupIds'].includes(keyName)
  ) {
    return (
      <>
        <FormContainer>
          <FormLabel
            id={`ttd-name`}
            value={'Key Name'}
            style={{ marginTop: 3, fontSize: 18, marginBottom: 3 }}
          >
            <FormText>{keyName}</FormText>
          </FormLabel>
          <ButtonContainer>
            <Button
              displayType={'outline'}
              size={'sm'}
              handleClick={() => {
                handleButtonClick(index, keyName, '', 'delete')
              }}
            >
              Remove
            </Button>
          </ButtonContainer>
        </FormContainer>
        <DataContainer>
          {data.map((arrayValue: any, arrayIndex: number) => (
            <RedisForm
              key={JSON.stringify(arrayValue)}
              name={arrayValue.keyName}
              nameLabel={'Key'}
              input={arrayValue.objectValue}
              inputLabel={'Value'}
              index={index}
              index1={arrayIndex}
              handleInput={(e: any) => {
                handleInput(e, index, keyName, 'objectValue', arrayIndex)
              }}
              handleButtonClick={(x: number, y: number, z: string) => {
                handleButtonClick(index, keyName, 'objectValue', z, arrayIndex)
              }}
            ></RedisForm>
          ))}
        </DataContainer>
      </>
    )
  } else if (typeof data === 'object') {
    return (
      <RedisObjectDisplay
        index={index}
        data={JSON.stringify(data, null, 2)}
        keyName={keyName}
        handleButtonClick={(x: number, y: any, z: string) => {
          handleButtonClick(index, keyName, '', z, 0, y)
        }}
      ></RedisObjectDisplay>
    )
  } else {
    return (
      <RedisForm
        name={keyName}
        nameLabel={'Key Name'}
        input={data}
        inputLabel={'Value'}
        index={index}
        handleInput={(e: any) => {
          handleInput(e, index, keyName, '')
        }}
        handleButtonClick={(x: number, y: number, z: string) => {
          handleButtonClick(index, keyName, '', z)
        }}
      ></RedisForm>
    )
  }
}

export default RedisObjectForm

const FormText: StyledComponent<'div', any, {}, never> = styled.div`
  overflow-wrap: break-word;
  width: 200px;
  font-size: 18px;
`
const FormContainer: StyledComponent<'div', any, {}, never> = styled.div`
  display: flex;
  max-width: 780px;
`
const DataContainer: StyledComponent<'div', any, {}, never> = styled.div`
  margin-left: 10px;
`

const ButtonContainer: StyledComponent<'div', any, {}, never> = styled.div`
  margin-top: 15px;
`
