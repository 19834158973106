import React, { useState, useEffect, ChangeEvent, useContext } from 'react'
import { Input, IconComponent, Flex, SVGIconComponent } from '../../common'

const ListItemOptions = ({
  data,
  item,
  type,
  deleteItem,
  editItem,
  showEditOptions,
}: {
  data: string[]
  deleteItem: (data: string[], type: string, value: string) => void
  item: string
  type: string
  showEditOptions: boolean
  editItem: (data: string[], type: string, value: string, item: string) => void
}) => {
  const [editValue, setEditValue] = useState(item)
  const [isEditing, setIsEditing] = useState(false)
  const saveEditHandler = (item: string) => {
    editItem(data, type, editValue, item)
  }
  return (
    <Flex>
      <Input
        handleChange={(e) => {
          if (!isEditing) return
          setEditValue(e.target.value)
        }}
        type="input"
        disabled={!isEditing ? true : false}
        style={{
          minWidth: `${(item.length + 5) * 8}px `,
          border: isEditing ? '1px solid gray' : 'none',
          marginRight: '6px',
          paddingLeft: '4px',
        }}
        value={editValue}
      />
      <Flex config={['column']}>
        <Flex>
          {isEditing && showEditOptions && (
            <SVGIconComponent
              onClickHandler={() => {
                setIsEditing(false)
                saveEditHandler(item)
              }}
              icon={'save'}
              style={{
                cursor: 'pointer',
              }}
            />
          )}
          {!isEditing && showEditOptions && (
            <>
              <SVGIconComponent
                onClickHandler={() => {
                  setEditValue(item)
                  setIsEditing(true)
                }}
                icon={'edit'}
                style={{
                  cursor: 'pointer',
                }}
              />

              <SVGIconComponent
                onClickHandler={() => {
                  setIsEditing(false)
                  deleteItem(data, type, item)
                }}
                icon={'close'}
                style={{
                  cursor: 'pointer',
                }}
              />
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
export default ListItemOptions
