import { Button, Flex, Modal } from '../../common'
import React from 'react'

export const ExpertNumberNotification = ({
  modalActions,
  modalName,
  data,
  state,
  dispatch,
}: any) => {
  const { toggleModal } = modalActions
  const { expertNumberNotificationData, selectedTaskQueue } = state
  return (
    <Modal
      close={() => toggleModal(modalName, false)}
      height={'250px'}
      style={{ marginTop: '25em', borderRadius: '25px', border: '.5px solid black' }}
    >
      <p>
        Notification : Only {expertNumberNotificationData} available to add to{' '}
        {selectedTaskQueue.display}
      </p>
      <Flex config={['row', 'alignCenter', 'justifyAround']}>
        <Button
          displayType={'outline'}
          size={'lg'}
          handleClick={() => toggleModal(modalName, false)}
        >
          Cancel
        </Button>
        <Button
          displayType={'flat'}
          size={'lg'}
          handleClick={() => {
            console.log('Affirm the data on the screen, send to AE')
          }}
        >
          Submit
        </Button>
      </Flex>
    </Modal>
  )
}
