/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react'
import { Button, Flex } from '../../index'

interface IDragAndDrop {
  closeModal: () => void
  // eslint-disable-next-line no-unused-vars
  confirm: (attachments: any) => void
}
const WarningModal = ({ closeModal, confirm }: IDragAndDrop) => {
  return (
    <Flex
      config={['column', 'justifyBetween']}
      style={{
        height: '100%',
      }}
    >
      <Flex config={['justifyCenter', 'alignCenter']} style={{ height: '100%' }}>
        <span style={{ display: 'block', marginBottom: '25px' }}>
          Are you sure you want to delete this item <br /> This action cannot be undone
        </span>
      </Flex>
      <Flex>
        <Button style={{ marginRight: 5 }} handleClick={confirm}>
          Delete
        </Button>
        <Button displayType="outline" handleClick={closeModal}>
          Cancel
        </Button>
      </Flex>
    </Flex>
  )
}

export default WarningModal
