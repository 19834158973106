import React from 'react'
import { Flex, Tooltip } from '../../'
import IconComponent from '../IconComponent'
import { useSelector } from 'react-redux'
import themes from '../../../../themes/schema'

interface IFormLabel {
  containerStyle?: any
  style?: any
  id: string
  value: string
  children: any
  tooltip?: any
}
const FormLabel = ({ containerStyle, style, id, value, children, tooltip }: IFormLabel) => {
  const {
    userSettings: { darkMode },
  } = useSelector((state: any) => state?.user)
  const customDisplayType = darkMode ? 'darkMode' : 'originalFlavor'
  const getStyle: Record<string, any> = {
    labelColor_darkMode: themes.darkMode.label.color,
    labelColor_originalFlavor: themes.originalFlavor.label.color,
  }

  return (
    <div style={{ ...containerStyle, width: '100%', marginBottom: 15 }}>
      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      <Flex config={['alignCenter']}>
        <label
          style={{ ...style, color: getStyle[`labelColor_${customDisplayType}`] }}
          className="genericLabelText"
          htmlFor={id}
        >
          {value}
        </label>
        {tooltip && (
          <div style={{ marginTop: '5px' }}>
            <Tooltip content={tooltip.content} selector={tooltip.selector}>
              <IconComponent className={'ttd-tooltip'} icon={'info'} />
            </Tooltip>
          </div>
        )}
      </Flex>
      {children}
    </div>
  )
}

export default FormLabel
