import React, { useState, createRef, useEffect, CSSProperties } from 'react'
import { Input } from '../Input'
import { Checkbox, IconComponent } from '../../index'
import styled, { StyledComponent } from 'styled-components'
import { useSelector } from 'react-redux'
import themes from '../../../../themes/schema'
export interface IDropdownOption {
  value: string
  display: string
  id: string
}

export interface IFilterDropdownOption extends IDropdownOption {
  checked?: boolean
}
export interface IAudioDropdownOption extends IDropdownOption {
  duration?: string
  tone?: string
  ramp?: string
}

interface IDropdown {
  selected: IDropdownOption
  isFilter?: boolean
  type?: string
  style?: CSSProperties | null | undefined
  children?: any
  options: IDropdownOption[] | IFilterDropdownOption[] | undefined
  emitDropdownOption?: (x: any) => typeof x | void
  onFilterSelect?: any
  multipleSelect?: boolean
  invertPopUp?: boolean
  noSearch?: boolean
}

const Dropdown = ({
  invertPopUp = false,
  isFilter,
  type = 'box',
  style,
  children,
  options = [],
  multipleSelect = false,
  emitDropdownOption,
  selected,
  onFilterSelect,
  noSearch = false,
}: IDropdown) => {
  const [scrollEnd, setScrollEnd] = useState<boolean>(false)
  const [showDropdownOptions, setShowDropdownOptions] = useState<boolean>(!close || false)
  const [searchedOptions, setSearchedOptions] = useState<IDropdownOption[]>(options)
  const [searchValue, setSearchValue] = useState<string>('')
  const {
    userSettings: { darkMode },
  } = useSelector((state: any) => state?.user)
  const customDisplayType = darkMode ? 'darkMode' : 'originalFlavor'
  const getStyle: Record<string, any> = {
    dropdownInput_backgroundColor_originalFlavor:
      themes.originalFlavor.dropdown.input.backgroundColor,
    dropdownInput_borderColor_originalFlavor: themes.originalFlavor.dropdown.input.borderColor,
    dropdown_option_color_originalFlavor: themes.originalFlavor.dropdown.option.color,
    dropdown_option_backgroundColor_originalFlavor:
      themes.originalFlavor.dropdown.option.backgroundColor,
    dropdownInput_backgroundColor_darkMode: themes.darkMode.dropdown.input.backgroundColor,
    dropdownInput_borderColor_darkMode: themes.darkMode.dropdown.input.borderColor,
    dropdown_option_color_darkMode: themes.darkMode.dropdown.option.color,
    dropdown_option_backgroundColor_darkMode: themes.darkMode.dropdown.option.backgroundColor,
  }

  useEffect(() => {
    if (!searchValue) {
      return setSearchedOptions(options)
    } else {
      const searchResult = options?.filter((option: IDropdownOption) =>
        option.display?.toLowerCase().includes(searchValue.toLowerCase()),
      )
      return setSearchedOptions(searchResult)
    }
  }, [searchValue, options])

  const canFilter = onFilterSelect || undefined

  const handleDropDown = (e: any) => {
    setShowDropdownOptions(!showDropdownOptions)
  }

  const handleSelect = (option: IDropdownOption) => {
    if (multipleSelect && isFilter) return
    setShowDropdownOptions(!showDropdownOptions)
    emitDropdownOption && emitDropdownOption(option)
    return
  }

  const scrollRef = createRef<HTMLDivElement>()

  useEffect(() => {
    const currentRef = scrollRef.current
    if (currentRef) {
      currentRef.addEventListener('scroll', () => {
        return setScrollEnd(currentRef.scrollTop + 55 >= 165)
      })
    }
    return
  }, [scrollRef, scrollEnd])

  useEffect(() => {
    document.addEventListener('mouseout', (e) => {
      const selector = e.target as HTMLDivElement
      if (!selector.closest('.dropdown-container-check')) {
        setShowDropdownOptions(false)

        return () => {
          document.removeEventListener('mouseout', () => {})
        }
      }
    })
    return
  }, [showDropdownOptions, setShowDropdownOptions])

  const renderArrow = () => {
    if (!showDropdownOptions && !invertPopUp) {
      return 'keyboard_arrow_down'
    }
    if (!showDropdownOptions && invertPopUp) {
      return 'keyboard_arrow_up'
    }
    if (showDropdownOptions && !invertPopUp) {
      return 'keyboard_arrow_up'
    }
    if (showDropdownOptions && invertPopUp) {
      return 'keyboard_arrow_down'
    }
  }

  return (
    <DropdownContainer className="dropdown-container-check">
      <div style={{ position: 'absolute', top: 12.5, left: 5, marginRight: 5 }}>{children}</div>
      <div>
        <Input
          type={type}
          style={{
            cursor: 'pointer',
            paddingLeft: 35,
            textShadow: '0 0 black',
            color: getStyle[`dropdown_option_color_${customDisplayType}`],
            backgroundColor: getStyle[`dropdownInput_backgroundColor_${customDisplayType}`],
            borderColor: getStyle[`dropdownInput_borderColor_${customDisplayType}`],
            ...style,
          }}
          value={selected?.display}
        >
          <button type="button" className={'ttd-bare-button'} onClick={handleDropDown}>
            <IconComponent className="material-icons">{renderArrow()}</IconComponent>
          </button>
        </Input>
      </div>
      {showDropdownOptions && (
        <OptionContainer
          displayType={type}
          invertPopUp={invertPopUp}
          style={{ maxHeight: isFilter ? 250 : 165 }}
          ref={scrollRef}
        >
          {!invertPopUp && !noSearch && (
            <Input
              handleChange={(e) => setSearchValue(e.currentTarget.value)}
              style={{
                paddingLeft: 35,
                cursor: 'pointer',
                color: 'transparent',
                textShadow: '0 0 black',
                border: 'none',
                position: 'sticky',
                borderBottom: '1px solid #dedede',
                ...style,
              }}
              placeholder="Search"
              value={searchValue}
            />
          )}
          <div style={{ overflow: 'auto' }}>
            {canFilter && (
              <Option style={{ ...style }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: 14,
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <Checkbox
                    checked={
                      options.length ===
                      options.filter((option: IFilterDropdownOption) => option.checked).length
                    }
                    onClick={(e) => {
                      onFilterSelect({}, -1)
                    }}
                  />
                  All
                </div>
              </Option>
            )}
            {searchedOptions.map(
              ({ id, value, display, checked }: IFilterDropdownOption, index) => {
                if (value === 'checkbox') return
                return (
                  <Option
                    tabIndex={0}
                    onClick={() => {
                      !canFilter && handleSelect({ id, value, display })
                    }}
                    key={JSON.stringify({ id, value, display })}
                    style={{
                      ...style,
                      color: getStyle[`dropdown_option_color_${customDisplayType}`],
                      backgroundColor:
                        getStyle[`dropdown_option_backgroundColor_${customDisplayType}`],
                      borderColor: getStyle[`dropdownInput_borderColor_${customDisplayType}`],
                    }}
                  >
                    {canFilter && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: 14,
                          width: '100%',
                          height: '100%',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <Checkbox
                          checked={checked}
                          onClick={() => {
                            onFilterSelect({ id, value, display, checked: !checked }, index)
                          }}
                        />
                        <button
                          className={'ttd-bare-button'}
                          style={{
                            flex: '1 1 100%',
                            display: 'flex',
                            alignItems: 'center',
                            color: getStyle[`dropdown_option_color_${customDisplayType}`],
                          }}
                          onClick={() => handleSelect({ id, value, display })}
                        >
                          {display}
                        </button>
                      </div>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    >
                      {!canFilter && display}
                    </div>
                  </Option>
                )
              },
            )}
          </div>
        </OptionContainer>
      )}
      {!isFilter && !scrollEnd && showDropdownOptions && options.length > 3 && <WhiteOverlay />}
    </DropdownContainer>
  )
}

export default Dropdown

const DropdownContainer: StyledComponent<'div', any, {}, never> = styled.div`
  position: relative;
`

const OptionContainer: StyledComponent<
  'div',
  any,
  { invertPopUp: boolean; displayType: string },
  never
> = styled.div`
  position: absolute;
  min-width: 100%;
  width: max-content;
  max-height: 165px;
  overflow-y: scroll;
  box-shadow: 0px 2px 4px 0.4px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  background-color: white;
  z-index: 5;
  top: ${({ invertPopUp, displayType }: { invertPopUp: boolean; displayType: string }) => {
    if (invertPopUp) {
      return ''
    } else if (!invertPopUp && displayType === 'rolled') {
      return '32px'
    } else {
      return '48px'
    }
  }};
  bottom: ${({ invertPopUp, displayType }: { invertPopUp: boolean; displayType: string }) => {
    if (!invertPopUp) {
      return ''
    } else if (invertPopUp && displayType === 'rolled') {
      return '32px'
    } else {
      return '48px'
    }
  }};
`

const Option: StyledComponent<'div', any, {}, never> = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 25px;
  height: 47px;
  min-width: 100%;
  width: max-content;
  border-bottom: 1px solid #dedede;
  cursor: pointer;
  background: white;
  &:hover {
    background-color: #dedede;
  }
`
const WhiteOverlay: StyledComponent<'div', any, {}, never> = styled.div`
  height: 20px;
  width: 100%;
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 5;
  position: absolute;
  bottom: -165px;
`
