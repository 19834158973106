import React, { SyntheticEvent, useContext, useEffect, useState } from 'react'
import { Button, ButtonSet, DatePicker, Flex, FormLabel, IconComponent, Input } from '../../common'
import { IReportingState } from '../../../interfaces'
import { handleInput } from '../../../utils'
import { deriveReportToSaveFromReportType } from '../functions'
import { useSelector } from 'react-redux'
import { saveReport } from '../../../actions'
import { ReportingDistributionContext } from '../../../context/ReportingDistributionContext'
import { defaultFrequency } from '../../../constants'
import { updateSavedReport } from '../../../actions/reporting'
import { isValid } from 'date-fns'

export interface Props {
  state: Partial<IReportingState>
  handleClose: (x: boolean, payload: any, state: any) => void
  selectedIds: Map<string, string[]>
}

interface IFrequency {
  isActive: '0' | '1'
  lastRunTime: string
}

// type IFrequencyTypes = 'daily' | 'last7' | 'last30'
type IFrequencyTypes = string

const SaveReportModal = ({ state, handleClose, selectedIds }: Props) => {
  const { savedReport = {} } = state
  const { updateSavedReportsList } = useContext(ReportingDistributionContext)
  const { user = {} } = useSelector((state: any) => state?.user)
  const { givenName, familyName, email, empId } = user

  const [reportTitle, setReportTitle] = useState<string>(savedReport.reportTitle || '')
  const [cc, setCC] = useState<string>(
    savedReport.cc && savedReport.cc.length > 0 ? savedReport.cc.join() : '',
  )
  const savedFreq = savedReport?.frequency || defaultFrequency

  const [selectedDate, setSelectedDate] = useState<string>(generateDateFromFrequency(savedFreq))
  const [frequency, setFrequency] = useState<Record<IFrequencyTypes, IFrequency>>(savedFreq)

  const [distributionPreference, setDistributionPreference] = useState<string>('')
  const [friendlyFrequencyName, setFriendlyFrequencyName] = useState<string>('Daily')
  const [showInvalidDateMessage, setShowInvalidDateMessage] = useState<boolean>(false)

  useEffect(() => {
    const newImage = { ...frequency }
    for (const freq in frequency) {
      if (isValid(new Date(selectedDate))) {
        setShowInvalidDateMessage(false)
        if (frequency[freq].isActive === '1') {
          newImage[freq].lastRunTime = new Date(selectedDate).toISOString()
        }
      } else {
        setShowInvalidDateMessage(true)
      }
    }
    setFrequency(newImage)
  }, [selectedDate])

  useEffect(() => {
    const selectedDateToDateObj = new Date(selectedDate)
    const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
    const weekdaySelected = weekdays[selectedDateToDateObj.getDay()]

    const readOnlyString = `E-mail report recurring ${friendlyFrequencyName} ${
      friendlyFrequencyName !== 'Daily' ? `every ${weekdaySelected}.` : ''
    }`

    setDistributionPreference(readOnlyString)
  }, [frequency])

  const handleSaveReport = async () => {
    const method = savedReport.hasOwnProperty('reportId') ? 'put' : 'post'
    const splitCC = (cc && cc.length && cc.split(',').map((email: string) => email.trim())) || []
    const { requestPayload = deriveReportToSaveFromReportType(state, selectedIds) } = savedReport
    const reportToSave = {
      givenName,
      familyName,
      email,
      empId,
      reportTitle,
      frequency,
      cc: splitCC,
      requestPayload,
    }
    if (method === 'post') {
      await saveReport(reportToSave).then(async () => updateSavedReportsList())
    } else {
      await updateSavedReport(reportToSave, savedReport.reportId).then(async () =>
        updateSavedReportsList(),
      )
    }
    return handleClose(false, {}, {})
  }

  const handleSetFrequencySelection = (key: IFrequencyTypes) => {
    const newImage = { ...frequency }
    const freqNameMap: Record<IFrequencyTypes, string> = {
      daily: 'Daily',
      last7: 'Weekly',
      last30: 'Monthly',
    }
    for (const freq in frequency) {
      if (freq === key) {
        setFriendlyFrequencyName(freqNameMap[key])
        newImage[key].isActive = '1'
        newImage[key].lastRunTime = new Date(selectedDate).toISOString()
      } else {
        newImage[freq].isActive = '0'
      }
    }
    setFrequency(newImage)
  }
  return (
    <React.Fragment>
      <FormLabel id="ttd-saved-report-title" value={'Title'}>
        <Input
          id="ttd-saved-report-title-text-input"
          value={reportTitle}
          handleChange={(e: SyntheticEvent<HTMLInputElement>) => handleInput(e, setReportTitle)}
          placeholder={'Title here'}
        />
      </FormLabel>
      <FormLabel id="ttd-saved-report-cc" value={'Additional Recipient List'}>
        <Input
          id="ttd-saved-report-cc-text-input"
          value={cc}
          handleChange={(e: SyntheticEvent<HTMLInputElement>) => handleInput(e, setCC)}
          placeholder={'Share with... (email, comma separated)'}
        />
      </FormLabel>
      <FormLabel id="isActive" value="Frequency">
        <Flex config={['column', 'justifyCenter', 'itemsCenter']}>
          <button
            data-testid={'ttd-saved-report-daily-frequency-radio-button'}
            style={{ display: 'flex', alignItems: 'center' }}
            className={'ttd-bare-button'}
            onClick={() => handleSetFrequencySelection('daily')}
          >
            <IconComponent
              id={'ttd-saved-report-daily-frequency-radio-button-icon'}
              icon={
                frequency.daily.isActive === '1' ? 'radio_button_checked' : 'radio_button_unchecked'
              }
            />
            <span style={{ marginLeft: 15 }}>Daily</span>
          </button>
          <button
            data-testid={'ttd-saved-report-last7-frequency-radio-button'}
            style={{ display: 'flex', alignItems: 'center' }}
            className={'ttd-bare-button'}
            onClick={() => handleSetFrequencySelection('last7')}
          >
            <IconComponent
              id={'ttd-saved-report-last7-frequency-radio-button-icon'}
              icon={
                frequency.last7.isActive === '1' ? 'radio_button_checked' : 'radio_button_unchecked'
              }
            />
            <span style={{ marginLeft: 15 }}>Weekly</span>
          </button>
          <button
            data-testid={'ttd-saved-report-last30-frequency-radio-button'}
            style={{ display: 'flex', alignItems: 'center' }}
            className={'ttd-bare-button'}
            onClick={() => handleSetFrequencySelection('last30')}
          >
            <IconComponent
              id={'ttd-saved-report-last30-frequency-radio-button-icon'}
              icon={
                frequency.last30.isActive === '1'
                  ? 'radio_button_checked'
                  : 'radio_button_unchecked'
              }
            />
            <span style={{ marginLeft: 15 }}>Monthly</span>
          </button>
        </Flex>
      </FormLabel>
      <FormLabel id={''} value={'Start automated reporting on'}>
        {showInvalidDateMessage && <p>date is invalid</p>}
        <DatePicker
          id={'ttd-saved-report-datepicker'}
          dateType={'date'}
          value={selectedDate}
          handleChange={setSelectedDate}
        />
      </FormLabel>
      <p>{distributionPreference}</p>

      {savedReport.reportId && (
        <Button
          size={'md'}
          handleClick={() => {
            handleClose(true, savedReport.requestPayload, state)
          }}
        >
          Load this report
        </Button>
      )}
      <ButtonSet
        id="ttd-saved-report-submit-button"
        formValid={reportTitle.length > 0}
        labels={['Cancel', 'Save']}
        handleSave={handleSaveReport}
        handleCancel={() => handleClose(false, {}, {})}
      />
    </React.Fragment>
  )
}

export default SaveReportModal

const generateDateFromFrequency = ({
  daily,
  last7,
  last30,
}: Record<IFrequencyTypes, IFrequency>) => {
  if (daily.isActive === '1') {
    return daily.lastRunTime.split('T')[0]
      ? daily.lastRunTime.split('T')[0]
      : new Date().toISOString().split('T')[0]
  } else if (last7.isActive === '1') {
    return last7.lastRunTime.split('T')[0]
      ? last7.lastRunTime.split('T')[0]
      : new Date().toISOString().split('T')[0]
  } else if (last30.isActive === '1') {
    return last30.lastRunTime.split('T')[0]
      ? last30.lastRunTime.split('T')[0]
      : new Date().toISOString().split('T')[0]
  } else {
    return new Date().toISOString().split('T')[0]
  }
}
