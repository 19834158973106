import React, { useState } from 'react'
import { Flex, FormLabel, Input, TextArea } from '../../../common'
import { toast } from 'react-toastify'

const Monitoring = ({
  name = '',
  label = '',
  message = '',
  timer = '0',
  title = '',
  handleChange,
  graceperiod,
}: {
  name: string
  label: string
  message: string
  timer: string
  title: string
  handleChange: (key: string, value: {}, keyMap: any) => void
  graceperiod?: string
}) => {
  const keyMap: Record<string, any> = {
    inactivity: {
      graceperiod: 'config.flexConfig.monitorConfig.inactivityTime',
      timer: 'config.flexConfig.monitorConfig.warnings.inactivity.timer',
      message: 'config.flexConfig.monitorConfig.warnings.inactivity.message',
      title: 'config.flexConfig.monitorConfig.warnings.inactivity.title',
    },
    lostfocus: {
      graceperiod: 'config.flexConfig.monitorConfig.focusGracePeriod',
      message: 'config.flexConfig.monitorConfig.warnings.lostfocus.message',
      timer: 'config.flexConfig.monitorConfig.warnings.lostfocus.timer',
      title: 'config.flexConfig.monitorConfig.warnings.lostfocus.title',
    },
  }

  const [currentGracePeriod, setCurrentGracePeriod] = useState(graceperiod)
  const [currentTimer, setCurrentTimer] = useState(timer)
  const popToast = (msg: string) => toast(msg)

  return (
    <Flex>
      <Flex config={['column']} style={{ marginRight: 25 }}>
        <FormLabel
          id={label + 'GracePeriod'}
          value={`${label} grace period`}
          tooltip={{
            selector: 'configuration.Flex.Monitoring.' + name + 'graceperiod',
          }}
        >
          <Input
            type="rolled"
            value={String(currentGracePeriod)}
            handleChange={(e) => {
              if (!/\D/g.test(e.currentTarget.value)) {
                setCurrentGracePeriod(e.currentTarget.value)
                handleChange(
                  name,
                  {
                    graceperiod: Number(e.currentTarget.value),
                  },
                  keyMap[name].graceperiod,
                )
              }
            }}
          />
        </FormLabel>
        <FormLabel id={label} value={label}>
          <Flex>
            <FormLabel
              id={label + 'Title'}
              value={'Title'}
              tooltip={{
                selector: 'configuration.Flex.Monitoring.' + name + 'title',
              }}
            >
              <Input
                type="rolled"
                value={title}
                handleChange={(e) => {
                  handleChange(
                    name,
                    {
                      title: e.currentTarget.value,
                    },
                    keyMap[name].title,
                  )
                }}
              />
            </FormLabel>
            <FormLabel
              id={label + 'Timer'}
              value={'Timer'}
              tooltip={{
                selector: 'configuration.Flex.Monitoring.' + name + 'timer',
              }}
            >
              <Input
                type="rolled"
                value={currentTimer}
                handleChange={(e) => {
                  if (!/\D/g.test(e.currentTarget.value)) {
                    setCurrentTimer(e.currentTarget.value)
                    handleChange(
                      name,
                      {
                        graceperiod: Number(e.currentTarget.value),
                      },
                      keyMap[name].timer,
                    )
                  }
                }}
              />
            </FormLabel>
          </Flex>
          <Flex>
            <FormLabel
              id={label + 'Message'}
              value={'Message'}
              containerStyle={{ marginRight: 10 }}
              tooltip={{ selector: 'configuration.Flex.Monitoring.' + name + 'message' }}
            >
              <TextArea
                value={message}
                handleChange={(e: any) =>
                  handleChange(
                    name,
                    {
                      message: e.currentTarget.value,
                    },
                    keyMap[name].message,
                  )
                }
              />
            </FormLabel>
          </Flex>
        </FormLabel>
      </Flex>
    </Flex>
  )
}

export default Monitoring
