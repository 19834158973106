import React from 'react'
import errorPage from './../../../images/error.jpg'
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import styled from 'styled-components'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignContent: 'center',
      height: '100%',
    },
  }),
)

const Error = () => {
  const classes = useStyles()
  return (
    <div
      style={{
        backgroundImage: `url(${errorPage})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'repeat',
        width: '100vw',
        height: '100vh',
      }}
    >
      <div className={classes.root}>
        <StyledPaper elevation={3}>
          <span>An Error has occurred with your permissions.</span>
          <span>
            Please contact <b>TTD-Support@asurion.com</b> if problems persist.
          </span>
        </StyledPaper>
      </div>
    </div>
  )
}
const StyledPaper = styled(Paper)((props) => ({
  color: '#6B8068',
  margin: 'auto',
  padding: '1.5em',
  borderRadius: 2,
  width: '100vw',
  height: '25vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}))

export default Error
