import React, { FC, ReactElement } from 'react'
import { ISVGIconComponent } from '../../../../interfaces'
import {
  Assignments,
  Close,
  Configuration,
  Dev,
  Edit,
  Filter,
  Fusion,
  Reporting,
  Rules,
  Save,
  Trainings,
  UserRoles,
  Broadcast,
} from './icons'
import { useSelector } from 'react-redux'
import themes from '../../../../themes/schema'

export const SVGIconComponent: FC<ISVGIconComponent> = ({
  icon = 'trainings',
  onClickHandler = () => {},
  style,
}: ISVGIconComponent): ReactElement => {
  const {
    userSettings: { darkMode },
  } = useSelector((state: any) => state?.user)
  const customDisplayType = darkMode ? 'darkMode' : 'originalFlavor'
  const getStyle: Record<string, any> = {
    svgColor_darkMode: themes.darkMode.svg.color,
    svgColor_originalFlavor: themes.originalFlavor.svg.color,
  }
  const iconColor = getStyle[`svgColor_${customDisplayType}`]
  const iconToDisplay: Record<string, ReactElement> = {
    assignments: <Assignments colorConfig={iconColor} />,
    close: <Close />,
    configuration: <Configuration colorConfig={iconColor} />,
    dev: <Dev colorConfig={iconColor} />,
    fusion: <Fusion colorConfig={iconColor} />,
    edit: <Edit />,
    filter: <Filter />,
    reporting: <Reporting colorConfig={iconColor} />,
    rules: <Rules colorConfig={iconColor} />,
    trainings: <Trainings colorConfig={iconColor} />,
    userRoles: <UserRoles colorConfig={iconColor} />,
    save: <Save />,
    broadcast: <Broadcast colorConfig={iconColor} />,
  }
  return (
    <div onClick={onClickHandler} style={{ ...style, marginRight: 15, marginTop: 5 }}>
      {iconToDisplay[icon]}
    </div>
  )
}

export default SVGIconComponent
