import React from 'react'
import { Flex, FormLabel, IconComponent, Input } from '../../../common'
import { playSound } from '../../../../utils'

const Audio = ({
  name,
  ramp,
  tone,
  duration,
}: {
  name: string
  ramp: number
  tone: number
  duration: number
}) => {
  return (
    <Flex config={['column']} style={{ width: '50%' }}>
      <Flex config={['row', 'alignCenter']} style={{ marginRight: 15 }}>
        <FormLabel id={name} value={'Name'}>
          <Input type="rolled" value={name} disabled={true} />
        </FormLabel>
        <button
          style={{ marginTop: 10 }}
          className={'ttd-bare-button'}
          onClick={() => playSound({ ramp, tone, duration })}
        >
          <IconComponent icon="play_circle_outline" />
        </button>
      </Flex>
    </Flex>
  )
}

export default Audio
