import { Checkbox, IconComponent } from '../../index'
import { optionConfig, dateKeys, dateTimeKeys, statuses } from '../../../../constants'
import React from 'react'
import { format } from 'date-fns'
import { IRenderData } from '../../../../interfaces'
import { minutesInHour } from 'date-fns/esm/fp'

/**
 *
 *  BIG NOTE: Any changes to this file will require a regression of all buttons (XLSX downloads),
 *  pages, views, modals, etc. basically everything...
 *
 * **/

export const maskReportStatus = (data: string) => {
  const statuses: Record<string, string> = {
    created: 'Offered',
    started: 'In Progress',
    completed: 'Completed',
    timeout: 'Ignored',
    timelimitmet: 'Time Exceeded',
    ejected: 'Ejected',
    inactivity: 'Ignored Warning - Mouse/Keyboard',
    lostfocus: 'Ignored Warning - Navigated Away',
  }

  return statuses[data]
}

export const maskReportFilter = (data: string, header: string) => {
  const filterMask: Record<string, any> = {
    Reason: statuses[data],
    Priority: maskReportingPriority(data),
    Duration: `${data} min`,
    'Employee Status': data ? 'Active' : 'Inactive',
    Status: data === 'completed' ? 'Complete' : 'Incomplete',
    'Expected Duration': `${data} min`,
  }
  return filterMask[header]
}
function formatAMPM(hours: number, minutes: number) {
  var ampm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  var strTime = hours + ':' + (minutes < 10 ? '0' + minutes : minutes) + ' ' + ampm
  return strTime
}

export const maskDate = (data: string, type: string) => {
  if (data === '' || !type) return ''
  const date = data.includes('Z') ? data : `${data}Z`
  if (isNaN(new Date(date).getTime())) return ''
  const dateSplit = date.slice(0, 10)
  const timeSplit = date.slice(11, -5)
  const [hours, minutes] = timeSplit.split(':')
  const [year, month, day] = dateSplit.split('-')
  const dateString = `${month}/${day}/${year}`
  const time = formatAMPM(parseInt(hours), parseInt(minutes))
  const dateTime = `${dateString} ${time}`

  switch (type) {
    case 'date':
      return dateString
    case 'datetime':
      return dateTime
    default:
      return ''
  }
}

export const maskTimeSpent = (data: any, header: string) => {
  if (!data && !header) return ''
  let diff = data[header]

  if (header === 'Time Taken') {
    let start = new Date(data['Start Time']).valueOf()
    let end = new Date(data['End Time']).valueOf()
    diff = end - start
    if (!data['Start Time'] && !data['End Time']) {
      return data['Time Taken']
    }
  }
  const minutes = Math.floor(diff / 1000 / 60)
  let seconds: string | number = Math.floor(diff / 1000 - minutes * 60)
  seconds = seconds < 10 ? `0${seconds}` : seconds
  const timeSpent = `${minutes < 1 ? 0 : minutes} : ${seconds}`
  return `${timeSpent}`
}

const maskPercent = (data: any) => {
  if (isNaN(parseInt(data))) return '0.0%'
  if (!data || data === '0.0' || data === 0) return '0.0%'
  return `${(Number(data) * 100).toFixed(1)}%`
}

const maskReportingPercents = (data: string) => data && `${parseInt(data) > 0 ? data : '0.0'}%`
export const maskReportingPriority = (data: any) => {
  switch (data) {
    case '0' || 'Low':
      return 'Low'
    case '1' || 'Medium':
      return 'Medium'
    case '2' || 'High':
      return 'High'
    default:
      return 'High'
  }
}

export const renderData = ({
  header = '',
  data = {},
  handleRowClick,
  checkboxClick,
  checkMap,
  idType,
  customDisplayType = 'originalFlavor',
  getStyle,
}: Partial<IRenderData>) => {
  const value = data[header]
  const dateKeyType = dateKeys.includes(header)
    ? 'date'
    : dateTimeKeys.includes(header)
    ? 'datetime'
    : undefined

  const percentKeys = [
    'threshold',
    'taskQueueMaxPercent',
    'completionPercent',
    'acceptedPercent',
    'warningIgnoredPercent',
  ]
  const addPercentSymbolKeys = [
    '% time spent',
    'Complete %',
    'incomplete %',
    'Incomplete %',
    'Accepted %',
    'Ignore %',
    'Completed %',
    'Ejected %',
    'Ignored Warning%',
    'Ignored Warning %',
    'Warning Ignored %',
  ]
  const render: Record<string, any> = {
    [[
      'reportTitle',
      'autoAssignmentName',
      'trainingName',
      'groupName',
      'trainingRuleName',
      'name',
      'bundleName',
      'details',
    ].includes(header)
      ? header
      : 'trainingName']: (
      <button
        style={{ color: getStyle[`table_color_${customDisplayType}`] }}
        className={'ttd-bare-button'}
        onClick={() => {
          handleRowClick && handleRowClick(data)
        }}
      >
        {header === 'details' ? 'Details' : value}
      </button>
    ),
    isEnabled: value === '1' ? 'Active' : 'Inactive',
    isActive: value === '1' ? 'Active' : 'Inactive',
    trainingLink: (
      <a href={value} target="blank" title={value} style={{ display: 'flex' }}>
        <IconComponent
          icon="public"
          style={{
            cursor: 'pointer',
            color: '#8223D2',
          }}
        />
      </a>
    ),
    checkbox: (
      <Checkbox
        checked={checkMap && checkMap.has(data[idType.current])}
        value={data}
        onClick={() => checkboxClick(data)}
      />
    ),
    date: maskDate,
    'End Status': maskReportStatus(data[header]),
    hasAttachments: data[header] && <IconComponent icon={'attach_file'} />,
    priority: data.priority ? optionConfig.priority[data.priority]?.display : '',
    percent: maskPercent,
    percentTimeSpent: data.percentTimeSpent
      ? `${(data.percentTimeSpent * 100).toFixed(1)} %`
      : '0.0%',
    percentComplete: data.percentTimeSpent
      ? `${(data.percentTimeSpent * 100).toFixed(1)} %`
      : '0.0%',
    Priority: maskReportingPriority(data),
    Reason: statuses[data.Reason] ? statuses[data.Reason] : data.Reason,
    reportingPercent: maskReportingPercents,
    reportingStatus: maskReportStatus(data[header]),
    Status: data[header] === 'completed' ? 'Complete' : 'Incomplete',
    status: data.status || '',
    'Employee Status': data['Employee Status'] === '1' ? 'Active' : 'Inactive',
    supervisor: data.supervisor ? data.supervisor : '',
    // [['timeInTraining', 'timeSpent'].includes(header) ? header : 'timeInTraining']:
    //   data?.training &&
    //   data?.training?.timeInTraining &&
    //   Math.floor(Number(data.training.timeInTraining) / 60000),
    timeSpent: maskTimeSpent(data, header),
    'Time Taken': maskTimeSpent(data, header),
    Duration: `${data[header]} min`,
    'Expected Duration': `${data[header]} min`,
    trainingRuleEnabled: data && data?.dispatchConfig?.isEnabled === '1' ? 'Active' : 'Inactive',
  }

  if (dateKeyType) {
    return render.date(value || '', dateKeyType || '')
  } else if (percentKeys.includes(header)) {
    return render.percent(value)
  } else if (addPercentSymbolKeys.includes(header)) {
    return render.reportingPercent(value)
  } else {
    return render[header] || value
  }
}
