import { fetchRedisData, updateRedisData, removeRedisData } from './redis'
import { awesomeCoolRecursiveScanFunction } from '../../../actions/assignments'
import { API } from 'aws-amplify'
const getEmpIdByWorker = async (searchValue: string) => {
  const table = 'Workers'
  const params = {
    queryStringParameters: {
      table,
      action: 'scan',
    },
    body: {
      paginate: true,
      attributesToGet: ['full_name', 'empId', 'workerSid'],
      scan: {
        full_name: [searchValue[0].toUpperCase() + searchValue.substr(1)],
      },
    },
  }
  return await awesomeCoolRecursiveScanFunction({ params })
}
const getEmpGroupByDepartmentName = async (searchValue: string) => {
  const table = 'EmpGroups'
  const params = {
    queryStringParameters: {
      table,
      action: 'scan',
    },
    body: {
      paginate: true,
      attributesToGet: ['groupName', 'groupId'],
      scan: {
        groupName: [searchValue],
      },
    },
  }
  return await awesomeCoolRecursiveScanFunction({ params })
}

const getAppConfig = async () => {
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const params = {
      queryStringParameters: { table: 'AppConfig' },
    }
    return await API.get(apiName, apiPath, params)
  } catch (ex) {
    console.error('Error saving: ', ex)
    return
  }
}

const updateAppConfig = async (oldURL: string, newURL: string) => {
  console.log({ oldURL, newURL })
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const params = {
      queryStringParameters: { table: 'AppConfig', id: 'LIVE' },
      body: {
        set: {
          [`globalConfig.Aspect.API_BASE_URL`]: oldURL,
          [`globalConfig.Aspect.API_BACKUP_URL`]: newURL,
        },
      },
    }
    await API.put(apiName, apiPath, params)
    return await updateRedisData({ actionType: `updateConfigData` })
  } catch (ex) {
    console.error('Error saving: ', ex)
    return
  }
}

export {
  getAppConfig,
  fetchRedisData,
  getEmpIdByWorker,
  getEmpGroupByDepartmentName,
  updateRedisData,
  removeRedisData,
  updateAppConfig,
}
