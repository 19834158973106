import React, { ChangeEvent, CSSProperties, ReactNode, useState } from 'react'
import styled, { StyledComponent } from 'styled-components'
import '../../Forms/index'

interface ITextArea {
  handleChange: any
  type: string
  children: ReactNode[] | ReactNode
  style: CSSProperties
  className: string | undefined
  value: string
  placeholder: string | undefined
  link?: string
}

const TextArea = ({
  style,
  handleChange,
  value = '',
  placeholder = 'Redis Data',
}: Partial<ITextArea>) => {
  const [searchValue, setSearchValue] = useState<string>(value)
  return (
    <StyledInputContainer style={style}>
      <StyledTextArea
        value={searchValue}
        placeholder={placeholder}
        onChange={(e) => {
          handleChange(e)
          setSearchValue(e.currentTarget.value)
        }}
      />
    </StyledInputContainer>
  )
}

const StyledInputContainer: StyledComponent<'div', any, {}, never> = styled.div`
  border: 1px solid #aaafb4;
  padding: 4px 5px 4px 12px;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  overflow: auto;
  align-items: center;
  &hover {
    border-color: #4a0684;
  }
`
const StyledTextArea: StyledComponent<'textarea', any, {}, never> = styled.textarea`
  margin: 0;
  min-width: 100%;
  word-break: break-all;
  border: none;
  font-size: 0.85em;
  resize: none;
  height: 40px;
  color: ${(props) => props.theme.color};
  :focus {
    outline: none;
  }
  &hover {
  }
`

export default TextArea
