import { User } from '../../data/datatypes'

const initialState = {
  authenticated: false,
  user: new User(),
  adGroup: null,
  userSettings: {},
}

// state selectors
export const selectorToUser = (state) => state.user.user,
  selectorToSaveUserSettings = (state) => state.user.userSettings,
  selectorToUserAuthenticated = (state) => state.user.authenticated,
  selectorToUserDarkMode = ({
    user: {
      userSettings: { darkMode = false },
    },
  }) => darkMode

// action types
export const SIGN_IN = 'SIGN_IN',
  SET_AD_GROUP = 'SET_AD_GROUP',
  SAVE_USER_SETTINGS = 'SAVE_USER_SETTINGS',
  SAVE_DARK_MODE = 'SAVE_DARK_MODE'

// action creators
export const userAuthToUserSignIn = (payload) => ({ type: SIGN_IN, payload }),
  adGroupTokenToSetAdGroup = (payload) => ({ type: SET_AD_GROUP, payload }),
  valueToSaveUserSettingsAction = (payload) => ({
    type: SAVE_USER_SETTINGS,
    payload,
  }),
  valueToSaveDarkMode = (payload) => ({
    type: SAVE_DARK_MODE,
    payload,
  })

// reducer
function userReducer(state = initialState, { type = '', payload }) {
  switch (type) {
    case SIGN_IN:
      return { ...state, ...payload }
    case SET_AD_GROUP: {
      return { ...state, adGroup: payload }
    }
    case SAVE_USER_SETTINGS:
      return { ...state, userSettings: payload, updateUserSettingsReady: true }
    case SAVE_DARK_MODE:
      return {
        ...state,
        userSettings: { ...state.userSettings, darkMode: payload },
      }
    default:
      return state
  }
}

export default userReducer
