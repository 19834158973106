export const mapSelectionsByTraining = (callback: any) => {
  return (ids: Array<[string, string]>) => {
    const updatedWorkers = ids.reduce((acc: Map<string, string[]>, id: [string, string]) => {
      const [trainingId, empId] = id
      const updatedIds: Set<string> = new Set(acc.get(trainingId) || [])
      updatedIds.add(empId)
      return acc.set(trainingId, Array.from(updatedIds.values()))
    }, new Map())
    callback(updatedWorkers)
  }
}
