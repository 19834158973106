import React from 'react'
import { Audio } from '../../Forms'
import { Flex } from '../../../../common'

const AudioConfig = ({ config }: any) => {
  const keysFromConfig: string[] = Object.keys(config)
  return (
    <React.Fragment>
      <Flex config={['row', 'wrap']} style={{ maxWidth: 600, padding: 5 }}>
        {keysFromConfig.map((key: string) => (
          <Audio name={key} {...config[key]} key={key} />
        ))}
      </Flex>
    </React.Fragment>
  )
}

export default AudioConfig
