import React, {MouseEventHandler} from 'react'
import styled from 'styled-components'

type Props = {
  marginTop?: number
  children?: React.ReactNode
  onClick?: MouseEventHandler
}

const StyledButton = styled.button<Props>`
  height: 50px;
  margin-top: ${({marginTop}) => (marginTop ? marginTop : 0)};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.submitButton.backgroundColor};
  color: ${(props) => props.theme.submitButton.color};
  border-radius: 25px;
  border: none;
  cursor: pointer;
`
const SubmitButton = ({marginTop, children, onClick}: Props) => (
  <StyledButton type="submit" marginTop={marginTop} onClick={onClick}>
    {children}
  </StyledButton>
)

export default SubmitButton
