import React, { useEffect } from 'react'

import { Amplify, Auth } from 'aws-amplify'
import { Redirect, Route, useLocation } from 'react-router-dom'
import config from '../../config.js'
import { getEnvironment } from '../../utils/applicationEnvironment'
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'
import { redirectOnAuth } from './AuthHub'

export const adGroupLocalStorageKey = 'adGroups'
export const adGroupAuthenticatedValue = 'HQDOMAIN_MGMT-US-N-ORBIT-EDITOR'

Amplify.configure(config.Amplify)
console.log(`config:`, config.Amplify)

const SSO = () => {
  const location = useLocation()
  useEffect(() => {
    ;(async () => {
      try {
        await Auth.federatedSignIn({
          customProvider: config.Amplify.Auth.oauth.identityProvider,
          customState: location.search.split('=')[1],
        })
      } catch (ex) {
        console.error('SSO Error: ', ex)
      }
    })()
  }, [location.search])

  return <React.Fragment />
}

const poolData = {
  UserPoolId: config.Amplify.Auth.userPoolId,
  ClientId: config.Amplify.Auth.userPoolWebClientId,
}
const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
const cognitoUser = userPool.getCurrentUser()

if (cognitoUser != null) {
  cognitoUser.getSession((err, session) => {
    if (err) {
      console.log(err)
    } else if (!session.isValid()) {
      console.log('Invalid session.')
    } else {
      const idToken = session.getIdToken().payload['custom:groups'] || ''
      localStorage.setItem(adGroupLocalStorageKey, idToken)
    }
  })
} else {
  console.log('User not found.')
}

export default SSO
