import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import { Flex, Loading, Tab } from '../common'
import { IDropdownOption, IFilterDropdownOption } from '../common/Atoms/Dropdown'
import styled from 'styled-components'
import { flexUiHeaders } from '../../constants'
import Dispatch from './Dispatch'
import FlexUI from './FlexUI'
import DomainsTitles from './DomainsTitles'
import Exclusions from './Exclusions'
import UserRolesConfig from './UserRoles'
import { checkPermissions } from '../../utils'

const Configuration = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [headers, setHeaders] = useState<any[]>([])
  const tabList: IDropdownOption[] = [
    { value: 'dispatch', display: 'Dispatch', id: '0' },
    { value: 'exclusions', display: 'Exclusions', id: '3' },
    { value: 'flex', display: 'Flex', id: '2' },
    { value: 'domainsTitles', display: 'Domains / Titles', id: '1' },
    { value: 'addUser', display: 'User Roles', id: '4' },
  ].filter(({ value }) => checkPermissions(value, 'read'))
  const [selectedTab, setSelectedTab] = useState<string>(tabList[0].value)
  const popToast = (msg: any, type?: string) => {
    switch (type) {
      case 'success':
        return toast.success(msg)
      case 'warning':
        return toast.warning(msg)
      case 'error':
        return toast.error(msg)
      default:
        return toast(msg)
    }
  }

  useEffect(() => {
    if (selectedTab === 'dispatch') return
    const getHeadersForTab = (tab: string) => {
      const headersFor: Record<any, any> = { flex: flexUiHeaders }
      return headersFor[tab]
    }
    setHeaders(getHeadersForTab(selectedTab))
  }, [selectedTab])

  return loading ? (
    <Loading size={'md'} />
  ) : (
    <div>
      <ToastContainer position="bottom-right" />
      <Flex config={['alignCenter']} style={{ marginBottom: 25 }}>
        {tabList.map(({ value, display }: IDropdownOption) => {
          return (
            <Tab
              onTabSelect={setSelectedTab}
              key={value}
              display={display}
              value={value}
              isSelected={selectedTab === value}
            />
          )
        })}
      </Flex>

      <div style={{ height: '100%' }}>
        {selectedTab === 'dispatch' && <Dispatch popToast={popToast} />}
        {selectedTab === 'exclusions' && <Exclusions popToast={popToast} />}
        {selectedTab === 'domainsTitles' && <DomainsTitles popToast={popToast} />}
        {selectedTab === 'flex' && <FlexUI popToast={popToast} />}
        {selectedTab === 'addUser' && <UserRolesConfig />}
      </div>
    </div>
  )
}

export default Configuration
