import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  SyntheticEvent,
  useReducer,
  Dispatch,
  SetStateAction,
} from 'react'
import '@asurion/atomic-ui-library'
import '@asurion/sass-design-system/build/asurion/asurion.css'
import styled from 'styled-components'
import { handleInput } from '../../../../utils'
import {
  AssignmentsModal,
  BulkEditModal,
  Button,
  ButtonSet,
  IconComponent,
  Loading,
  Modal,
  SelectionBar,
  SVGIconComponent,
  Table,
  Input,
  FormLabel,
  Dropdown,
  Flex,
} from '../../index'
import { combinedDateTimeKeys, getFilters, statuses, defaultEnv } from '../../../../constants'
import FilterBar from '../../Molecules/FilterBar/FilterBar'
import SelectedDateFilters from './SelectedDateFilters'
import { IDropdownOption, IFilterDropdownOption } from '../../Atoms/Dropdown'
import { add, format, isAfter, isBefore, parseISO } from 'date-fns'
import {
  updateAssignments,
  del,
  getWorkersForGroup,
  createNewAutoAssignment,
  postBundle,
  updateAutoAssignmentTraining,
  updateBundle,
  updateConfig,
} from '../../../../actions'
import { RouteContext } from '../../../../context/RouteContext'
import { nestedMapSize, sort } from '../../../../utils'
import SideBar from '../../../Trainings/TrainingAssignmentModal/SideBar'
import { AssignTrainingContext } from '../../../../context/AssignTrainingContext'
import { saveAs } from 'file-saver'
import {
  IAssignmentsForUpdate,
  IDateFilter,
  IHeaders,
  IMappedAssignments,
  IEmitDateFilter,
  IWorker,
  IMenuOption,
} from '../../../../interfaces'
import BabyTable from '../../Molecules/BabyTable'
import { GroupsContext } from '../../../../context/GroupsContext'
import Pagination from '../../Molecules/Table/Pagination'
import XLSX from 'xlsx'
import { useSelector } from 'react-redux'
import {
  reportingInitialState,
  reportingReducer,
} from '../../../../reducers/TTDReducers/reportingReducer'

import { IHeader } from '../../../../interfaces'
import { maskDate, renderData, maskTimeSpent } from '../../Molecules/Table/renderData'
import themes from '../../../../themes/schema'
import { getFusionExpertsByEmpGroupId } from '../../../../actions/fusion'
import { getValueFromLocalStorage, setWithExpiry } from '../../../../hooks/UseLocalStorage'

const stateToUserReducer = ({ user = {} } = {}) => user

const FilterableTable = ({
  addToStaging,
  addWorkerToWorkerSelected,
  canDownload = true,
  clearChecks = false,
  close,
  data,
  displayFilter = true,
  displayEllipse = true,
  exclusions,
  handleRowClick,
  handleTrainingUpdate,
  headers,
  hideSelectionBar = false,
  isRealTime = false,
  fusion = false,
  fusionDispatch,
  loading = false,
  noExport = false,
  onUpdate,
  postDispatchRule,
  postEjectRule,
  removeRow,
  reportType = '',
  ruleType,
  setData,
  setPayload,
  type = { name: '', data: {} },
  useEditModal = false,
  workersSelected = [],
}: {
  type?: { name: 'bundle' | 'autoAssignment' | ''; data?: any }
  canDownload?: boolean
  displayFilter?: boolean
  bundle?: any
  close?: any
  data: any[]
  headers: any[]
  noExport?: boolean
  hideSelectionBar?: boolean
  isRealTime?: boolean
  loading?: boolean
  menuActions?: any
  payload?: any
  reportType?: string
  ruleType?: string
  truncated?: boolean
  fusion?: boolean
  fusionDispatch?: any
  useEditModal?: boolean
  width: string | number
  displayEllipse?: boolean
  workersSelected?: any[] | Map<any, any>
  postDispatchRule?: (rule: any) => void
  // eslint-disable-next-line no-unused-vars
  handleRowClick?: (e: any) => void
  // eslint-disable-next-line no-unused-vars
  removeRow?: (e: any) => void
  // eslint-disable-next-line no-unused-vars
  addToStaging?: (e: any) => void
  // eslint-disable-next-line no-unused-vars
  addWorkerToWorkerSelected?: (...e: any[]) => void
  // eslint-disable-next-line no-unused-vars
  onUpdate?: (e: string) => void
  postEjectRule?: (rule: any) => void
  handleTrainingUpdate?: (map: any) => void
  filterOut?: { key: string; data: any[] }
  filterIndex?: number
  // eslint-disable-next-line no-unused-vars
  setData?: Dispatch<SetStateAction<any[]>>
  setPayload?: any
  exclusions?: any
  clearChecks?: boolean
}) => {
  const [state, dispatch] = useReducer(reportingReducer, reportingInitialState)
  const [columnData, setColumnData] = useState({})
  const { user }: any = useSelector(stateToUserReducer)
  const {
    bundles,
    loadAutoAssignments,
    optimisticallyDeleteAssignment,
    optimisticallyUpdateAssignmentStatus,
  } = useContext(GroupsContext)
  const { selectedLink } = useContext(RouteContext)
  const selLinkPlus = ruleType ? `${selectedLink}:${ruleType}` : selectedLink
  const { resetAllChecks, selectedIds, shouldGetGroupsOnly } = useContext(AssignTrainingContext)

  const formatFilters = (headers: IHeaders[]) =>
    headers.map(({ value, display, order }) => ({
      value,
      display,
      id: String(order),
      checked: true,
    }))
  const formattedOptions = formatFilters(headers)

  const initialSortIndex = headers.findIndex((header) => header.sortable)
  const initialSortCol = initialSortIndex >= 0 ? headers[initialSortIndex].value : ''

  const [aspectFilterHasBeenApplied, setAspectFilterHasBeenApplied] = useState<boolean>(false)
  const [chunkSize, setChunkSize] = useState<IDropdownOption>({
    id: 'chunkSizes',
    value: '50',
    display: '50',
  })

  const fIndex: number = headers.findIndex((header) => header.value === 'checkbox') >= 0 ? 1 : 0
  const [autoAssignmentName, setAutoAssignmentName] = useState<string>()
  const [bundlesAsDropdownOptions, setBundlesAsDropdownOptions] = useState<IDropdownOption[]>([])
  const [consolidatedData, setConsolidatedData] = useState<typeof data[]>([])
  const [dateFilters, setDateFilters] = useState<IDateFilter>({})
  const [direction, setDirection] = useState<string>('asc')
  const [filters, setFilters] = useState<any>({})
  const [filteredData, setFilteredData] = useState<typeof data>([{}])
  const [filteredHeaders, setFilteredHeaders] = useState<typeof headers>(headers)
  const [filterOptions, setFilterOptions] = useState<IDropdownOption[]>(formattedOptions)
  const [keyToFilter, setKeyToFilter] = useState<any>(filterOptions[fIndex])
  const [modalType, setModalType] = useState<string>('')
  const [page, setPage] = useState<number>(0)
  const [searchedData, setSearchedData] = useState<typeof data>([{}])
  const [searchValue, setSearchValue] = useState('')
  const [selected, setSelected] = useState<string>(initialSortCol)
  const [selectedTrainingBundle, setSelectedTrainingBundle] = useState<IDropdownOption>()
  const [showDateFiltersSelected, setShowDateFiltersSelected] = useState(true)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showSideBar, setShowSideBar] = useState<boolean>(false)
  const [trackChecks, setTrackChecks] = useState<Map<number, any | IMappedAssignments>>(new Map())
  const [trainingBundleName, setTrainingBundleName] = useState<string>()
  const [bundleValid, setBundleValid] = useState<boolean>(false)

  const compare = (
    valueA: string = '',
    valueB: string = '',
    type: 'single' | 'multiple' = 'single',
  ) => {
    if (type === 'multiple') {
      return valueB.replace(' ', '').toLowerCase().includes(valueA.toLowerCase())
    }
    if (Array.isArray(valueA)) {
      return valueA.join().toLowerCase().includes(valueB.toLowerCase())
    } else {
      return valueA?.toLowerCase().includes(valueB?.toLocaleLowerCase())
    }
  }

  const multipleEmpIdFilter = (value: string, data: any[]) => {
    if (value?.length > 7 && /\d{0,}/g.test(value)) {
      const allEmpsToSearchFor = value.split(/\D/g).filter((empId) => empId)
      return data.filter((worker) => allEmpsToSearchFor?.some((empId) => worker.empId === empId))
    }
  }

  const chunkData = (data: any[], chunk: IDropdownOption) => {
    const arr: any[][] = []
    let step: any[] = []
    for (let i = 0; i < data.length; i++) {
      if (i > 0 && i % parseInt(chunk.value) === 0) {
        arr.push(step)
        step = []
      }
      if (i === data.length - 1) {
        step.push(data[i])
        arr.push(step)
      } else {
        step.push(data[i])
      }
    }
    return arr
  }
  useEffect(() => {
    if (addWorkerToWorkerSelected && trackChecks.size !== nestedMapSize(workersSelected)) {
      const checksToArray = Array.from(trackChecks.values())
      const isAssignmentObj = checksToArray[0] && 'assignmentId' in checksToArray[0]
      if (isAssignmentObj) {
        addWorkerToWorkerSelected(
          checksToArray.map(({ trainingId, empId }: any) => [trainingId, empId]),
        )
      } else {
        addWorkerToWorkerSelected(
          checksToArray.map((worker: any) => {
            return worker.empId
          }),
        )
      }
    }
  }, [trackChecks])

  useEffect(() => {
    ;(async () => {
      if (fusion && setPayload) {
        //  displayData(getFusionExpertsByEmpGroupId, setData)
        // @ts-ignore

        const empIdFromAspectTree = await getFusionExpertsByEmpGroupId(
          Array.from(selectedIds.values()),
        )
        setPayload(fusionDispatch, 'setAssignmentState', empIdFromAspectTree)
        setPayload(
          fusionDispatch,
          'setPayload',
          Array.from(trackChecks.values()).map((worker: any) => worker.workerSid),
        )
      }
    })()
  }, [selectedIds])

  useEffect(() => {
    if (fusion && setPayload) {
      // @ts-ignore
      setPayload(
        fusionDispatch,
        'setPayload',
        Array.from(trackChecks.values()).map((worker: any) => worker.workerSid),
      )
    }
  }, [trackChecks])

  useEffect(() => {
    if (fusion && clearChecks) {
      setTrackChecks(new Map())
    }
  }, [clearChecks])

  const mapDateFilters = (headers: IHeaders[]) => {
    return headers.reduce((acc: any, cur: any) => {
      if (!cur.value) return acc
      if (combinedDateTimeKeys.includes(cur.value)) {
        acc[cur.value] = { from: '', to: '' }
      }
      return acc
    }, {})
  }
  useMemo(() => {
    if (!data) return
    const formattedHeaders = formatFilters(headers)
    setFilterOptions(formattedHeaders)
    const tempFilters = getFilters(data, headers, filters)
    if (isRealTime) {
      const storedFilters = getValueFromLocalStorage('realTime-unchecked-filters', [])
      for (const filter of storedFilters) {
        const { id, checked, value } = filter
        tempFilters[id] = tempFilters[id]?.map((item: any) => {
          if (item.value === value) {
            return {
              ...item,
              checked,
            }
          } else {
            return item
          }
        })
      }
    }
    const mappedDateFilters = mapDateFilters(headers)
    if (data && data.length > 0) {
      const sortedData = selected ? sort(data, selected, direction) : data
      setDateFilters(mappedDateFilters)
      setKeyToFilter(formattedHeaders[fIndex])
      setFilters(tempFilters)
      setFilteredHeaders(headers)
      setFilteredData(sortedData)
      setSearchedData(sortedData)
    } else {
      setFilteredData([])
      setSearchedData([])
    }
    return { data }
  }, [data, headers])
  const getLongestLength = (arr: {}[], value: string) => {
    let longestLength: number = value.length
    arr.forEach((item: any) => {
      longestLength =
        item && item[value] && item[value].length > longestLength
          ? item[value].length
          : longestLength
    })
    return longestLength
  }
  const mapColumnData = () => {
    const shownValues = [...data].slice(0, Number(chunkSize.value))
    let columnsData = getValueFromLocalStorage(`columnsData-${selectedLink}`, {})
    if (Object.keys(columnsData).length === 0) {
      columnsData = headers.reduce((acc, cur) => {
        const key = cur.value
        if (key) {
          if (key === 'checkbox') return acc
          acc[key] = getLongestLength(shownValues, key) * 4
        }
        return acc
      }, {})
    }
    setColumnData(columnsData)
  }

  useEffect(() => {
    let allUncheckedFilters: any = []
    for (const filterKey in filters) {
      const unchecked = filters[filterKey]?.filter((checkbox: any) => !checkbox.checked)
      if (unchecked?.length) {
        // What is the point of this
        allUncheckedFilters.push(...unchecked)
      }
    }
    if (isRealTime) {
      // @ts-ignore
      setWithExpiry('realTime-unchecked-filters', allUncheckedFilters)
    }
    const unSetDateFilters = JSON.stringify(mapDateFilters(headers))
    const hasDateFilters = dateFilters ? JSON.stringify(dateFilters) !== unSetDateFilters : false
    let payload: any[][] = []
    let dataToChunk: any[] = []

    if (!allUncheckedFilters.length) {
      if (searchValue) {
        let bulkEmpIdCheck: any[] = multipleEmpIdFilter(searchValue, searchedData) || []
        dataToChunk = bulkEmpIdCheck.length
          ? bulkEmpIdCheck.filter((training) =>
              compare(training[keyToFilter.value], searchValue, 'multiple'),
            )
          : searchedData.filter(
              (training) => training && compare(training[keyToFilter.value], searchValue),
            )
      } else {
        dataToChunk = filteredData
      }
    }
    if (allUncheckedFilters.length) {
      if (searchValue) {
        let bulkEmpIdCheck: any[] = multipleEmpIdFilter(searchValue, searchedData) || []
        dataToChunk = filteredData?.filter(
          (training) =>
            !allUncheckedFilters.some((filter: any) => filter.value === training[filter.id]) &&
            (bulkEmpIdCheck.length
              ? bulkEmpIdCheck.some((empId) => compare(training[keyToFilter?.value], empId))
              : compare(training[keyToFilter?.value], searchValue)),
        )
      } else {
        dataToChunk = filteredData.filter(
          (training) =>
            !allUncheckedFilters.some((filter: any) => filter.value === training[filter.id]),
        )
      }
    }
    payload = hasDateFilters
      ? chunkData(applyDateFilters(dataToChunk), chunkSize)
      : chunkData(dataToChunk, chunkSize)
    if (!canDownload && reportType === 'Daily/Monthly') {
      dispatch({
        type: 'setConsolidatedData',
        payload,
      })
    }
    mapColumnData()
    return setConsolidatedData(payload)
  }, [
    chunkSize,
    dateFilters,
    filters,
    filteredData,
    keyToFilter,
    searchValue,
    searchedData,
    setConsolidatedData,
    setDateFilters,
  ])

  useEffect(() => {
    if (bundles && !bundles.length) return
    const mappedBundles: IDropdownOption[] =
      (bundles &&
        bundles.length > 0 &&
        bundles?.map((bundle: any) => ({
          value: bundle.bundleId,
          display: bundle.bundleName,
          id: bundle.bundleId,
        }))) ||
      []
    setBundlesAsDropdownOptions(mappedBundles)
  }, [bundles])

  const applyDateFilters = (data: any) => {
    const editedDateFilters: IDateFilter[] = Object.keys(dateFilters)
      .filter((filter) => {
        return Boolean(dateFilters[filter].from)
      })
      .map((filter) => ({ [filter]: dateFilters[filter] }))
    const filteredData = data.filter((item: any) => {
      let shouldDisplay = true
      for (let i = 0; i < editedDateFilters.length; i++) {
        const filter = editedDateFilters[i]
        const key = Object.keys(filter)[0]
        const dateToCompare = !parseISO(item[key]) ? parseISO(item[key]) : new Date(item[key])
        shouldDisplay =
          isBefore(parseISO(filter[key].from), dateToCompare) &&
          isBefore(dateToCompare, parseISO(filter[key].to))
        if (!shouldDisplay) {
          return false
        }
      }
      return shouldDisplay
    })
    return filteredData
  }

  const evaluateFilters = (
    { id, value }: { id: string; value: string },
    action: 'add' | 'remove',
  ) => {
    const updatedFilters = { ...filters }
    let shouldCheck: boolean
    if (id) {
      if (action === 'add') {
        if (!Array.isArray(updatedFilters[id])) {
          updatedFilters[id] = []
        }
        shouldCheck = true
      } else {
        shouldCheck = false
      }
      updatedFilters[id] = updatedFilters[id].map((filter: any) => {
        if (Array.isArray(value)) {
          if (JSON.stringify(filter.value.sort()) === JSON.stringify(value.sort())) {
            filter.checked = shouldCheck
          }
        } else if (filter.value === value) {
          filter.checked = shouldCheck
        }
        return filter
      })
      setFilters(updatedFilters)
    }
  }

  /**
   * if an endpoint of a range is selected, the other endpoint defaults to 2020/01/01 for "from" and formatted new Date for "to"
   * @param key = name of the column, type = "to" or "from", value = selected date
   */
  const emitDateFilter = ({ key, type, value }: IEmitDateFilter) => {
    if (dateFilters) {
      const otherRangeEndpoint = type === 'from' ? 'to' : 'from'
      const defaultToDate = isAfter(parseISO(value), new Date())
        ? format(add(parseISO(value), { days: 10 }), 'yyyy-MM-dd')
        : format(new Date(), 'yyyy-MM-dd')
      const defaultFromDate = '2021-01-01'
      const defaultValue = otherRangeEndpoint === 'from' ? defaultFromDate : defaultToDate
      const otherRangeEndpointValue = dateFilters[key][otherRangeEndpoint]
        ? dateFilters[key][otherRangeEndpoint]
        : defaultValue
      const updatedRange = {
        [otherRangeEndpoint]: otherRangeEndpointValue,
        [type]: value,
      }
      const updatedFilters = Object.assign({}, dateFilters, {
        [key]: updatedRange,
      })
      setShowDateFiltersSelected(true)
      setDateFilters(updatedFilters)
    }
  }

  const emitAction = async (action: string, data?: any[]) => {
    const actions: Record<string, any> = {
      removeGroup: (link: string, list: any[]) => {
        const { trainingGroupId } = list[0]
        const empGroupIds = consolidatedData[0]
          .filter((i: any) => !list.some((_i: any) => i.groupId === _i.groupId))
          .map(({ groupId }: any) => groupId)
        const removePayload = { trainingGroupId, empGroupIds }
        setTrackChecks(new Map())
        return onUpdate && onUpdate(JSON.stringify(removePayload))
      },
      removeExclusions: async (link: string, groupsToRemove: any[]) => {
        const tableToRemove = groupsToRemove[0].tableName
        const objMapCopy = JSON.parse(JSON.stringify(exclusions))
        const groupIdsToRemove = Array.from(new Set(groupsToRemove.map(({ groupId }) => groupId)))

        const updatedExclusions = objMapCopy[tableToRemove].filter((each: any) => {
          return !groupIdsToRemove.includes(each.groupId)
        })
        objMapCopy[tableToRemove] = updatedExclusions
        const reformattedExclusions = Object.keys(objMapCopy).reduce((acc: any[], curr: any) => {
          acc = [...acc, ...objMapCopy[curr]]
          return acc
        }, [])
        if (exclusions && setData) setData(reformattedExclusions)

        const cleanedGroups = reformattedExclusions.map((group: any) => {
          const { childGroupIds = [], groupId, groupName, groupTreeId, parentId, tableName } = group
          return {
            childGroupIds,
            groupId,
            groupName,
            groupTreeId,
            parentId,
            tableName,
          }
        })
        await updateConfig('config.exclusionConfig', cleanedGroups).then(() => {
          return onUpdate && onUpdate(selLinkPlus)
        })
      },
      delete: async (link: string, list: any[]) => {
        setTrackChecks(new Map())
        if (['newTraining', `assignments`].includes(link)) {
          const assignmentIds = list.map((assignment) => assignment.assignmentId)
          optimisticallyDeleteAssignment(assignmentIds)
        }
        await del(link, list).then(() => {
          return onUpdate && onUpdate(selLinkPlus)
        })
      },
      createBundle: () => {
        return onUpdate && onUpdate(selLinkPlus)
      },
      addToBundle: () => {
        return onUpdate && onUpdate(selLinkPlus)
      },
      createAutoAssignment: () => {
        return onUpdate && onUpdate('autoAssignments')
      },
      updateAutoAssignment: () => {
        return onUpdate && onUpdate(selLinkPlus)
      },
      [['update', 'reassign'].includes(action) ? action : 'update']: async (
        a: string,
        data: any,
        action: string,
      ) => {
        await updateAssignments(a, data, action)
        if (action === 'reassign') {
          optimisticallyUpdateAssignmentStatus(data)
        }
        setTrackChecks(new Map())
        return onUpdate && onUpdate(selLinkPlus)
      },
    }
    data = data?.length ? data : Array.from(trackChecks.values())
    await actions[action](selLinkPlus, data, action)
  }

  const prevAssignmentData = (assignmentId: string) => {
    return data.find((assignment) => assignment.assignmentId === assignmentId)
  }

  const reassignAssignments = (workersData: Map<string, string[]>): IAssignmentsForUpdate[] => {
    return Array.from(workersData)
      .map(([trainingId, empIds]) => {
        return empIds.map((empId) => {
          const {
            startDate,
            endDays,
            dueDays,
            trainingBundleId = '',
          } = prevAssignmentData(`${trainingId}:${empId}`)
          return {
            empId: [empId],
            trainingId,
            startDate,
            dueDays,
            endDays,
            ...(trainingBundleId !== '' && {
              trainingBundleId,
            }),
          }
        })
      })
      .flat()
  }

  const handleAction = async (action: string) => {
    if (action === 'addToBundle') {
      setShowModal(true)
      return setModalType('addToBundle')
    }
    if (action === 'bulkEditTrainings') {
      setShowModal(true)
      return setModalType('bulkEditTrainings')
    }
    if (action === 'createBundle') {
      setShowModal(true)
      return setModalType('createBundle')
    }
    if (action === 'createAutoAssignment') {
      setShowModal(true)
      return setModalType('createAutoAssignment')
    }
    if (!useEditModal || action === ('delete' || 'removeExclusion')) {
      return await emitAction(action)
    }

    if (action === 'reassign') {
      /* This processing is only done for assignments on assignments page, as they can be from multiple trainings*/
      /* For assignments on the trainings page toUpdate will be falsy, and emitAction will use values in trackChecks*/
      const toUpdate = reassignAssignments(workersSelected as Map<string, string[]>)
      return await emitAction(action, toUpdate)
    }

    if (action === 'update') {
      setModalType('assignment')
      setShowModal(true)
      return
    }

    return setShowModal(true)
  }

  const menuOptions: Record<string, IMenuOption[]> = {
    fusion: [{ display: 'Send to Voice', type: 'addToVoice', permission: 'fusion.edit' }],
    reporting: [{ display: 'Delete Saved Report', type: 'delete', permission: 'reporting.delete' }],
    [['trainings', 'singleBundle'].includes(selLinkPlus) ? selLinkPlus : 'trainings']: [
      { display: 'Delete Training', type: 'delete', permission: 'trainings.delete' },
      { display: 'Create Bundle', type: 'createBundle', permission: 'bundles.create' },
      { display: 'Add to Bundle', type: 'addToBundle', permission: 'bundles.create' },
      {
        display: 'Create Auto Assignment',
        type: 'createAutoAssignment',
        permission: 'autoassignments.create',
      },
      { display: 'Edit Trainings', type: 'bulkEditTrainings', permission: 'trainings.edit' },
    ],
    [['addTraining', 'assignments', 'newTraining'].includes(selLinkPlus)
      ? selLinkPlus
      : 'assignments']: [
      { display: 'Delete Assignment', type: 'delete', permission: 'assignments.delete' },
      { display: 'Modify Assignment', type: 'update', permission: 'assignments.edit' },
      { display: 'Reassign Training', type: 'reassign', permission: 'assignments.edit' },
    ],
    'rules:removeGroup': [
      { display: 'Remove Group', type: 'removeGroup', permission: 'rules.delete' },
    ],
    [['addTrainingRule', 'rules:dispatch', 'rules:eject', 'rules:trainingRules'].includes(
      selLinkPlus,
    )
      ? selLinkPlus
      : 'rules']: [{ display: 'Delete Rule', type: 'delete', permission: 'rules.delete' }],
  }

  const formatReportsToXLSX = (dataObj: any, headers: IHeader[]) => {
    let datum = dataObj
    if (reportType === 'Real Time') {
      datum = {}
      headers.forEach((header: IHeader) => {
        if (header.value === 'startedTraining') {
          datum[header.display] = maskDate(dataObj[header.value], 'datetime')
        } else {
          datum[header.display] = dataObj[header.value]
        }
      })
    }
    const formattedData: any = {}
    for (const key in datum) {
      if (
        ['Date', 'Status Date', 'Start Date', 'Due Date', 'End Date', 'dateHierarchy'].includes(key)
      ) {
        if ((!datum[key] && !datum[key].length) || !datum[key]) {
          formattedData[key] = datum[key]
        } else {
          formattedData[key] = format(new Date(datum[key]), 'MM/dd/yyyy')
        }
      } else if (
        ['Date/Time', 'Start Time', 'End Time'].includes(key) &&
        reportType !== 'Real Time'
      ) {
        if ((!datum[key] && !datum[key].length) || !datum[key]) {
          formattedData[key] = datum[key]
        } else {
          formattedData[key] = maskDate(datum[key], 'datetime')
        }
      } else if (
        [
          'Completed %',
          'Accepted %',
          'Warning Ignored %',
          'Ignored Warning %',
          'Ignored Warning%',
          '% time spent',
          'Incomplete %',
          'Ignore %',
          'Ejected %',
        ].includes(key)
      ) {
        if ((!datum[key] && !datum[key].length) || !datum[key]) {
          formattedData[key] = datum[key]
        } else {
          formattedData[key] =
            isNaN(parseInt(datum[key])) || datum[key] === 'NaN' ? '0.0%' : datum[key] + '%'
        }
      } else if (['completionPercent', 'acceptedPercent', 'warningIgnoredPercent'].includes(key)) {
        if ((!datum[key] && !datum[key].length) || !datum[key]) {
          formattedData[key] = datum[key]
        } else {
          formattedData[key] = `${(datum[key] * 100).toFixed(1)}%`
        }
      } else if (['Expected Duration', 'Duration'].includes(key)) {
        if ((!datum[key] && !datum[key].length) || !datum[key]) {
          formattedData[key] = datum[key]
        } else {
          formattedData[key] = datum[key] + 'min'
        }
      } else if (['Priority'].includes(key)) {
        if ((!datum[key] && !datum[key].length) || !datum[key]) {
          formattedData[key] = datum[key]
        } else {
          const priority = ['Low', 'Medium', 'High']
          formattedData[key] = priority[datum[key]]
        }
      } else if (key === 'Status') {
        formattedData[key] = datum[key] === 'completed' ? 'Completed' : 'Incomplete'
      } else if (['End Status', 'Reason'].includes(key)) {
        formattedData[key] = renderData({ header: key, data: datum, getStyle })
      } else if (key === 'Time Taken') {
        formattedData[key] = maskTimeSpent(datum, key)
      } else if (key === `Employee Status`) {
        formattedData[key] = parseInt(datum[key]) === 1 ? `Active` : `Inactive`
      } else {
        formattedData[key] = isNaN(Number(datum[key])) ? datum[key] : Number(datum[key])
      }
    }
    return formattedData
  }

  const getStyle: Record<string, any> = {
    table_color_originalFlavor: themes.originalFlavor.table.color,
    table_color_darkMode: themes.darkMode.table.color,
  }

  const formatTableToXLSX = (data: any, headers: Record<string, string>) => {
    const formattedData: any = {}
    for (const key of Object.keys(headers)) {
      if ('tags' === key) {
        formattedData[headers[key]] =
          typeof data[key] === 'object'
            ? data[key].join('').replace(/[\[\]]/g, '')
            : data[key].replace(/[\[\]]/g, '')
      } else if ('ejectRules' === key) {
        formattedData[headers[key]] = data[key] && data[key].length ? data[key].join(', ') : ''
      } else if (
        [
          'reportTitle',
          'autoAssignmentName',
          'trainingName',
          'groupName',
          'name',
          'bundleName',
          'details',
          'trainingLink',
        ].includes(key) &&
        data[key]
      ) {
        formattedData[headers[key]] = data && data[key]
      } else {
        formattedData[headers[key]] = renderData({ header: key, data, getStyle })
      }
    }
    return formattedData
  }

  const downloadReport = () => {
    const wb = XLSX.utils.book_new()
    wb.Props = {
      Title: `TTD Generated ${reportType ? reportType : selectedLink} Report`,
      Subject: reportType ? reportType : selectedLink,
      Author: `${user.familyName}, ${user.givenName}`,
      CreatedDate: new Date(),
    }
    wb.SheetNames.push('Sheet')
    let ws
    if (!reportType) {
      const headersAsKeys: Record<string, string> = {}
      headers.forEach((header: IHeader) =>
        header.value !== 'checkbox' ? (headersAsKeys[header.value] = header.display) : undefined,
      )
      ws = XLSX.utils.json_to_sheet(
        consolidatedData.flat(1).map((data: any) => formatTableToXLSX(data, headersAsKeys)),
        {
          // @ts-ignore
          cellDates: 'd',
        },
      )
    } else {
      ws = XLSX.utils.json_to_sheet(
        consolidatedData.flat(1).map((data: any) => formatReportsToXLSX(data, headers)),
        {
          // @ts-ignore
          cellDates: 'd',
        },
      )
    }
    wb.Sheets['Sheet'] = ws
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })

    function s2ab(s: any) {
      const buf = new ArrayBuffer(s.length) //convert s to arrayBuffer
      const view = new Uint8Array(buf) //create uint8array as viewer
      for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff //convert to octet
      return buf
    }

    saveAs(
      new Blob([s2ab(wbout)], { type: 'application/octet-stream' }),
      `${reportType ? reportType : selectedLink}-${new Date().toLocaleString()}.xlsx`,
    )
  }

  const applyAspectGroupFilter = async () => {
    setAspectFilterHasBeenApplied(true)
    try {
      const queryArray = Array.from(selectedIds.values())
      const workers = (await getWorkersForGroup(queryArray)) || []
      const workerEmpIdsOnly = workers.map((worker: IWorker) => worker.empId)
      setShowSideBar(false)
      const filterAssignmentsByWorker = data.filter((assignment: any) => {
        if (workerEmpIdsOnly.includes(assignment.empId)) {
          return assignment
        }
      })
      setConsolidatedData(chunkData(filterAssignmentsByWorker, chunkSize))
    } catch (e) {
      console.error(e)
    }
  }

  const handleBundleNameChange = (e: SyntheticEvent<HTMLInputElement>) => {
    setTrainingBundleName(e.currentTarget.value)
    if (e.currentTarget.value.trim() !== '') {
      setBundleValid(true)
    } else {
      setBundleValid(false)
    }
  }
  const handleAutoAssignmentNameChange = (e: SyntheticEvent<HTMLInputElement>) => {
    setAutoAssignmentName(e.currentTarget.value)
  }

  const handleAddTrainingToBundle = async () => {
    let bundleToUpdate
    let existingTrainingsForSelectedBundle
    if (bundles && selectedTrainingBundle && selectedTrainingBundle.value !== 'selectOne') {
      bundleToUpdate = bundles.find(
        (bundle: any) => bundle.bundleId === selectedTrainingBundle.value,
      )
      existingTrainingsForSelectedBundle = bundleToUpdate.trainingIds || []
    } else {
      bundleToUpdate = type.data
      existingTrainingsForSelectedBundle = type.data.trainingIds || []
    }
    const bundleObj: {
      bundleName: string
      trainingIds: string[]
      bundleId: string
    } = {
      bundleName: bundleToUpdate.bundleName,
      trainingIds:
        [
          ...existingTrainingsForSelectedBundle,
          ...Array.from(trackChecks.values()).map(
            ({ trainingId }: { trainingId: string }) => trainingId,
          ),
        ] || [],
      bundleId: bundleToUpdate.bundleId,
    }
    await updateBundle(bundleObj).then(() => {
      emitAction('addToBundle')
      handleTrainingUpdate && handleTrainingUpdate(trackChecks.values())
      setTrackChecks(new Map())
      setModalType('')
      setShowModal(false)
    })
  }

  const handleAddTrainingToAutoAssignment = async () => {
    const selected = Array.from(trackChecks.values())
    const newTrainingIds = selected.map((item) => item.trainingId)
    const {
      data: {
        autoAssignment: { assignmentRuleId },
        trainings,
      },
    } = type

    const trainingIds = trainings.map(({ trainingId }: { trainingId: string }) => trainingId)
    const updatedTrainingIds = [...new Set([...trainingIds, ...newTrainingIds])]
    await updateAutoAssignmentTraining(assignmentRuleId, updatedTrainingIds).then(async () => {
      await loadAutoAssignments()
      handleTrainingUpdate && handleTrainingUpdate([...Array.from(trackChecks.values())])
      setTrackChecks(new Map())
      setModalType('')
      emitAction('updateAutoAssignment')
      setShowModal(false)
    })
  }

  const handleCreateBundleSave = () => {
    const bundleObj: { bundleName: string; trainingIds: string[] } = {
      trainingIds:
        Array.from(trackChecks.values()).map(
          ({ trainingId }: { trainingId: string }) => trainingId,
        ) || [],
      bundleName: trainingBundleName || '',
    }
    postBundle(bundleObj)
    setShowModal(false)
    setTrackChecks(new Map())
    setModalType('')
    emitAction('createBundle')
  }

  const handleCreateAutoAssignmentSave = async () => {
    const autoAssignmentObj: {
      autoAssignmentName: string
      trainingIds: string[]
    } = {
      trainingIds:
        Array.from(trackChecks.values()).map(
          ({ trainingId }: { trainingId: string }) => trainingId,
        ) || [],
      autoAssignmentName: autoAssignmentName || '',
    }
    return await createNewAutoAssignment(autoAssignmentObj).then(async () => {
      setShowModal(false)
      setAutoAssignmentName('')
      setTrackChecks(new Map())
      setModalType('')
      await emitAction('createAutoAssignment')
    })
  }
  const handleCancel = () => {
    close && close(false)
    setShowModal(false)
    setTrackChecks(new Map())
    setModalType('')
    if (showModal && modalType === 'addToBundle') {
      setSelectedTrainingBundle({
        value: 'selectOne',
        display: 'Select One',
        id: 'selectOne',
      })
    }
  }

  const handleSort = (value: string, selectedDirection: string) => {
    setSelected(value)
    setDirection(selectedDirection)
    setFilteredData([...sort(filteredData, value, selectedDirection)])
    setPage(0)
  }
  const handleToggleAll = (shouldActivateAll: boolean, key?: any) => {
    const allRows = shouldActivateAll
      ? new Map(consolidatedData.flat().map((row) => [row[key], row]))
      : new Map()
    setTrackChecks(allRows)
  }

  return loading ? (
    <div>
      <Loading size={'md'} />
    </div>
  ) : (
    <Container>
      {canDownload && consolidatedData.length > 0 && !noExport && (
        <Button
          style={{ marginBottom: 30 }}
          handleClick={() => {
            downloadReport()
          }}
        >
          <IconComponent icon="get_app" />
          Export Table to XLSX
        </Button>
      )}

      {/* Filter Bar, Filter Icon, Clear Icon*/}
      <Flex>
        <FilterBar
          defaultDropdownValue={filterOptions[fIndex]}
          onFilterSelect={(e: IFilterDropdownOption, index: number) => {
            // This function handles column add/remove
            let updatedOptions: IFilterDropdownOption[] = []
            const toggleAll = index === -1
            if (toggleAll) {
              const isAllChecked =
                filterOptions.length ===
                filterOptions.filter((item: IFilterDropdownOption) => item.checked).length
              updatedOptions = filterOptions.map((item: IFilterDropdownOption) => {
                item.checked = !isAllChecked
                return item
              })
            } else {
              updatedOptions = [...filterOptions]
              updatedOptions[index].checked = e.checked
            }

            const updatedHeaders = headers.filter((header: any) =>
              updatedOptions.some(
                (filter: IFilterDropdownOption) => header.value === filter.value && filter.checked,
              ),
            )

            setFilterOptions(updatedOptions)
            setFilteredHeaders(updatedHeaders)
          }}
          options={filterOptions}
          searchValue={searchValue}
          onFilter={(e: any) => {
            // This function handles search input
            handleInput(e, setSearchValue)
          }}
          keyToFilter={keyToFilter}
          setKeyToFilter={(e: IDropdownOption) => {
            setKeyToFilter(e)
          }}
        />
        {selectedLink === 'assignments' && (
          <div>
            <button
              className={'ttd-bare-button'}
              onClick={() => setShowSideBar(true)}
              style={{
                cursor: 'pointer',
                marginTop: 10,
                marginLeft: 15,
                display: 'flex',
                alignItems: 'center',
                color: '#8223D2',
              }}
            >
              <SVGIconComponent icon={'filter'} />
              Filter
            </button>
            {showSideBar && (
              <Modal
                close={() => {
                  setShowSideBar(false)
                }}
              >
                <div style={{ height: 500, overflow: 'auto' }}>
                  <SideBar />
                </div>
                <ButtonSet
                  formValid={true}
                  labels={['Cancel', 'Apply']}
                  handleSave={applyAspectGroupFilter}
                  handleCancel={() => {
                    setShowSideBar(false)
                  }}
                />
              </Modal>
            )}
          </div>
        )}
        {aspectFilterHasBeenApplied && (
          <button
            className={'ttd-bare-button'}
            style={{
              alignItems: 'center',
              cursor: 'pointer',
              display: 'flex',
              marginLeft: 15,
              marginTop: 5,
            }}
            onClick={() => {
              setAspectFilterHasBeenApplied(false)
              resetAllChecks(true)
              setFilters(getFilters(data, headers))
              setConsolidatedData(data)
            }}
          >
            <IconComponent icon={'close'} />
            <div style={{ marginTop: 2 }}>Clear</div>
          </button>
        )}
      </Flex>

      {/* Selection Bar */}
      {!hideSelectionBar && trackChecks.size !== 0 && (
        <SelectionBar
          displayEllipse={displayEllipse}
          bundle={bundles}
          checkedSelections={trackChecks}
          setCheckedSelections={setTrackChecks}
          emitAction={handleAction}
          options={menuOptions[selLinkPlus]}
        />
      )}

      {/* Date Filter, Clear Date Filter */}
      {showDateFiltersSelected && (
        <SelectedDateFilters dateFilters={dateFilters} setDateFilters={setDateFilters} />
      )}

      {/* Table */}
      <TableWrapper className={'filterable-table-wrapper'}>
        <Table
          allSelected={
            consolidatedData.flat().length === trackChecks.size && trackChecks.size !== 1
          }
          selected={selected}
          handleSort={handleSort}
          addToStaging={addToStaging}
          checkbox={true}
          displayFilter={displayFilter}
          checkMap={trackChecks}
          checkTracker={handleToggleAll}
          reportType={reportType}
          emitFilters={(data, action) => {
            evaluateFilters(data, action)
          }}
          emitDateFilter={emitDateFilter}
          filters={filters}
          data={consolidatedData[page]}
          dateFilters={dateFilters}
          handleRowClick={handleRowClick}
          headers={filteredHeaders}
          setHeaders={setFilteredHeaders}
          loading={loading}
          onUpdate={onUpdate}
          postDispatchRule={postDispatchRule}
          postEjectRule={postEjectRule}
          removeRow={removeRow}
          ruleType={ruleType}
          selectRow={setTrackChecks}
          columnData={columnData}
          setColumnData={setColumnData}
        />
      </TableWrapper>
      <div
        style={{
          width: '100%',
          marginTop: 'auto',
          alignSelf: 'center',
        }}
      >
        <Pagination
          page={page}
          resultCount={consolidatedData.flat(1).length}
          chunkSize={chunkSize}
          setPage={setPage}
          setChunkSize={setChunkSize}
        />
      </div>

      {/* Modals */}
      {showModal && modalType === 'assignment' && (
        <AssignmentsModal
          headers={headers}
          handleOnCancel={handleCancel}
          onUpdate={onUpdate}
          setVisible={() => setShowModal(false)}
          data={Array.from(trackChecks.values())}
        />
      )}
      {showModal && modalType === 'bulkEditTrainings' && (
        <BulkEditModal handleCancel={handleCancel} data={Array.from(trackChecks.values())} />
      )}
      {showModal && modalType === 'createBundle' && (
        <Modal header="Edit Task Details" close={() => setShowModal(false)}>
          <div>
            <FormLabel id="trainingBundleInput" value="Bundle Name">
              <Input value={trainingBundleName} handleChange={handleBundleNameChange} />
            </FormLabel>
            <BabyTable
              headers={[{ display: 'Training Name', value: 'trainingName' }]}
              data={Array.from(trackChecks.values())}
            />
            <ButtonSet
              labels={['Cancel', 'Submit']}
              handleSave={handleCreateBundleSave}
              handleCancel={handleCancel}
              formValid={bundleValid}
            />
          </div>
        </Modal>
      )}
      {showModal && modalType === 'addToBundle' && (
        <Modal
          header="Add to Bundle"
          close={() => {
            setShowModal(false)
            handleCancel()
          }}
        >
          <div>
            <FormLabel id="trainingBundleInput" value="Bundle Name">
              <Dropdown
                options={bundlesAsDropdownOptions}
                selected={
                  selectedTrainingBundle || {
                    value: 'selectOne',
                    display: 'Select One',
                    id: 'selectOne',
                  }
                }
                emitDropdownOption={setSelectedTrainingBundle}
              />
            </FormLabel>
            <BabyTable
              headers={[{ display: 'Training Name', value: 'trainingName' }]}
              data={Array.from(trackChecks.values())}
            />
            <ButtonSet
              labels={['Cancel', 'Submit']}
              handleSave={handleAddTrainingToBundle}
              handleCancel={handleCancel}
              formValid={true}
            />
          </div>
        </Modal>
      )}
      {showModal && modalType === 'createAutoAssignment' && (
        <Modal header="Edit Assignment Details" close={() => setShowModal(false)}>
          <div>
            <FormLabel id="autoAssignmentInput" value="Auto Assignment Name">
              <Input value={autoAssignmentName} handleChange={handleAutoAssignmentNameChange} />
            </FormLabel>
            <BabyTable
              headers={[{ display: 'Training Name', value: 'trainingName' }]}
              data={Array.from(trackChecks.values())}
            />
            <ButtonSet
              labels={['Cancel', 'Submit']}
              handleSave={handleCreateAutoAssignmentSave}
              handleCancel={handleCancel}
              formValid={true}
            />
          </div>
        </Modal>
      )}

      {(type.name === 'bundle' || type.name === 'autoAssignment') && (
        <ButtonSet
          formValid={true}
          labels={['Cancel', 'Save']}
          handleSave={
            type.name === 'bundle' ? handleAddTrainingToBundle : handleAddTrainingToAutoAssignment
          }
          handleCancel={handleCancel}
        />
      )}
    </Container>
  )
}

export default FilterableTable

const TableWrapper = styled.div`
  width: 100%;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* overflow: scroll; */
`
