import styled from 'styled-components'
import { ToggleSwitchStyledProps, ToggleSwitchDivProps } from './interfaces'

export const ToggleSwitchStyled = styled.button<ToggleSwitchStyledProps>`
  width: 300px;
  background: ${(props) =>
    props.selectedStyle
      ? props.theme.toggleSwitch.on.backgroundColor
      : props.theme.toggleSwitch.off.backgroundColor};
  height: 37px;
  border-radius: 18.5px;
  position: relative;
  border: ${(props) => props.theme.toggleSwitch.border};
  display: flex;
  cursor: pointer;
`
export const ToggleSwitchDivStyled = styled.div<ToggleSwitchDivProps>`
  height: 35px;
  border-radius: 17.5px;
  width: 50%;
  transition: all 0.5s ease-in-out;
  color: ${(props) =>
    props.selectedStyle ? props.theme.toggleSwitch.on.color : props.theme.toggleSwitch.off.color};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  transform: translateX(0);
`
export const ToggleSwitchDivTop = styled.div<ToggleSwitchDivProps>`
  height: 35px;
  border-radius: 17.5px;
  width: 50%;
  transition: all 0.5s ease-in-out;
  background: ${(props) => props.theme.toggleSwitch.main};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 97;
  transform: ${({ selectedStyle }) => (selectedStyle ? 'translateX(100%)' : 'translateX(0)')};
`
