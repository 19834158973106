import React, { ChangeEvent } from 'react'
import { Dropdown, FormLabel } from '../../index'
import { IDropdownOption } from '../../Atoms/Dropdown'

const MaxDeliveryType = ({
  maxDispatchLimitType = { id: 'attempts', value: 'attempts', display: 'Attempts' },
  setMaxDispatchLimitType,
  maxDispatchLimitTypesOptions,
}: any) => {
  return (
    <FormLabel id="maxDeliveryType" value="Max Delivery Type">
      <Dropdown
        type="rolled"
        style={{ width: 170 }}
        options={maxDispatchLimitTypesOptions}
        selected={maxDispatchLimitType}
        emitDropdownOption={(e: IDropdownOption) => {
          setMaxDispatchLimitType(e)
        }}
      />
    </FormLabel>
  )
}
export default MaxDeliveryType
