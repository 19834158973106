import React, { useState, useReducer } from 'react'
import { Button, Flex, FormLabel, Input, TaskQueueLookup, Modal } from '../../common'
import { FusionModalContent } from '../styles'
import { fusionInitialState, fusionReducer } from '../../../reducers/TTDReducers/fusionReducer'
import { postFusionExpertsNumber } from '../../../actions/fusion'
import { checkPermissions } from '../../../utils'

export const ExpertNumber = ({ modalActions, modalName }: any) => {
  const [state, dispatch] = useReducer(fusionReducer, fusionInitialState)
  const [requestedExperts, setRequestedExperts] = useState<string>('0')
  const dispatcher = (dispatch: any, type: string, payload: any) => {
    return dispatch({ type, payload })
  }
  const [taskQueues, setTaskQueues] = useState<any[]>([])
  const { toggleModal } = modalActions
  const handleTaskQueueInput = (queue: any, action: string) => {
    if (action === 'remove') {
      const newTaskQueueList = taskQueues.filter(
        (item: any) => item.taskqueueSid !== queue.taskqueueSid,
      )
      setTaskQueues(newTaskQueueList)
      dispatcher(dispatch, 'setSelectedTaskQueues', newTaskQueueList)
    } else {
      setTaskQueues([...taskQueues, queue])
      dispatcher(dispatch, 'setSelectedTaskQueues', [...taskQueues, queue])
    }
  }
  const permission = checkPermissions('fusion', 'edit')
  return (
    <Modal
      close={() => toggleModal(modalName, false)}
      style={{ overflow: 'hidden!important' }}
      width={'80%'}
    >
      <FusionModalContent>
        <Flex config={['column']}>
          <Flex config={['row', 'justifyStart']}>
            <h5>Configure Experts</h5>
          </Flex>
          <Flex config={['row']}>
            <Flex config={['column']} style={{ margin: '0 1em 0 0' }}>
              <FormLabel id={'Number of Experts'} value={'Number of Experts'}>
                <Input
                  dataType="number"
                  type="rolled"
                  min={'1'}
                  max={'50'}
                  style={{ height: '2.65em', width: '90%', maxWidth: '100%' }}
                  handleChange={(e) => setRequestedExperts(e.target.value)}
                />
              </FormLabel>
            </Flex>
            <Flex config={['column']}>
              <FormLabel id={'Voice Task Queue'} value={'Voice Task Queue'}>
                <TaskQueueLookup selections={taskQueues} handleSelect={handleTaskQueueInput} />
              </FormLabel>
            </Flex>
          </Flex>
          <Flex config={['column']} style={{ height: '100%', justifyContent: 'center' }}>
            {permission && (
              <Flex config={['row', 'justifyEnd']}>
                <Button
                  displayType={'outline'}
                  size={'lg'}
                  handleClick={async () => {
                    await postFusionExpertsNumber(requestedExperts, taskQueues, 'Ejector')
                    toggleModal(modalName, false)
                  }}
                >
                  Add to Voice
                </Button>
                <Button
                  displayType={'flat'}
                  size={'lg'}
                  handleClick={async () => {
                    await postFusionExpertsNumber(requestedExperts, taskQueues, 'Dispatcher')
                    toggleModal(modalName, false)
                  }}
                >
                  Add to Messaging
                </Button>
              </Flex>
            )}
          </Flex>
        </Flex>
      </FusionModalContent>
    </Modal>
  )
}
export default ExpertNumber
