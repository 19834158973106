// @ts-ignore
import React, {
  ChangeEventHandler,
  CSSProperties,
  MouseEventHandler,
  ReactNode,
  useContext,
} from 'react'
import styled, { StyledComponent } from 'styled-components'
import { ClickHandler } from '../../../../interfaces/types'
import { Flex, Menu } from '../../index'
import { RouteContext } from '../../../../context/RouteContext'
import { useSelector } from 'react-redux'
import themes from '../../../../themes/schema'
import { checkPermissions } from '../../../../utils'
import { IMenuOption } from '../../../../interfaces'

interface ISelectionBar {
  checkedSelections: Map<any, any>
  // eslint-disable-next-line no-unused-vars
  setCheckedSelections: (e: Map<any, any>) => void
  options: IMenuOption[]
  bundle?: any
  displayEllipse?: boolean
  // eslint-disable-next-line no-unused-vars
  emitAction: (selection: any) => void
}

const SelectionBar = ({
  checkedSelections,
  setCheckedSelections,
  options,
  displayEllipse = true,
  bundle,
  emitAction,
}: ISelectionBar) => {
  const {
    userSettings: { darkMode },
  } = useSelector((state: any) => state?.user)
  const customDisplayType = darkMode ? 'darkMode' : 'originalFlavor'
  const getStyle: Record<string, any> = {
    selectionBar_color_darkMode: themes.darkMode.selectionBar.color,
    selectionBar_backgroundColor_darkMode: themes.darkMode.selectionBar.backgroundColor,
    secondColor_darkMode: themes.darkMode.secondColor,
    selectionBar_color_originalFlavor: themes.originalFlavor.selectionBar.color,
    selectionBar_backgroundColor_originalFlavor: themes.originalFlavor.selectionBar.backgroundColor,
    secondColor_originalFlavor: themes.originalFlavor.secondColor,
  }

  const { selectedLink } = useContext(RouteContext)
  const permittedOptions = options.filter((option: IMenuOption) => {
    const [path, requiredAction] = option.permission.split('.')
    return checkPermissions(path, requiredAction)
  })

  const language: Record<string, string> = {
    trainings: 'training',
    [['assignments', 'addTraining', 'newTraining'].includes(selectedLink)
      ? selectedLink
      : 'assignments']: 'expert',
    [['addTrainingRule', 'rules', 'removeGroup'].includes(selectedLink) ? selectedLink : 'rules']:
      'rule',
    [['singleBundle'].includes(selectedLink) ? selectedLink : 'trainings']: 'training',
    configuration: 'group',
  }
  const hasBundle = () => {
    for (const [key, value] of checkedSelections) {
      for (const bundleId of bundle) {
        if (bundleId.trainingIds && bundleId.trainingIds.includes(value.trainingId)) {
          return true
        }
      }
    }
    return false
  }

  return (
    <div>
      <div
        style={{
          width: '100%',
          height: '60px',
          background: getStyle[`selectionBar_backgroundColor_${customDisplayType}`],
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '20px',
        }}
      >
        <StyledMenuContainer style={{ display: 'flex', padding: '0px 22px' }}>
          <div>
            {checkedSelections.size} {language[selectedLink]}
            {checkedSelections.size > 1 && language[selectedLink] && <span>s</span>} selected
          </div>
          <div
            style={{
              color: getStyle[`secondColor_${customDisplayType}`],
              padding: '0px 20px',
              cursor: 'pointer',
            }}
            role="presentation"
            onClick={() => {
              setCheckedSelections(new Map())
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                return setCheckedSelections(new Map())
              }
            }}
          >
            Cancel
          </div>
        </StyledMenuContainer>
        <div style={{ display: 'flex' }}>
          <Menu
            options={permittedOptions}
            emitAction={emitAction}
            hasBundle={hasBundle()}
            displayEllipse={displayEllipse}
          />
        </div>
      </div>
    </div>
  )
}

export interface IMenuItem {
  children: ReactNode[] | ReactNode
  style: CSSProperties
  className: string | undefined
  handleChange: ChangeEventHandler<HTMLInputElement>
  value: string
  display: string
  placeholder?: string | undefined
  onMenuSelect: MouseEventHandler
}

export const StyledMenuContainer: StyledComponent<
  'div',
  { onClick: ClickHandler; value: string; display: string },
  any
> = styled.div`
  padding: 25px;

  &:hover {
    border-color: green;
  }

  &:active {
    border-color: #8223d2;
  }
`

export default SelectionBar
