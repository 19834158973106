import { Auth } from 'aws-amplify'
import configJSON from './config.json'
import { getEnvironment } from './utils/applicationEnvironment'
const env = getEnvironment()
const config = configJSON[env]

config.Amplify.API.endpoints[0].custom_header = async () => {
  const session = await Auth.currentSession()
  const idToken = session.getIdToken()
  // const { payload } = idToken
  // const adGroups = payload['custom:groups'] || []
  return { Authorization: `Bearer ${idToken.getJwtToken()}` }
}

export default config
