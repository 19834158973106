import React, { useState, useEffect, ChangeEvent, useContext } from 'react'
import { Flex } from '../../common'
import List from './List'
import { fetchAdminConfig, postAdminConfig } from '../../../actions'
import { API, Storage } from 'aws-amplify'

const DomainsTitles = ({ popToast }: { popToast: (msg: string) => void }) => {
  const api: any = API
  const [adminConfig, setAdminConfig] = useState<any>({})
  const [domains, setDomains] = useState<any>([])
  const [windowTitles, setWindowTitles] = useState<any>([])

  const addItem = (type: string, item: string) => {
    const updated = [...adminConfig.expertMonitoringConfig?.allowedFocus[type], item]
    postUpdates({ type, updated })
  }
  const deleteItem = (data: string[], type: string, item: string) => {
    const updated = data.filter((title: string) => title !== item)
    postUpdates({ type, updated })
  }
  const editItem = (data: string[], type: string, value: string, item: string) => {
    const updated = data.map((title) => (title === item ? value : title))
    postUpdates({ type, updated })
  }

  const postUpdates = async ({ type, updated }: { type: string; updated: string[] }) => {
    const set = {
      [`config.expertMonitoringConfig.allowedFocus.${type}`]: updated,
    }
    try {
      const response = await postAdminConfig({ set }) // update adminConfig
      getAdminConfig() // refetch updated adminConfig
      if (response.success) {
        popToast('Update Successful')
      }
    } catch (error) {
      console.log('Error updating admin config: ', error)
    }
  }

  const getAdminConfig = async () => {
    try {
      let configs = await fetchAdminConfig()
      const { domains, windowTitles } = configs && configs?.expertMonitoringConfig?.allowedFocus
      setDomains(
        domains.sort((a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase())),
      )
      setWindowTitles(
        windowTitles.sort((a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase())),
      )
      setAdminConfig(configs)
    } catch (err) {
      console.error('Error fetching adminConfig', err)
    }
  }
  useEffect(() => {
    getAdminConfig()
  }, [setAdminConfig])

  return (
    <Flex>
      <div
        style={{
          flex: '1',
          borderRight: '1px solid rgb(223, 223, 223)',
          paddingRight: '1rem',
        }}
      >
        <List
          data={domains}
          title={'Domains'}
          type={'domains'}
          addItem={addItem}
          editItem={editItem}
          deleteItem={deleteItem}
        />
      </div>
      <div
        style={{
          flex: '1',
          marginLeft: '1rem',
        }}
      >
        <List
          data={windowTitles}
          title={'Window Titles'}
          type={'windowTitles'}
          addItem={addItem}
          editItem={editItem}
          deleteItem={deleteItem}
        />
      </div>
    </Flex>
  )
}
export default DomainsTitles
