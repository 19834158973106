import React, { useEffect, useState, useContext } from 'react'
import { RouteContext } from '../../../context/RouteContext'
import useInterval from '../../../hooks/UseInterval'
import '@asurion/sass-design-system/build/asurion/asurion.css'
import '@asurion/atomic-ui-library'
import { ToastContainer, toast } from 'react-toastify'
import { AddTrainingModal, FormLabel, Flex, Input, Modal } from '../../common/index'
import { handleInput } from '../../../utils'
import UseDebounce from '../../../hooks/UseDebounce'
import { API } from 'aws-amplify'
import { IConfigProps } from '../../../interfaces'
import { checkPermissions } from '../../../utils'

import {
  del,
  updateBundle,
  mapAssignments,
  getTrainingBundlesById,
  batchGetAssignmentsByAssignmentId,
  getAssignmentsByBundleId,
  getUpdatedAssignmentsByBundleId,
} from '../../../actions'
import Sortable from '../../common/Molecules/Sortable'
import { bundleExpertsHeaders } from '../../../constants/headers'
import { Button } from '../../common'
import { IMappedAssignments, IMappedTraining } from '../../../interfaces'
import TrainingAssignmentModal from '../TrainingAssignmentModal'
import { arrayMove } from 'react-sortable-hoc'
import FilterableTable from '../../common/Organisms/FilterableTable'
import { GroupsContext } from '../../../context/GroupsContext'
import { getTrainings } from '../../../actions/trainings'

const TrainingBundles = () => {
  const {
    handleNavigation,
    selectedLinkProps,
    setSelectedLinkProps,
    setSelectedLink,
    setSelectedTab,
  } = useContext(RouteContext)
  const {
    loadBundles,
    setBundles,
    assignments,
    getAssignmentsForBundleContext,
    setAssignments,
    setTrainings,
    setLoading,
    setTrainingAspectData,
  } = useContext(GroupsContext)
  const bundle = selectedLinkProps

  const [allAssignments, setAllAssignments] = useState<IMappedAssignments[]>([])
  const [allTrainings, setAllTrainings] = useState<IMappedTraining[]>(bundle.trainings || [])
  const [bundleName, setBundleName] = useState<string>(bundle.bundleName || '')
  const [showAddTrainingModal, setShowAddTrainingModal] = useState<boolean>(false)
  const [showAssignmentModal, setShowAssignmentModal] = useState<boolean>(false)
  const [showBundleDelete, setShowBundleDelete] = useState<boolean>(false)
  const [assignmentType, setAssignmentType] = useState<string>('')
  const [loadingData, setLoadingData] = useState<boolean>(false)
  const debouncedValue = UseDebounce(bundleName, 750)
  const closeSingleBundle = () => {
    handleNavigation('bundles')
  }
  const mapAssignmentToTraining = (allTheAssignments: any) => {
    if (bundle.trainings && bundle.trainings.length > 0) {
      const currentBundlesTrainings = bundle.trainings.filter(Boolean)
      if (allTheAssignments.length > 0) {
        const flatAssignments = allTheAssignments.flat()
        if (flatAssignments.length > 0) {
          setAllAssignments(flatAssignments)
        }
      } else {
        setAllAssignments([])
      }
      setAllTrainings(currentBundlesTrainings)
    }
  }
  useEffect(() => {
    if (!bundleName) return
    ;(async () => {
      try {
        const apiName = 'ADMIN'
        const apiPath = '/admin'

        const bundlesParams: IConfigProps = {
          queryStringParameters: { table: 'TrainingBundles', id: bundle.bundleId },
          body: { set: { bundleName }, rdsSet: { bundleName } },
        }
        await API.put(apiName, apiPath, bundlesParams)
        popToast('Successfully Saved')
        return
      } catch (ex) {
        console.error('Error saving: ', ex)
        popToast('Error Saving.  See log')
        return
      }
    })()
  }, [debouncedValue])

  useEffect(() => {
    const filteredAssignments = getAssignmentsForBundleContext(bundle.bundleId)
    setAllAssignments(filteredAssignments)
  }, [bundle, assignments])

  useEffect(() => {
    ;(async () => {
      setLoadingData(true)
      const bundleAssignments = (await getUpdatedAssignmentsByBundleId(bundle.bundleId)) || []
      if (bundleAssignments.length && assignments.length) {
        const newBundleAssignments = bundleAssignments.map((item) => item.assignmentId)
        const deduplicateAssignments = assignments.filter(
          (item: IMappedAssignments) => !newBundleAssignments.includes(item.assignmentId),
        )
        setAssignments([...deduplicateAssignments, ...bundleAssignments])
      }
      setLoadingData(false)
    })()
  }, [])

  const popToast = (msg: string) => toast(msg)

  const callUpdateBundles = async (
    newTrainingIds: any[],
    isRemoved: boolean = false,
    trainingId?: string,
  ) => {
    try {
      const trainingIds = newTrainingIds.map((each: any) => each.trainingId)
      const bundleData = {
        bundleId: bundle.bundleId,
        bundleName,
        trainingIds,
      }
      const rdsSet = {
        isRemoved,
        bundleName,
        trainingIds,
        ...(trainingId && { trainingId }),
      }
      return await updateBundle(bundleData, isRemoved, trainingId).then(async () => {
        popToast('Successfully Saved')
        const updatedBundles = await loadBundles()
        setBundles(updatedBundles)
        const updatedBundle = updatedBundles.find(
          (_bundle: any) => _bundle.bundleId === bundle.bundleId,
        )
        const assignments = getAssignmentsForBundleContext(updatedBundle.bundleId)
        const ketchupData = assignments.filter((eachAssignment: IMappedAssignments) => {
          updatedBundle.trainings.some((_training: IMappedTraining) =>
            eachAssignment.assignmentId.includes(_training.trainingId),
          )
        })
        mapAssignmentToTraining(ketchupData)
        console.log('Ketchup Data', ketchupData)
        setSelectedLinkProps(updatedBundle)
        if (!updatedBundle?.trainings) {
          setAllTrainings([])
        } else {
          setAllTrainings(updatedBundle.trainings)
        }
        console.log('updatedBundles.trainings', updatedBundle)
      })
    } catch (err) {
      return console.error(err)
    }
  }

  const onSortEnd = async ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    const orderedTrainings = arrayMove(allTrainings, oldIndex, newIndex)
    setAllTrainings(() => orderedTrainings)
    await callUpdateBundles(orderedTrainings)
    handleBundleAssignmentUpdate()
    return
  }

  const deleteRow = async (index: any) => {
    if (!permission) return
    const newStaging = [...allTrainings]
    const [{ trainingId }] = newStaging.splice(index, 1)
    await callUpdateBundles(newStaging, true, trainingId)
    console.log('Removed Training ID ', trainingId)
    popToast('Successfully Saved')
    return
  }

  const handleBundleAssignmentUpdate = () => {
    if (showAssignmentModal) {
      setShowAssignmentModal(false)
    }
    const allAssignments = getAssignmentsForBundleContext(bundle.bundleId)
    setAllAssignments(allAssignments)
    popToast('Successfully Saved')
    return
  }

  const handleBundleTrainingUpdate = async (map: any) => {
    let bundlesToDisplay: any = []
    if (map) {
      for (const item of map) {
        bundlesToDisplay.push(item)
      }
    } else {
      bundlesToDisplay = await getTrainingBundlesById(bundle.bundleId)
    }
    setAllTrainings([...allTrainings, ...bundlesToDisplay])
    setShowAddTrainingModal(false)
    const bundles = await getTrainingBundlesById(bundle.bundleId)
    setSelectedLinkProps(bundles)
    popToast('Successfully Saved')
    return
  }

  const handleDeleteBundle = async () => {
    closeSingleBundle()
    setShowBundleDelete(false)
    await del('trainingBundles', [bundle])
    popToast('Successfully Saved')
    setSelectedLinkProps('update')
    setSelectedLink('bundles')
    return
  }

  const handleAssignExperts = () => {
    setShowAssignmentModal(true)
  }

  const handleAddTraining = async () => {
    await getTrainings(setTrainings, setTrainingAspectData, setLoading)
    setShowAddTrainingModal(true)
  }
  const permission = checkPermissions('bundles', 'edit')

  return (
    <Flex config={['column']}>
      <button
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setSelectedTab('bundles')
          handleNavigation('bundles')
        }}
        className="ttd-text-link ttd-bare-button"
      >
        &lt; Back to Bundles
      </button>
      <ToastContainer position="bottom-right" />
      <Flex
        config={['column']}
        style={{
          padding: '15px 25px',
          borderBottom: '1px solid #a5aaaf',
        }}
      >
        <Flex>
          <FormLabel id="bundleName" value="Name of Bundle">
            <Input
              value={bundleName}
              type={'rolled'}
              handleChange={(e) => handleInput(e, setBundleName)}
            />
          </FormLabel>
        </Flex>
        <Flex>
          <Flex config={['column']} style={{ marginRight: 50 }}>
            <Flex style={{ marginBottom: 25 }} config={['column']}>
              <Sortable data={allTrainings} onSortEnd={onSortEnd} deleteRow={deleteRow} />
              {permission && (
                <Button displayType={'outline'} size={'sm'} handleClick={handleAddTraining}>
                  Add Training
                </Button>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Flex>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {permission && (
              <Button
                handleClick={() => {
                  setShowBundleDelete(true)
                }}
                displayType="ghost"
                size="lg"
                style={{ width: '100%' }}
              >
                Delete Bundle
              </Button>
            )}
          </div>
        </Flex>
      </Flex>
      <Flex style={{ margin: '25px 0px', alignItems: 'center' }}>
        <h5 style={{ marginRight: '15px' }}>Currently Assigned</h5>
        {permission && (
          <Button
            handleClick={() => {
              setAssignmentType('individual')
              handleAssignExperts()
            }}
            displayType="outline"
            size="sm"
            style={{ width: '100%' }}
          >
            Assign Individual Experts
          </Button>
        )}
        {permission && (
          <Button
            handleClick={() => {
              setAssignmentType('group')
              handleAssignExperts()
            }}
            displayType="outline"
            size="sm"
            style={{ width: '100%', marginLeft: 15 }}
          >
            Assign Group Experts
          </Button>
        )}
      </Flex>
      <Flex config={['column']}>
        <FilterableTable width={'100%'} data={allAssignments} headers={bundleExpertsHeaders} />
      </Flex>
      {showBundleDelete && (
        <Modal
          header="Confirm Delete"
          close={() => {
            setShowBundleDelete(false)
          }}
          height={'250'}
        >
          <Flex config={['column']} style={{ borderRadius: '16px', width: 'max-content' }}>
            Are you sure you want to delete this training bundle? This action cannot be undone.
            <Flex style={{ margin: '15px 0px 15px 0px' }}>
              <Button handleClick={handleDeleteBundle} style={{ marginRight: 15 }}>
                Delete Bundle
              </Button>
              <Button
                handleClick={() => {
                  setShowBundleDelete(false)
                }}
                displayType="outline"
                size="lg"
              >
                Cancel
              </Button>
            </Flex>
          </Flex>
        </Modal>
      )}
      {showAssignmentModal && (
        <Modal
          header="Assignments on Bundle"
          close={() => {
            setShowAssignmentModal(false)
          }}
        >
          <TrainingAssignmentModal
            ruleType={'assignments'}
            assignmentType={assignmentType}
            trainingId={allTrainings[0].trainingId}
            trainingIds={bundle.trainingIds}
            bundleId={bundle.bundleId}
            handleRefreshAssignment={async (assignmentIds) => {
              setShowAssignmentModal(false)
              const localAssignments = await batchGetAssignmentsByAssignmentId(assignmentIds)
              const deduplicateAssignments = []
              for (const _assignment of assignments) {
                const record = localAssignments.find(
                  (item) => item.assignmentId === _assignment.assignmentId,
                )
                if (!record) {
                  deduplicateAssignments.push(_assignment)
                }
              }
              setAssignments([...deduplicateAssignments, ...localAssignments])
              popToast('Successfully Saved')
            }}
            handleCancel={() => {
              setShowAssignmentModal(false)
            }}
            showsHeader={true}
            filterOut={{
              key: 'empId',
              data: allAssignments.map(({ empId }) => empId),
            }}
          />
        </Modal>
      )}
      {showAddTrainingModal && (
        <Modal
          width="90%"
          header="Assign Training Bundle"
          close={() => {
            setShowAddTrainingModal(false)
          }}
        >
          <AddTrainingModal
            loading={loadingData}
            onUpdate={handleBundleTrainingUpdate}
            bundle={selectedLinkProps}
            close={() => {
              setShowAddTrainingModal(false)
            }}
          />
        </Modal>
      )}
    </Flex>
  )
}
export default TrainingBundles
