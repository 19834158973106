import React, { createContext, useState, useEffect } from 'react'
import { API } from 'aws-amplify'

export const AdminConfigContext = createContext<any>([])
const AdminConfigProvider = ({ children }: { children: any }) => {
  const [adminConfig, setAdminConfig] = useState<any>({})
  const [tags, setTags] = useState()
  const apiName = 'ADMIN'
  const apiPath = '/admin'
  const options = {
    queryStringParameters: {
      table: 'AdminConfig',
      action: 'scan',
    },
    body: {
      scan: {
        hkey: 'admin-config',
      },
    },
  }

  const refreshContext = () =>
    API.get(apiName, apiPath, options)
      .then((res: any) => {
        let tagsArray = res[0].config.tags
        setAdminConfig({ ...res[0] })
        setTags(tagsArray)
      })
      .catch((ex: Error) => {
        console.error(ex)
        return 'Failure on getting Tags from DB'
      })

  useEffect(() => {
    ;(async () => await refreshContext())()
  }, [])

  return (
    <AdminConfigContext.Provider value={{ tags, adminConfig, refreshContext }}>
      {children}
    </AdminConfigContext.Provider>
  )
}

export default AdminConfigProvider
