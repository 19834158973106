import { API } from 'aws-amplify'

interface IRoleProps {
  value: string
  display: string
  id: string
}

export const queryAllRoles = async () => {
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const table = 'UserRoleDetails'
    const params = {
      queryStringParameters: { table, action: 'select' },
      body: {},
    }
    const {
      response: { rows },
    } = await API.post(apiName, apiPath, params)
    return rows
  } catch (ex) {
    console.error(ex)
  }
}

export const attachRoles = async (args: any) => {
  console.log('attach role args: ', args)
  const { selectedUsers, role } = args
  // Iterate through Array of users who should have a newly attached role
  return await selectedUsers.map(async (user: IRoleProps) => {
    try {
      const apiName = 'ADMIN'
      const apiPath = '/admin'
      const params = {
        queryStringParameters: {
          table: `Workers`, //table corresponds with the name of the Controller
          id: user.value, //ID is required
        },
        //TODO: Modify selectedUsers to account "forEach" User
        // set treats this as a put
        // set is for dynamo
        // rdsSet is for RDS
        body: { rdsSet: { userRoleNames: role } },
        //method is only if you're calling a stored procedure
      }
      //Using API.put because we are updating
      return await API.put(apiName, apiPath, params)
    } catch (e) {
      console.error(e)
      return e
    }
  })
  // Confirmation of success
  // Close the dialog within like 2 seconds, but not immediately because that looks like crap and is confusing.
  // Not related but ensure a role has been selected before allowing access to Attach Button.
}

export const getSingleUser = async (empId: string) => {
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const params = {
      queryStringParameters: {
        table: `Workers`,
        empId,
      },
      body: {},
    }
    return await API.post(apiName, apiPath, params)
  } catch (e) {
    console.error(e)
    return e
  }
}
export const getAssignedRole = async (empId: string) => {
  console.log(`file: roles.ts 💫 line 77 💫 getAssignedRole 💫 empId`, empId)
  try {
    const apiName = 'ADMIN'
    const apiPath = '/admin'
    const params = {
      queryStringParameters: {
        table: `UserRoleDetails`, //table corresponds with the name of the Controller
        action: `scan`,
      },
      body: {
        config: {
          empId,
          page: 0,
          pageSize: 50,
          method: `getAssignedRole`,
        },
      },
    }
    //Using API.post because we are not counting
    const roles = await API.post(apiName, apiPath, params)
    return roles ? roles : []
  } catch (e) {
    console.error(e)
    return e
  }
}
