export const onWorkerStatusUpdate = /* GraphQL */ `
  subscription onWorkerStatusUpdate($latestAction: String!) {
    onAddAEWorker(latestAction: $latestAction) {
      latestAction
      businessUnit
      department
      empId
      full_name
      operationsManager
      supervisorName
      taskStatus
      workerSid
    }
  }
`
